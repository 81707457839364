<template v-bind="$props">
  <v-dialog width="30%" v-model="scheduleWarningDialog">
    <v-card class="pa-3">
      <span class="text-h5"
        >Your schedule will end on <b>{{ formatDateTime(endDate) }}</b
        >. Please update it now.</span
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closescheduleWarningDialog()"
          >close
        </v-btn>
        <v-btn color="primary" text @click="navigateToUpdateSchdule()"
          >Update schedule
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { SET_PARAMS_VALUE } from "@/store/mutations";
import Vue from "vue";
import { formatDateTime } from "@/common/utility";
import { mapMutations } from "vuex";
export default Vue.extend({
  data() {
    return {
      scheduleWarningDialog: true,
    };
  },
  props: ["endDate"],
  methods: {
    ...mapMutations({
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    navigateToUpdateSchdule() {
      var paramsValue = {};
      paramsValue.isScheduleExpired = false;
      paramsValue.endDate = this.endDate;
      this.setParams(paramsValue);
      this.scheduleWarningDialog = false;
      this.$router.push({ path: "/home/update-schedule" });
    },
    openscheduleWarningDialog() {
      this.scheduleWarningDialog = true;
    },
    closescheduleWarningDialog() {
      this.scheduleWarningDialog = false;
    },
  },
});
</script>
