<template>
  <div v-bind="$props">
    <div
      v-if="
        codelyAssignment.submissions && codelyAssignment.submissions.length == 0
      "
    >
      <vue-friendly-iframe
        :style="iframeStyles"
        :src="codelyAssignment.codelyLink"
        allow="clipboard-read; clipboard-write"
      ></vue-friendly-iframe>
      <v-row
        style="position: absolute; bottom: -1.9rem; left: 0; right: 0"
        v-model="submitCodelyAssignmentValidation.valid"
      >
        <v-col cols="10">
          <v-text-field
            v-model="codelyAssignmentSubmission"
            outlined
            label="Answer Link"
            :rules="submitCodelyAssignmentValidation.nameRules"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn color="error" class="mt-2" @click="submitCodelyAssignment()"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div
      v-if="
        codelyAssignment.submissions && codelyAssignment.submissions.length != 0
      "
      class="srollContainer pa-6 pr-12"
    >
      <v-row>
        <v-col cols="12" class="pa-0 d-flex justify-end">
          <v-chip
            v-if="codelyAssignment.submissions[0].status == 'EVALUATED'"
            class="mr-3 font-weight-bold text-body-1"
            color="primary-chip"
            small
          >
            <v-avatar left>
              <v-img
                :src="require('@/assets/Coin.svg')"
                style="width: 1.2rem; height: 1.2rem"
                contain
              ></v-img>
            </v-avatar>
            {{ codelyAssignment.submissions[0].earnedCoins }}/{{
              codelyAssignment.coins
            }}
          </v-chip>
          <v-chip small class="secondary-chip">
            {{ codelyAssignment.submissions[0].status }}
          </v-chip>
        </v-col>
        <v-col cols="12" class="ma-3 pa-0">
          <span class="font-weight-medium text-h6 error--text"
            >Question Link:</span
          >
          <v-text-field
            v-model="codelyAssignment.codelyLink"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="copyToClipboard(codelyAssignment.codelyLink)"
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!showCodelyLink"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openLinkInIframe('codelyLink')"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="showCodelyLink = false"
                    color="primary"
                    >mdi-close</v-icon
                  >
                </template>
                <span>{{ showCodelyLink ? "Close" : "Open" }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <vue-friendly-iframe
            v-if="showCodelyLink"
            class="iframe"
            :src="codelyAssignment.codelyLink"
            allow="clipboard-read; clipboard-write"
          ></vue-friendly-iframe>
        </v-col>
        <v-col cols="12" class="ma-3 pa-0">
          <span class="font-weight-medium text-h6 error--text"
            >Solution Link:</span
          >
          <v-text-field
            v-model="codelyAssignment.solutionLink"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="copyToClipboard(codelyAssignment.solutionLink)"
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!showSolutionLink"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openLinkInIframe('solutionLink')"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="showSolutionLink = false"
                    color="primary"
                    >mdi-close</v-icon
                  >
                </template>
                <span>{{ showSolutionLink ? "Close" : "Open" }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <vue-friendly-iframe
            v-if="showSolutionLink"
            class="iframe"
            :src="codelyAssignment.solutionLink"
            allow="clipboard-read; clipboard-write"
          ></vue-friendly-iframe>
        </v-col>
        <v-col cols="12" class="ma-3 pa-0">
          <span class="font-weight-medium text-h6 error--text"
            >Submission Link:</span
          >
          <v-text-field
            v-model="codelyAssignment.submissions[0].submission"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="
                      copyToClipboard(
                        codelyAssignment.submissions[0].submission
                      )
                    "
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!showSubmissionLink"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openLinkInIframe('submissionLink')"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="showSubmissionLink = false"
                    color="primary"
                    >mdi-close</v-icon
                  >
                </template>
                <span>{{ showSubmissionLink ? "Close" : "Open" }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <vue-friendly-iframe
            v-if="showSubmissionLink"
            class="iframe"
            :src="codelyAssignment.submissions[0].submission"
            allow="clipboard-read; clipboard-write"
          ></vue-friendly-iframe>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>
<script>
import { BASE_URL, SUBMIT_CODELY_ASSIGNMENT } from "@/common/apiEndpoints";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
export default {
  data() {
    return {
      codelyAssignment: {},
      codelyAssignmentSubmission: "",

      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      showCodelyLink: false,
      showSolutionLink: false,
      showSubmissionLink: false,
      submitCodelyAssignmentValidation: {
        valid: false,
        nameRules: [
          (v) => !!v || "This is required",
          (v) => this.isValidURL(v) || "Please enter a valid link",
        ],
      },
    };
  },
  props: ["scaleFactor", "classOpId", "assignment"],
  watch: {
    assignment: {
      handler(assignment) {
        if (assignment) {
          this.codelyAssignment = assignment;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    iframeStyles() {
      return {
        width: `${100 / this.scaleFactor}%`,
        height: `${100 / this.scaleFactor}%`,
        transform: `scale(${this.scaleFactor})`,
        transformOrigin: "top left",
      };
    },
  },
  mounted: function () {
    this.codelyAssignment = this.assignment || this.paramsValue;
  },
  methods: {
    isValidURL(value) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // port and path
          "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // query string
          "(\\#[-a-zA-Z0-9_]*)?$",
        "i"
      );
      return !!pattern.test(value);
    },
    openLinkInIframe(urlType) {
      this.showCodelyLink = false;
      this.showSolutionLink = false;
      this.showSubmissionLink = false;
      switch (urlType) {
        case "codelyLink":
          this.showCodelyLink = true;
          break;
        case "solutionLink":
          this.showSolutionLink = true;
          break;
        case "submissionLink":
          this.showSubmissionLink = true;
          break;
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackbar("Link copied to clipboard", "success");
        },
        () => {
          this.showSnackbar("Failed to copy link", "error");
        }
      );
    },
    showSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type === "success" ? "green" : "red";
      this.snackbar = true;
    },
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    submitCodelyAssignment() {
      if (this.submitCodelyAssignmentValidation.valid) {
        const url = SUBMIT_CODELY_ASSIGNMENT;
        const payload = {};
        payload.assignmentNumber = this.codelyAssignment.assignmentNumber;
        payload.classOpId = this.classOpId;
        payload.submission = this.codelyAssignmentSubmission;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.$emit(
              "assignmentCompleted",
              this.classOpId,
              "codelyAssignment"
            );
            this.setApiSuccess("Assignment Submitted Successfully");
            this.codelyAssignmentSubmission = "";
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
<style scoped>
.srollContainer {
  height: 100%;
  overflow-y: auto;
}
</style>
