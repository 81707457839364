export const USER_TYPE_ADMIN = 'ADMIN';
export const USER_TYPE_CONTENT_MANAGER = 'CONTENT_MANAGER';
export const USER_TYPE_RESOURCE_MANAGER = 'RESOURCE_MANAGER';
export const USER_TYPE_SALES_MANAGER = 'SALES_MANAGER';
export const USER_TYPE_OPERATIONAL_MANAGER = 'OPERATIONAL_MANAGER';
export const USER_TYPE_STUDENT = 'STUDENT';
export const USER_TYPE_TEACHER = 'TEACHER';
export const USER_TYPE_INSTITUTE = 'INSTITUTE';
export const STATUS_PUBLISHED = 'PUBLISHED';
export const STATUS_REVIEW = 'IN_REVIEW';
export const STATUS_DRAFT = 'DRAFT';
export const CONTENT_STATUS_BLOCKED = 'BLOCKED';
export const CONTENT_STATUS_DRAFT = 'DRAFT';
export const COURSE_TYPE_PRIVATE = 'PRIVATE';
export const COURSE_TYPE_PUBLIC = 'PUBLIC';
export const CLASS_STATUS_CREATED = 'CREATED';
export const CLASS_STATUS_STARTED = 'STARTED';
export const CLASS_STATUS_COMPLETED = 'COMPLETED';
export const CLASS_STATUS_RESCHEDULED = 'RESCHEDULED';
export const CLASS_STATUS_CANCELED = 'CANCELLED';
export const ORDER_STATUS_CANCELLED = 'CANCELLED';
export const ORDER_STATUS_PAYMENT_DONE = 'PAYMENT_DONE';
export const ORDER_STATUS_PAYMENT_PENDING = 'PAYMENT_PENDING';
export const ORDER_STATUS_SUCCESS = 'SUCCESS';
export const ORDER_STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CONTENT_TYPE_TEXT = 'TEXT';
export const CONTENT_TYPE_QUIZ = 'QUIZ';
export const CONTENT_TYPE_CODELY = 'BLOCKLY';
export const CONTENT_TYPE_MEDIA = 'MEDIA';
export const COMP_STATE_LIVE = 'LIVE';
export const COMP_STATE_UPCOMING = 'UPCOMING';
export const FRANCHISE_MODEL = "FRANCHISE";
export const NON_FRANCHISE_MODEL = "NON_FRANCHISE";
export const SMAPLE_FILE_DOWNLOAD_PATH = process.env.VUE_APP_SAMPLE_FILE_DOWNLOAD_PATH;
export const grades = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];