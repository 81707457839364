<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)"
          >mdi-arrow-left</v-icon
        >
        Competition Analytics
      </v-toolbar-title>
    </v-toolbar>
    <v-card elevation="0">
      <v-row class="pa-1 ma-0 pb-0">
        <v-col cols="7" class="">
          <span style="font-weight: 500; font-size: 1.4rem">
            {{ compAnalysis.name }}
          </span>
        </v-col>
        <v-col cols="5" class="mt-2 d-flex justify-end">
          <span style="color: grey; font-size: 0.7rem; margin-right: 0.1rem">
            Start:
          </span>
          <span
            style="
              font-weight: 500;
              color: grey;
              font-size: 0.7rem;
              margin-right: 0.3rem;
            "
            class="mr-4"
          >
            {{ formatDateTime(compAnalysis.startDate) }}
          </span>
          <span style="color: grey; font-size: 0.7rem; margin-right: 0.1rem">
            Ends:
          </span>
          <span style="font-weight: 500; color: grey; font-size: 0.7rem">
            {{ formatDateTime(compAnalysis.endDate) }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mr-3 mt-0" justify="end">
        <v-col cols="2" class="ma-0 pa-1 d-flex justify-end">
          <v-btn
            label
            color="grey"
            small
            style="font-size: 0.5rem"
            class="mr-2"
            outlined
          >
            {{ compAnalysis.duration }} mins
          </v-btn>
          <v-btn label color="grey" small style="font-size: 0.5rem" outlined>
            {{ maxCoins }} Coins
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-1">
        <v-col cols="8">
          <v-card>
            <v-card-text style="font-weight: 500" class="pb-0"
              >Your Progress</v-card-text
            >
            <v-row class="ma-1">
              <v-col cols="6">
                <v-card height="10rem" elevation="3">
                  <v-row class="ma-1">
                    <v-col cols="8">
                      <span
                        style="
                          font-size: 0.9rem;
                          color: #0466a7;
                          font-weight: 500;
                        "
                        >SCORE</span
                      >
                      <br />
                      <span style="font-size: 1.6rem; color: #1aab28"
                        >{{ earnedCoins }}
                      </span>
                      <span style="font-size: 1.6rem">/ {{ maxCoins }} </span>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-center align-center">
                      <v-progress-circular
                        :rotate="-90"
                        :size="70"
                        :width="9"
                        :value="progressInPercentage"
                        color="#0466A7"
                        >{{ earnedCoins }}</v-progress-circular
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card height="10rem" elevation="3">
                  <v-row class="ma-1">
                    <v-col cols="8">
                      <span
                        style="
                          font-size: 0.9rem;
                          color: #0466a7;
                          font-weight: 500;
                        "
                        >RANK</span
                      >
                      <br />
                      <span style="font-size: 1.6rem"
                        >{{ currentUser.rank ?? userRank ?? "-" }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-center align-center">
                      <v-img
                        src="../assets/rank.png"
                        max-height="70"
                        max-width="70"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="ma-1">
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="rgba(109, 163, 255, 0.2)"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-icon color="rgba(109, 163, 255)"
                      >mdi-timer-outline</v-icon
                    >
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Time Consumed</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{ timeTaken }}
                    </strong>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="grey lighten-4"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-img src="../assets/accuracy.png"></v-img>
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Accuracy</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{
                        quizScore == 0
                          ? 0
                          : ((quizScore / attempted) * 100).toFixed(2)
                      }}%</strong
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="rgba(213, 153, 48, 0.2)"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-icon color="#D59930">mdi-format-list-checks</v-icon>
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Attempted</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{ attempted }}/{{ totalQuestions }}</strong
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="ma-1">
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="rgba(90, 173, 0, 0.2)"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-icon color="#5AAD00">mdi-check</v-icon>
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Corrected</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{ quizScore }}/{{ totalQuestions }}</strong
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="rgba(211, 61, 38, 0.2)"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-icon color="rgba(211, 61, 38)">mdi-window-close</v-icon>
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Wrong</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{ attempted - quizScore }}/{{ totalQuestions }}</strong
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="5rem" elevation="3" class="d-flex align-center">
                  <v-avatar
                    color="grey lighten-3"
                    :size="avatarSize"
                    class="mr-3 ml-2"
                  >
                    <v-icon color="#8D8F90" size="30">mdi-step-forward</v-icon>
                  </v-avatar>
                  <div class="text-left">
                    <span :style="{ fontSize: spanFontSize, color: 'grey' }"
                      >Skipped</span
                    >
                    <br />
                    <strong
                      :style="{ fontSize: strongFontSize }"
                      class="font-weight-medium"
                      >{{ totalQuestions - attempted }}/{{
                        totalQuestions
                      }}</strong
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-card-actions class="pt-0">
              <v-row class="ma-1 mb-2" justify="center">
                <v-col cols="4">
                  <v-btn color="primary" @click="viewDetails()"
                    >View Details</v-btn
                  >
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" @click="openCertificateDialog()"
                    >View Certificate</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-dialog v-model="certificateDialog" width="50%">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title style="font-weight: 500; font-size: 1.6rem"
                >Competition Certificate</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeCertificateDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="ma-1">
              <v-col class="ma-1">
                <span style="font-size: large">
                  You are awarded this certificate for successfully completeing
                  the
                  <span style="font-weight: 500">{{
                    certificateDetails.compName
                  }}</span>
                  competition.
                </span>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-col cols="4" class="ml-1 pl-0">
                <span style="font-size: large" class="mr-1">
                  No of Downloads
                </span>
                <span
                  style="font-size: large; font-weight: 500"
                  class="primary--text"
                  >{{ certificateDetails.downloads }}</span
                >
              </v-col>
              <v-col>
                <v-row justify="end">
                  <v-btn text color="primary" @click="verifyCertificate()"
                    >Verify Certificate</v-btn
                  >
                  <v-btn text color="primary" @click="downloadCertificate()"
                    >Download Certificate</v-btn
                  >
                </v-row>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <CertificateVerification
          v-if="certificateDialog"
          :certificateDetails="certificateDetails"
          @update="certificateDialog = false"
        />
        <v-col cols="4">
          <v-card>
            <v-simple-table class="ma-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <v-icon color="green">mdi-trophy</v-icon>
                    </th>
                    <th class="text-left">Leaderboard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in compRanks"
                    :key="item.name"
                    :class="{ 'black-row': item.userId === profile.userId }"
                  >
                    <td>
                      <v-avatar size="30" class="mr-2" v-if="item.profile">
                        <img :src="item.profile" alt="Profile Picture" />
                      </v-avatar>
                      <v-avatar v-else size="30" class="mr-2">
                        <img
                          src="../assets/Male Avatar.png"
                          alt="Profile Picture"
                        />
                      </v-avatar>
                      {{ item.name }}
                    </td>
                    <td>{{ item.rank }}</td>
                  </tr>
                  <tr v-if="currentUser.name" class="black-row">
                    <th class="text-left">
                      <v-avatar
                        size="30"
                        class="mr-2"
                        v-if="currentUser.profile"
                      >
                        <img :src="currentUser.profile" alt="Profile Picture" />
                      </v-avatar>
                      <v-avatar v-else size="30" class="mr-2">
                        <img
                          src="../assets/Male Avatar.png"
                          alt="Profile Picture"
                        />
                      </v-avatar>
                      {{ currentUser.name }}
                    </th>
                    <th class="text-left">{{ currentUser.rank }}</th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  GET_ALL_COMPETITIONS,
  QUIZ_COMPETITION_COMP_PROGRESS,
  BASE_URL,
  QUIZ_COMPETITION_COMP_PREVIOUS,
  QUIZ_COMPETITION_COMP_LEADERBOARD,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import {
  GET_TOKEN,
  GET_USER_TYPE,
  GET_PARAMS_VALUE,
  GET_USER_PROFILE,
} from "@/store/getters";
import { COMP_STATE_LIVE, COMP_STATE_UPCOMING } from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import CertificateVerification from "./CertificateVerification.vue";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
export default {
  data() {
    return {
      studentCompetitions: {},
      compLeaderboard: {},
      currentUser: { name: "", profile: "../assets/Male Avatar.png" },
      compAnalysis: {},
      certificateDialog: false,
      certificateDetails: {},
      userRank: null,
      coinValue: 70,
      timeTaken: "0",
      studentPreviousCompetitions: {},
      studentCompetitionsProgress: {},
      COMP_STATE_LIVE: COMP_STATE_LIVE,
      COMP_STATE_UPCOMING: COMP_STATE_UPCOMING,
      // analyseDialogue: false,
      quizComp: { quizes: [] },
      attempted: 0,
      // notAnswered: 0,
      quizContent: { quizQuestions: [] },
      quizID: "",
      quizScore: 0,
      earnedCoins: 0,
      maxCoins: 0,
      totalQuestions: 0,
      progressInPercentage: 0,
      compRanks: [],
    };
  },
  components: {
    CertificateVerification,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
    avatarSize() {
      if (this.$vuetify.breakpoint.xs) {
        return 24;
      } else if (this.$vuetify.breakpoint.sm) {
        return 32;
      } else if (this.$vuetify.breakpoint.md) {
        return 40;
      } else {
        return 48;
      }
    },
    spanFontSize() {
      if (this.$vuetify.breakpoint.xs) {
        return "0.2rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "0.4rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "0.7rem";
      } else {
        return "0.9rem";
      }
    },
    strongFontSize() {
      if (this.$vuetify.breakpoint.xs) {
        return "0.3rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "0.6rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "0.8rem";
      } else {
        return "1rem";
      }
    },
  },
  mounted: function () {
    this.getCompetitionAnalytics();
    // this.fetchCompetition();
    // this.fetchPreviousCompetition();
  },

  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    getCompetitionAnalytics() {
      this.compAnalysis = this.paramsValue.competition;
      this.getCompProgress(this.compAnalysis.quizCompId);
      this.getCompLeaderboard(this.compAnalysis.quizCompId);

      // for (let i = 0; i < this.compAnalysis.quizes.length; i++) {
      //   this.quizId = this.compAnalysis.quizes[i].quizId;
      //   this.quizContent = this.compAnalysis.quizes[i];
      // }
      if (this.compAnalysis.quizes.length > 0) {
        this.totalQuestions = this.compAnalysis.quizes[0].quizQuestions.length;
      }
      this.maxCoins = this.totalQuestions * this.compAnalysis.coinsPerQuestion;

      // this.earnedCoins = (this.compAnalysis.totalCoins + (this.quizScore*this.compAnalysis.coinsPerQuestion));

      // this.analyseDialogue = true;
    },
    // closeAnalyseDialog() {
    //   this.analyseDialogue = false;
    // },
    viewDetails() {
      var compParams = {};
      compParams.competition = this.compAnalysis;
      compParams.isFinished = true;
      this.setParams(compParams);
      this.$router.push({
        name: "QuizCompetitionDetails",
      });
    },
    openCertificateDialog() {
      this.searchCertificate();
      this.certificateDialog = true;
    },

    searchCertificate() {
      const url = SEARCH_CERTIFICATE;
      const payload = {};
      payload.compProgressId = this.quizComp.progressId;
      payload.type = "competition";
      return axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          if (res) {
            this.certificateDetails = res.data;
            this.certificateDialog = true;
          }
        })
        .catch((error) => {
          this.setApiError(
            "Certificate is not generated. Please contact Admin."
          );
          this.setOverlayValue(false);
        });
    },
    // verifyCertificate() {
    //   var url = BASE_URL + VERIFY_CERTIFICATE;
    //   url = url + "?secureKey=" + this.certificateDetails.certId;
    //   window.open(url);
    // },
    // downloadCertificate() {
    //   const url = DOWNLOAD_CERTIFICATE;
    //   const payload = {};
    //   payload.certId = this.certificateDetails.certId;
    //   axios
    //     .post(url, payload, {
    //       responseType: "arraybuffer",
    //       ...getAxiosOptions(BASE_URL, this.token),
    //     })
    //     .then((res) => {
    //       const blob = new Blob([res.data], { type: "application/pdf" });
    //       const pdfUrl = URL.createObjectURL(blob);
    //       this.closeCertificateDialog();
    //       window.open(pdfUrl, "_blank");
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    getTimeEndDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return `${days} days ${hours} hours ${minutes} mins`;
    },
    getTimeStartDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return `${days} days ${hours} hours ${minutes} mins`;
    },
    // No need to fetch all student competitions
    // fetchCompetition() {
    //   this.setOverlayValue(true);
    //   let url = GET_ALL_COMPETITIONS;
    //   axios
    //     .get(url, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.studentCompetitions = res.data.competitions;
    //       this.studentCompetitions.forEach((comp) => {
    //         comp.alreadyAttempted = false;
    //         this.getCompProgress(comp.quizCompId);
    //       });
    //       this.setOverlayValue(false);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    // Need to use when only Completed Competitions Screen Implemented
    // fetchPreviousCompetition() {
    //   this.setOverlayValue(true);
    //   let url = QUIZ_COMPETITION_COMP_PREVIOUS;
    //   axios
    //     .get(url, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.studentPreviousCompetitions = res.data.previousComps;

    //       this.setOverlayValue(false);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    //     getCompProgress(quizCompId) {
    //      const url = QUIZ_COMPETITION_COMP_PROGRESS;
    //       const payload = {};
    //      payload.competitionId = quizCompId;
    //   axios
    //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       if (quizCompId == res.data.competitionId) {
    //         this.studentCompetitions.forEach((comp) => {
    //           if (comp.quizCompId == res.data.competitionId) {
    //             comp.alreadyAttempted = true;
    //           }
    //         });
    //       }
    //       this.studentCompetitionsProgress = Object.assign(
    //         {},
    //         this.studentCompetitions
    //       );

    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    getCompProgress(quizCompId) {
      const url = QUIZ_COMPETITION_COMP_PROGRESS;
      const payload = { competitionId: quizCompId };
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizComp = res.data;
          if (res.data.earnedCoins != null) {
            this.earnedCoins = res.data.earnedCoins;
          }
          const quiz = res.data.quizes[0];
          this.attempted = 0;
          // this.notAnswered = 0;
          this.quizScore = 0;
          this.attempted = quiz.questions.length;
          quiz.questions.forEach((q) => {
            if (q.isCorrect) this.quizScore++;
          });
          //   for (let j = 0; j < this.quizComp.quizes.length; j++) {
          //     let question = this.quizComp.quizes[j].questions.find(
          //       (question) => question.questionNo === questionNo
          //     );
          //     if (question) {
          //       this.attempted += 1;
          //       if (question.isCorrect) {
          //         this.quizScore = this.quizScore + 1;
          //       }
          //     } else {
          //       this.notAnswered += 1;
          //     }
          // }
          this.earnedCoins =
            this.quizScore * this.compAnalysis.coinsPerQuestion;
          this.progressInPercentage = (this.earnedCoins / this.maxCoins) * 100;
          const startDateTime = new Date(quiz.startDateTime);
          const endDateTime = new Date(quiz.endDateTime);
          const timeTakenMs = endDateTime.getTime() - startDateTime.getTime();
          const timeTakenMinutes = Math.floor(timeTakenMs / (1000 * 60));
          const timeTakenInSecond = Math.floor((timeTakenMs / 1000) % 60);
          const duration = this.compAnalysis.duration * 60 * 1000;
          if (timeTakenMs > duration) {
            this.timeTaken = this.compAnalysis.duration + " mins";
          } else {
            this.timeTaken =
              timeTakenMinutes + " mins " + timeTakenInSecond + " sec";
          }
        })
        .catch((error) => {
          const errorMessage = error.errorMessage;
          this.setApiError(errorMessage);
          this.setOverlayValue(false);
          // throw new Error(errorMessage); // Ensure the error is propagated
        });
    },
    getCompLeaderboard(quizId) {
      const url = QUIZ_COMPETITION_COMP_LEADERBOARD;
      const payload = { compId: quizId };
      return axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          const leaderboard = res.data.leaderboard;
          leaderboard.forEach((item, index) => {
            if (index < 10) {
              this.compRanks.push({
                name: `${item.firstName} ${item.lastName}`,
                profile: item.profilePic,
                rank: item.rank,
                userId: item.userId,
              });
              if (item.userId == this.profile.userId) {
                this.userRank = item.rank;
              }
            }
            if (index == 10) {
              this.currentUser.name = `${item.firstName} ${item.lastName}`;
              this.currentUser.profile = item.profilePic;
              this.currentUser.userId = item.userId;
              this.currentUser.rank = item.rank;
            }
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.errorMessage || error.message;
          this.setOverlayValue(false);
          throw new Error(errorMessage);
        });
    },
    getChipColor(state) {
      if (state == COMP_STATE_LIVE) {
        return "red";
      } else if (state == COMP_STATE_UPCOMING) {
        return "green";
      } else {
        return "blue";
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
};
</script>
<style>
.black-row {
  font-size: 1rem;
  font-weight: 500;
  background-color: #714eff !important;
  color: #714eff;
}
</style>
