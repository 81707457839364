<template>
  <div>
    <v-carousel
      v-model="currentIndex"
      hide-delimiters
      style="height: 100%; width: 100%;"
    >
      <v-carousel-item
      v-for="(image, index) in images"
      :key="index"
      :style="{
        'background-image': `url(${image.source})`,
        'background-size': 'contain',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'height': '100%',
        'width': '100%',
      }"
      >
      </v-carousel-item>
      <div class="number-container">
        <div class="numbering">{{ currentIndex + 1 }} / {{ images.length }}</div>
      </div>
    </v-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  props: ["images", "title"],
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.number-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.numbering {
  position: absolute;
  bottom: 0rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.12rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.9rem;

}
</style>
