<template>
  <div v-bind="$props">
    <v-col class="pa-5 srollContainer">
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span class="font-weight-medium text-h6 error--text">Question: </span>
        </v-col>
        <v-col class="pt-1 ma-1 pa-0">
          <span class="text-body-1" v-html="assignmentProblem.question"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span class="font-weight-medium text-h6 error--text">Solution: </span>
        </v-col>
        <v-col class="ma-1 pb-0 pa-0">
          <span class="text-body-1" v-html="assignmentProblem.answer"></span>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],

            ["link"],
          ],
        },
      },
      assignmentProblem: {},
    };
  },
  props: ["classOpId", "assignment"],
  watch: {
    assignment: {
      handler(assignment) {
        if (assignment) {
          this.assignmentProblem = assignment;
        }
      },
    },
  },
  mounted: function () {
    this.assignmentProblem = this.assignment;
  },
};
</script>
<style scoped>
.srollContainer {
  height: 100%;
  overflow-y: auto;
}
</style>
