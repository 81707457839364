import { render, staticRenderFns } from "./ClassScheduleTableView.vue?vue&type=template&id=4b9c4a74&scoped=true"
import script from "./ClassScheduleTableView.vue?vue&type=script&lang=js"
export * from "./ClassScheduleTableView.vue?vue&type=script&lang=js"
import style0 from "./ClassScheduleTableView.vue?vue&type=style&index=0&id=4b9c4a74&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9c4a74",
  null
  
)

export default component.exports