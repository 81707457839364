export function getAxiosOptions(baseUrl, token) {
  const options = {
    headers: {},
  };
  options.baseURL = baseUrl;
  if (typeof token == "object") {
    options.headers.Authorization = token.token;
  } else {
    options.headers.Authorization = token;
  }
  return options;
}
export function formatDate(date) {
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en", options);
}
export function formatDateTime(date) {
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleString("en", options);
}
export function formatDateTimeWithDay(date) {
  date = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleString("en", options);
}
export function formatTime(date) {
  date = new Date(date);
  const options = {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleTimeString("en", options);
}
export function formattedTime(date) {
  const time = new Date(date);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  let hours12 = hours % 12;
  hours12 = hours12 ? hours12 : 12; // If hour is 0, set it to 12
  return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
}

export function checkVisibility(currentRole) {
  const ROLES = ["ADMIN", "RESOURCE_MANAGER", "CONTENT_MANAGER", "TEACHER"];
}

export function formatDateNumeric(date) {
  var getYear = date.toLocaleString("default", { year: "numeric" });
  var getMonth = date.toLocaleString("default", { month: "2-digit" });
  var getDay = date.toLocaleString("default", { day: "2-digit" });
  return getYear + "-" + getMonth + "-" + getDay;
}
export function rightClickHandler(textToCopy,x,y){
var contextMenuObj={showMenu:true,xPosProp:x,yPosProp:y,copyText:textToCopy}
return contextMenuObj;
}


export function findDateFromDayAndTime(day, time) {
    // List of days of the week in order
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // Convert day to title case to match the daysOfWeek array
    const normalizedDay = day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
    
    // Check if the given day is valid
    if (!daysOfWeek.includes(normalizedDay)) {
        console.error('Invalid day of the week.');
        return null;
    }

    // Parse time string (assumes format HH:MM AM/PM)
    const [timeStr, period] = time.split(' ');
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    
    if (isNaN(hours) || isNaN(minutes) || (period !== 'AM' && period !== 'PM')) {
        console.error('Invalid time format.');
        return null;
    }

    // Convert 12-hour time to 24-hour time
    const adjustedHours = period === 'PM' && hours !== 12 ? hours + 12 : (period === 'AM' && hours === 12 ? 0 : hours);

    // Get the current date
    const now = new Date();
    
    // Get the index of the current day and target day
    const todayIndex = now.getDay();
    const targetIndex = daysOfWeek.indexOf(normalizedDay);
    
    // Calculate the difference in days
    const daysDifference = (targetIndex - todayIndex + 7) % 7;
    
    // Create the target date
    const targetDate = new Date(now);
    targetDate.setDate(now.getDate() + daysDifference);
    targetDate.setHours(adjustedHours, minutes, 0, 0);
    
    console.log(targetDate.toUTCString());
    return targetDate.toUTCString();
  }

export function formatQuestionTitle(questionNo,questionTitle,totalQuestions){
  if(questionTitle.substring(0,3) == '<p>'){
    if(totalQuestions){
      return '<p>'+questionNo+'/'+totalQuestions+' . '+questionTitle.substring(3);
    }else{
    return '<p>'+questionNo+' . '+questionTitle.substring(3);
    }
  }else{
    if(totalQuestions){
      return questionNo+'/'+totalQuestions+' . '+questionTitle;
    }else{
    return questionNo+' . '+questionTitle;
    }
  }
}

