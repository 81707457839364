<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Courses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchCourse"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" title="Filter">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="courseFilter"
              label="All"
              color="primary"
              value="ALL"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseStatusFilter"
              label="Draft"
              color="primary"
              value="DRAFT"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseStatusFilter"
              label="Published"
              color="primary"
              value="PUBLISHED"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Public"
              color="primary"
              value="PUBLIC"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Private"
              color="primary"
              value="PRIVATE"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseStatusFilter"
              label="Review"
              color="primary"
              value="IN_REVIEW"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <!-- <v-list-item>
            <v-btn color="primary" text @click="getAllCourses()"> Apply </v-btn>
          </v-list-item> -->
        </v-list>
      </v-menu>

      <v-dialog persistent v-model="dialog" width="1000" :retain-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="text-body-2">
            New Course
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary--text">New Course</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="createCourseValidation.valid">
                <v-row>
                  <v-col cols="7">
                    <span style="font-weight: bold">Course Name</span>
                    <v-text-field
                      v-model="editedCourse.name"
                      dense
                      outlined
                      :rules="createCourseValidation.coursenameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <span style="font-weight: bold">Select Course Type </span>
                    <v-radio-group
                      row
                      :disabled="editedCourse.status == STATUS_PUBLISHED"
                      class="ma-1 pa-1 ml-n3"
                      v-model="editedCourse.courseType"
                    >
                      <v-radio
                        label="Public"
                        color="primary"
                        value="PUBLIC"
                      ></v-radio>
                      <v-radio
                        label="Private"
                        color="primary"
                        value="PRIVATE"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="mt-n10">
                  <v-col cols="4">
                    <span style="font-weight: bold">Grades</span>
                    <v-combobox
                      v-model="editedCourse.grades"
                      :items="gradesArray"
                      label="Grades"
                      multiple
                      outlined
                      dense
                      :rules="createCourseValidation.multiValueRules"
                      deletable-chips
                      chips
                      item-text="desc"
                      item-value="id"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="selectAll()">
                          <v-list-item-action>
                            <v-icon>{{ icon() }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-combobox>
                  </v-col>
                  <!--  <v-col cols="3">
                      <v-row>
                    <v-col cols="6" class="mt-3 pa-2"
                  v-if="editedCourse.courseType == 'PRIVATE'">
                    <span class="primary--text text-h6">For UK:</span>
                  </v-col>
                  <v-col v-if="editedCourse.courseType == 'PRIVATE'" class="pa-0 ml-n8">
                    <v-switch 
                      v-model="editedCourse.isForUK"
                      @click="buyableFalse()"
                    ></v-switch>
                  </v-col>
                  </v-row>
                    </v-col>
                    -->
                  <v-col cols="5" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-checkbox
                      v-model="editedCourse.buyable"
                      class="ml-n3 ma-1 pa-2"
                      label="Do you want to make the course buyable?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-text-field
                      v-model="editedCourse.noOfClasses"
                      :rules="createCourseValidation.noOfClassesRules"
                      dense
                      class="ma-1 pa-1"
                      type="number"
                      label="No of Classes"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="
                      editedCourse.courseType == 'PRIVATE' &&
                      editedCourse.buyable
                    "
                  >
                    <v-text-field
                      v-model="editedCourse.price"
                      :rules="createCourseValidation.priceRules"
                      dense
                      class="ma-1 pa-1"
                      type="number"
                      label="Price"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n7">
                  <v-col cols="12">
                    <span style="font-weight: bold">Description</span>
                    <quill-editor
                      v-model="editedCourse.description"
                      @input="validateCourseDesc()"
                      style="height: 70%; margin-bottom: 3rem"
                      :options="editorOptions"
                    ></quill-editor>
                    <span class="error--text">{{ courseDescError }}</span>
                  </v-col>
                  <v-col cols="2" class="mt-3">
                    <span class="ma-2 primary--text text-h5">Chapters:</span>
                  </v-col>
                  <v-col cols="1" class="mt-2">
                    <v-text-field
                      v-model="chapterCount"
                      dense
                      @input="addChapter"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="chapter in editedCourse.chapters"
                    :key="chapter.chapterNo"
                  >
                    <span>{{ chapter.name }} </span>
                    <v-text-field
                      v-model="chapter.lessonCount"
                      dense
                      label="Lessons"
                      @input="addLesson(chapter)"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <span class="ml-2 mt-4 pa-0  primary--text text-h6">Select Course Type:</span> -->

                <!-- <v-row>
                  <v-col cols="3">
                    <span class="ml-2 ma-0 pa-0 primary--text text-h6"
                      >Select Course Type:</span
                    >
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      row
                      :disabled="editedCourse.status == STATUS_PUBLISHED"
                      class="ma-1 pa-1 ml-n6"
                      v-model="editedCourse.courseType"
                    >
                      <v-radio
                        label="Public"
                        color="primary"
                        value="PUBLIC"
                      ></v-radio>
                      <v-radio
                        label="Private"
                        color="primary"
                        value="PRIVATE"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="5">
                    <v-combobox
                      v-model="editedCourse.grades"
                      :items="gradesArray"
                      label="Grades"
                      multiple
                      outlined
                      dense
                      :rules="createCourseValidation.multiValueRules"
                      class="ml-n4"
                      deletable-chips
                      chips
                      item-text="desc"
                      item-value="id"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="selectAll()">
                          <v-list-item-action>
                            <v-icon>{{ icon() }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 pa-0"
                  v-if="editedCourse.courseType == 'PRIVATE'"
                >
                  <v-col cols="2" class="pt-0 ma-0">
                    <span class="primary--text text-h6">For UK:</span>
                  </v-col>
                  <v-col cols="1" class="ma-0 pt-1 ml-n16">
                    <v-switch
                      v-model="editedCourse.isForUK"
                      class="ma-0 pa-0"
                      @click="buyableFalse()"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-checkbox
                      v-model="editedCourse.buyable"
                      :disabled="editedCourse.isForUK == true"
                      class="ml-n3 ma-1 pa-2"
                      label="Do you want to make the course buyable?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-text-field
                      v-model="editedCourse.noOfClasses"
                      :rules="createCourseValidation.noOfClassesRules"
                      dense
                      class="ma-1 pa-1"
                      type="number"
                      label="No of Classes"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="
                      editedCourse.courseType == 'PRIVATE' &&
                      editedCourse.buyable
                    "
                  >
                    <v-text-field
                      v-model="editedCourse.price"
                      :rules="createCourseValidation.priceRules"
                      dense
                      class="ma-1 pa-1"
                      v-if="editedCourse.isForUK == false"
                      type="number"
                      label="Price"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="close()">Cancel </v-btn>
                  <v-btn color="primary" text @click="saveCourse()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card-text>
          <!-- </v-card>
            </v-tab-item>
          </v-tabs> -->
        </v-card>
      </v-dialog>
      <!-- <v-dialog persistent v-model="reviewConfirmDialog" max-width="35rem">
        <v-card>
          <v-card-title
            >Are you sure you want to review
            {{ editedCourse.name }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeReviewDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="reviewCourse()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <ConfirmDialog
        :dialog="reviewConfirmDialog"
        :subMessage1="'Are you sure you want to review'"
        :subMessage2="editedCourse.name"
        @close="closeReviewDailog()"
        @confirm="reviewCourse()"
      />
      <ConfirmDialog
        :dialog="resetCourseReviewConfirmDialog"
        :subMessage1="'Are you sure you want to reset review of'"
        :subMessage2="editedCourse.name"
        @close="closeResetCourseReviewConfirmDialog()"
        @confirm="resetCourse()"
      />
      <ConfirmDialog
        :dialog="publishConfirmDialog"
        :subMessage1="'Are you sure you want to publish'"
        :subMessage2="editedCourse.name"
        @close="closePublishDailog()"
        @confirm="publishCourse()"
      />
      <!-- <v-dialog persistent v-model="publishConfirmDialog" max-width="35rem">
        <v-card>
          <v-card-title
            >Are you sure you want to publish
            {{ editedCourse.name }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closePublishDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="publishCourse()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-toolbar>
    <v-dialog v-model="imgDialog" width="450">
      <v-card>
        <v-img
          v-if="!selectedItem.preview && !selectedItem.thumbnail"
          class="align-start text-white"
          height="50"
          src="@/assets/Avatar_sales.png"
          contain
        ></v-img>
        <v-img
          v-if="!selectedItem.preview && selectedItem.thumbnail"
          :src="selectedItem.thumbnail"
        ></v-img>
        <v-img v-if="selectedItem.preview" :src="selectedItem.preview"></v-img>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <CustomDataTable
        :headers="headers"
        :items="filteredCourses"
        :search="searchText"
        :item-key="keyField"
      >
        <template v-slot:[`item.thumbnail`]="{ item }">
          <v-card flat>
            <v-badge icon bottom avatar overlap @click.native.stop>
              <template v-slot:badge>
                <v-icon @click="addThumbnail(item)">mdi-camera</v-icon>
                <input
                  :ref="'uploader-' + item.courseId"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  @change="onFileChanged($event, item)"
                />
              </template>
              <v-avatar
                v-if="!item.preview && !item.thumbnail"
                style="border: 0.1rem solid #714eff"
                size="50"
                @click="openImageDialog(item)"
              >
                <v-img
                  class="align-start text-white"
                  height="50"
                  src="@/assets/Avatar_sales.png"
                  contain
                >
                </v-img>
              </v-avatar>
              <v-avatar
                v-else-if="!item.preview && item.thumbnail"
                style="border: 0.1rem solid #714eff"
                size="50"
                @click="openImageDialog(item)"
              >
                <v-img :src="item.thumbnail"> </v-img>
              </v-avatar>
              <v-avatar
                style="border: 0.1rem solid #714eff"
                v-else
                size="70"
                @click="openImageDialog(item)"
              >
                <v-img :src="item.preview"> </v-img>
              </v-avatar>
            </v-badge>
            <v-card-actions v-if="item.showUploadBtns">
              <v-btn color="primary" x-small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="uploadThumbnail(item)"
                      >mdi-upload</v-icon
                    >
                  </template>
                  <span>Upload</span>
                </v-tooltip>
              </v-btn>
              <v-btn color="primary" x-small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error" @click="cancelUpload(item)"
                      >mdi-cancel</v-icon
                    >
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-row>
            <v-col>
              <span class="text-body-1 font-weight-medium">{{ item.name }}</span
              ><br />
              <span style="font-size: 0.8rem"
                >Created On: {{ formatDateTime(item.createdOn) }}</span
              >
            </v-col>
            <!-- <v-col cols="2" class="d-flex align-center justify-center">
              <v-img
                v-if="item.courseType == COURSE_TYPE_PRIVATE && item.buyable"
                src="../assets/premium-quality 1.svg"
                max-height="30"
                max-width="30"
              ></v-img>
              <v-img
                v-if="
                  item.courseType == COURSE_TYPE_PRIVATE &&
                  !item.buyable &&
                  !item.isForUK
                "
                src="../assets/school.png"
                max-height="30"
                max-width="30"
              ></v-img>
              <v-img
                v-if="
                  item.courseType == COURSE_TYPE_PRIVATE &&
                  !item.buyable &&
                  item.isForUK == true
                "
                src="../assets/alpha-u-circle-outline.svg"
                max-height="30"
                max-width="30"
              ></v-img>
            </v-col> -->
          </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-col justify="center">
            <v-row justify="center">
              <v-chip small :color="getStatusColor(item)"
                >{{ item.status }}
              </v-chip>
            </v-row>
            <v-row justify="center">
              <v-chip class="mt-1" small :color="getColorByType(item)"
                >{{ item.courseType }}
              </v-chip>
            </v-row>
          </v-col>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price ? item.price : "-" }}
        </template>
        <template v-slot:[`item.noOfClasses`]="{ item }">
          {{ item.noOfClasses ? item.noOfClasses : "-" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :key="1" @click="openCourseView(item)">
                <v-icon class="mr-2" color="success"> mdi-eye </v-icon>
                <span>View</span>
              </v-list-item>
              <v-list-item
                :key="2"
                v-if="item.status != STATUS_PUBLISHED"
                @click="editCourse(item)"
              >
                <v-icon class="mr-2" color="warning"> mdi-pencil </v-icon>
                <span>Edit</span>
              </v-list-item>
              <v-list-item :key="3" @click="openCopyCourseDialog(item)">
                <v-icon class="mr-2" color="blue"> mdi-content-copy </v-icon>
                <span>Copy</span>
              </v-list-item>
              <v-list-item
                :key="4"
                v-if="
                  item.status != STATUS_PUBLISHED &&
                  item.status != STATUS_REVIEW
                "
                @click="deleteCourseConfirmDialog(item)"
              >
                <v-icon
                  v-if="item.status != STATUS_PUBLISHED"
                  class="mr-2"
                  color="error"
                >
                  mdi-delete
                </v-icon>
                <span>Delete</span>
              </v-list-item>
              <v-list-item
                :key="5"
                v-if="
                  item.status == STATUS_DRAFT &&
                  item.courseType == COURSE_TYPE_PRIVATE
                "
                @click="ReviewCourseConfirm(item)"
              >
                <v-icon class="mr-2" color="primary"> mdi-file-eye </v-icon>
                <span>Review</span>
              </v-list-item>
              <v-list-item
                :key="6"
                v-if="item.status == STATUS_REVIEW"
                @click="resetCourseReview(item)"
              >
                <v-icon class="mr-2" color="red"> mdi-restart </v-icon>
                <span>Reset Review</span>
              </v-list-item>
              <v-list-item
                :key="7"
                v-if="
                  item.status == STATUS_REVIEW ||
                  item.courseType == COURSE_TYPE_PUBLIC
                "
                @click="publishCourseConfirm(item)"
              >
                <v-icon class="mr-2" color="accent"> mdi-file-upload </v-icon>
                <span>Publish</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </CustomDataTable>
    </v-container>
    <v-dialog persistent v-model="copyCourseDialog" max-width="30rem">
      <v-card>
        <v-card-title>Write the name of the New Course</v-card-title>
        <v-form ref="form" v-model="createCourseValidation.valid">
          <v-container>
            <v-col cols="8">
              <v-text-field
                v-model="editedCourse.courseName"
                :rules="createCourseValidation.nameRules"
                required
                label="Course Name"
              ></v-text-field>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeCopyCourseDialog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="copyCourse()">OK</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-dialog persistent v-model="deleteCourseDialog" max-width="35rem">
      <v-card>
        <v-card-title
          >Are you sure you want to delete "{{
            editedCourse.name
          }}"</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDeleteDailog()">Cancel</v-btn>
          <v-btn color="primary" text @click="deleteCourse()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <ConfirmDialog
      :dialog="deleteCourseDialog"
      :subMessage1="'Are you sure you want to delete'"
      :subMessage2="editedCourse.name"
      @close="closeDeleteDailog()"
      @confirm="deleteCourse()"
    />
    <EditCourse
      v-if="editCourseDailog"
      :course="editedCourse"
      @updateCourseDialog="closeEditCourseDailog"
    />
    <!-- <v-dialog persistent v-model="editCourseDailog" width="1000">
      <v-card>
        <v-card-title class="primary--text">Edit Course</v-card-title>
        <v-form ref="form" v-model="createCourseValidation.valid">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedCourse.name"
                  label="Course Name"
                  :rules="createCourseValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="editedCourse.grades"
                  :rules="createCourseValidation.multiValueRules"
                  :items="gradesArray"
                  label="Grades"
                  multiple
                  dense
                  deletable-chips
                  chips
                  item-text="desc"
                  item-value="id"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="selectAll">
                      <v-list-item-action>
                        <v-icon>{{ icon() }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Description</span>
                <quill-editor
                  v-model="editedCourse.description"
                  @input="validateCourseDesc()"
                  style="height: 140rem; margin-bottom: 30rem"
                  :options="editorOptions"
                ></quill-editor>
                <span class="error--text">{{ courseDescError }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-6">
              <v-col cols="3" v-if="editedCourse.courseType == 'PRIVATE'">
                <v-text-field
                  v-model="editedCourse.noOfClasses"
                  :rules="createCourseValidation.noOfClassesRules"
                  dense
                  type="number"
                  label="No of Classes"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="editedCourse.buyable">
                <v-text-field
                  v-model="editedCourse.price"
                  :rules="createCourseValidation.priceRules"
                  dense
                  type="number"
                  label="Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="mt-1">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close()">Cancel </v-btn>
              <v-btn color="primary" text @click="saveCourse()"> Save </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog> -->
    <!-- <v-expansion-panels multiple class="courseExp" v-model="panel">
      <v-expansion-panel
        class="mt-2"
        v-for="course in filteredCourses"
        :key="course.courseId"
        :ref="panelRef(course.courseId)"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon large color="#F55B49"> $expand </v-icon>
          </template>
          <CourseHeader
            v-bind:course="course"
            @onPublishCourse="onChange($event)"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Chapters
            v-bind:courseId="course.courseId"
            @updateCourse="onChange($event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  GET_ALL_COURSES,
  BASE_URL,
  CREATE_COURSE,
  PUBLISH_COURSE,
  RESET_REVIEW_COURSE,
  ADD_COURSE_THUMBNAIL,
  COURSE_DELETE,
  UPDATE_COURSE,
  COPY_COURSE,
  REVIEW_COURSE,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import Chapters from "@/components/CourseDetails.vue";
import {
  GET_TOKEN,
  GET_USER_TYPE,
  GET_COURSE_EXPANSION,
  GET_PARAMS_VALUE,
} from "@/store/getters";
import {
  COURSE_TYPE_PRIVATE,
  COURSE_TYPE_PUBLIC,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  grades,
  STATUS_REVIEW,
  STATUS_DRAFT,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { formatDateTime, getAxiosOptions } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COURSE_EXPANSION_PANEL,
  SET_PARAMS_VALUE,
  SET_API_ERROR,
} from "@/store/mutations";
import EditCourse from "./EditCourse.vue";
import CustomDataTable from "./CustomDataTable.vue";

export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      STATUS_REVIEW: STATUS_REVIEW,
      STATUS_DRAFT: STATUS_DRAFT,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      COURSE_TYPE_PUBLIC: COURSE_TYPE_PUBLIC,
      courses: [],
      courseStatusFilter: [],
      courseTypeFilter: [],
      courseFilter: [],
      menu: false,
      showSearchField: false,
      searchText: "",
      keyField: "courseId",
      selectedItem: {},
      filteredCourses: [],
      headers: [
        {
          align: "start",
          sortable: false,
          value: "thumbnail",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Price",
          align: "center",
          value: "price",
        },
        {
          text: "Classes",
          align: "center",
          value: "noOfClasses",
        },
        {
          text: "Grades",
          align: "center",
          value: "grades",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
      dialog: false,
      publishConfirmDialog: false,
      resetCourseReviewConfirmDialog: false,
      reviewConfirmDialog: false,
      editedCourse: {
        chapters: [],
        grades: [],
        buyable: false,
      },
      gradesArray: grades,
      editCourseDailog: false,
      deleteCourseDialog: false,
      copyCourseDialog: false,
      imgDialog: false,
      defaultItem: {
        name: "",
        courseType: "",
        grades: [],
        buyable: false,
      },
      courseDescError: "",
      createCourseValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        multiValueRules: [
          (v) => !!v.length || "Please select at least one grade",
        ],
        coursenameRules: [(v) => !!v || "Course Name is required"],
        numberRules: [(v) => !!v || v > 0 || "This is required"],
        descriptionRules: [
          (v) => !!v || "Description is required",
          (v) => /.{50,}/.test(v) || "Description must be minimum 50 character",
          (v) =>
            (v && v.length <= 500) ||
            "Description must not be above 500 character",
        ],
        priceRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Price must be greater than 0",
        ],
        noOfClassesRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "No of classes must be greater than 0",
        ],
      },
      file: "",
      preview: "",
      showUploadBtns: false,
      chapterCount: 0,
      panel: [0],
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ["link"],
          ],
        },
      },
    };
  },
  components: { ConfirmDialog, Chapters, EditCourse, CustomDataTable },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    publishConfirmDialog(val) {
      val || this.closePublishDailog();
    },
  },
  mounted: function () {
    this.getAllCourses();
    this.filteredCourses = this.courses;
    // setTimeout(() => {
    //   // Scroll the last active panel into view
    //   const lastActiveCourse = this.panel[this.panel.length - 1];
    //   if (lastActiveCourse !== undefined) {
    //     const courseId = this.courses[lastActiveCourse]?.courseId;
    //     if (courseId !== undefined) {
    //       const panelRef = this.$refs[this.panelRef(courseId)];
    //       if (panelRef !== undefined && panelRef[0] !== undefined) {
    //         panelRef[0].$el.scrollIntoView({
    //           behavior: "smooth",
    //           block: "start",
    //         });
    //       }
    //     }
    //   }
    // }, 1000);
  },

  methods: {
    openCourseView(item) {
      var courseParams = {};
      courseParams = item;
      this.setParams(courseParams);
      this.$router.push({
        name: "CourseDetails",
      });
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    // panelRef(courseId) {
    //   return `panel_${courseId}`;
    // },
    openImageDialog(item) {
      this.selectedItem = item;
      this.imgDialog = true;
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getStatusColor(item) {
      if (item.status == "PUBLISHED") return "success-chip";
      else if (item.status == STATUS_REVIEW) return "primary-chip";
      else return "error-chip";
    },
    getColorByType(item) {
      if (item.courseType == "PRIVATE") return "secondary-chip";
      else if (item.courseType == "PUBLIC") return "orange-chip";
      else return "error-chip";
    },
    onChange() {
      console.log("Inside courses for emit");
      this.getAllCourses();
    },
    buyableFalse() {
      this.editedCourse.buyable = false;
    },
    validateCourseDesc() {
      if (this.editedCourse.description.length == 0) {
        this.courseDescError = "Description is required";
      } else if (this.editedCourse.description.length < 50) {
        this.courseDescError = "Description must be minimum 50 character";
      } else if (this.editedCourse.description.length > 500) {
        this.courseDescError = "Description must not be above 500 character";
      } else {
        this.courseDescError = "";
      }
    },
    selectAllGrades() {
      return this.editedCourse.grades.length == this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedCourse.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getAllCourses() {
      this.menu = false;
      let url = GET_ALL_COURSES;
      this.setOverlayValue(true);
      if (!this.courseFilter.includes("ALL")) {
        if (
          !(
            this.courseStatusFilter.length == 0 ||
            this.courseStatusFilter.length == 2
          )
        ) {
          url = url + "?status=" + this.courseStatusFilter[0];
        }
        if (
          !(
            this.courseTypeFilter.length == 0 ||
            this.courseTypeFilter.length == 2
          )
        ) {
          if (url.indexOf("?") > 0) {
            url = url + "&courseType=" + this.courseTypeFilter[0];
          } else {
            url = url + "?courseType=" + this.courseTypeFilter[0];
          }
        }
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          for (let i = 0; i < this.courses.length; i++) {
            const grade = this.courses[i].grades;
            grade.sort((a, b) => {
              if (Number(a) < Number(b)) return -1;
              if (Number(a) > Number(b)) return 1;
              return 0;
            });
          }
          this.filteredCourses = res.data.courses;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    close() {
      this.editCourseDailog = false;
      this.createCourseValidation.valid = false;
      this.dialog = false;
      this.chapterCount = 0;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    resetCourseReview(item) {
      this.editedIndex = this.courses.indexOf(item);
      this.editedCourse = Object.assign({}, item);
      this.resetCourseReviewConfirmDialog = true;
    },
    publishCourseConfirm(item) {
      if (item.courseType == COURSE_TYPE_PRIVATE) {
        if (!(item.studentReview || item.teacherReview)) {
          this.setApiError("Course Review is Pending with Student and Teacher");
        } else if (!item.studentReview) {
          this.setApiError("Course Review is Pending with Student");
        } else if (!item.teacherReview) {
          this.setApiError("Course Review is Pending with Teacher");
        } else {
          this.editedIndex = this.courses.indexOf(item);
          this.editedCourse = Object.assign({}, item);
          this.publishConfirmDialog = true;
        }
      } else {
        this.editedIndex = this.courses.indexOf(item);
        this.editedCourse = Object.assign({}, item);
        this.publishConfirmDialog = true;
      }
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeResetCourseReviewConfirmDialog() {
      this.resetCourseReviewConfirmDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ReviewCourseConfirm(item) {
      this.editedIndex = this.courses.indexOf(item);
      this.editedCourse = Object.assign({}, item);
      this.reviewConfirmDialog = true;
    },
    closeReviewDailog() {
      this.reviewConfirmDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    trimEditedCourses() {
      this.editedCourse.name = this.editedCourse.name.trim();
    },
    editCourse(item) {
      this.editCourseDailog = true;
      this.editedIndex = 0;
      this.editedCourse = Object.assign({}, item);
    },
    closeEditCourseDailog() {
      this.editCourseDailog = false;
      this.getAllCourses();
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveCourse() {
      if (
        this.createCourseValidation.valid &&
        this.courseDescError.length == 0
      ) {
        this.trimEditedCourses();
        let payload = {};
        this.setOverlayValue(true);
        if (this.editedIndex > -1) {
          payload.courseId = this.editedCourse.courseId;
          payload.courseName = this.editedCourse.name;
          payload.grades = this.editedCourse.grades;
          payload.courseType = this.editedCourse.courseType;
          payload.noOfClasses = this.editedCourse.noOfClasses;
          payload.price = this.editedCourse.price;
          payload.description = this.editedCourse.description;
          let url = UPDATE_COURSE;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createCourseValidation.valid = false;
              this.editedIndex = -1;
              this.close();
              this.getAllCourses();
              this.setOverlayValue(false);
              this.setApiSuccess("Course updated successfully");
              // this.initialize();
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);
            });
        } else {
          payload = this.editedCourse;
          if (this.editedCourse.courseType == "PUBLIC") {
            payload.buyable = false;
          }
          let url = CREATE_COURSE;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createCourseValidation.valid = false;
              this.close();
              this.getAllCourses();
              this.setOverlayValue(false);
              this.setApiSuccess("Course created successfully");
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);
            });
        }
      }
    },
    deleteCourseConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedCourse = Object.assign({}, item);
      this.deleteCourseDialog = true;
    },
    closeDeleteDailog() {
      this.deleteCourseDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteCourse() {
      const url = COURSE_DELETE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeDeleteDailog();
          this.getAllCourses();
          this.setOverlayValue(false);
          this.setApiSuccess("Course Deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeDeleteDailog();
          this.setOverlayValue(false);
        });
    },
    openCopyCourseDialog(item) {
      this.editedIndex = 0;
      this.editedCourse = Object.assign({}, item);
      this.copyCourseDialog = true;
    },
    closeCopyCourseDialog() {
      this.copyCourseDialog = false;
    },
    copyCourse() {
      this.$refs.form.validate();
      if (this.createCourseValidation.valid) {
        this.setOverlayValue(true);
        let payload = {};
        let url = COPY_COURSE;
        payload.courseId = this.editedCourse.courseId;
        payload.courseName = this.editedCourse.courseName;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCourseValidation.valid = false;
            this.editedIndex = -1;
            this.getAllCourses();
            this.closeCopyCourseDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Course copied successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCopyCourseDialog();
            this.setOverlayValue(false);
          });
      }
    },
    resetCourse() {
      const url = RESET_REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeResetCourseReviewConfirmDialog();
          this.setApiSuccess("Course review reset successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeResetCourseReviewConfirmDialog();
        });
    },
    publishCourse() {
      const url = PUBLISH_COURSE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.getAllCourses();
          this.setApiSuccess("Course published successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closePublishDailog();
        });
    },
    reviewCourse() {
      const url = REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeReviewDailog();
          this.getAllCourses();
          this.setApiSuccess("Course sent for review successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeReviewDailog();
        });
    },
    searchCourse() {
      if (this.searchText) {
        this.filteredCourses = this.courses.filter((course) => {
          return course.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredCourses = this.courses;
      }
    },
    chapterCountChange() {
      let chapters = [];
      for (var i = 0; i < this.editedCourse.chapterCount; i++) {
        let data = {};
        data.name = `Chapter ${i + 1}`;
        data.chapterNo = i + 1;
        chapters.push(data);
      }
      this.editedCourse.chapters = chapters;
    },
    lessonCountChange(chapter) {
      let lessons = [];
      for (var i = 0; i < chapter.lessonCount; i++) {
        let data = {};
        data.name = `Lesson ${i + 1}`;
        data.lessonNo = i + 1;
        lessons.push(data);
      }
      chapter.lessons = lessons;
    },
    addChapter() {
      var chapters = [];
      for (var i = 0; i < this.chapterCount; i++) {
        let newChapter = {
          lessons: [],
        };
        newChapter.name = `Chapter ${i + 1}`;
        newChapter.chapterNo = i + 1;
        chapters.push(newChapter);
      }

      this.editedCourse.chapters = chapters;
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedCourse.grades = [];
        } else {
          this.editedCourse.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },
    removeChapter() {
      this.editedCourse.chapters.pop();
    },
    addLesson(chapter) {
      var lessons = [];
      for (var i = 0; i < chapter.lessonCount; i++) {
        let newLesson = {};
        newLesson.name = `Lesson ${i + 1}`;
        newLesson.lessonNo = i + 1;
        lessons.push(newLesson);
      }
      chapter.lessons = lessons;
    },
    addThumbnail(item) {
      const uploader = this.$refs["uploader-" + item.courseId];
      if (uploader) {
        uploader.click();
      }
    },
    onFileChanged(e, item) {
      if (e.target && e.target.files && e.target.files.length > 0) {
        item.file = e.target.files[0];
        if (item.file) {
          item.preview = URL.createObjectURL(item.file);
        } else {
          item.preview = "";
        }
        // item.showUploadBtns = true;
        this.$set(item, "showUploadBtns", true);
        e.target.value = null;
      }
    },
    uploadThumbnail(item) {
      let payload = {};
      payload.file = item.file;
      payload.courseId = item.courseId;
      let url = ADD_COURSE_THUMBNAIL;
      this.setOverlayValue(true);
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      axios
        .post(url, payload, options)
        .then((res) => {
          item.file = "";
          item.preview = "";
          item.showUploadBtns = false;
          this.setOverlayValue(false);
          this.setApiSuccess("Thumbnail updated successfully");
          this.getAllCourses();
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload(item) {
      item.showUploadBtns = false;
      item.file = "";
      item.preview = "";
    },
  },
};
</script>
