<template>
  <v-container class="pa-0">
    <v-toolbar flat dark color="primary">
      <v-btn icon class="hidden-xs-only" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{
        textContent.title
      }}</v-toolbar-title>
    </v-toolbar>
    <p class="pa-4" v-html="textContent.source"></p>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_PARAMS_VALUE } from "@/store/getters";
export default {
  data() {
    return {
      textContent: {},
    };
  },
  computed: {
    ...mapGetters({
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    this.textContent = this.paramsValue.textParams;
  },
};
</script>
