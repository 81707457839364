<template>
  <div>
    <v-row>
      <v-col cols="11" class="pt-0">
        <v-tabs
          v-model="selectedTab"
          center-active
          color="error"
          class="custom-tabs-border"
          v-if="selectedChapterLessons.length > 0"
        >
          <v-tab
            :style="{ width: '6rem', textTransform: 'none' }"
            v-for="(lesson, index) in selectedChapterLessons"
            :key="index"
            @change="courseViewWithLesson(selectedChapter, lesson.lessonNo)"
          >
            {{ lesson.lessonName }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="courseObj.status != STATUS_PUBLISHED" cols="1">
        <v-row class="d-flex justify-start mt-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="success"
                class="mr-1"
                @click="openAddLessonDialog"
                >mdi-plus-circle</v-icon
              >
            </template>
            <span>Add Lesson</span>
          </v-tooltip>
          <v-menu v-if="selectedChapterLessons.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item @click="openEditLessonDialog">
                <v-list-item-icon>
                  <v-icon color="warning">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Edit Lesson</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDeleteLessonDialog">
                <v-list-item-icon>
                  <v-icon color="error">mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Delete Lesson</v-list-item-title>
              </v-list-item>
              <v-list-item
                :disabled="selectedChapterLessons[selectedTab]?.lessonNo == 1"
                @click="
                  openMoveLessonDialog(
                    selectedChapterLessons[selectedTab]?.lessonNo,
                    'UP'
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon
                    :disabled="
                      selectedChapterLessons[selectedTab]?.lessonNo == 1
                    "
                    color="blue"
                    >mdi-arrow-up-bold</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>Move Up</v-list-item-title>
              </v-list-item>
              <v-list-item
                :disabled="
                  selectedChapterLessons[selectedTab]?.lessonNo ==
                  selectedChapterLessons.length
                "
                @click="
                  openMoveLessonDialog(
                    selectedChapterLessons[selectedTab]?.lessonNo,
                    'DOWN'
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon
                    :disabled="
                      selectedChapterLessons[selectedTab]?.lessonNo ==
                      selectedChapterLessons.length
                    "
                    color="blue"
                    >mdi-arrow-down-bold</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>Move Down</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="selectedChapterLessons.length > 0">
      <v-col cols="2" class="mt-n4">
        <v-list dense class="custom-list">
          <v-list-item-group
            if="selectedChapter.lessons"
            v-model="selectedItem"
          >
            <v-list-item
              v-for="(item, index) in lessonsTabs"
              :key="index"
              active-class=""
              :class="index == selectedNavItemIndex ? 'selectedone' : ''"
              @click="selectNavItem(index)"
            >
              <v-list-item-content>
                <v-list-item-title class="list-item-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col class="ml-n6 mt-n6">
        <v-card flat>
          <Contents
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-if="selectedNavItemIndex === 0"
            :content="contentsList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab]?.lessonNo"
            :status="courseObj.status"
          />
          <Notes
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-else-if="selectedNavItemIndex === 1"
            :notes="notesList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab]?.lessonNo"
            :status="courseObj.status"
          />
          <CourseQuizAssignment
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-else-if="selectedNavItemIndex === 2"
            :quizAssignments="quizAssignmentsList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab].lessonNo"
            :status="courseObj.status"
            :noOfContents="contentsList.length"
          />
          <CourseTextAssignment
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-else-if="selectedNavItemIndex === 3"
            :textAssignments="textAssignmentsList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab].lessonNo"
            :status="courseObj.status"
            :noOfContents="contentsList.length"
          />
          <CourseCodeAssignment
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-else-if="selectedNavItemIndex === 4"
            :codeAssignments="codeAssignmentsList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab].lessonNo"
            :status="courseObj.status"
            :noOfContents="contentsList.length"
          />
          <CourseCodelyAssignment
            :key="
              selectedNavItemIndex +
              selectedChapterLessons[selectedTab]?.lessonNo +
              selectedChapter
            "
            v-else-if="selectedNavItemIndex === 5"
            :codelyAssignments="codelyAssignmentsList"
            :courseId="courseObj.courseId"
            :chapterNo="selectedChapter"
            :lessonNo="selectedChapterLessons[selectedTab].lessonNo"
            :status="courseObj.status"
            :noOfContents="contentsList.length"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addLessonDialog" width="500">
      <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="courseObj.status != STATUS_PUBLISHED
                " color="primary" v-bind="attrs" v-on="on" @click="addLessonDialog()" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success">mdi-plus</v-icon>
                  </template>
                  <span>Add Lesson</span>
                </v-tooltip>
              </v-btn>
            </template> -->
      <v-card>
        <v-card-title class="primary--text">Add Lesson</v-card-title>
        <v-form ref="form" v-model="createLessonValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedLesson.lessonName"
                    label="Lesson Name"
                    :rules="createLessonValidation.nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeAddLessonDialog()"
                  >Cancel
                </v-btn>
                <v-btn color="primary" text @click="saveCourseLesson()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="editLessonDialog" width="500">
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="courseObj.status != STATUS_PUBLISHED"
            color="warning"
            v-bind="attrs"
            v-on="on"
            @click="editLesson()"
            icon
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-pencil</v-icon>
              </template>
              <span>Edit Lesson</span>
            </v-tooltip>
          </v-btn>
        </template> -->
      <v-card>
        <v-card-title class="primary--text">Edit Lesson</v-card-title>
        <v-form ref="form" v-model="createLessonValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editedLesson.lessonName"
                    label="Lesson Name"
                    :rules="createLessonValidation.nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeEditLessonDialog()"
                  >Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="saveEditCourseLesson(selectedTab)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :dialog="deleteLessonDialog"
      :subMessage1="'Are you sure you want to delete'"
      :subMessage2="courseObj?.chapters?.[0]?.lessons?.[0]?.lessonName"
      @close="closeDeleteLessonDialog()"
      @confirm="deleteLesson(selectedTab)"
    />
    <!-- <v-dialog persistent v-model="deleteLessonDialog" max-width="35rem">
      <v-card>
        <v-card-title
          >Are you sure you want to delete this Lesson?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDeleteLessonDialog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="deleteLesson(selectedTab)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog persistent v-model="moveLessonDialog" width="500">
      <v-card>
        <v-card-title class="primary--text"
          >Move Lesson {{ direction }}</v-card-title
        >
        <v-form ref="form" v-model="moveLessonValidation.valid">
          <v-card-text>
            <v-chip class="primary ml-1"
              >Lesson {{ editedLesson.lessonNo }}</v-chip
            >
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="moveOptions"
                    dense
                    outlined
                    v-model="editedLesson.destinationIndex"
                    :rules="moveLessonValidation.nameRules"
                    label="Move Lesson to"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeMoveLessonDialog()"
                  >Cancel
                </v-btn>
                <v-btn color="primary" text @click="moveLesson()"> Save </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Contents from "@/components/Contents.vue";
import Notes from "@/components/Notes.vue";
import CourseQuizAssignment from "@/components/CourseQuizAssignment.vue";
import CourseTextAssignment from "@/components/CourseTextAssignment.vue";
import CourseCodeAssignment from "@/components/CourseCodeAssignment.vue";
import CourseCodelyAssignment from "@/components/CourseCodelyAssignment.vue";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
} from "@/common/constants";
import {
  ADD_LESSON,
  UPDATE_LESSON,
  DELETE_LESSON,
  BASE_URL,
  COURSE_VIEW,
  MOVE_LESSON,
} from "@/common/apiEndpoints";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { getAxiosOptions, rightClickHandler } from "@/common/utility";

export default {
  data() {
    return {
      selectedItem: 0,
      selectedNavItemIndex: 0,
      lessonsTabs: [
        { id: 0, title: "Contents", value: "content" },
        { id: 1, title: "Notes", value: "notes" },
        { id: 2, title: "Quiz Assignment", value: "quizAssignments" },
        { id: 3, title: "Text Assignment", value: "textAssignments" },
        { id: 4, title: "Code Assignment", value: "codeAssignments" },
        { id: 5, title: "Codely Assignment", value: "codelyAssignments" },
      ],
      selectedTab: 0,
      selectedChapterLessons: [],
      contextMenuObject: {},
      addLessonDialog: false,
      editLessonDialog: false,
      deleteLessonDialog: false,
      editedIndex: -1,
      defaultItem: {},
      itemsPerPage: -1,
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      moveLessonDialog: false,
      editedLesson: {},
      direction: "",
      courseObj: {},
      moveLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      codelyAssignmentsList: [],
      quizAssignmentsList: [],
      codeAssignmentsList: [],
      textAssignmentsList: [],
      notesList: [],
      contentsList: [],
    };
  },
  components: {
    ConfirmDialog,
    Contents,
    Notes,
    CourseQuizAssignment,
    CourseTextAssignment,
    CourseCodeAssignment,
    CourseCodelyAssignment,
  },
  props: ["course", "selectedChapter", "lessonNo", "selectedNav"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    moveOptions() {
      if (
        !this.selectedChapterLessons ||
        this.selectedChapterLessons.length === 0
      ) {
        return [];
      }
      return Array.from(
        { length: this.selectedChapterLessons.length },
        (_, i) => i + 1
      );
    },
  },
  watch: {
    course: {
      handler(value) {
        this.selectedTab = this.lessonNo - 1 || 0;
        this.selectedNavItemIndex = this.getSelectedNav();
        this.courseObj = value;
        this.getAllLessons(this.selectedChapter);
        this.assignContentsLists();
      },
    },
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getSelectedNav() {
      var navItem = this.lessonsTabs.find(
        (tab) => tab.value == this.selectedNav
      );
      if (navItem) return navItem.id;
      return 0;
    },
    getAllLessons(chapterNo) {
      const url = COURSE_VIEW;
      const payload = {
        courseId: this.course.courseId,
        chapterNo: chapterNo,
      };
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedChapterLessons = res.data.chapters[0].lessons;
        })
        .catch((error) => {
          console.error("Error fetching chapter data:", error);
        });
    },
    selectNavItem(index) {
      this.selectedNavItemIndex = index;
      var lessonNo = this.selectedTab + 1;
      this.courseViewWithLesson(this.selectedChapter, lessonNo);
    },
    onUpdate() {
      console.log("Inside onUpdateLesson for update lesson");
      this.$emit("updateChapter");
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    openMoveLessonDialog(a, direction) {
      this.moveLessonDialog = true;
      this.editedLesson.chapterNo = this.selectedChapter;
      this.editedLesson.lessonNo = a;
      this.editedLesson.courseId = this.courseObj.courseId;
      this.direction = direction;
      if (direction == "UP") {
        this.editedLesson.direction = "UP";
      } else {
        this.editedLesson.direction = "DOWN";
      }
    },
    closeMoveLessonDialog() {
      this.moveLessonDialog = false;
      this.$nextTick(() => {
        this.editedLesson = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveLesson() {
      if (this.moveLessonValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.editedLesson.courseId;

        payload.chapterNo = this.editedLesson.chapterNo;
        payload.lessonNo = this.editedLesson.lessonNo;
        payload.direction = this.editedLesson.direction;
        payload.destinationIndex = this.editedLesson.destinationIndex;
        let url = MOVE_LESSON;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.selectedChapterLessons=res.data.chapters[this.selectedChapter-1].lessons;
            this.getAllLessons(this.selectedChapter);
            this.courseViewWithLesson(
              this.selectedChapter,
              this.editedLesson.lessonNo
            );
            this.moveLessonValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("updateChapter");
            this.setOverlayValue(false);
            this.closeMoveLessonDialog();
            this.setApiSuccess("Lesson moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveLessonDialog();
          });
      }
    },
    openAddLessonDialog() {
      this.addLessonDialog = true;
      this.editedLesson = Object.assign({}, this.defaultItem);
      this.editedIndex = 0;
    },
    closeAddLessonDialog() {
      // this.deleteChapterDialog = false;
      // this.editChapterDailog = false;
      this.addLessonDialog = false;
      this.$nextTick(() => {
        // this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedlesson = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    openEditLessonDialog() {
      this.editLessonDialog = true;
      this.editedLesson.lessonName = this.selectedChapterLessons.find(
        (lesson) => lesson.lessonNo == this.selectedTab + 1
      ).lessonName;
    },
    closeEditLessonDialog() {
      this.editLessonDialog = false;
    },
    openDeleteLessonDialog() {
      this.deleteLessonDialog = true;
    },
    closeDeleteLessonDialog() {
      this.deleteLessonDialog = false;
    },
    saveCourseLesson() {
      console.log(this.courseObj);
      this.$refs.form.validate();
      if (this.createLessonValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          let url = BASE_URL + ADD_LESSON;
          payload.lessonName = this.editedLesson.lessonName;
          payload.chapterNo = this.selectedChapter;
          payload.addType = "CHAPTER";
          payload.courseId = this.courseObj.courseId;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              //   this.courseObj = res.data;
              //  this.selectedChapterLessons  = this.courseObj?.chapters[this.selectedChapter-1]?.lessons;
              this.getAllLessons(this.selectedChapter);
              this.closeAddLessonDialog();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lession Added successfully");
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);
            });
        }
      }
    },

    saveEditCourseLesson(selectedTab) {
      if (this.createLessonValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.courseObj.courseId;
        payload.lessonName = this.editedLesson.lessonName;
        payload.lessonNo = this.selectedChapterLessons[selectedTab].lessonNo;
        payload.chapterNo = this.selectedChapter;
        let url = UPDATE_LESSON;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.selectedChapterLessons = res.data.chapters[this.selectedChapter-1].lessons;
            this.getAllLessons(this.selectedChapter);
            this.closeEditLessonDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Chapter Lesson updated successfully");
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    deleteLesson(selectedTab) {
      const url = DELETE_LESSON;
      let payload = {};
      payload.courseId = this.courseObj.courseId;
      payload.chapterNo = this.selectedChapter;
      payload.lessonNo = this.selectedChapterLessons[selectedTab].lessonNo;
      payload.deleteType = "CHAPTER";
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          // this.selectedChapterLessons =
          //   res.data.chapters[this.selectedChapter - 1].lessons;
          this.getAllLessons(this.selectedChapter);
          if (
            this.selectedChapterLessons[selectedTab].lessonNo ===
            this.selectedChapterLessons.length
          ) {
            this.courseViewWithLesson(
              this.selectedChapter,
              this.selectedChapterLessons[selectedTab - 1].lessonNo
            );
          } else {
            this.courseViewWithLesson(
              this.selectedChapter,
              this.selectedChapterLessons[selectedTab].lessonNo
            );
          }
          this.closeDeleteLessonDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter's Lesson deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    courseViewWithLesson(chapterNo, lessonNo) {
      const url = COURSE_VIEW;
      const payload = {
        courseId: this.courseObj.courseId,
        chapterNo: chapterNo,
      };
      if (lessonNo) {
        payload.lessonNo = lessonNo;
      }
      var tabType = this.lessonsTabs[this.selectedNavItemIndex].value;
      if (tabType) {
        switch (tabType) {
          case "content":
            payload.showContent = true;
            break;
          case "notes":
            payload.showNotes = true;
            break;
          case "quizAssignments":
            payload.showQuizAssignments = true;
            payload.showContent = true;
            break;
          case "codeAssignments":
            payload.showCodeAssignments = true;
            payload.showContent = true;
            break;
          case "textAssignments":
            payload.showTextAssignments = true;
            payload.showContent = true;
            break;
          case "codelyAssignments":
            payload.showCodelyAssignments = true;
            payload.showContent = true;
            break;
        }
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courseObj = res.data;
          this.assignContentsLists();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          console.error("Error fetching chapter data:", error);
          this.setOverlayValue(false);
        });
    },
    assignContentsLists() {
      this.codeAssignmentsList =
        this.courseObj?.chapters[0]?.lessons[0]?.codeAssignments;
      this.codelyAssignmentsList =
        this.courseObj?.chapters[0]?.lessons[0]?.codelyAssignments;
      this.quizAssignmentsList =
        this.courseObj?.chapters[0]?.lessons[0]?.quizAssignments;
      this.textAssignmentsList =
        this.courseObj?.chapters[0]?.lessons[0]?.textAssignments;
      this.notesList = this.courseObj?.chapters[0]?.lessons[0]?.notes;
      this.contentsList = this.courseObj?.chapters[0]?.lessons[0]?.content;
    },
  },
};
</script>
<style scoped>
.custom-list {
  border: 0.05rem solid #dedbe1;
  border-radius: 0.3rem;
  padding: 0.4rem;
}
.selectedone {
  border: 0.05rem solid #ff6060;
  border-radius: 0.3rem;
  color: white;
}
.selectedone .list-item-title {
  color: #ff6060;
  padding: 0.3rem;
}
.list-item-title {
  white-space: normal;
  text-align: center;
}
.custom-tabs-border {
  border-radius: 0.3rem;
}
.v-list-item--link:before {
  background-color: white;
}
</style>
