<template>
  <v-card flat>
    <v-dialog persistent v-model="dialog" width="1000">
      <v-card>
        <v-card-title class="primary--text">Edit Course</v-card-title>
        <v-form ref="form" v-model="createCourseValidation.valid">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedCourse.name"
                  label="Course Name"
                  :rules="createCourseValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="editedCourse.grades"
                  :rules="createCourseValidation.multiValueRules"
                  :items="gradesArray"
                  label="Grades"
                  multiple
                  dense
                  deletable-chips
                  chips
                  item-text="desc"
                  item-value="id"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="selectAll">
                      <v-list-item-action>
                        <v-icon>{{ icon() }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Description</span>
                <quill-editor
                  v-model="editedCourse.description"
                  @input="validateCourseDesc()"
                  style="height: 10rem; margin-bottom: 3rem"
                  :options="editorOptions"
                ></quill-editor>
                <span class="error--text">{{ courseDescError }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-6">
              <v-col cols="3" v-if="editedCourse.courseType == 'PRIVATE'">
                <v-text-field
                  v-model="editedCourse.noOfClasses"
                  :rules="createCourseValidation.noOfClassesRules"
                  dense
                  type="number"
                  label="No of Classes"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="editedCourse.buyable">
                <v-text-field
                  v-model="editedCourse.price"
                  :rules="createCourseValidation.priceRules"
                  dense
                  type="number"
                  label="Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="mt-1">
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="close()">Cancel </v-btn>
              <v-btn color="primary" text @click="saveCourse()"> Save </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { UPDATE_COURSE, BASE_URL } from "@/common/apiEndpoints.js";
import { STATUS_PUBLISHED, grades } from "@/common/constants";
import { getAxiosOptions } from "@/common/utility";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import { GET_TOKEN } from "@/store/getters";
import axios from "axios";

export default {
  data() {
    return {
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      dialog: true,
      courseDescError: "",
      gradesArray: grades,
      editedCourse: {
        chapters: [],
        grades: [],
        buyable: false,
      },
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ["link"],
          ],
        },
      },
      createCourseValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        multiValueRules: [
          (v) => !!v.length || "Please select at least one grade",
        ],
        coursenameRules: [(v) => !!v || "Course Name is required"],
        numberRules: [(v) => !!v || v > 0 || "This is required"],
        priceRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Price must be greater than 0",
        ],
        noOfClassesRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "No of classes must be greater than 0",
        ],
      },
    };
  },
  props: ["course", "chapters"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.editedCourse = this.course;
  },
  watch: {
    course: {
      handler(value) {
        this.editedCourse = value;
      },
    },
  },

  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    validateCourseDesc() {
      if (this.editedCourse.description.length == 0) {
        this.courseDescError = "Description is required";
      } else if (this.editedCourse.description.length < 50) {
        this.courseDescError = "Description must be minimum 50 character";
      } else if (this.editedCourse.description.length > 500) {
        this.courseDescError = "Description must not be above 500 character";
      } else {
        this.courseDescError = "";
      }
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedCourse.grades = [];
        } else {
          this.editedCourse.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },
    selectAllGrades() {
      return this.editedCourse.grades.length == this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedCourse.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    close() {
      this.createCourseValidation.valid = false;
      this.dialog = false;
      this.$emit("updateCourseDialog");
    },
    trimEditedCourses() {
      this.editedCourse.name = this.editedCourse.name.trim();
    },
    saveCourse() {
      if (
        this.createCourseValidation.valid &&
        this.courseDescError.length == 0
      ) {
        this.trimEditedCourses();
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.editedCourse.courseId;
        payload.courseName = this.editedCourse.name;
        payload.grades = this.editedCourse.grades;
        payload.courseType = this.editedCourse.courseType;
        payload.noOfClasses = this.editedCourse.noOfClasses;
        payload.description = this.editedCourse.description;
        payload.price = this.editedCourse.price;
        let url = UPDATE_COURSE;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCourseValidation.valid = false;
            this.close();
            this.$emit("updateCourseDialog");
            this.setOverlayValue(false);
            this.setApiSuccess("Course updated successfully");
            // this.initialize();
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
