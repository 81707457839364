<template>
  <div v-bind="$props">
    <v-dialog v-model="imgDialog" width="44rem">
      <div style="position: relative">
        <v-btn
          color="red"
          dark
          width="1.3rem"
          class="fixed-button"
          @click="closeImage()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="questionMedia"></v-img>
      </div>
    </v-dialog>
    <v-col
      class="srollContainer"
      v-if="quizContent && quizContent.questions.length != 0"
    >
      <v-col class="ma-2"
        ><span
          class="text-h5"
          v-html="
            formatQuestionTitle(
              quizContent.questions[index].questionNo,
              quizContent.questions[index].title,
              quizContent.questions.length
            )
          "
        >
        </span>
        <div class="ma-2" v-if="quizContent.questions[index].media">
          <v-img
            width="300"
            height="300"
            @click="viewImage(quizContent.questions[index])"
            :src="quizContent.questions[index].media"
          ></v-img>
          <span v-if="quizContent.questions[index].media" class="text-caption"
            >If image is not clear , click on image to see full image</span
          >
        </div>
        <v-col
          class="pl-0"
          v-for="option in quizContent.questions[index].options"
          :key="option.number"
        >
          <label>
            <input
              v-model="selected"
              type="radio"
              name="option.value"
              :value="option.number"
              :disabled="alreadyAttempted || userType != USER_TYPE_STUDENT"
            />
            <span class="ml-2 pt-0">{{ option.value }}</span></label
          >
        </v-col>
      </v-col>
      <v-row class="ma-2">
        <!-- <v-col cols="12" v-if="alreadyAttempted">
          <span style="font-size: 1.1rem; font-weight: 500"
            >Result :
            <span
              :class="isCorrect ? 'success--text' : 'error--text'"
              style="font-weight: 400; font-size: 1rem"
              >{{ isCorrect ? "Right" : "Wrong" }}</span
            ></span
          >
        </v-col> -->
        <v-col
          cols="12"
          :style="{ backgroundColor: isCorrect ? '#e9f5e9' : '#FFEFF0' }"
          v-if="alreadyAttempted"
        >
          <span
            :class="`${
              isCorrect ? 'success--text' : 'error--text'
            } text-body-1 font-weight-medium`"
            >{{ isCorrect ? "👍🏻 Correct Answer" : "👎🏻 Incorrect Answer" }}</span
          ><br />
          <span class="primary--text">{{ getCorrectAnswer(index) }} </span
          ><span> is correct answer.</span>
          <!-- getCorrectAnswer(index) -->
        </v-col>
        <v-col
          cols="12"
          class="mt-4"
          style="background-color: #f0f1f7"
          v-if="alreadyAttempted"
        >
          <span class="text-body-1 font-weight-medium">Explanation</span><br />
          <span
            class="info--text"
            v-html="explaination ? explaination : getExplaination(index)"
          >
          </span>
        </v-col>
        <v-col cols="12" class="pl-0" v-if="displaySuccessMsg">
          <v-chip
            color="success"
            label
            style="font-weight: 500; box-shadow: 0px 7px 8px 5px #0e0e0f40"
          >
            <v-avatar left>
              <v-img
                max-width="1.2rem"
                contain
                src="../assets/Coin.svg"
              ></v-img>
            </v-avatar>
            Congratulations! You have earned {{ earnedCoins }} coins
          </v-chip>
        </v-col>
        <v-col cols="12" class="pl-0" v-if="displayFailureMsg">
          <v-chip
            color="error"
            label
            style="font-weight: 500; box-shadow: 0px 7px 8px 5px #0e0e0f40"
          >
            😔 Oops! You could not earn any coins. Better luck next time!
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-btn
          class="ma-2"
          small
          :disabled="index == 0"
          color="primary"
          @click="backpage()"
          >Back</v-btn
        >
        <v-btn
          class="ma-2"
          small
          :disabled="index == quizContent.questions.length - 1"
          color="primary"
          @click="nextPage()"
          >Next</v-btn
        >
        <v-btn
          class="ma-2"
          small
          color="success"
          v-if="!alreadyAttempted && userType == USER_TYPE_STUDENT"
          :disabled="selected == '' ? true : false"
          @click="studentQuiz()"
          >Submit</v-btn
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import {
  QUIZ_ASSIGNMENT_PROGRESS,
  GET_QUIZ_BY_ID,
  GET_CLASS_OPERATIONS_BY_ID,
  BASE_URL,
  GET_COIN_BALANCE,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatQuestionTitle } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COIN_BALANCE,
} from "@/store/mutations";
import { USER_TYPE_STUDENT } from "@/common/constants";
export default {
  data() {
    return {
      index: 0,
      selected: false,
      imgDialog: false,
      questionMedia: "",
      quizAssignmentData: this.quizAssignment,
      quizAnswers: {},
      quizContent: { questions: [] },
      correctOption: "",
      explaination: "",
      isCorrect: "",
      userResponse: "",
      earnedCoins: "",
      alreadyAttempted: false,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      displaySuccessMsg: false,
      displayFailureMsg: false,
      // classOperationId : this.classOpId,
    };
  },
  props: ["classOpId", "quizAssignment"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    // this.getClassOpById();
    this.getQuizBYQuizId();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
    closeImage() {
      this.imgDialog = false;
    },
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    nextPage() {
      if (this.index < this.quizContent.questions.length) {
        this.index++;
        this.selected = false;
        this.explaination = "";
        this.checkForAlreadyAttempted(
          this.quizContent.questions[this.index].questionNo
        );
        this.displaySuccessMsg = false;
        this.displayFailureMsg = false;
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
        this.selected = false;
        this.explaination = "";
        this.checkForAlreadyAttempted(
          this.quizContent.questions[this.index].questionNo
        );
        this.displaySuccessMsg = false;
        this.displayFailureMsg = false;
      }
    },
    checkForAlreadyAttempted(questionNo) {
      if (this.quizAssignmentData.progress.length > 0) {
        let question = this.quizAssignmentData.progress[0].questions.find(
          (question) => question.questionNo == questionNo
        );
        if (question) {
          this.alreadyAttempted = true;
        } else {
          this.alreadyAttempted = false;
        }
      } else {
        this.alreadyAttempted = false;
      }
    },
    getQuizBYQuizId() {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = this.quizAssignmentData.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizContent = res.data;
          this.checkForAlreadyAttempted(1);
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    studentQuiz() {
      const url = QUIZ_ASSIGNMENT_PROGRESS;
      const payload = {};
      payload.classOpId = this.classOpId;
      payload.assignmentNumber = this.quizAssignmentData.assignmentNumber;
      payload.questionNo = this.quizContent.questions[this.index].questionNo;
      payload.selectedOption = this.selected;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizAssignmentData.progress[0] = res.data;
          this.earnedCoins = res.data.earnedCoins;
          this.$emit(
            "assignmentQuestionCompleted",
            this.quizAssignmentData.progress[0]
          );
          this.alreadyAttempted = true;
          this.setOverlayValue(false);
          this.getExplaination(this.index);
          if (this.isCorrect) {
            this.playAudio("success");
            this.displaySuccessMsg = true;
            this.updateCoinBalance();
          } else {
            this.playAudio("failure");
            this.displayFailureMsg = true;
          }
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getExplaination(index) {
      let questionNo = this.quizContent.questions[index].questionNo;
      let question = this.quizAssignmentData.progress[0].questions.find(
        (question) => question.questionNo == questionNo
      );
      if (question) {
        this.selected = question.userResponse;
        this.isCorrect = question.isCorrect;
        this.explaination = question.explaination;
      }
    },
    getCorrectAnswer(index) {
      return this.quizContent.questions[index].options.find(
        (option) => option.isAnswer == true
      ).value;
      // let question = this.quizAssignment.progress[0].questions.find(question => question.questionNo == questionNo);
      // if(question)   {
      //      this.selected = question.userResponse;
      //      this.isCorrect = question.isCorrect;
      //      return question.correctAnswer;
      // }
    },
    playAudio(soundToPlay) {
      let audioName = "";
      if (soundToPlay === "success") {
        audioName = "coin_added.mp3";
      }
      if (soundToPlay === "failure") {
        audioName = "wrong_outcome.wav";
      }
      var audio = new Audio(require(`../assets/Sounds/${audioName}`));
      audio.play();

      setTimeout(() => {
        audio.pause();
      }, 2200);
    },
    submitQuiz() {
      this.alreadyAttempted = true;
    },
    updateCoinBalance() {
      const url = GET_COIN_BALANCE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let newCoinBalance = res.data.balance;
          this.setCoinBalance(newCoinBalance);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
  },
};
</script>
<style>
.srollContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.fixed-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
</style>
