<template>
    <div v-bind="$props">
      <div
        class="srollContainer pa-6 pr-12"
      >
        <v-row>
          <v-col cols="12" class="ma-3 pa-0">
            <span class="font-weight-medium text-h6 error--text"
              >Question Link:</span
            >
            <v-text-field
              v-model="codelyAssignment.codelyLink"
              prepend-inner-icon="mdi-link"
              readonly
              outlined
              dense
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="copyToClipboard(codelyAssignment.codelyLink)"
                      color="primary"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!showCodelyLink"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="openLinkInIframe('codelyLink')"
                      color="primary"
                      >mdi-open-in-new</v-icon
                    >
                    <v-icon
                      v-else
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="showCodelyLink = false"
                      color="primary"
                      >mdi-close</v-icon
                    >
                  </template>
                  <span>{{ showCodelyLink ? "Close" : "Open" }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <vue-friendly-iframe
              v-if="showCodelyLink"
              class="iframe"
              :src="codelyAssignment.codelyLink"
              allow="clipboard-read; clipboard-write"
            ></vue-friendly-iframe>
          </v-col>
          <v-col cols="12" class="ma-3 pa-0">
            <span class="font-weight-medium text-h6 error--text"
              >Solution Link:</span
            >
            <v-text-field
              v-model="codelyAssignment.solutionLink"
              prepend-inner-icon="mdi-link"
              readonly
              outlined
              dense
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="copyToClipboard(codelyAssignment.solutionLink)"
                      color="primary"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!showSolutionLink"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="openLinkInIframe('solutionLink')"
                      color="primary"
                      >mdi-open-in-new</v-icon
                    >
                    <v-icon
                      v-else
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="showSolutionLink = false"
                      color="primary"
                      >mdi-close</v-icon
                    >
                  </template>
                  <span>{{ showSolutionLink ? "Close" : "Open" }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <vue-friendly-iframe
              v-if="showSolutionLink"
              class="iframe"
              :src="codelyAssignment.solutionLink"
              allow="clipboard-read; clipboard-write"
            ></vue-friendly-iframe>
          </v-col>
        </v-row>
      </div>
      <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor" top>
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        codelyAssignment: {},
  
        snackbar: false,
        snackbarText: "",
        snackbarColor: "",
        showCodelyLink: false,
        showSolutionLink: false,
      };
    },
    props: ["scaleFactor", "assignment"],
    computed: {
      iframeStyles() {
        return {
          width: `${100 / this.scaleFactor}%`,
          height: `${100 / this.scaleFactor}%`,
          transform: `scale(${this.scaleFactor})`,
          transformOrigin: "top left",
        };
      },
    },
    mounted: function () {
      this.codelyAssignment = this.assignment || this.paramsValue;
    },
    methods: {
      isValidURL(value) {
        const pattern = new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // port and path
            "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // query string
            "(\\#[-a-zA-Z0-9_]*)?$",
          "i"
        );
        return !!pattern.test(value);
      },
      openLinkInIframe(urlType) {
        this.showCodelyLink = false;
        this.showSolutionLink = false;
        switch (urlType) {
          case "codelyLink":
            this.showCodelyLink = true;
            break;
          case "solutionLink":
            this.showSolutionLink = true;
            break;
        }
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(
          () => {
            this.showSnackbar("Link copied to clipboard", "success");
          },
          () => {
            this.showSnackbar("Failed to copy link", "error");
          }
        );
      },
      showSnackbar(message, type) {
        this.snackbarText = message;
        this.snackbarColor = type === "success" ? "green" : "red";
        this.snackbar = true;
      },
    },
  };
  </script>
  <style scoped>
  .srollContainer {
    height: 100%;
    overflow-y: auto;
  }
  </style>
  