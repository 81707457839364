export const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const BASE_URL2 = process.env.VUE_APP_API_BASE_URL2;

export const BLOCKLY_URL = process.env.VUE_APP_BLOCKLY_URL;
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const REGISTRATION_URL = "/registration";
export const LOGOUT_URL = "/logout";
export const VALIDATE_OTP_URL = "/login/validateOtp";
export const GET_PROFILE = "/user/get-profile";
export const UPDATE_PROFILE = "/user/update-profile";
export const GET_ALL_COURSES = "/course/get-all";
export const GET_ALL_QUIZ = "/quiz/get-all";
export const SEARCH_QUIZ = "/quiz/search";
export const GET_ALL_QUIZ_COMPETITIONS = "/quiz-competition/get-all";
export const GET_USER_DASHBOARD = "/user/dashboard";
export const GET_STUDENT_DASHBOARD = "/user/dashboard/student";
export const GET_COURSE_BY_ID = "/course/get";
export const COURSE_VIEW = "/course/view";
export const SEARCH_USERS = "/user/search";
export const BLOCK_USER = "/user/block";
export const DELETE_USER = "/user/delete";
export const UN_BLOCK_USER = "/user/un-block";
export const CREATE_USER = "/user/create";
export const GET_QUIZ_BY_ID = "/quiz/get";
export const GET_COMPETETION_BY_ID = "/quiz-competition/get";
export const COMPETETION_ENROLL_STUDENTS = "/quiz-competition/enroll-students";
export const COMPETETION_UNENROLL_STUDENTS = "/quiz-competition/unenroll-students";
export const SAVE_COMPETETION_DETAILS = "/quiz-competition/update";
export const PUBLISH_COMPETETION = "/quiz-competition/publish";
export const DELETE_COMPETETION = "/quiz-competition/delete";
export const CREATE_COMPETETION = "/quiz-competition/create";
export const ADD_COMPETITION_THUMBNAIL = "/quiz-competition/add-thumbnail";
export const STUDENT_CREATE_BULK = "/user/bulk-student-create";
export const CREATE_QUIZ = "/quiz/create";
export const UPDATE_QUIZ = "/quiz/update";
export const DELETE_QUIZ = "/quiz/delete";
export const COPY_QUIZ = "/quiz/copy";
export const CREATE_COURSE = "/course/create";
export const UPDATE_COURSE = "/course/update";
export const COPY_COURSE = "/course/copy";
export const COURSE_DELETE = "/course/delete";
export const QUIZ_PUBLISH = "/quiz/publish";
export const ADD_CHAPTER = "/course/add-chapter";
export const ADD_COURSE_THUMBNAIL = "/course/add-thumbnail";
export const UPDATE_CHAPTER = "/course/update-chapter";
export const DELETE_CHAPTER = "/course/delete-chapter";
export const ADD_LESSON = "/course/add-lesson";
export const UPDATE_LESSON = "/course/update-lesson";
export const DELETE_LESSON = "/course/delete-lesson";
export const UPLOAD_FILE = "/upload/file-upload";
export const ADD_QUESTION = "/quiz/add-question";
export const DELETE_QUESTION = "/quiz/delete-question";
export const UPDATE_QUESTION = "/quiz/update-question";
export const ADD_CONTENT = "/course/add-content";
export const MOVE_CONTENT = "/course/move-content";
export const MOVE_CHAPTER = "/course/move-chapter";
export const MOVE_LESSON = "/course/move-lesson";
export const DELETE_CONTENT = "/course/delete-content";
export const UPDATE_CONTENT = "/course/update-content";
export const PUBLISH_COURSE = "/course/publish";
export const RESET_REVIEW_COURSE = "/course/reset-review";
export const REVIEW_COURSE = "/course/review";
export const CREATE_RESOURCE = "/content-resource/create";
export const GET_RESOURCE = "/content-resource/get";
export const UPDATE_RESOURCE = "/content-resource/update";
export const UPLOAD_RESOURCE = "/content-resource/upload";
export const UPLOAD_IMAGES = "/content-resource/upload-images";
export const SEARCH_RESOURCE = "/content-resource/search";
export const ENROLL_STUDENTS = "/student/enroll-course";
export const DE_ENROLL_STUDENT = "/student/enroll-course/remove";
export const SEARCH_STUDENT = "/student/search";
export const UPLOAD_BLOCKLY_FILE = "/upload/blockly-upload";
export const PUBLISH_RESOURCE = "/content-resource/publish";
export const BLOCK_RESOURCE = "/content-resource/block";
export const DELETE_RESOURCE = "/content-resource/delete";
export const UNBLOCK_RESOURCE = "/content-resource/un-block";
export const SEND_VERIFICATION_CODE = "/send-verification-code";
export const CREATE_INSTITUTE = "/institute/create";
export const UPDATE_INSTITUTE = "/institute/update-address";
export const GET_ADDRESS_DETAILS = "/pincode/";
export const GET_INSTITUTE_BY_ID = "/institute/get";
export const GET_ALL_INSTITUTES = "/institute/get-all";
export const CREATE_BATCH = "institute/create-batch";
export const ADD_SESSION = "/institute/add-session";
export const END_CURRENT_SESSION = "/institute/end-current-session";
export const CREATE_CLASSROOM = "/class-room/create";
export const CLASSROOM_ASSIGN_STUDENTS = "/class-room/assign-students";
export const CLASSROOM_ASSIGN_TEACHER = "/class-room/assign-teacher";
export const CLASSROOM_UN_ASSIGN_STUDENTS = "/class-room/unassign-students";
export const CLASSROOM_UN_ASSIGN_TEACHER = "/class-room/unassign-teacher";
export const SEARCH_TEACHER = "/teacher/search";
export const UPDATE_PASSWORD = "/user/update-password";
export const GET_ALL_CLASSROOMS = "/class-room/get-all";
export const GET_CLASSROOM_BY_ID = "/class-room/get";
export const CLASSROOM_CREATE_SCHEDULE = "/class-operation/create-schedule";
export const GET_ALL_CLASS_OPERATIONS = "class-operation/get-all";
export const GET_CLASS_OPERATIONS_BY_ID = "class-operation/get";
export const UPDATE_CLASSROOM = "/class-room/update";
export const GET_STUDENT_COURSES = "/course/student/get-courses";
export const GET_STUDENT_CLASSES = "/class-room/student/get-all";
export const GET_TEACHER_CLASSES = "/class-room/teacher/get-all";
export const GET_TEACHER_COURSES = "/course/teacher/get-all";
export const GET_TEACHER_BY_ID = "/teacher/get";
export const START_CLASS = "/class-operation/start-class";
export const END_CLASS = "/class-operation/end-class";
export const EXTRA_CLASS = "/class-operation/create-extra-class";
export const STUDENT_CONTENT_QUIZ_PROGRESS =
  "/course/student/quiz-question-progress";
export const GET_STUDENT_COURSE_PROGRESS = "/course/student/get-progress";
export const GET_STUDENT_LESSON_PROGRESS = "/course/student/lesson-progress";
export const ADD_NOTES = "/course/add-note";
export const MOVE_NOTE = "/course/move-note";
export const UPDATE_NOTES = "/course/update-note";
export const DELETE_NOTES = "/course/delete-note";
export const RESCHEDULE_CLASS = "/class-operation/reschedule-class";
export const CANCEL_CLASS = "/class-operation/cancel-class";
export const CREATE_QUIZ_ASSIGNMENT = "/class-operation/create-quizassignment";
export const CREATE_CODE_ASSIGNMENT = "/class-operation/create-codeassignment";
export const UPDATE_CODE_ASSIGNMENT = "/class-operation/update-codeassignment";
export const UPDATE_QUIZ_ASSIGNMENT = "/class-operation/update-quizassignment";
export const QUIZ_ASSIGNMENT_PROGRESS =
  "/class-operation/progress-quizassignment";
export const EVALUATE_CODE_ASSIGNMENT =
  "/class-operation/evaluate-codeassignment";
export const CREATE_TEXT_ASSIGNMENT = "/class-operation/create-textassignment";
export const UPDATE_TEXT_ASSIGNMENT = "/class-operation/update-textassignment";
export const SUBMIT_TEXT_ASSIGNMENT = "/class-operation/submit-textassignment";
export const EVALUATE_TEXT_ASSIGNMENT =
  "/class-operation/evaluate-textassignment";
export const EXECUTE_CODE = "/code/execute";
export const GET_CODE = "/code/get";
export const SUBMIT_CODE = "/class-operation/submit-codeassignment";
export const TEACHER_ONBOARD = "/teacher/onboard";
export const STUDENT_ONBOARD = "/student/onboard";
export const GET_ALL_TEACHER_AVAILABILITY = "/teacher/availability";
export const GET_ORDERS = "/orders";
export const CREATE_ORDER = "/order/create";
export const ORDER_VALIDATE = "/order/validate";
export const CHECK_PAYMENT = "/order/check-payment";
export const TEACHER_CALENDAR = "/teacher/calendar";
export const GET_ALL_COUPONS = "/coupon/get-all";
export const CREATE_COUPON = "/coupon/create";
export const EDIT_COUPON = "/coupon/edit";
export const DELETE_COUPON = "/coupon/delete";
export const APPLY_COUPON = "/coupon/apply";
export const DELETE_ASSIGNMENT = "/course/delete-assignment";
export const UPDATE_CODEASSIGNMENT = "/course/update-codeassignment";
export const UPDATE_TEXTASSIGNMENT = "/course/update-textassignment";
export const UPDATE_QUIZASSIGNMENT = "/course/update-quizassignment";
export const ADD_CODEASSIGNMENT = "/course/add-codeassignment";
export const ADD_TEXTASSIGNMENT = "/course/add-textassignment";
export const ADD_QUIZASSIGNMENT = "/course/add-quizassignment";
export const GET_ALL_ORGANIZATIONS = "/organization/get-all";
export const CREATE_ORGANIZATION = "/organization/create";
export const SUBSCRIBE_ORGANIZATION = "/organization/add-subscription";
export const RECHARGE_ORGANIZATION = "/organization/subscription/recharge";
export const GET_ALL_PLANS = "/plan/get-all";
export const CREATE_PLAN = "/plan/create";
export const GET_PLAN_BY_ID = "/plan/get";
export const GET_ALL_TEXT_ASSIGNMENTS = "/assignment/text-assignment/get-all";
export const GET_ALL_CODE_ASSIGNMENTS = "/assignment/code-assignment/get-all";
export const GET_ALL_QUIZ_ASSIGNMENTS = "/assignment/quiz-assignment/get-all";
export const CREATE_VARIANT_TEXT_ASSIGNMENTS =
  "/assignment/create-textassignment";
export const AI_ASSISTANT_ASSIST = "/ai-assistant/assist";
export const AI_ASSISTANT_GET = "/ai-assistant/get";
export const GET_NOTICE_BOARD_MESSAGES = "/notice-board";
export const STUDENT_LEADERBOARD = "/student/leaderboard";
export const GET_COIN_HISTORY = "/user/get-coin-history";
export const GET_COIN_BALANCE = "/user/get-coin-balance";
export const REDEEM_COINS = "/student/redeem-coins";
export const GET_REGISTRATION_LIST = "/uk/registrations";
export const GET_ALL_COMPETITIONS = "/quiz-competition/student/get-all";
export const QUIZ_COMPETITION_START = "/quiz-competition/student/start-quiz";
export const QUIZ_COMPETITION_QUIZ_PROGRESS =
  "/quiz-competition/student/quiz-question-progress";
export const QUIZ_COMPETITION_COMP_PROGRESS =
  "/quiz-competition/student/get-progress";
   export const QUIZ_COMPETITION_COMP_PREVIOUS =
  "/quiz-competition/student/get-previous";  
export const QUIZ_COMPETITION_QUIZ_END = "/quiz-competition/student/end-quiz";
export const QUIZ_COMPETITION_ADMIN_ANALYTICS = "/quiz-competition/admin/analytics";
export const QUIZ_COMPETITION_COMP_LEADERBOARD = "/quiz-competition/student/leaderboard";
export const UPDATE_CLASSLINK="/teacher/update-classlink";
export const MOVE_ASSIGNMENT="/course/move-assignment";
export const ADD_CODELYASSIGNMENT="/course/add-codelyassignment";
export const UPDATE_CODELYASSIGNMENT="/course/update-codelyassignment";
export const SUBMIT_CODELY_ASSIGNMENT = "/class-operation/submit-codelyassignment";
export const EVALUATE_CODELY_ASSIGNMENT = "/class-operation/evaluate-codelyassignment";
export const GET_ALL_CERTIFICATES="/certificates/get-all";
export const DOWNLOAD_CERTIFICATE="/certificates/download";
export const SEARCH_CERTIFICATE="/certificates/search";
export const VERIFY_CERTIFICATE="/certificates/verify";
export const UPDATE_TEACHER_SCHEDULE="teacher/update-schedule";
export const GET_STUDENT_COURSE_DETAILS="/course/student/get-course";
export const USER_LAST_LOGIN_DETAILS="/user/last-logins";