
<template>
    <div class="chart-container">
      <canvas ref="chart"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from 'chart.js';
  
  
  Chart.register(...registerables);
  
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        new Chart(this.$refs.chart, {
          type: 'line', 
          data: this.data,
          options: this.options,
        });
      },
    },
  };
  </script>
  
  <style>
  .chart-container {
    position: relative;
    width: 100%;
    height: 13rem; 
  }
  </style>
  