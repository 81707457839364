<template>
  <div>
    <v-toolbar dark color="primary" elevation="2"
      ><v-toolbar-title v-if="$route.params.firstName || paramsValue.firstName">
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)">mdi-arrow-left</v-icon
        >{{
          paramsValue.firstName + " " + paramsValue.lastName
        }}</v-toolbar-title
      ><v-toolbar-title v-else>My Classes</v-toolbar-title>
    </v-toolbar>
    <v-dialog persistent v-model="studentListDialog" max-width="70rem">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <CustomDataTable
          :headers="studentHeaders"
          :items="studentsList"
          item-key="studentId"
          item-value="studentId"
          :search="searchStudent"
        >
          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  item.email
                    ? item.email.length > 20
                      ? item.email.substring(0, 20) + "..."
                      : item.email
                    : "---"
                }}</span>
              </template>
              <span>{{ item.email ? item.email : "---" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-row justify="center">
              <v-col>
                <v-chip
                  x-small
                  :color="
                    item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                  "
                >
                  {{ item.status }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.lastLogin`]="{ item }">
            {{ item.lastLogin ? formatDateTime(item.lastLogin) : "---" }}
          </template>
        </CustomDataTable>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeStudentListDialog()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <CustomDataTable
        :headers="ClassRoomheaders"
        :items="classRooms"
        item-key="classRoomId"
        item-value="classRoomId"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDateTime(item.startDate) }}
        </template>
        <template
          v-slot:[`item.courseId`]="{ item }"
          v-if="teacherCourses && teacherCourses.length > 0"
        >
          <router-link
            style="text-decoration: none"
            class="text-h6"
            to="/home/teacher-courses"
            >{{ getCourseNameById(item.courseId) }}</router-link
          >
        </template>
        <template v-slot:[`item.classes`]="{ item }">
          <v-row
            justify="center"
            v-if="item.classes != null && item.classes != 0"
          >
            {{ item.classes }}
            {{
              item.extraClasses != null && item.extraClasses != 0
                ? " + " + item.extraClasses
                : ""
            }}
          </v-row>
          <v-row justify="center" v-else> -- </v-row>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <v-chip v-if="item.progress != null" small color="success-chip"
            >{{ item.progress.completed }} %</v-chip
          >
          <v-chip v-if="item.progress == null" small color="success-chip"
            >0</v-chip
          >
        </template>
        <template v-slot:[`item.students`]="{ item }">
          <v-btn
            v-if="item.students != null"
            text
            small
            color="primary text-body-2"
            label
            @click="viewStudents(item)"
            >{{ item.students.length }}</v-btn
          >
          <v-btn
            v-else
            text
            small
            color="primary text-body-2"
            label
            >0</v-btn
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="mr-2" @click="viewSchedule(item)">
                mdi-eye
              </v-icon>
            </template>
            <span>View Schedule</span>
          </v-tooltip>
        </template>
      </CustomDataTable>
    </v-container>
  </div>
</template>

<script>
import ClassSchedule from "@/components/ClassSchedule.vue";
import {
  BASE_URL,
  GET_TEACHER_CLASSES,
  GET_TEACHER_COURSES,
  SEARCH_STUDENT,
} from "@/common/apiEndpoints";
import { USER_TYPE_TEACHER } from "@/common/constants";
import axios from "axios";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import { GET_PARAMS_VALUE, GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      classRooms: [],
      teacherCourses: [],
      studentListDialog: false,
      searchStudent: "",
      studentsList: [],
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      ClassRoomheaders: [
        {
          text: "Classroom Name ",
          align: "start",
          value: "name",
        },
        {
          text: "Start Date",
          value: "startDate",
          align: "center",
        },
        {
          text: "Course Name",
          value: "courseId",
          align: "center",
        },
        {
          text: "Classes",
          value: "classes",
          align: "center",
        },
        {
          text: "Progress",
          value: "progress",
          align: "center",
        },
        {
          text: "Students",
          value: "students",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      studentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Mobile No",
          value: "mobileNo",
        },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        {
          text: "School Name",
          value: "schoolName",
        },
        { text: "Grade", value: "grade" },
        {
          text: "Last Login",
          align: "center",
          value: "lastLogin",
        },
      ],
    };
  },
  components: { ClassSchedule, CustomDataTable },
  mounted: function () {
    this.setParams(this.paramsValue);
    this.fetchCourses();
    this.getClasses();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  methods: {
    // getClassNameById(teacherId) {
    //   let c = this.teachers.filter((teacherId) => {
    //     return this.teachers.teacherId == teacherId;
    //   });
    //   return ;
    // },
    fetchCourses() {
      let url = GET_TEACHER_COURSES;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacherCourses = res.data.courses;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    viewSchedule(item) {
      var classroomParams = {};
      classroomParams.classRoomId = item.classRoomId;
      classroomParams.model = item.model;
      classroomParams.className = item.name;
      this.setParams(classroomParams);
      this.$router.push({
        name: "ClassScheduleTeacher",
      });
    },
    viewStudents(item) {
      this.studentListDialog = true;
      this.searchStudents(item.students);
    },
    closeStudentListDialog() {
      this.studentListDialog = false;
      this.studentsList = [];
    },
    searchStudents(studentIds) {
      const payload = {};
      let flag = false;
      if (studentIds && studentIds.length > 0) {
        payload.studentIds = studentIds;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    getClasses() {
      let url = GET_TEACHER_CLASSES;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classRooms = res.data.classes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseNameById(courseId) {
      let c = this.teacherCourses.filter((course) => {
        return course.courseId == courseId;
      });
      if (c.length && c[0].name) return c[0].name;
      return "No Course";
    },
  },
};
</script>
