<template>
  <div v-bind="$props">
    <v-col class="pa-5 srollContainer">
      <v-row class="ma-2 justify-end">
        <v-chip
          v-if="codeAssignment.submissions[0].status == 'EVALUATED'"
          color="primary-chip"
          class="mr-3 font-weight-bold text-body-1"
          small
        >
          <v-avatar left>
            <v-img
              :src="require('@/assets/Coin.svg')"
              style="width: 1rem; height: 1rem"
              contain
            ></v-img>
          </v-avatar>
          {{ codeAssignment.submissions[0].earnedCoins }}/{{
            codeAssignment.coins
          }}
        </v-chip>
        <v-chip small class="secondary-chip">
          {{ codeAssignment.submissions[0].status }}
        </v-chip>
      </v-row>
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span class="font-weight-medium text-h6 error--text">Question: </span>
        </v-col>
        <v-col class="pa-0 ma-1">
          <span class="text-body-1" v-html="codeAssignment.question"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span class="font-weight-medium text-h6 error--text"
            >Submission:
          </span>
        </v-col>
        <v-col class="pa-0 ma-1">
          <v-textarea
            class="text-body-1"
            v-model="codeAssignment.submissions[0].submission"
            auto-grow
            outlined
            :readonly="true"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span class="font-weight-medium text-h6 error--text">Solution: </span>
        </v-col>
        <v-col class="pa-0 ma-1">
          <span class="text-body-1" v-html="codeAssignment.solution"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span class="font-weight-medium text-h6 error--text"
            >Expected Output:
          </span>
        </v-col>
        <v-col class="pa-0 ma-1">
          <span
            class="text-body-1"
            v-html="codeAssignment.expectedOutput"
          ></span>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeAssignment: this.assignment,
    };
  },

  props: ["assignment"],
};
</script>
<style scoped>
.srollContainer {
  height: 100%;
  overflow-y: auto;
}
</style>
