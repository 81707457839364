<template>
  <v-card>
    <v-toolbar dark color="primary" class="mb-1" elevation="2">
      <v-toolbar-title>Users</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <!-- <v-col cols="3" class="mt-3">
        <v-select :items="userTypes" item-text="key" item-value="value"></v-select>
      </v-col> -->
    </v-toolbar>
    <v-container fluid>
      <v-data-table
        v-if="users.length > 0"
        :headers="headers"
        :items="users"
        :sort-by="['createdOn']"
        :sort-desc="[true]"
        ref="myDataTable"
      >
        <template v-slot:[`item.firstName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                item.firstName + " " + item.lastName
                  ? (item.firstName + " " + item.lastName).length > 15
                    ? (item.firstName + " " + item.lastName).substring(0, 15) +
                      "..."
                    : item.firstName + " " + item.lastName
                  : "---"
              }}</span>
            </template>
            <span>{{
              item.firstName + " " + item.lastName
                ? item.firstName + " " + item.lastName
                : "---"
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.street`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                item.street +
                "," +
                item.suburb +
                "," +
                item.country +
                "," +
                item.postcode
                  ? (
                      item.street +
                      "," +
                      item.suburb +
                      "," +
                      item.country +
                      "," +
                      item.postcode
                    ).length > 30
                    ? (
                        item.street +
                        "," +
                        item.suburb +
                        "," +
                        item.country +
                        "," +
                        item.postcode
                      ).substring(0, 30) + "..."
                    : item.street +
                      "," +
                      item.suburb +
                      "," +
                      item.country +
                      "," +
                      item.postcode
                  : "---"
              }}</span>
            </template>
            <span>{{
              item.street +
              "," +
              item.suburb +
              "," +
              item.country +
              "," +
              item.postcode
                ? item.street +
                  "," +
                  item.suburb +
                  "," +
                  item.country +
                  "," +
                  item.postcode
                : "---"
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.mobileNo`]="{ item }">
          {{ item.mobileNo ? item.mobileNo : "---" }}
        </template>
        <template v-slot:[`item.emailId`]="{ item }">
          {{ item.emailId ? item.emailId : "---" }}
        </template>
        <template v-slot:[`item.studentFirstName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                item.studentFirstName + " " + item.studentLastName
                  ? (item.studentFirstName + " " + item.studentLastName)
                      .length > 20
                    ? (
                        item.studentFirstName +
                        " " +
                        item.studentLastName
                      ).substring(0, 20) + "..."
                    : item.studentFirstName + " " + item.studentLastName
                  : "---"
              }}</span>
            </template>
            <span>{{
              item.studentFirstName + " " + item.studentLastName
                ? item.studentFirstName + " " + item.studentLastName
                : "---"
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.schoolYear`]="{ item }">
          {{ item.schoolYear ? item.schoolYear : "---" }}
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          {{ item.gender ? item.gender : "---" }}
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template v-slot:no-data>
          <span>No Data</span>
        </template>
      </v-data-table>
      <CustomDataTable v-else :headers="headers" :items="users" />
    </v-container>
  </v-card>
</template>

<script>
import { GET_REGISTRATION_LIST, BASE_URL } from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { USER_TYPE_STUDENT } from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      users: [],
      headers: [
        {
          text: "Parent Name",
          align: "center",
          sortable: false,
          value: "firstName",
        },
        {
          text: "Address",
          align: "center",
          value: "street",
        },
        {
          text: "Mobile No",
          align: "center",
          value: "mobileNo",
        },
        {
          text: "Email",
          align: "center",
          value: "emailId",
        },
        // { text: "Suburb", align: "center", value: "suburb" },

        // { text: "Country", align: "center", value: "country" },
        // {
        //   text: "Post Code",
        //   align: "center",
        //   value: "postcode",
        // },
        {
          text: "Student Name",
          align: "center",
          value: "studentFirstName",
        },
        {
          text: "School Year",
          align: "center",
          value: "schoolYear",
        },
        {
          text: "Gender",
          align: "center",
          value: "gender",
        },
        {
          text: "Date-Time",
          align: "center",
          value: "createdOn",
        },
      ],
      defaultItem: {
        studentFirstName: "",
      },
      // userTypes: [
      //   { key: "Select", value: "" },
      //   { key: "Mumbai", value: "MUMBAI" },
      //   { key: "Baanglore", value: "BANGLORE" },
      //   { key: "Delhi", value: "DELHI" },
      //   { key: "Noida", value: "NOIDA" },
      // ],
    };
  },

  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: {
    CustomDataTable,
  },
  mounted: function () {
    this.getAllRegistrationList();
  },
  methods: {
    ...mapMutations({
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getAllRegistrationList() {
      let url = GET_REGISTRATION_LIST;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.users = res.data.users;

          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
