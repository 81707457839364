<template v-bind="$props">
  <v-container fluid class="ma-1">
    <CustomDataTable
      :headers="loginDetailsHeaders"
      :items="loginDetailsData"
      items-per-page="8"
      :hide-default-footer="loginDetailsData.length <= 8"
    >
      <template v-slot:[`item.createdOn`]="{ item }">
        {{ formatDateTime(item.createdOn) }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <v-icon v-if="item.isActive == true" color="success">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="error">mdi-close-circle-outline</v-icon>
      </template>
    </CustomDataTable>
  </v-container>
</template>
<script>
import axios from "axios";
import { BASE_URL, USER_LAST_LOGIN_DETAILS } from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters } from "vuex";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      loginDetailsData: [],
      loginDetailsHeaders: [
        {
          text: "Last Login Date",
          value: "createdOn",
        },
        {
          text: "Active",
          value: "isActive",
        },
      ],
    };
  },
  props: ["userId"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: {
    CustomDataTable,
  },
  mounted: function () {
    this.getLoginDetails();
  },
  methods: {
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getLoginDetails() {
      let url = USER_LAST_LOGIN_DETAILS;
      const payload = {};
      payload.userId = this.userId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.loginDetailsData = res.data.logins;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
  },
};
</script>
