<template>
  <div class="container">
    <span class="text-wrap text-h5" v-html="htmlContent"></span>
  </div>
</template>
<script>
export default {
  props: ["htmlContent"],
};
</script>
<style scoped>
.container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  height: 100%;
  overflow-y: auto;
}
</style>
