<template>
  <div>
    <v-toolbar flat dark color="primary">
      <v-btn icon>
        <v-icon @click="$router.push('/home/student-courses')"
          >mdi-arrow-left</v-icon
        >
      </v-btn>
      <v-toolbar-title>Course Details</v-toolbar-title>
    </v-toolbar>
    <v-card flat class="ma-3">
      <v-row class="ma-1">
        <v-col cols="4">
          <v-img style="border-radius: 0.5rem" :src="course.thumbnail"></v-img>
        </v-col>
        <v-col cols="8">
          <v-list-item>
            <v-list-item-content>
              <div class="mb-4 primary--text text-h3 font-weight-medium">
                {{ course.courseName }}
              </div>
              <!-- <v-list-item-title class="text-h5 mb-1">
            Price : {{ course.price }}
          </v-list-item-title> -->
              <div class="text-body-2" v-if="course.description">
                <span v-html="course.description"> </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
    <div>
      <BuyCourse v-bind:course="course" />
    </div>
  </div>
</template>
<script>
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import BuyCourse from "@/components/BuyCourse.vue";

export default {
  data() {
    return {
      course: {},
    };
  },
  components: { BuyCourse },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if (this.$route && this.$route.params && this.$route.params.course) {
      this.course = this.$route.params.course;
      let courseParamsValue = {};
      courseParamsValue.course = this.$route.params.course;
      this.setParams(courseParamsValue);
    } else {
      this.course = this.paramsValue.course;
    }
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
  },
};
</script>
