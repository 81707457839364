<template>
  <div>
    <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :no-data-text="''"
      :headers="headers"
      :items="filteredItems"
    >
      <!-- Search bar - display only if searchEnabled is true -->
      <template v-if="searchEnabled" v-slot:[`header.title`]="{ header }">
        <v-text-field
          v-model="searchText"
          style="width: 60%"
          flat
          dense
          solo
          :append-icon="searchText.length > 0 ? '' : 'mdi-magnify'"
          label="Search"
          hide-details
          clearable
        ></v-text-field>
      </template>
      <template v-if="quizSearchEnabled" v-slot:[`header.quizName`]="{ header }">
        <v-text-field
          v-model="searchText"
          style="width: 60%"
          flat
          dense
          solo
          :append-icon="searchText.length > 0 ? '' : 'mdi-magnify'"
          label="Search"
          hide-details
          clearable
        ></v-text-field>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="header in headers"
            :key="header.value"
            :align="header.align"
            class="wrap-text"
            @contextmenu="
              (event) => {
                rightClickHandler(event, item);
              }
            "
          >
            <slot :name="`item.${header.value}`" :item="item">
              <div v-if="Array.isArray(item[header.value])">
                <template>
                  <span v-for="(val, index) in item[header.value]" :key="index">
                    <span>
                      {{ val
                      }}{{ index < item[header.value].length - 1 ? ", " : "" }}
                    </span>
                  </span>
                </template>
              </div>
              <span v-else>{{ item[header.value] }}</span>
            </slot>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <div class="d-flex flex-column align-center justify-center">
          <v-img max-width="1.5rem" contain src="../assets/No-Data.png"></v-img>
          <span class="mt-1 font-weight-bold"
            >Uh! No Data Available At The Moment</span
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { rightClickHandler } from "@/common/utility";
import ContextMenu from "./ContextMenu.vue";

export default {
  name: "CustomDataTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    searchEnabled: {
      type: Boolean, // Add a prop to control the search bar visibility
      default: false,
    },
    quizSearchEnabled: {
      type: Boolean, // Add a prop to control the search bar visibility
      default: false,
    },
  },
  data() {
    return {
      contextMenuObject: {},
      searchText: "",
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchText) return this.items;
      const searchLower = this.searchText.toLowerCase();
      return this.items.filter((item) => {
        const title = item.title ? item.title.toLowerCase() : "";
        const quizName = item.quizName ? item.quizName.toLowerCase() : "";
        return title.includes(searchLower) || quizName.includes(searchLower);
      });
    },
  },
  components: { ContextMenu },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent.trim();

      if (!textInCell) return;

      if (textInCell.endsWith("...")) {
        const curText = textInCell.slice(0, -3);
        let matchingHeaderValue = null;

        for (let header of this.headers) {
          if (
            item[header.value] &&
            item[header.value].toString().includes(curText)
          ) {
            matchingHeaderValue = item[header.value];
            break;
          }
        }

        if (matchingHeaderValue) {
          this.contextMenuObject = rightClickHandler(
            matchingHeaderValue,
            e.clientX,
            e.clientY
          );
          return;
        }
      }

      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
  },
};
</script>

<style>
.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
  overflow-wrap: anywhere;
}
</style>
