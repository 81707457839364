<template>
  <div class="app-container">
    <AppBarAuth />
    <div class="content-container">
      <SideNavbar />
      <v-main class="main-content">
        <ScheduleWarningDialog v-if="warningDialog" v-bind:endDate="endDate" />
        <AnimatedVAlert
          v-if="noticeMessages.length > 0"
          v-bind:notificationMessages="noticeMessages"
        />
        <v-overlay :value="overlay" v-if="overlay" z-index="1000">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto mt-15" max-width="500" v-if="tokenExpired">
          <v-img max-height="150" src="../assets/APT_LogoR.png"></v-img>
          <v-card-title class="ma-1 font-weight-medium justify-center">
            Your Token has Expired, please login again
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="reLoginPage()">Re-Login</v-btn>
          </v-card-actions>
        </v-card>
        <router-view v-else></router-view>
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          persistent
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="expand_chatbot ? 'chatbot-container' : 'closed-container'"
            >
              <v-btn
                fab
                @click="minimiseChatbot($event)"
                small
                class="mt-3"
                text
              >
                <v-icon v-if="!expand_chatbot"> mdi-step-backward </v-icon>
                <v-icon v-else size="30"> mdi-step-forward </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
                class="mr-3 fixed-btn"
                style="bottom: 4.2rem"
                v-if="expand_chatbot"
              >
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-avatar v-else size="42">
                  <img src="../assets/Icons/Group_227.svg" />
                </v-avatar>
              </v-btn>
            </div>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
      </v-main>
    </div>
    <!-- <AppFooter /> -->
  </div>
</template>

<script>
import Vue from "vue";
import AppBarAuth from "../components/AppBarAuth.vue";
import SideNavbar from "../components/SideNavbar.vue";
import AppFooter from "../components/AppFooter.vue";
import ChatBox from "../components/ChatBox.vue";
import AnimatedVAlert from "../components/AnimatedVAlert.vue";
import ScheduleWarningDialog from "../components/ScheduleWarningDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_OVERLAY_VALUE,
  SET_TOKEN_EXPIRED,
  SET_USER_PROFILE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";

import {
  GET_OVERLAY_VALUE,
  GET_TOKEN,
  GET_TOKEN_EXPIRED,
  GET_USER_PROFILE,
} from "@/store/getters";
import {
  USER_TYPE_SALES_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from "@/common/constants";
import { BASE_URL, GET_NOTICE_BOARD_MESSAGES } from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
export default Vue.extend({
  data() {
    return {
      expand_chatbot: true,
      // minimiseBottom: "7rem",
      // minimiseRight: "3re,",
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      USER_TYPE_SALES_MANAGER: USER_TYPE_SALES_MANAGER,
      relogin: false,
      fab: false,
      alertState: false,
      noticeMessages: [],
      interval: {},
      isSmall: false,
      warningDialog: false,
      endDate: "",
    };
  },
  components: {
    SideNavbar,
    AppBarAuth,
    AppFooter,
    ChatBox,
    AnimatedVAlert,
    ScheduleWarningDialog,
  },
  watch: {
    userProfile: {
      handler(userProfile) {
        if (this.$router.history.current.path == "/home") {
          if (userProfile.userType === this.USER_TYPE_STUDENT) {
            if (userProfile.registrationCompleted) {
              // if (!document.fullscreenElement) {
              //   document.documentElement.requestFullscreen();
              // }
              this.$router.push({ path: "/home/dashboard" });
            } else {
              this.$router.push({ path: "/home/student/on-board" });
            }
          } else if (userProfile.userType === this.USER_TYPE_TEACHER) {
            if (!userProfile.registrationCompleted) {
              this.$router.push({ path: "/home/teacher/on-board" });
            } else if (this.moveToUpdateSchedule()) {
              var paramsValue = {};
              paramsValue.isScheduleExpired = true;
              paramsValue.endDate = this.endDate;
              this.setParams(paramsValue);
              this.$router.push({ path: "/home/update-schedule" });
            } else {
              this.$router.push({ path: "/home/dashboard" });
            }
          } else if (userProfile.userType === this.USER_TYPE_SALES_MANAGER) {
            this.$router.push({ path: "/home/uk/registrations" });
          } else {
            this.$router.push({ path: "/home/dashboard" });
          }
        }
      },
    },
  },
  mounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.intervalFunction();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  computed: {
    ...mapGetters({
      userProfile: `schoolModule/${GET_USER_PROFILE}`,
      overlay: `schoolModule/${GET_OVERLAY_VALUE}`,
      tokenExpired: `schoolModule/${GET_TOKEN_EXPIRED}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setTokenExpired: `schoolModule/${SET_TOKEN_EXPIRED}`,
      setUserProfile: `schoolModule/${SET_USER_PROFILE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),

    reLoginPage() {
      sessionStorage.clear();
      this.setParams({});
      this.setTokenExpired(true);
      this.setOverlayValue(false);
      this.setUserProfile({});
      this.$router.push({ path: "/login" });
    },
    toggleAlert() {
      this.alertState = !this.alertState;
      // console.log(this.alertState);
    },
    intervalFunction: function () {
      if (this.interval) {
        clearInterval(this.interval);
      }
      var oThis = this;
      this.interval = setInterval(function () {
        oThis.messages = oThis.getNoticeBoardMessages();
      }, 15000);
    },
    getNoticeBoardMessages() {
      if (
        !this.tokenExpired &&
        (this.userProfile.userType === this.USER_TYPE_STUDENT ||
          this.userProfile.userType === this.USER_TYPE_TEACHER) &&
        this.userProfile.registrationCompleted
      ) {
        let url = GET_NOTICE_BOARD_MESSAGES;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.noticeMessages = res.data.messages;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    moveToUpdateSchedule() {
      if (
        this.userProfile.teacher &&
        this.userProfile.teacher.schedule &&
        this.userProfile.teacher.schedule.length > 0
      ) {
        // var lastIndex = this.userProfile.teacher.schedule.length - 1;
        // var endTime = new Date(
        //   this.userProfile.teacher.schedule[lastIndex].endDateTime
        // ).getTime();
        // this.endDate = new Date(
        //   this.userProfile.teacher.schedule[lastIndex].endDateTime
        // );
        this.endDate = new Date(
          Math.max(
            ...this.userProfile.teacher.schedule.map(
              (dateTime) => new Date(dateTime.endDateTime)
            )
          )
        );
        var endTime = this.endDate.getTime();
        var currentTime = new Date().getTime();
        if (endTime < currentTime) {
          return true;
        } else if (endTime < currentTime + 7 * 24 * 60 * 60 * 1000) {
          this.warningDialog = true;
          return false;
        } else {
          return false;
        }
      }
      return true;
    },
    minimiseChatbot(event) {
      this.expand_chatbot = !this.expand_chatbot;
      event.currentTarget.blur();
    },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
}
.chatbot-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 8rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.closed-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 2rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.app-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.content-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  background-color: #f4f7fe;
}
.main-content {
  background-color: #f4f7fe;
  border: 0.1rem solid #d5d7e2;
  border-radius: 0.5rem !important;
  margin-left: 16.7rem !important;
  margin-top: 4.6rem !important;
  margin-bottom: 0.5rem !important;
  margin-right: 0.5rem !important;
  padding: 0 !important;
  flex-grow: 1;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  max-width: calc(100% - 17.2rem);
}
</style>
