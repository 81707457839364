import Vue from "vue";
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#714EFF",
        secondary: "#0094FF",
        accent: "#195D80",
        error: "#FF6584",
        warning: "#FAAF19",
        info: "#4281EE",
        success: "#26BD67",
        orange: "#fd5900",
        mainContentColor: "#f4f7fe",
      },
    },
  },
});

export default vuetify;
