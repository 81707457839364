<template>
  <v-card>
    <v-toolbar dark color="#7A52CC" elevation="2">
      <v-toolbar-title>Organization</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchOrg"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="OrgStatusFilter"
              label="Created"
              color="primary"
              value="CREATED"
              @click="getAllOrganizations()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="OrgStatusFilter"
              label="Active"
              color="primary"
              value="ACTIVE"
              @click="getAllOrganizations()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="OrgStatusFilter"
              label="Blocked"
              color="primary"
              value="COMPLETED"
              @click="getAllOrganizations()"
              hide-details
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog persistent v-model="orgDialog" max-width="750">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 1rem"
            >New Organization</v-btn
          >
        </template>

        <v-card style="border-radius: 1.3rem">
          <v-toolbar dark color="#7A52CC" elevation="2">
            <v-toolbar-title style="font-weight: 500; font-size: 1.6rem"
              >Create Organization</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-icon @click="close()">mdi-close-circle</v-icon>
          </v-toolbar>
          <v-form ref="form" v-model="createOrgValidation.valid">
            <v-container>
              <v-row class="ma-0 pa-0">
                <v-col cols="3"> <span>Organization Id:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.orgId"
                    :rules="createOrgValidation.idRules"
                    @keyup="uppercase"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3"> <span>Organization Name:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.name"
                    :rules="createOrgValidation.nameRules"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3"> <span>Mobile Number:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.mobileNo"
                    type="number"
                    :rules="createOrgValidation.mobileRules"
                    required
                    :counter="10"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="3"> <span>Email Id:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.emailId"
                    :rules="createOrgValidation.emailRules"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="3"> <span>First Name:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.firstName"
                    :rules="createOrgValidation.nameRules"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="3"> <span>Last Name:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedOrg.lastName"
                    :rules="createOrgValidation.nameRules"
                    class="pa-0 ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-row justify="center" class="mb-0">
              <v-col cols="2">
                <v-btn
                  color="#7A52CC"
                  text
                  @click="saveOrganization()"
                  class="ma-2"
                  style="
                    font-size: 1rem;
                    font-weight: 400;
                    outline-color: #7a52cc;
                    outline-width: 0.0625rem;
                    outline-style: solid;
                  "
                >
                  Done
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-container class="pa-0" fluid>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-data-table
        class="orgClass"
        :headers="headers"
        :items="filteredOrg"
        @contextmenu:row="rightClickHandler"
        item-key="orgId"
        item-value="orgId"
        :single-expand="true"
        show-expand
        @item-expanded="expandOrganizations"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            :color="getOrgStatusColor(item)"
            :text-color="getOrgStatusTextColor(item)"
            style="
              outline-color: getOrgStatusTextColor(item);
              outline-style: solid;
              outline-width: 0.0625rem;
              font-size: 0.9rem;
            "
            label
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template v-slot:[`item.Name`]="{ item }">
          {{ item.firstName + " " + item.lastName }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="ma-1">
              <v-col>
                <v-btn
                  dark
                  color="#4E5266"
                  style="font-size: 1rem; font-weight: 400"
                  >Owner Details</v-btn
                >
                <div class="ma-2 mt-4">
                  <v-row>
                    <v-col
                      class="pa-0 ma-1"
                      cols="4"
                      style="font-size: 1rem; color: white"
                      >Name:</v-col
                    >
                    <v-col
                      class="pa-0 ma-1"
                      style="font-size: 1rem; font-weight: 500; color: white"
                      >{{ item.firstName + " " + item.lastName }}</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col
                      class="pa-0 ma-1"
                      cols="4"
                      style="font-size: 1rem; color: white"
                      >Contact No:</v-col
                    >
                    <v-col
                      class="pa-0 ma-1"
                      style="font-size: 1rem; font-weight: 500; color: white"
                      >{{ item.mobileNo }}</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col
                      class="pa-0 ma-1"
                      cols="4"
                      style="font-size: 1rem; color: white"
                      >Email:</v-col
                    >
                    <v-col
                      class="pa-0 ma-1"
                      style="font-size: 1rem; font-weight: 500; color: white"
                      >{{ item.emailId }}</v-col
                    >
                  </v-row>
                </div>
              </v-col>
              <v-col v-if="item.subscription">
                <v-row
                  class="ma-1"
                  justify="center"
                  v-if="item.subscription.subscriptionId == null"
                  ><v-btn
                    color="#E0FFF0"
                    @click="openSelectPlansDialog(item)"
                    style="
                      font-size: 1rem;
                      font-weight: 400;
                      outline-color: #1f995c;
                      outline-width: 0.125rem;
                      outline-style: solid;
                    "
                    ><span :style="{ color: '#1F995C' }">Subscribe</span></v-btn
                  ></v-row
                >
                <v-row v-else>
                  <v-col>
                    <v-row class="ma-1">
                      <v-btn
                        dark
                        color="#4E5266"
                        style="font-size: 1rem; font-weight: 400"
                        >Plan Details</v-btn
                      >
                      <v-chip
                        v-if="daysDifference >= 0"
                        small
                        class="ma-2"
                        color="#F3F0FA"
                        text-color="#7A52CC"
                        style="
                          outline-color: #7a52cc;
                          outline-style: solid;
                          outline-width: 0.125rem;
                          font-size: 0.9rem;
                        "
                        label
                        >Days Left -
                        <span style="font-weight: 600">{{
                          daysDifference
                        }}</span></v-chip
                      >
                      <v-chip
                        v-else
                        small
                        class="ma-2"
                        color="#FFE5E5"
                        text-color="#FF4D4D"
                        style="
                          outline-color: #ff4d4d;
                          outline-style: solid;
                          outline-width: 0.125rem;
                          font-size: 0.9rem;
                        "
                        label
                        >Plan Expired! Please Recharge</v-chip
                      >
                    </v-row>
                    <div class="ma-3 mt-4">
                      <v-row>
                        <v-col
                          class="pa-0 ma-1"
                          cols="4"
                          style="font-size: 1rem; color: white"
                          >Plan Name:</v-col
                        >
                        <v-col
                          class="pa-0 ma-1"
                          style="
                            font-size: 1rem;
                            font-weight: 500;
                            color: white;
                          "
                          >{{ planDetails.name }}</v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 ma-1"
                          cols="4"
                          style="font-size: 1rem; color: white"
                          >Price:</v-col
                        >
                        <v-col
                          class="pa-0 ma-1"
                          style="
                            font-size: 1rem;
                            font-weight: 500;
                            color: white;
                          "
                          >₹ {{ planDetails.price }}</v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 ma-1"
                          cols="4"
                          style="font-size: 1rem; color: white"
                          >Plan Type:</v-col
                        >
                        <v-col
                          class="pa-0 ma-1"
                          style="
                            font-size: 1rem;
                            font-weight: 500;
                            color: white;
                          "
                          >{{ planDetails.type }}</v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 ma-1"
                          cols="4"
                          style="font-size: 1rem; color: white"
                          >Duration:</v-col
                        >
                        <v-col
                          class="pa-0"
                          style="
                            font-size: 1rem;
                            font-weight: 500;
                            color: white;
                          "
                        >
                          <v-row class="ma-1">
                            {{ formatDateTime(item.subscription.startDate) }} to
                          </v-row>
                          <v-row class="ma-1">
                            {{ formatDateTime(item.subscription.endDate) }}
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          ><v-btn
                            color="#E0FFF0"
                            @click="openRechargeDialog(item)"
                            style="
                              font-size: 1rem;
                              font-weight: 400;
                              outline-color: #1f995c;
                              outline-width: 0.125rem;
                              outline-style: solid;
                            "
                            ><span :style="{ color: '#1F995C' }"
                              >Recharge</span
                            ></v-btn
                          ></v-col
                        >
                        <v-col
                          ><v-btn
                            color="#FFEECC"
                            @click="openSelectPlansDialog(item)"
                            style="
                              font-size: 1rem;
                              font-weight: 400;
                              outline-color: #f5ab18;
                              outline-width: 0.125rem;
                              outline-style: solid;
                            "
                            ><span :style="{ color: '#F5AB18' }"
                              >Switch Plan</span
                            ></v-btn
                          ></v-col
                        >
                        <!-- <v-col class="pr-0 ma-1"
                          ><v-btn
                            color="#FFE5E5"
                            style="
                              font-size: 1rem;
                              font-weight: 400;
                              outline-color: #ff4d4d;
                              outline-width: 0.125rem;
                              outline-style: solid;
                            "
                            ><span :style="{ color: '#FF4D4D' }"
                              >Block Plan</span
                            ></v-btn
                          ></v-col
                        > -->
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="selectPlansDialog" max-width="700">
      <v-card style="border-radius: 2rem">
        <v-toolbar dark color="#7A52CC" elevation="2">
          <v-toolbar-title>Select Plans</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeSelectPlansDialog()">mdi-close-circle</v-icon>
        </v-toolbar>
        <v-form ref="form" v-model="subscribeOrgValidation.valid">
          <v-container>
            <v-row>
              <v-col cols="3" class="ma-3" style="font-size: 1.3rem">
                Select Plans:
              </v-col>
              <v-col>
                <v-select
                  v-model="planId"
                  :items="plans"
                  label="Select Plan"
                  item-text="name"
                  item-value="planId"
                  :rules="subscribeOrgValidation.nameRules"
                  @change="findPlan()"
                >
                  <template slot="item" slot-scope="data">
                    <v-row>
                      <v-col
                        class="ml-3"
                        style="border-bottom: 0.0625rem solid #7a52cc"
                        >{{ data.item.name }}</v-col
                      >
                      <v-col
                        class="mr-3"
                        style="border-bottom: 0.0625rem solid #7a52cc"
                        cols="2"
                        >₹ {{ data.item.price }}</v-col
                      >
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <div v-if="planId">
              <v-container>
                <v-row>
                  <v-col
                    class="ml-4 pa-0"
                    style="font-size: 1.6rem; font-weight: 600; color: #ffbb33"
                    >{{ selectedPlan.name }}
                    <v-row
                      class="ma-1"
                      style="font-size: 1rem; font-weight: 400; color: black"
                      >{{ selectedPlan.type }}</v-row
                    >
                  </v-col>
                  <v-col
                    class="pa-0"
                    style="font-size: 1.6rem; font-weight: 600; color: #ffbb33"
                    cols="2"
                    >₹ {{ selectedPlan.price }}</v-col
                  >
                </v-row>
                <v-row justify="center">
                  <v-col
                    cols="5"
                    class="ma-0 pa-1"
                    v-for="(parameter, index) in selectedPlan.parameters"
                    :key="index"
                  >
                    <v-icon class="ml-1" size="8" style="color: #ffbb33">
                      mdi-circle
                    </v-icon>
                    {{ parameter.key }} -
                    <span style="font-weight: 500"> {{ parameter.value }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-row justify="center" class="mb-0">
            <v-col cols="2">
              <v-btn
                color="#7A52CC"
                text
                @click="subscribe()"
                class="ma-2"
                style="
                  font-size: 1rem;
                  font-weight: 400;
                  outline-color: #7a52cc;
                  outline-width: 0.0625rem;
                  outline-style: solid;
                "
              >
                Done
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="rechargeDialog" max-width="700">
      <v-card style="border-radius: 3rem">
        <v-toolbar dark color="#7A52CC" elevation="2">
          <v-toolbar-title>Are you sure you want to recharge?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeRechargeDialog()">mdi-close-circle</v-icon>
        </v-toolbar>
        <v-card-actions>
          <v-row justify="center" class="mb-0 ma-1">
            <v-col cols="2">
              <v-btn
                color="#7A52CC"
                text
                @click="closeRechargeDialog()"
                class="ma-2"
                style="
                  font-size: 1rem;
                  font-weight: 400;
                  outline-color: #7a52cc;
                  outline-width: 0.0625rem;
                  outline-style: solid;
                "
              >
                No
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="#7A52CC"
                @click="rechargePlan()"
                class="ma-2"
                style="font-size: 1rem; font-weight: 400; color: white"
              >
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
import {
  BASE_URL2,
  GET_ALL_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  GET_ALL_PLANS,
  SUBSCRIBE_ORGANIZATION,
  RECHARGE_ORGANIZATION,
  GET_PLAN_BY_ID,
} from "@/common/apiEndpoints";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import ContextMenu from "./ContextMenu.vue";
import {
  getAxiosOptions,
  formatDateTime,
  rightClickHandler,
} from "@/common/utility";
export default {
  data() {
    return {
      contextMenuObject: {},
      orgDialog: false,
      selectPlansDialog: false,
      rechargeDialog: false,
      OrgStatusFilter: false,
      dateMenu: false,
      search: "",
      showSearchField: false,
      searchText: "",
      selectedPlan: {},
      orgId: "",
      subscriptionId: "",
      planId: "",
      daysDifference: "",
      itemsPerPage: -1,
      organizations: [],
      plans: [],
      planDetails: {},
      editedOrg: {
        defaultInstitute: "APTCODER INST",
        countryCode: "+91",
      },
      defaultItem: {
        defaultInstitute: "APTCODER INST",
        countryCode: "+91",
      },
      createOrgValidation: {
        valid: false,
        nameRules: [
          (v) => !!v || "This is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only",
        ],
        idRules: [
          (v) => !!v || "Organization Id is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only",
          (v) =>
            (v && v.length >= 8 && v.length <= 12) ||
            "Org Id must be between 8 and 12",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        mobileRules: [
          (v) => !!v || "Mobile no is required",
          (v) => (v && v.length == 10) || "Mobile must be 10 characters",
        ],
      },
      headers: [
        {
          text: "ORG ID",
          align: "start",
          value: "orgId",
          class: "custom-header-style",
        },
        {
          text: "ORGANIZATION NAME",
          align: "center",
          value: "name",
          class: "custom-header-style",
        },

        {
          text: "STATUS",
          align: "center",
          value: "status",
          class: "custom-header-style",
        },
        {
          text: "DATE",
          align: "center",
          value: "createdOn",
          class: "custom-header-style",
        },
        {
          text: "NAME",
          value: "Name",
          sortable: true,
          class: "custom-header-style",
        },
        { text: "", value: "data-table-expand", class: "custom-header-style" },
      ],
      subscribeOrgValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      filteredOrg: [
        {
          id: "SHREY1234",
          name: "ORGANIZATION NAME",
          createdOn: "Apr-27-2023",
          createdBy: "SHREY GUPTA",
          isActive: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: { ContextMenu },
  mounted: function () {
    this.getAllOrganizations();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    uppercase() {
      this.editedOrg.orgId = this.editedOrg.orgId.toUpperCase();
    },
    validate() {
      this.$refs.form.validate();
    },
    getAllOrganizations() {
      this.menu = false;
      let url = GET_ALL_ORGANIZATIONS;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL2, this.token))
        .then((res) => {
          this.organizations = res.data.orgs;
          this.filteredOrg = this.organizations;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getPlanByID(planId) {
      let payload = {};
      payload.planId = planId;
      let url = GET_PLAN_BY_ID;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL2, this.token))
        .then((res) => {
          this.planDetails = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getOrgStatusColor(item) {
      if (item.status == "CREATED") {
        return "#FFEECC";
      } else if (item.status == "ACTIVE") {
        return "#E0FFF0";
      } else {
        return "white";
      }
    },
    getOrgStatusTextColor(item) {
      if (item.status == "CREATED") {
        return "#F5AB18";
      } else if (item.status == "ACTIVE") {
        return "#1F995C";
      } else {
        return "white";
      }
    },
    openRechargeDialog(item) {
      this.orgId = item.orgId;
      this.subscriptionId = item.subscription.subscriptionId;
      this.rechargeDialog = true;
    },
    closeRechargeDialog() {
      this.rechargeDialog = false;
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    saveOrganization() {
      this.validate();
      if (this.createOrgValidation.valid) {
        let url = CREATE_ORGANIZATION;
        const payload = this.editedOrg;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL2, this.token))
          .then((res) => {
            this.createOrgValidation.valid = false;
            this.close();
            this.getAllOrganizations();
            this.setOverlayValue(false);
            this.setApiSuccess("Organization created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.close();
            this.setOverlayValue(false);
          });
      }
    },
    close() {
      this.orgDialog = false;
      this.editedOrg = Object.assign({}, this.defaultItem);
    },
    openSelectPlansDialog(item) {
      this.orgId = item.orgId;
      this.selectPlansDialog = true;
      this.getAllPlans();
    },
    closeSelectPlansDialog() {
      this.selectPlansDialog = false;
      this.planId = "";
    },
    searchOrg() {
      if (this.searchText) {
        this.filteredOrg = this.organizations.filter((org) => {
          return org.name.toLowerCase().includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredOrg = this.organizations;
      }
    },
    expandOrganizations({ item }) {
      this.orgId = item.orgId;
      if (item.subscription.planId) {
        this.getPlanByID(item.subscription.planId);
        let today = new Date();
        let endDate = new Date(item.subscription.endDate);
        let dayMilliseconds = 1000 * 60 * 60 * 24;
        this.daysDifference = Math.ceil(
          (endDate.getTime() - today.getTime()) / dayMilliseconds
        );
      }
    },
    getAllPlans() {
      let url = GET_ALL_PLANS;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL2, this.token))
        .then((res) => {
          this.plans = res.data.plans;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    findPlan() {
      this.selectedPlan = this.plans.find((plan) => plan.planId == this.planId);
    },
    subscribe() {
      this.validate();
      if (this.subscribeOrgValidation.valid) {
        let payload = {};
        payload.orgId = this.orgId;
        payload.planId = this.planId;
        let url = SUBSCRIBE_ORGANIZATION;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL2, this.token))
          .then((res) => {
            this.subscribeOrgValidation.valid = false;
            this.closeSelectPlansDialog();
            this.getAllOrganizations();
            this.setOverlayValue(false);
            this.setApiSuccess("Plan subscribed successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.subscribeOrgValidation.valid = false;
            this.closeSelectPlansDialog();
            this.setOverlayValue(false);
          });
      }
    },
    rechargePlan() {
      let payload = {};
      payload.orgId = this.orgId;
      payload.subscriptionId = this.subscriptionId;
      let url = RECHARGE_ORGANIZATION;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL2, this.token))
        .then((res) => {
          this.getAllOrganizations();
          this.closeRechargeDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Plan recharged successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeRechargeDialog();
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
<style scoped>
.theme--light.v-list {
  background: #f3f0fa;
}
</style>
