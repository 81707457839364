<template>
  <v-navigation-drawer absolute permanent class="sidenavbar">
    <v-list-item class="ml-n4 mb-3">
      <v-list-item-avatar v-if="myProfile.profilePhoto">
        <img :src="myProfile.profilePhoto" />
      </v-list-item-avatar>
      <v-list-item-avatar
        v-else-if="
          myProfile.userType == USER_TYPE_STUDENT && !myProfile.profilePhoto
        "
      >
        <img
          v-if="myProfile.student && myProfile.student.gender == 'MALE'"
          src="../assets/Male Avatar.png"
          alt="John"
        />
        <img v-else src="../assets/Female Avatar.png" alt="Jill" />
      </v-list-item-avatar>
      <v-list-item-avatar
        v-else-if="
          myProfile.userType == USER_TYPE_TEACHER && !myProfile.profilePhoto
        "
      >
        <img
          v-if="myProfile.teacher && myProfile.teacher.gender == 'MALE'"
          src="../assets/Male Avatar.png"
          alt="John"
        />
        <img v-else src="../assets/Female Avatar.png" alt="Jill" />
      </v-list-item-avatar>
      <v-list-item-avatar v-else>
        <img src="../assets/Male Avatar.png" alt="John" />
      </v-list-item-avatar>
      <v-chip
        v-if="myProfile.firstName"
        class="ml-n2 text-wrap py-6 text-body-2"
        label
        color="#fff1d6"
        style="width: 80%"
        router
        to="/home/profile"
        :disabled="disableSideBar"
      >
        {{
          myProfile.userType == USER_TYPE_STUDENT && disableSideBar
            ? "--"
            : myProfile.firstName + " " + myProfile.lastName
        }}
      </v-chip>
    </v-list-item>

    <v-divider></v-divider>

    <v-list v-if="myProfile.userType" class="overflow-y-auto">
      <v-list-item-group v-if="myProfile.userType == USER_TYPE_ADMIN">
        <v-list-item
          color="primary"
          v-for="(item, index) in adminOptions"
          :key="index"
          link
          router
          :to="item.path"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group
        v-else-if="myProfile.userType == USER_TYPE_CONTENT_MANAGER"
      >
        <v-list-item
          color="primary"
          v-for="item in cmOptions"
          :key="item.title"
          link
          router
          :to="item.path"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group
        v-else-if="myProfile.userType == USER_TYPE_RESOURCE_MANAGER"
      >
        <v-list-item
          color="primary"
          v-for="item in rmOptions"
          :key="item.title"
          link
          router
          :to="item.path"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group
        v-else-if="myProfile.userType == USER_TYPE_SALES_MANAGER"
      >
        <v-list-item
          color="primary"
          v-for="item in smOptions"
          :key="item.title"
          link
          router
          :to="item.path"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_STUDENT">
        <v-list-item
          color="primary"
          v-for="item in stOptions"
          :key="item.title"
          link
          router
          :to="item.path"
          style="padding: 0 0.5rem"
          :disabled="disableSideBar"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group color="primary" prepend-icon="mdi-pencil">
          <template v-slot:activator>
            <v-list-item-title class="text-body-1">Practice</v-list-item-title>
          </template>
          <v-list-item
            color="primary"
            @click="openBlocklyCoding"
            :disabled="disableSideBar"
          >
            <v-list-item-avatar tile>
              <v-icon>mdi-puzzle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-1"
                >Blockly Coding</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            v-for="item in pcOptions"
            :key="item.title"
            link
            router
            :to="item.path"
            :disabled="disableSideBar"
            @click="resetParams()"
          >
            <v-list-item-avatar tile>
              <v-icon>mdi-code-tags</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
      <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_TEACHER">
        <v-list-item
          color="primary"
          v-for="item in tcOptions"
          :key="item.title"
          link
          router
          :to="item.path"
          :disabled="disableSideBar"
        >
          <v-list-item-avatar tile>
            <v-icon>{{ getIcons(item.title) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group color="primary" prepend-icon="mdi-pencil">
          <template v-slot:activator>
            <v-list-item-title class="text-body-1">Practice</v-list-item-title>
          </template>
          <v-list-item
            color="primary"
            @click="openBlocklyCoding"
            :disabled="disableSideBar"
          >
            <v-list-item-avatar tile>
              <v-icon>mdi-puzzle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-1"
                >Blockly Coding</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            v-for="item in pcOptions"
            :key="item.title"
            link
            router
            :to="item.path"
            :disabled="disableSideBar"
            @click="resetParams()"
          >
            <v-list-item-avatar tile>
              <v-icon>mdi-code-tags</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
    <v-footer app class="d-flex justify-center">
      <span>{{ new Date().getFullYear() }} — <strong> APTCODER</strong></span
      ><span style="color: black" class="ma-1"> (v.5.3)</span>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { GET_PARAMS_VALUE, GET_USER_PROFILE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { SET_PARAMS_VALUE } from "@/store/mutations";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_INSTITUTE,
  USER_TYPE_SALES_MANAGER,
} from "@/common/constants";
import { BLOCKLY_URL } from "@/common/apiEndpoints";

export default {
  data: () => ({
    drawer: true,
    myProfile: {},
    disableSideBar: true,
    adminOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      { title: "Users", path: "/home/users" },
      { title: "Institutes", path: "/home/institutes" },
      { title: "Courses", path: "/home/courses" },
      { title: "Quiz", path: "/home/quiz" },
      { title: "Competitions", path: "/home/competitions" },
      { title: "Coupons", path: "/home/coupons" },
      // { title: "Organization", path: "/home/organization" },
      // { title: "Plans", path: "/home/plans" },
      // { title: "Assignments", path: "/home/assignments" },

      // { title: "Blockly", path: "/home/blockly" },
    ],
    cmOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      {
        title: "Courses",
        path: "/home/courses",
      },
      { title: "Quiz", path: "/home/quiz" },
      {
        title: "Competitions",
        path: "/home/competitions",
      },
    ],
    rmOptions: [
      { title: "Dashboard", icon: "mdi-account", path: "/home/dashboard" },
      {
        title: "Courses",
        path: "/home/courses",
      },
      { title: "Quiz", path: "/home/quiz" },
      {
        title: "Competitions",
        path: "/home/competitions",
      },
      {
        title: "Resources",
        path: "/home/resources",
      },
    ],
    stOptions: [
      { title: "Dashboard", icon: "mdi-account", path: "/home/dashboard" },
      { title: "My Courses", path: "/home/student-courses" },
      { title: "My Classes", path: "/home/student-classes" },
      { title: "My Orders", path: "/home/student-orders" },
      { title: "Competitions", path: "/home/student-competition" },
    ],
    pcOptions: [
      // { title: 'Blockly Coding', icon: 'mdi-code-array', path: '/home/blockly-coding' },
      { title: "Syntax Coding", path: "/home/practice-coding" },
    ],
    tcOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      {
        title: "My Courses",
        path: "/home/teacher-courses",
      },
      {
        title: "My Classes",
        path: "/home/teacher-classes",
      },
      {
        title: "My Calendar",
        path: "/home/teacher-calendar",
      },
      { title: "Quiz", path: "/home/quiz" },
    ],
    smOptions: [{ title: "Registrations", path: "/home/uk/registrations" }],
    USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
    USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_SALES_MANAGER: USER_TYPE_SALES_MANAGER,
    USER_TYPE_INSTITUTE: USER_TYPE_INSTITUTE,
  }),
  computed: {
    ...mapGetters({
      profile: `schoolModule/${GET_USER_PROFILE}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  watch: {
    profile: {
      handler(profile) {
        if (profile) {
          this.myProfile = Object.assign({}, this.profile);
          this.disableSideBar =
            !this.myProfile.registrationCompleted ||
            this.paramsValue.isScheduleExpired;
        }
      },
    },
    immediate: true,
  },
  mounted: function () {
    if (this.profile) {
      this.myProfile = Object.assign({}, this.profile);
      this.disableSideBar =
        !this.myProfile.registrationCompleted ||
        this.paramsValue.isScheduleExpired;
    }
    // this.$forceUpdate();
  },
  methods: {
    ...mapMutations({
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    openBlocklyCoding() {
      window.open(BLOCKLY_URL, "_self");
    },
    getIcons(title) {
      if (title == "Dashboard") return "mdi-home-outline";
      else if (title == "Users") return "mdi-account-group-outline";
      else if (title == "Institutes") return "mdi-school-outline";
      else if (title == "Courses") return "mdi-layers-triple-outline";
      else if (title == "Quiz") return "mdi-comment-question-outline";
      else if (title == "Competitions") return "mdi-trophy-variant-outline";
      else if (title == "Coupons") return "mdi-ticket-percent-outline";
      else if (title == "Blockly") return "mdi-code-array-outline";
      else if (title == "Resources") return "mdi-folder-multiple-image";
      else if (title == "My Courses") return "mdi-library";
      else if (title == "My Classes") return "mdi-book-open";
      else if (title == "My Orders") return "mdi-cart-outline";
      else if (title == "My Calendar") return "mdi-calendar-blank";
      else if (title == "Blockly Coding") return "mdi-puzzle-outline";
      else if (title == "Syntax Coding") return "mdi-code-tags";
      else if (title == "Organization")
        return require("../assets/Icons/organisation 1.svg");
      else if (title == "Plans") return require("../assets/Icons/plans 1.svg");
      else if (title == "Assignments")
        return require("../assets/Icons/plans 1.svg");
      else return require("../assets/Icons/teamwork 1.svg");
    },
    resetParams() {
      var codeParams = {};
      this.setParams(codeParams);
    },
  },
};
</script>
<style scoped>
.sidenavbar {
  /* border: 0.1rem solid #d5d7e2;
  border-radius: 1rem; */
  padding: 1rem;
  /* background-color: #f5f7f9; */
  margin-top: 3.7rem;
  /* margin-left: 0.3rem !important; */
  max-height: calc(100% - 3.7rem);
}
</style>
