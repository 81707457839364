<template>
  <div>
    <v-toolbar dark color="primary" enlevation="2">
      <v-toolbar-title>Dashboard</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid class="ma-0" v-if="userType == USER_TYPE_STUDENT">
      <v-row class="ma-1">
        <v-col cols="12">
          <v-card class="mt-3" outlined>
            <v-card height="60" color="error">
              <v-card-title class="d-flex justify-center white--text"
                >Recent Classes
              </v-card-title>
            </v-card>
            <v-tabs
              v-if="classes.length > 0"
              centered
              grow
              v-model="tab"
              class="mt-2"
              center-active
              show-arrows
              color="primary"
            >
              <!-- background-color="#e7fce9" -->
              <v-tab v-for="(clss, index) in newClasses" :key="index">{{
                clss.date
              }}</v-tab>
            </v-tabs>
            <v-card v-else
              ><v-card-text class="text-body-1 black--text"
                ><v-row justify="center">
                  <v-col cols="3">You have no classes yet!</v-col>
                </v-row></v-card-text
              ></v-card
            >
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(clss, index) in newClasses" :key="index">
                <v-container class="pt-0">
                  <CustomDataTable
                    :headers="classesHeaders"
                    :items="clss.classes"
                    class="mt-2"
                    hide-default-footer
                    item-key="classOpId"
                    item-value="classOpId"
                  >
                    <template v-slot:[`item.courseId`]="{ item }"
                      >{{ getCourseNameById(item.courseId) }}
                    </template>
                    <template v-slot:[`item.time`]="{ item }">
                      {{ formattedTime(item.startDateTime) }} to
                      {{ formattedTime(item.endDateTime) }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-row justify="center">
                        <v-chip small :class="getClassStatusColor(item)">
                          {{ item.status }}
                        </v-chip>
                        <v-icon class="ml-1" v-if="item.isExtra == true" medium
                          >mdi-alpha-e-circle-outline</v-icon
                        >
                      </v-row>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip
                        bottom
                        v-if="item.status == CLASS_STATUS_STARTED"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            class="mr-2"
                            color="primary"
                            size="20"
                            @click="joinClass(item)"
                          >
                            mdi-account-plus-outline
                          </v-icon>
                        </template>
                        <span>Join Class</span>
                      </v-tooltip>
                    </template>
                  </CustomDataTable>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="ma-0" v-else>
      <v-row class="ma-1">
        <v-col
          cols="4"
          class="mt-2"
          v-for="(card, index) in cards"
          :key="index"
        >
          <v-card min-height="19rem" v-if="card.entries !== null">
            <div height="3.5rem" :class="`${getColor(index)}`">
              <v-row class="ma-0 pa-0">
                <v-col cols="7" class="d-flex justify-start align-center">
                  <v-btn
                    dark
                    text
                    @click="goTo(card)"
                    class="font-weight-bold text-body-1"
                  >
                    {{ card.title }}
                  </v-btn>
                </v-col>
                <v-col cols="4" class="d-flex justify-end align-center">
                  <span class="font-weight-bold text-body-1 white--text">{{
                    card.total
                  }}</span>
                </v-col>
              </v-row>
            </div>
            <div v-for="entry in card.entries" :key="entry.title">
              <v-container fluid>
                <v-row>
                  <v-col cols="9">
                    <v-icon class="ma-1" size="6" style="color: black">
                      mdi-circle
                    </v-icon>
                    {{ entry.title }}
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-center font-weight-bold"
                  >
                    <span>
                      {{ entry.count }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  BASE_URL,
  GET_USER_DASHBOARD,
  GET_STUDENT_DASHBOARD,
  GET_STUDENT_COURSES,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formattedTime, formatDate } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  CLASS_STATUS_CANCELED,
  CLASS_STATUS_COMPLETED,
  CLASS_STATUS_CREATED,
  CLASS_STATUS_RESCHEDULED,
  CLASS_STATUS_STARTED,
} from "@/common/constants";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import { SET_OVERLAY_VALUE, SET_API_ERROR } from "@/store/mutations";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  name: "Dashboard",
  data: () => ({
    cards: [],
    classes: [],
    newClasses: [],
    todayClass: false,
    todayDate: "",
    courses: [],
    tab: null,
    classesHeaders: [
      {
        text: "Classroom Name",
        align: "center",
        value: "name",
      },
      {
        text: "Course",
        value: "courseId",
        align: "center",
      },
      {
        text: "Time",
        value: "time",
        align: "center",
      },
      {
        text: "Teacher",
        value: "teacherName",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    CLASS_STATUS_CREATED: CLASS_STATUS_CREATED,
    CLASS_STATUS_RESCHEDULED: CLASS_STATUS_RESCHEDULED,
    CLASS_STATUS_CANCELED: CLASS_STATUS_CANCELED,
    CLASS_STATUS_STARTED: CLASS_STATUS_STARTED,
    CLASS_STATUS_COMPLETED: CLASS_STATUS_COMPLETED,
  }),
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: { CustomDataTable },
  mounted: function () {
    if (this.userType == this.USER_TYPE_STUDENT) {
      this.fetchCourses();
      this.fetchStudentDashboard();
    } else {
      this.fetchUserDashboard();
    }
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formattedTime(date) {
      return formattedTime(date);
    },
    formatDate(date) {
      return formatDate(date);
    },
    fetchCourses() {
      let url = GET_STUDENT_COURSES;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    fetchUserDashboard() {
      this.setOverlayValue(true);
      axios
        .get(GET_USER_DASHBOARD, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.cards = res.data.dashboard;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    fetchStudentDashboard() {
      this.setOverlayValue(true);
      axios
        .get(GET_STUDENT_DASHBOARD, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classes = res.data.studentClasses;
          this.getTodayClass();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
      if (c.length && c[0].courseName) return c[0].courseName;
      return "No Course";
    },
    getTodayClass() {
      this.newClasses = this.classes.map((clss) =>
        new Date(clss.date).toLocaleDateString() ===
        new Date().toLocaleDateString()
          ? {
              ...clss,
              date: "Today" + " " + this.formatDate(new Date()),
            }
          : { ...clss, date: this.formatDate(clss.date) }
      );
      for (let i = 0; i < this.classes.length; i++) {
        if (
          new Date(this.classes[i].date).toLocaleDateString() ===
          new Date().toLocaleDateString()
        ) {
          this.tab = i;
          break;
        } else {
          if (new Date(this.classes[i].date) > new Date()) {
            this.tab = i;
            this.newClasses.splice(i, 0, {
              classes: [],
              date: "Today" + " " + this.formatDate(new Date()),
            });
            break;
          } else {
            if (i === this.classes.length - 1) {
              if (new Date(this.classes[i].date) < new Date()) {
                this.tab = this.classes.length;
                this.newClasses.push({
                  classes: [],
                  date: "Today" + " " + this.formatDate(new Date()),
                });
                break;
              }
            }
          }
        }
      }
    },
    goTo(card) {
      if (card.title == "Users") {
        this.$router.push({ name: "Users" });
      } else if (card.title == "Courses") {
        this.$router.push({ name: "Courses" });
      } else if (card.title == "Coupons") {
        this.$router.push({ name: "Coupons" });
      } else if (card.title == "Institutes") {
        this.$router.push({ name: "Institutes" });
      } else if (card.title == "Quizes") {
        this.$router.push({ name: "Quiz" });
      } else if (card.title == "Resources") {
        this.$router.push({ name: "Resources" });
      } else if (card.title == "Competitions") {
        this.$router.push({ name: "Competitions" });
      } else if (
        (card.title == "Classes" || card.title == "Class operations") &&
        this.userType == USER_TYPE_TEACHER
      ) {
        this.$router.push({ name: "TeacherClasses" });
      }
    },
    getColor(index) {
      const classes = ["primary", "error", "success", "secondary"];
      return classes[index % classes.length];
    },
    getClassStatusColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "secondary-chip";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success-chip";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "primary-chip";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error-chip";
      } else {
        return "orange-chip";
      }
    },
    joinClass(item) {
      if (item.status == CLASS_STATUS_STARTED) {
        if (
          item.classLink.startsWith("http://") ||
          item.classLink.startsWith("https://")
        ) {
          window.open(item.classLink, "_blank");
        } else {
          let x = "https://";
          item.classLink = x.concat(item.classLink);
          window.open(item.classLink, "_blank");
        }
      } else {
        this.setApiError("Class Not Started Yet !");
      }
    },
  },
};
</script>
