<template>
  <div>
    <v-toolbar flat dark color="primary">
      <v-btn icon class="hidden-xs-only">
        <v-icon @click="backToPreviousPage()">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>Review Course Payment</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row align="center" class="ma-4">
        <v-col cols="6">
          <v-row justify="center">
            <v-card
              class="ma-2"
              style="border: 0.05rem solid #368bef"
              width="80%"
            >
              <v-card-text class="mb-2">
                <v-list v-if="course" class="pt-0 pb-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="mb-2" style="font-size: 1.4rem"
                        >Course Details</v-list-item-title
                      >
                      <v-list-item-subtitle style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text">Name </span
                        >: {{ course.courseName }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="mb-4"
                        style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text"
                          >Classes </span
                        >: {{ course.noOfClasses }}</v-list-item-subtitle
                      >
                      <v-divider></v-divider>
                      <!-- <v-list-item-subtitle
                  >experience :
                  {{ teacher.experience }} years</v-list-item-subtitle
                > -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list v-if="teacher" class="pt-0 pb-0">
                  <!-- <v-list-item-title>Teacher</v-list-item-title> -->
                  <v-list-item class="pt-0">
                    <v-list-item-content>
                      <v-list-item-title class="mb-2" style="font-size: 1.4rem"
                        >Teacher details</v-list-item-title
                      >
                      <v-list-item-subtitle style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text"
                          >Teacher Name </span
                        >: {{ teacher.firstName }}
                        {{ teacher.lastName }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text"
                          >Gender </span
                        >: {{ teacher.gender }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text"
                          >Experience </span
                        >: {{ teacher.experience }} years</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        style="font-size: 1.1rem"
                        v-if="teacher.highestQualification"
                        ><span class="font-weight-bold error--text"
                          >Qualification </span
                        >: {{ teacher.highestQualification.qName }},
                        {{
                          teacher.highestQualification.passingYear
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="mb-4"
                        style="font-size: 1.1rem"
                        ><span class="font-weight-bold error--text"
                          >Languages </span
                        >:
                        <span
                          v-for="(language, index) in teacher.speakingLanguages"
                          :key="index"
                        >
                          {{ language
                          }}{{
                            index != teacher.speakingLanguages.length - 1
                              ? ","
                              : ""
                          }}
                        </span>
                      </v-list-item-subtitle>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title style="font-size: 1.4rem"
                      >Selected Slots</v-list-item-title
                    >
                  </v-list-item>
                  <ul>
                    <v-list-item
                      class="mt-n4"
                      v-for="(slot, index) in selectedSlots"
                      :key="index"
                    >
                      <li>
                        <v-list-item-subtitle style="font-size: 1.1rem"
                          ><span class="font-weight-bold error--text">
                            {{ slot.day.day }} -</span
                          >
                          {{ slot.openTime }} to
                          {{ slot.closeTime }}
                        </v-list-item-subtitle>
                      </li>
                    </v-list-item>
                  </ul>
                </v-list>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
        <v-col>
          <v-row justify="center">
            <v-card
              class="ma-2"
              style="border: 0.05rem solid #368bef"
              width="80%"
            >
              <v-card-text>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="mb-2" style="font-size: 1.4rem"
                      >Course Price:
                      <span class="font-weight-bold error--text"
                        >₹ {{ course.price }}</span
                      ></v-list-item-title
                    >
                    <v-list-item-title class="mb-2" style="font-size: 1.4rem"
                      >Discount:
                      <span class="font-weight-bold error--text"
                        >₹ {{ discount }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-card-text>
                <!-- <div class="font-weight-black">
              You Pay {{ course.price - discount }}
            </div> -->

                <v-row>
                  <v-col cols="6" v-if="!couponApplied">
                    <v-text-field
                      class="ma-0"
                      dense
                      outlined
                      v-model="code"
                      placeholder="Enter Coupon Code"
                      @keyup="uppercase"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" v-if="!couponApplied">
                    <v-btn medium @click="applyCoupon" class="error-btn"
                      ><span class="font-weight-medium text-body-1"
                        >Apply</span
                      ></v-btn
                    >
                  </v-col>
                  <v-col cols="6" v-else>
                    <v-chip
                      class="ma-3 text-body-1 font-weight-medium"
                      color="success"
                      close
                      label
                      @click:close="removeCoupon"
                    >
                      {{ code }}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-card-text>
                  <div
                    v-if="couponApplied"
                    class="text-body-1 font-weight-medium success--text"
                  >
                    Coupen Applied successfully
                  </div>
                  <div
                    v-if="couponApplied"
                    class="mt-1 text-body-1 font-weight-medium success--text"
                  >
                    You Save ₹ {{ discount }}
                  </div>
                </v-card-text>

                <v-divider></v-divider>
              </v-card-text>
              <div class="font-weight-medium ml-6" style="font-size: 1.4rem">
                You Have To Pay:
                <span class="font-weight-bold error--text"
                  >₹ {{ course.price - discount }}</span
                >
              </div>

              <!--  <v-card-text>
            <div v-if="couponApplied" class="font-weight-black success--text">
              Coupen Applied successfully
            </div>
            <div v-if="couponApplied" class="font-weight-black success--text">
              You Save {{ discount }}
            </div>
          </v-card-text> -->
              <v-card-actions class="d-flex justify-center ma-2">
                <v-btn
                  class="text-h6"
                  color="primary"
                  @click="goForPayment(course.courseId)"
                >
                  Pay Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {
  GET_TOKEN,
  GET_USER_PROFILE,
  GET_USER_TYPE,
  GET_PARAMS_VALUE,
} from "@/store/getters";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import {
  APPLY_COUPON,
  BASE_URL,
  CREATE_ORDER,
  GET_ALL_TEACHER_AVAILABILITY,
  GET_COURSE_BY_ID,
  GET_TEACHER_BY_ID,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";

export default {
  data() {
    return {
      teacher: {},
      course: {},
      selectedSlots: [],
      CourseDiscount: {},
      code: "",
      discount: 0,
      couponApplied: false,
      teacherId: "",
      redirectedFromOrder: false,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    // this.getCourseById();
    if (this.$route && this.$route.params && this.$route.params.course) {
      this.teacherId = this.$route.params.teacherId;
      this.getTeacherById();
      this.course = this.$route.params.course;
      this.selectedSlots = this.$route.params.schedule;
      this.redirectedFromOrder = this.$route.params.redirectedFromOrder;
      let coursePaymentParamsValue = {};
      coursePaymentParamsValue.course = this.$route.params.course;
      coursePaymentParamsValue.teacherId = this.$route.params.teacherId;
      coursePaymentParamsValue.schedule = this.$route.params.schedule;
      coursePaymentParamsValue.redirectedFromOrder =
        this.$route.params.redirectedFromOrder;
      this.setParams(coursePaymentParamsValue);
    } else {
      this.teacherId = this.paramsValue.teacherId;
      this.getTeacherById();
      this.course = this.paramsValue.course;
      this.selectedSlots = this.paramsValue.schedule;
      this.redirectedFromOrder = this.paramsValue.redirectedFromOrder;
    }
    console.log(this.selectedSlots);
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    uppercase() {
      this.code = this.code.toUpperCase();
    },
    applyCoupon() {
      const url = APPLY_COUPON;
      let payload = {};
      payload.code = this.code.trim();
      payload.amount = this.course.price;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          console.log(res.data);
          this.discount = res.data.discount;
          if (res.data.result == "APPLIED") {
            this.couponApplied = true;
          }
          this.setOverlayValue(false);
          this.setApiSuccess("Coupon applied successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    removeCoupon() {
      this.code = "";
      this.discount = 0;
      this.couponApplied = false;
      // this.setApiSuccess("Coupon removed successfully");
    },
    goForPayment() {
      const url = CREATE_ORDER;
      let payload = {};
      payload.courseId = this.course.courseId;
      payload.schedule = this.selectedSlots.map((slot) => {
        return {
          startDateTime: slot.startDateTime,
          endDateTime: slot.endDateTime,
        };
      });
      // for (let i = 0; i < this.selectedSlots.length; i++) {
      //   let ss = {};
      //   ss.day = this.selectedSlots[i].day.day;
      //   ss.openTime = this.selectedSlots[i].openTime;
      //   ss.closeTime = this.selectedSlots[i].closeTime;
      //   ss.timezoneOffset = new Date().getTimezoneOffset();
      //   payload.schedule.push(ss);
      // }
      payload.teacherId = this.teacher.teacherId;
      if (this.code && this.couponApplied) {
        payload.couponCode = this.code;
        payload.couponDiscount = this.discount;
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let gateWayOrderId = res.data.gatewayOrderId;
          this.$router.push({
            name: "RazorPayPayment",
            params: {
              orderId: gateWayOrderId,
              studentDetails: this.profile,
              price: this.course.price,
            },
          });
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getTeacherById() {
      let url = GET_TEACHER_BY_ID;
      var payload = {};
      payload.teacherId = this.teacherId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacher = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    backToPreviousPage() {
      if (this.redirectedFromOrder) {
        this.$router.push({
          name: "StudentOrders",
        });
      } else {
        this.$router.push({
          name: "StudentCourseDetails",
          params: { course: this.course, isCourseBuyable: true },
        });
      }
    },
  },
};
</script>
