<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Profile</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <!--<v-card class="pa-2 ma-2" width="1200">-->
        <v-card-text>
          <v-row>
            <v-col cols="3" class="pa-0">
              <v-list-item-avatar
                size="200"
                class="ma-8"
                v-if="myProfile.profilePhoto"
              >
                <img :src="myProfile.profilePhoto" />
              </v-list-item-avatar>
              <v-list-item-avatar
                size="200"
                class="ma-8"
                v-else-if="
                  myProfile.userType == USER_TYPE_STUDENT &&
                  !myProfile.profilePhoto
                "
              >
                <img
                  v-if="myProfile.student.gender == 'MALE'"
                  src="../assets/Male Avatar.png"
                  alt="John"
                />
                <img v-else src="../assets/Female Avatar.png" />
              </v-list-item-avatar>
              <v-list-item-avatar
                size="200"
                class="ma-8"
                v-else-if="
                  myProfile.userType == USER_TYPE_TEACHER &&
                  !myProfile.profilePhoto
                "
              >
                <img
                  v-if="myProfile.teacher.gender == 'MALE'"
                  src="../assets/Male Avatar.png"
                />
                <img v-else src="../assets/Female Avatar.png" />
              </v-list-item-avatar>
              <v-list-item-avatar size="200" class="ma-8" v-else>
                <img src="../assets/Male Avatar.png" />
              </v-list-item-avatar>
              <!-- <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_CONTENT_MANAGER &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_RESOURCE_MANAGER &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_INSTITUTE &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar> -->
              <v-card-actions class="mx-4">
                <v-btn
                  v-if="myProfile.profilePhoto"
                  color="primary"
                  medium
                  @click="changeProfile()"
                  >Change Profile Photo</v-btn
                >
                <v-btn v-else color="primary" @click="changeProfile()"
                  >Upload Profile Photo</v-btn
                >
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileChanged"
                />
              </v-card-actions>
              <v-card-actions class="mx-12" v-if="showUploadBtns">
                <v-row justify="center">
                  <v-btn color="primary" icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="uploadProfilePhoto()"
                          >mdi-upload</v-icon
                        >
                      </template>
                      <span>Upload</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn color="error" icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="cancelUpload()"
                          >mdi-cancel</v-icon
                        >
                      </template>
                      <span>Cancel</span>
                    </v-tooltip>
                  </v-btn>
                </v-row>
              </v-card-actions>
              <v-card-actions v-if="myProfile.userType == USER_TYPE_TEACHER">
                <v-btn
                  class="mx-10 mt-2"
                  color="error"
                  outlined
                  @click="openUpdateClassLinkDialog()"
                  >Edit Class Link</v-btn
                >
              </v-card-actions>
              <v-card-actions v-if="myProfile.userType == USER_TYPE_TEACHER">
                <v-btn
                  class="mx-10 mt-2"
                  color="error"
                  outlined
                  @click="moveToUpdateSchedule()"
                  >Edit Schedule</v-btn
                >
              </v-card-actions>
              <v-card-actions v-if="myProfile.userType == USER_TYPE_STUDENT">
                <v-btn
                  class="mx-12 mt-2"
                  color="error"
                  outlined
                  @click="editProfile()"
                  >Edit Profile</v-btn
                >
              </v-card-actions>

              <!-- <v-card-actions class="ma-2" v-if="myProfile.userType == USER_TYPE_TEACHER">
              <v-row class="ma-4">
              <v-btn v-if="!openScheduleTable" color="primary" @click="viewSchedule()">View Schedule</v-btn>
              <v-btn v-else color="primary" @click="viewProfile()">View Profile</v-btn>
              </v-row>
            </v-card-actions> -->
            </v-col>
            <v-col cols="9" class="pa-0">
              <v-tabs
                v-model="tab"
                v-if="myProfile.userType == USER_TYPE_TEACHER"
              >
                <v-tab>Profile</v-tab>
                <v-tab>Schedule</v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                v-if="myProfile.userType == USER_TYPE_TEACHER"
              >
                <v-tab-item v-if="myProfile.userType == USER_TYPE_TEACHER">
                  <v-simple-table class="pa-1 mytable">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Name
                          </th>
                          <td>
                            {{ myProfile.firstName + " " + myProfile.lastName }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Mobile No.
                          </th>
                          <td>
                            {{ myProfile.mobileNo }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Email
                          </th>
                          <td v-if="myProfile.email == null">--</td>
                          <td v-else>
                            {{ myProfile.email }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Gender
                          </th>
                          <td>
                            {{
                              myProfile.teacher.gender[0] +
                              myProfile.teacher.gender.slice(1).toLowerCase()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Class Link
                          </th>
                          <td>
                            {{ myProfile.teacher.classLink }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Date of Birth
                          </th>
                          <td v-if="myProfile.email == null"></td>
                          <td v-else>
                            {{ formatDate(myProfile.teacher.dob) }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Experience
                          </th>
                          <td>{{ myProfile.teacher.experience }} years</td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Institue Name
                          </th>
                          <td>
                            {{
                              myProfile.teacher.highestQualification.institute
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Passing Year
                          </th>
                          <td>
                            {{
                              myProfile.teacher.highestQualification.passingYear
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Qualification Name
                          </th>
                          <td>
                            {{ myProfile.teacher.highestQualification.qName }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <v-icon class="ml-1 profileIcon" small>
                              mdi-circle
                            </v-icon>
                            Preferred Grades
                          </th>
                          <td>
                            <span
                              class="mr-1"
                              v-for="(preferredGrade, index) in myProfile
                                .teacher.preferredGrades"
                              :key="index"
                              >{{ preferredGrade }}
                              {{
                                index !=
                                myProfile.teacher.preferredGrades.length - 1
                                  ? ","
                                  : ""
                              }}</span
                            >
                          </td>
                        </tr>
                        <!-- <tr>
                            <th>
                              <v-icon
                                class="ml-1"
                                x-small
                               
                              >
                                mdi-circle
                              </v-icon>
                              Ratings
                            </th>
                            <td>
                              {{ myProfile.teacher.ratings }}
                            </td>
                          </tr> -->
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <v-tab-item v-if="myProfile.userType == USER_TYPE_TEACHER">
                  <v-row class="ma-1">
                    <v-col class="ma-1 pa-0" style="height: 70vh; overflow-y: auto;">
                      <v-sheet style="overflow-y: auto;">
                        <v-calendar
                          :weekdays="weekDaysFormat"
                          ref="calendar"
                          type="week"
                          color="primary"
                          :events="events"
                          interval-height="22"
                          style="max-height: 100%"
                        >
                          <template v-slot:event="{ eventParsed }">
                            <span class="text-body-2 font-weight-medium text-wrap"
                              >{{ eventParsed.start.time }} -
                              {{ eventParsed.end.time }}</span
                            >
                          </template>
                        </v-calendar>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <!-- <v-col v-if="myProfile.userType == USER_TYPE_TEACHER">
                      <v-row justify="end" class="mr-1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="primary"
                              @click="openscheduleWarningDialog()"
                              >mdi-pencil</v-icon
                            >
                          </template>
                          <span>Alert</span>
                        </v-tooltip>
                      </v-row>
                    </v-col> -->
                </v-tab-item>
              </v-tabs-items>
              <v-simple-table v-else class="mr-8 mt-8 mytable">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Name
                      </th>

                      <td>
                        {{ myProfile.firstName + " " + myProfile.lastName }}
                      </td>
                    </tr>

                    <tr v-if="myProfile.userType == USER_TYPE_STUDENT">
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Parent/Guardian Name
                      </th>
                      <td v-if="myProfile.student.parentName == null">--</td>
                      <td v-else>
                        {{ myProfile.student.parentName }}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Mobile No.
                      </th>
                      <td>
                        {{ myProfile.mobileNo }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Email
                      </th>
                      <td v-if="myProfile.email == null">--</td>
                      <td v-else>
                        {{ myProfile.email }}
                      </td>
                    </tr>
                    <tr v-if="myProfile.userType == USER_TYPE_STUDENT">
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Gender
                      </th>
                      <td v-if="myProfile.userType == USER_TYPE_STUDENT">
                        {{
                          myProfile.student.gender[0] +
                          myProfile.student.gender.slice(1).toLowerCase()
                        }}
                      </td>
                    </tr>
                    <tr v-if="myProfile.userType == USER_TYPE_STUDENT">
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Age
                      </th>
                      <td>
                        {{ myProfile.student.age }}
                      </td>
                    </tr>
                    <tr v-if="myProfile.userType == USER_TYPE_STUDENT">
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        School Name
                      </th>
                      <td>
                        {{ myProfile.student.schoolName }}
                      </td>
                    </tr>
                    <tr v-if="myProfile.userType == USER_TYPE_STUDENT">
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Grade
                      </th>
                      <td>
                        {{ myProfile.student.grade }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
      <span
        v-if="
          myProfile.userType === USER_TYPE_STUDENT &&
          studentcertificates &&
          studentcertificates.length > 0
        "
        class="ml-3 text-h5 font-weight-medium"
      >
        Certificate List</span
      >

      <v-card
        flat
        outlined
        class="ma-2 pa-4"
        v-for="(certs, index) in studentcertificates"
        :key="index"
      >
        <v-row>
          <v-icon class="ml-1" color="primary" size="3rem">
            mdi-certificate
          </v-icon>
          <span class="mx-2 mt-2">
            {{ certs.courseName }}
          </span>
          <span class="mt-2">
            {{ certs.compName }}
          </span>
          <v-chip label color="#93E9BE" class="mx-2 mt-2" small>
            {{ certs.type }}
          </v-chip>
          <v-col col="12" class="d-flex justify-end align-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mx-2"
                  color="success"
                  @click="openCertificateDialog(certs)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <CertificateVerification
      v-if="certificateDialog"
      :certificate="selectedCert"
      @update="certificateDialog = false"
    />
    <v-dialog v-model="editProfileDialog" max-width="1000">
      <v-card v-if="editedProfile.student != null">
        <v-card-title class="primary--text">Edit Profile</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="profileValidation.valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.age"
                    :rules="profileValidation.ageRules"
                    type="number"
                    required
                    label="Age"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <span>Select Your Gender</span>
                  <v-row>
                    <v-col class="pt-0">
                      <v-radio-group
                        row
                        v-model="editedProfile.student.gender"
                        column
                      >
                        <v-radio
                          label="Male"
                          color="primary"
                          value="MALE"
                        ></v-radio>
                        <v-radio
                          label="Female"
                          color="primary"
                          value="FEMALE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.schoolName"
                    label="School Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.parentName"
                    :rules="profileValidation.parentNameRule"
                    label="Parent/Guardian Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    @input="getAddressDetails()"
                    v-model="editedProfile.address.pincode"
                    :rules="profileValidation.pinRules"
                    type="number"
                    label="Pin Number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.address.state"
                    label="State"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close()"> Cancel </v-btn>
          <v-btn color="primary" text @click="updateProfile()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="classLinkDialog" width="40%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Edit Class Link</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeUpdateClassLinkDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="profileValidation.valid">
            <v-text-field
              type="string"
              v-model="classLink"
              label="Enter class Link"
              :rules="profileValidation.classLinkRule"
              hint="https://meet.google.com/zgk-xmue-vdr"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="primary" text @click="updateClassLink()"
            >save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  BASE_URL,
  UPLOAD_FILE,
  UPDATE_PROFILE,
  GET_ADDRESS_DETAILS,
  UPDATE_CLASSLINK,
  GET_ALL_CERTIFICATES,
  DOWNLOAD_CERTIFICATE,
  SEARCH_CERTIFICATE,
} from "@/common/apiEndpoints";
import {
  formatDate,
  formatDateNumeric,
  getAxiosOptions,
} from "@/common/utility";
import { FETCH_USER_PROFILE } from "@/store/actions";
import { GET_TOKEN, GET_USER_PROFILE } from "@/store/getters";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_INSTITUTE,
} from "@/common/constants";
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
// import { forEach } from "core-js/core/array";
import CertificateVerification from "./CertificateVerification.vue";
export default {
  name: "UserProfile",
  data: () => ({
    scheduleWarningDialog: false,
    myProfile: {},
    tab: null,
    showUploadBtns: false,
    file: "",
    openScheduleTable: false,
    editProfileDialog: false,
    classLink: " ",
    certificateDialog: false,
    studentcertificates: {},
    selectedCert: null,
    classLinkDialog: false,
    editedProfile: {},
    events: [],
    weekDaysFormat: [],
    profileValidation: {
      valid: false,
      nameRules: [
        (v) =>
          /^[A-Za-z\s]+$/.test(v) || "Please enter alphabetic characters only",
      ],
      ageRules: [
        (v) => /^\d+$/.test(v) || "Age must be a number",
        (v) => Number(2) <= v || "Age must be greater than 2",
        (v) => Number(99) >= v || "Age must be less than 99",
      ],
      pinRules: [
        (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
      ],
      classLinkRule: [(v) => !!v || "This is required"],
      parentNameRule: [(v) => !!v || "This is required"],
    },
    USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
    USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_INSTITUTE: USER_TYPE_INSTITUTE,
  }),
  components: { CertificateVerification },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
  },
  created() {
    this.getweekDaysFormat();
  },
  watch: {
    profile: {
      handler(profile) {
        if (profile) {
          this.myProfile = Object.assign({}, this.profile);
          if (this.myProfile.userType == USER_TYPE_STUDENT) {
            this.getAllCertificates();
          }
          if (this.myProfile.userType == USER_TYPE_TEACHER) {
            this.getEvents();
          }
          for (let i = 0; i < this.myProfile.length; i++) {
            const teacher = this.myProfile.teacher[i];
            for (let j = 0; j < teacher.length; j++) {
              const preferredGrade = teacher.preferredGrades[j];
              preferredGrade.sort();
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    ...mapActions({
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),

    formatDate(date) {
      return formatDate(date);
    },
    getEvents() {
      this.events = [];
      this.myProfile.teacher.schedule.forEach((schedule) => {
        const startDateTime = new Date(schedule.startDateTime);
        const endDateTime = new Date(schedule.endDateTime);

        this.events.push({
          start: startDateTime,
          end: endDateTime,
          color: "success",
          timed: true,
        });
      });
    },
    getweekDaysFormat() {
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let dateObj = new Date();
      let dayName = dayNames[dateObj.getDay()];

      switch (dayName) {
        case "Sun":
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
          break;
        case "Mon":
          this.weekDaysFormat = [1, 2, 3, 4, 5, 6, 0];
          break;
        case "Tue":
          this.weekDaysFormat = [2, 3, 4, 5, 6, 0, 1];
          break;
        case "Wed":
          this.weekDaysFormat = [3, 4, 5, 6, 0, 1, 2];
          break;
        case "Thu":
          this.weekDaysFormat = [4, 5, 6, 0, 1, 2, 3];
          break;
        case "Fri":
          this.weekDaysFormat = [5, 6, 0, 1, 2, 3, 4];
          break;
        case "Sat":
          this.weekDaysFormat = [6, 0, 1, 2, 3, 4, 5];
          break;
        default:
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
      }
    },
    // viewSchedule() {
    //   this.openScheduleTable = true;
    // },
    // viewProfile() {
    //   this.openScheduleTable = false;
    // },

    getAllCertificates() {
      // this.menu = false;
      let url = GET_ALL_CERTIFICATES;

      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentcertificates = res.data.certs;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    openCertificateDialog(certs) {
      this.certificateDialog = true;
      this.selectedCert = certs;
    },
    changeProfile() {
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener("focus", () => {}, {
        once: true,
      });

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    validate() {
      this.$refs.form.validate();
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.editedProfile.address.pincode.length == 6) {
        url += this.editedProfile.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.state == null) {
              this.editedProfile.address.state = "";
            } else {
              this.editedProfile.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    editProfile() {
      this.editedProfile = Object.assign({}, this.myProfile);
      this.editedProfile.student = Object.assign({}, this.myProfile.student);
      this.editedProfile.address = Object.assign({}, this.myProfile.address);
      this.editProfileDialog = true;
    },
    close() {
      this.editProfileDialog = false;
      // this.editedProfile = Object.assign({}, this.myProfile);
      this.editedProfile = {};
    },
    openUpdateClassLinkDialog() {
      this.classLinkDialog = true;
      this.classLink = this.myProfile.teacherclassLink;
    },
    closeUpdateClassLinkDialog() {
      this.classLinkDialog = false;
      this.classLink = " ";
    },
    updateProfile() {
      this.validate();
      if (this.profileValidation.valid) {
        let payload = {};
        payload.gender = this.editedProfile.student.gender;
        payload.age = this.editedProfile.student.age;
        payload.schoolName = this.editedProfile.student.schoolName.trim();
        payload.parentName = this.editedProfile.student.parentName.trim();
        payload.pincode = this.editedProfile.address.pincode;
        payload.state = this.editedProfile.address.state.trim();
        let url = UPDATE_PROFILE;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.profileValidation.valid = false;
            this.setApiSuccess("Profile updated successfully");
            this.fetchProfile().then(() => {
              this.close();
              this.setOverlayValue(false);
            });
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    updateClassLink() {
      this.validate();
      if (this.profileValidation.valid) {
        let url = UPDATE_CLASSLINK;
        let payload = {};
        this.setOverlayValue(true);
        payload.classLink = this.classLink.trim();
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.setApiSuccess("Class Link updated successfully");
            this.fetchProfile().then(() => {
              this.closeUpdateClassLinkDialog();
              this.setOverlayValue(false);
            });
          })
          .catch((error) => {
            this.closeUpdateClassLinkDialog();
            this.setOverlayValue(false);
          });
      }
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.myProfile.profilePhoto = URL.createObjectURL(e.target.files[0]);
      this.showUploadBtns = true;
    },
    uploadProfilePhoto() {
      let payload = {};
      payload.file = this.file;
      payload.type = "PROFILE_PHOTO";
      payload.userId = this.myProfile.userId;
      let url = UPLOAD_FILE;

      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.fetchProfile().then(() => {
            this.setApiSuccess("Profile photo updated successfully");
            this.myProfile = Object.assign({}, this.profile);
            this.showUploadBtns = false;
            this.file = "";
          });
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload() {
      this.myProfile = Object.assign({}, this.profile);
      this.showUploadBtns = false;
      this.file = "";
    },
    moveToUpdateSchedule() {
      var paramsValue = {};
      paramsValue.isScheduleExpired = false;
      this.setParams(paramsValue);
      this.$router.push({ path: "/home/update-schedule" });
    },
  },
};
</script>
