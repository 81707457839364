<template>
  <v-dialog v-model="dialog" width="50%">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Course Certificate</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeCertificateDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-row class="ma-1">
        <v-col v-if="certificate" class="ma-1">
          <span v-if="certificate.type == 'course'" style="font-size: large">
            You are awarded this certificate for successfully completeing the
            <span class="font-weight-medium"
              >{{ certificate.courseName }}
            </span>
            course.
          </span>
          <span v-else style="font-size: large">
            You are awarded this certificate for successfully completeing the
            <span class="font-weight-medium">{{ certificate.compName }} </span
            >competition.
          </span>
        </v-col>
        <v-col v-if="certificateDetails" class="ma-1">
          <span
            v-if="certificateDetails.type == 'course'"
            style="font-size: large"
          >
            You are awarded this certificate for successfully completeing the
            <span class="font-weight-medium"
              >{{ certificateDetails.courseName }}
            </span>
            course.
          </span>
          <span v-else style="font-size: large">
            You are awarded this certificate for successfully completeing the
            <span class="font-weight-medium"
              >{{ certificateDetails.compName }} </span
            >competition.
          </span>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-col v-if="certificate" cols="4" class="ml-1 pl-0">
          <span class="mr-1 text-h6"> No of Downloads </span>
          <span class="text-h6 font-weight-medium primary--text">{{
            certificate.downloads
          }}</span>
        </v-col>
        <v-col v-else cols="4" class="ml-1 pl-0">
          <span class="mr-1 text-h6"> No of Downloads </span>
          <span class="text-h6 font-weight-medium primary--text">{{
            certificateDetails.downloads
          }}</span>
        </v-col>
        <v-col>
          <v-row justify="end">
            <v-btn text color="primary" @click="verifyCertificate()"
              >Verify Certificate</v-btn
            >
            <v-btn text color="primary" @click="downloadCertificate()"
              >Download Certificate</v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  BASE_URL,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
} from "@/common/apiEndpoints.js";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
import { GET_TOKEN, GET_USER_PROFILE } from "@/store/getters";
import { mapMutations, mapGetters } from "vuex";

import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
export default {
  data: () => {
    return {
      dialog: true,
      certificateDialog: false,
    };
  },
  props: ["certificate", "certificateDetails"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    openCertificateDialog() {
      this.searchCertificate();
    },
    closeCertificateDialog() {
      this.dialog = false;
      this.$emit("update");
    },
    verifyCertificate() {
      var url = BASE_URL + VERIFY_CERTIFICATE;
      if (this.certificate) {
        url = url + "?secureKey=" + this.certificate.certId;
      } else {
        url = url + "?secureKey=" + this.certificateDetails.certId;
      }
      window.open(url);
    },
    downloadCertificate() {
      const url = DOWNLOAD_CERTIFICATE;
      let payload = {};
      if (this.certificate) {
        payload = { certId: this.certificate.certId };
      } else {
        payload = { certId: this.certificateDetails.certId };
      }
      axios
        .post(url, payload, {
          responseType: "arraybuffer",
          ...getAxiosOptions(BASE_URL, this.token),
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);

          // Append zoom parameter to the PDF URL
          const zoomedPdfUrl = `${pdfUrl}#zoom=200`;

          // Open the PDF in a new tab at 200% zoom
          window.open(zoomedPdfUrl, "_blank");
          this.closeCertificateDialog();
        })
        .catch((error) => {
          console.error(error);
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
