<template>
  <div v-bind="$props">
    <v-dialog v-model="imgDialog" width="44rem">
      <div style="position: relative">
        <v-btn
          color="red"
          dark
          width="1.3rem"
          class="fixed-button"
          @click="closeImage()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="questionMedia"></v-img>
      </div>
    </v-dialog>
    <v-col
      class="srollContainer"
      v-if="quizContent && quizContent.questions.length != 0"
    >
      <v-col class="ma-2"
        ><span
          class="text-h5"
          v-html="
            formatQuestionTitle(
              quizContent.questions[index].questionNo,
              quizContent.questions[index].title,
              quizContent.questions.length
            )
          "
        >
        </span>
        <div class="ma-2" v-if="quizContent.questions[index].media">
          <v-img
            width="300"
            height="300"
            @click="viewImage(quizContent.questions[index])"
            :src="quizContent.questions[index].media"
          ></v-img>
          <span v-if="quizContent.questions[index].media" class="text-caption"
            >If image is not clear , click on image to see full image</span
          >
        </div>
        <v-col
          class="pl-0"
          v-for="option in quizContent.questions[index].options"
          :key="option.number"
        >
          <label :class="option.isAnswer ? 'success--text' : ''">
            <input
              type="radio"
              name="option.value"
              :value="option.number"
              disabled
              :checked="option.isAnswer"
            />
            <span class="ml-2 pt-0">{{ option.value }}</span></label
          >
        </v-col>
      </v-col>

      <v-row class="ma-4">
        <span class="font-weight-medium text-body-1">
          Explanation :&nbsp;
        </span>
        <span
          class="purple--text text-body-2"
          v-html="quizContent.questions[index].explaination"
        >
        </span>
      </v-row>
      <v-row class="ma-2">
        <v-btn
          class="ma-2"
          small
          :disabled="index == 0"
          color="primary"
          @click="backpage()"
          >Back</v-btn
        >
        <v-btn
          class="ma-2"
          small
          :disabled="index == quizContent.questions.length - 1"
          color="primary"
          @click="nextPage()"
          >Next</v-btn
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { GET_QUIZ_BY_ID, BASE_URL } from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatQuestionTitle } from "@/common/utility";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
export default {
  data() {
    return {
      index: 0,
      imgDialog: false,
      questionMedia: "",
      quizActualData: "",
      quizContent: { questions: [] },
      correctOption: "",
      explaination: "",
    };
  },
  props: ["quizData"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.quizActualData = this.quizData;
    this.getQuizBYQuizId();
  },
  methods: {
    ...mapMutations({
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    closeImage() {
      this.imgDialog = false;
    },
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    nextPage() {
      if (this.index < this.quizContent.questions.length) {
        this.index++;
        this.explaination = "";
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
        this.explaination = "";
      }
    },
    getQuizBYQuizId() {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = this.quizActualData.quizId || this.quizActualData.source;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizContent = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
  },
};
</script>
<style>
.srollContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.fixed-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
</style>
