<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Institues</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchInstitute"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="InstituteStatusFilter"
              label="Draft"
              color="primary"
              value="DRAFT"
              @click="getAllInstitutes()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="InstituteStatusFilter"
              label="Published"
              color="primary"
              value="PUBLISHED"
              @click="getAllInstitutes()"
              hide-details
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn dark text class="text-body-2" @click="openInstitueDialog()"
        >New Institute</v-btn
      >
    </v-toolbar>
    <v-container fluid>
      <CustomDataTable
        :headers="instituteHeaders"
        :items="filteredInstitutes"
        item-key="instituteId"
        class="elevation-0"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="text-body-1 font-weight-medium">{{
            item.instituteName
          }}</span>
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          {{ item.createdBy.name }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewClassrooms(item)">
                <v-icon class="mr-2" color="success"> mdi-eye </v-icon>
                <v-list-item-title>View Classrooms</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openInstitueDialog(item)">
                <v-icon class="mr-2" color="warning"> mdi-pencil </v-icon>
                <v-list-item-title>Edit Details</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.createdOn="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
      </CustomDataTable>
    </v-container>
    <v-dialog persistent v-model="editInstdialog" max-width="700">
      <v-form ref="form" v-model="createInstituteValidation.valid">
        <v-card>
          <v-container>
            <v-row>
              <v-card-title class="primary--text"
                >New Institute</v-card-title
              ></v-row
            >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedInstitute.instituteName"
                  label="Institute Name"
                  :rules="createInstituteValidation.nameRules"
                  required
                  :disabled="editedIndex == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row><v-card-subtitle>Address</v-card-subtitle></v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="editedInstitute.address.addressLine1"
                  label="Service Address Line1"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedInstitute.address.addressLine2"
                  label="Service Address Line2"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  @input="getAddressDetails()"
                  v-model="editedInstitute.address.pincode"
                  label="Pin Number"
                  :rules="createInstituteValidation.pinRules"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedInstitute.address.city"
                  label="City"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedInstitute.address.state"
                  label="State"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeInstitueDialog()">
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="saveInstitute()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  BASE_URL,
  GET_ALL_INSTITUTES,
  CREATE_INSTITUTE,
  UPDATE_INSTITUTE,
  GET_ADDRESS_DETAILS,
} from "@/common/apiEndpoints";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      dialog: false,
      editInstdialog: false,
      showSearchField: false,
      searchText: "",
      editedIndex: -1,
      filteredInstitutes: [],
      InstituteDialogTitle: "New Institute",
      InstituteStatusFilter: false,
      editedInstitute: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      defaultItem: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      createInstituteValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        instituteRules: [(v) => !!v || "Institute Name is required"],
        addressRules: [(v) => !!v || "Address Line1 is required"],
        addresslineRules: [(v) => !!v || "Address Line2 is required"],
        cityRules: [(v) => !!v || "City is required"],
        stateRules: [(v) => !!v || "State is required"],
        pinRules: [
          (v) => !!v || "Pin is required",
          (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
        ],
      },
      institutes: [],
      instituteHeaders: [
        { text: "Institute Name", value: "name" },
        { text: "Created On", value: "createdOn" },
        { text: "Created By", value: "createdBy" },
        { text: "Actions", value: "action", sortable: false },
      ],
      menu: false,
      instituteStatusFilter: [],
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: {
    CustomDataTable,
  },
  mounted: function () {
    this.getAllInstitutes();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    viewClassrooms(item) {
      let InstituteParamsValue = {};
      InstituteParamsValue.instituteId = item.instituteId;
      InstituteParamsValue.instituteName = item.instituteName;
      this.setParams(InstituteParamsValue);
      this.$router.push({ name: "ClassRoom" });
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.editedInstitute.address.pincode.length == 6) {
        url += this.editedInstitute.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.district == null) {
              this.editedInstitute.address.city = "";
              this.editedInstitute.address.state = "";
            } else {
              this.editedInstitute.address.city = res.data.district;
              this.editedInstitute.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    openInstitueDialog(item) {
      this.editInstdialog = true;
      if (item) {
        this.editedIndex = 0;
        this.editedInstitute = Object.assign({}, item);
      }
    },
    closeInstitueDialog() {
      this.editInstdialog = false;
      this.editedInstitute = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    getAllInstitutes() {
      this.menu = false;
      let url = GET_ALL_INSTITUTES;
      if (
        !(
          this.instituteStatusFilter.length == 0 ||
          this.instituteStatusFilter.length == 2
        )
      ) {
        url = url + "?status=" + this.instituteStatusFilter[0];
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institutes = res.data.institutes;
          this.filteredInstitutes = this.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    searchInstitute() {
      if (this.searchText) {
        this.filteredInstitutes = this.institutes.filter((inst) => {
          return inst.instituteName
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredInstitutes = this.institutes;
      }
    },
    trimEditedInstitue() {
      this.editedInstitute.instituteName =
        this.editedInstitute.instituteName.trim();
      this.editedInstitute.address.addressLine1 =
        this.editedInstitute.address.addressLine1.trim();
      this.editedInstitute.address.addressLine2 =
        this.editedInstitute.address.addressLine2.trim();
      this.editedInstitute.address.city =
        this.editedInstitute.address.city.trim();
      this.editedInstitute.address.state =
        this.editedInstitute.address.state.trim();
    },
    saveInstitute() {
      this.validate();
      if (this.createInstituteValidation.valid) {
        this.trimEditedInstitue();
        let payload = {};
        let url = "";
        if (this.editedIndex > -1) {
          payload = this.editedInstitute;
          url = UPDATE_INSTITUTE;
        } else {
          payload = this.editedInstitute;
          url = CREATE_INSTITUTE;
        }
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createInstituteValidation.valid = false;
            this.editedIndex = -1;
            this.editedInstitute = res.data;
            this.setOverlayValue(false);
            this.getAllInstitutes();
            if (this.editedIndex > -1) {
              this.setApiSuccess("Institute updated successfully");
            } else {
              this.setApiSuccess("Institute created successfully");
            }
            this.closeInstitueDialog();
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
