<template>
  <div>
    <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
    <v-dialog persistent v-model="attendanceDialog" max-width="70rem">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <CustomDataTable
          :headers="studentHeaders"
          :items="studentsList"
          item-key="studentId"
          item-value="studentId"
          :search="searchStudent"
        >
          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  item.email
                    ? item.email.length > 20
                      ? item.email.substring(0, 20) + "..."
                      : item.email
                    : "---"
                }}</span>
              </template>
              <span>{{ item.email ? item.email : "---" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-row justify="center">
              <v-col>
                <v-chip
                  small
                  :color="
                    item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                  "
                >
                  {{ item.status }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </CustomDataTable>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAttendanceDialog()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="endClassDialog" width="35rem">
      <v-card>
        <v-card-title class="primary--text">End Class</v-card-title>
        <v-form ref="form" v-model="endClassValidation.valid">
          <v-container>
            <v-card-title v-if="model == NON_FRANCHISE_MODEL"
              >Please select the notes you want to release</v-card-title
            >
            <v-row class="ma-1" v-if="model == NON_FRANCHISE_MODEL">
              <v-col>
                <v-select
                  v-model="courseCoverage.chapterNo"
                  :items="course.chapters"
                  label="Chapter Name"
                  required
                  outlined
                  dense
                  item-text="chapterName"
                  item-value="chapterNo"
                  @change="fetchLessons(courseCoverage.chapterNo)"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="courseCoverage.lessonNo"
                  :items="lessons"
                  label="Lesson Name"
                  outlined
                  dense
                  item-text="lessonName"
                  item-value="lessonNo"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
            <v-card-title
              >Can you give us some feedback of the class?</v-card-title
            >
            <v-row class="ma-1">
              <v-col>
                <v-text-field
                  v-model="editedClassOps.feedback"
                  label="Feedback"
                  :rules="endClassValidation.feedbackRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeEndClassDialog()"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="openEndClassConfirm()"
                >End Class</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="startClassDialog" width="40rem">
      <v-card>
        <v-card-title class="primary--text">Start Class</v-card-title>
        <v-form ref="form" v-model="startClassValidation.valid">
          <v-container>
            <v-card-title
              >Please select the Chapter and Lesson name for which you want to
              release the content</v-card-title
            >
            <v-row class="ma-1">
              <v-col>
                <v-select
                  v-model="courseCoverage.chapterNo"
                  :items="course.chapters"
                  label="Chapter Name"
                  required
                  outlined
                  dense
                  item-text="chapterName"
                  item-value="chapterNo"
                  @change="fetchLessons(courseCoverage.chapterNo)"
                  :rules="startClassValidation.nameRules"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="courseCoverage.lessonNo"
                  :items="lessons"
                  label="Lesson Name"
                  outlined
                  dense
                  item-text="lessonName"
                  item-value="lessonNo"
                  required
                  :rules="startClassValidation.nameRules"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeEndClassDialog()"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="JoinClass(editedClassOps)"
                >Start Class</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="endClassConfirmDialog" max-width="35rem">
      <v-card>
        <v-card-title>Please verify Chapter and Lesson Name</v-card-title>
        <v-card-subtitle class="ma-2"
          >Chapter : {{ selectedChapterName }}</v-card-subtitle
        >
        <v-card-subtitle class="ma-2"
          >Lesson : {{ selectedLessonName }}</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEndClassConfirm()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="endClass()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="rescheduleClassDialog" max-width="44rem">
      <v-card>
        <v-card-title class="primary--text">Reschedule Class</v-card-title>
        <v-form ref="form" v-model="rescheduleClassValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                  v-model="editedClassOps.reason"
                  label="Reason"
                  :rules="rescheduleClassValidation.nameRules"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="20rem"
                    min-width="20rem"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedClassOps.startDate"
                        label="Start date"
                        :rules="rescheduleClassValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedClassOps.startDate"
                      full-width
                      :min="new Date().toISOString().substr(0, 10)"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-model="openTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="20rem"
                    min-width="20rem"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedClassOps.startTime"
                        label="Start Time"
                        :rules="rescheduleClassValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="editedClassOps.startTime"
                      :allowed-minutes="[0, 30]"
                      full-width
                      @input="openTimeMenu = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="closeTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="20rem"
                    min-width="20rem"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedClassOps.endTime"
                        label="End Time"
                        :rules="rescheduleClassValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      full-width
                      :allowed-minutes="[0, 30]"
                      v-model="editedClassOps.endTime"
                      @input="closeTimeMenu = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeRescheduleDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="reScheduleClass()"
                  >Reschedule</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="cancelClassConfirmDialog" max-width="35rem">
      <v-card>
        <v-card-title>Are you sure you want to cancel class ? </v-card-title>
        <v-form ref="form" v-model="cancelClassValidation.valid">
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="editedClassOps.reason"
                label="Reason"
                :rules="cancelClassValidation.nameRules"
                required
              ></v-text-field>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeCancelConfirmDialog()"
            >No</v-btn
          >
          <v-btn color="primary" text @click="cancelClass()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createUpdateAssignmentDialog" max-width="1000">
      <v-card>
        <v-card-title>{{ assignmentDialogTitle }}</v-card-title>
        <v-card-text>
          <v-radio-group
            v-model="editedClassOps.assignmentType"
            row
            :disabled="editedIndex == 0"
          >
            <v-radio label="Quiz" value="QUIZ"></v-radio>
            <v-radio label="Code" value="CODE"></v-radio>
            <v-radio label="Text" value="TEXT"></v-radio>
            <v-radio label="Codely" value="CODELY"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-form
          class="ml-3 mr-3"
          ref="form"
          v-model="quizAssignmentValidation.valid"
          v-if="editedClassOps.assignmentType == 'QUIZ'"
        >
          <v-container>
            <v-select
              :items="filteredQuiz"
              v-model="editedClassOps.quiz"
              label="Quiz Name"
              :rules="quizAssignmentValidation.nameRules"
              dense
              item-text="quizName"
              item-value="quizId"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="searchTerm"
                      placeholder="Search"
                      @input="searchQuiz"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.quizName }}
              </template>
              <template v-slot:append-outer>
                <v-tooltip bottom v-if="editedClassOps.quiz">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="getQuizByQuizId(editedClassOps.quiz)"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>Preview Quiz</span>
                </v-tooltip>
              </template>
            </v-select>
            <!-- <v-autocomplete
              label="Quiz Name"
              :items="quizes"
              v-model="editedClassOps.quiz"
              :search-input.sync="searchQuiz"
              dense
              item-text="quizName"
              item-value="quizId"
              :rules="quizAssignmentValidation.nameRules"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom v-if="editedClassOps.quiz">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="getQuizByQuizId(editedClassOps.quiz)"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>Preview Quiz</span>
                </v-tooltip>
              </template>
            </v-autocomplete> -->
          </v-container>
        </v-form>
        <v-dialog v-model="imgDialog">
          <v-card>
            <v-img :src="questionMedia"></v-img>
          </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="quizPreviewDialog" eager persistent max-width="700">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                selectedQuiz.quizName
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeQuizPreviewDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div
              v-if="
                selectedQuiz &&
                selectedQuiz.questions &&
                selectedQuiz.questions.length > 0
              "
            >
              <v-col>
                <span
                  style="font-size: 1.1rem"
                  v-html="
                    formatQuestionTitle(
                      selectedQuiz.questions[index].questionNo,
                      selectedQuiz.questions[index].title,
                      selectedQuiz.questions.length
                    )
                  "
                ></span>
                <div class="mt-4" v-if="selectedQuiz.questions[index].media">
                  <v-img
                    max-width="300"
                    max-height="300"
                    @click="viewImage(selectedQuiz.questions[index])"
                    :src="selectedQuiz.questions[index].media"
                  ></v-img>
                  <span
                    v-if="selectedQuiz.questions[index].media"
                    style="font-size: 0.9rem"
                    >If image is not clear , click on image to see full
                    image</span
                  >
                </div>
                <v-col
                  class="pl-0"
                  v-for="option in selectedQuiz.questions[index].options"
                  :key="option.number"
                >
                  <label :class="option.isAnswer ? 'success--text' : ''">
                    <input
                      type="radio"
                      name="option.value"
                      :value="option.number"
                      disabled
                      :checked="option.isAnswer"
                    />
                    {{ option.value }}</label
                  >
                </v-col>
              </v-col>
              <v-row class="ma-4">
                <span style="font-weight: 500; font-size: 1rem">
                  Explanation :
                </span>
                <span
                  class="purple--text"
                  style="font-weight: 400; font-size: 1rem"
                  v-html="selectedQuiz.questions[index].explaination"
                >
                </span>
              </v-row>
              <v-row class="ma-2">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  small
                  :disabled="index == 0"
                  color="primary"
                  @click="backpage()"
                  >Back</v-btn
                >
                <v-btn
                  class="ma-2"
                  small
                  :disabled="index == selectedQuiz.questions.length - 1"
                  color="primary"
                  @click="nextPage()"
                  >Next</v-btn
                >
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-card>
        </v-dialog> -->

        <v-form
          class="ml-3 mr-3"
          ref="form"
          v-model="codeAssignmentValidation.valid"
          v-if="editedClassOps.assignmentType == 'CODE'"
        >
          <v-container fluid>
            <v-col>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="editedClassOps.title"
                    label="Name"
                    :rules="codeAssignmentValidation.nameRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    type="number"
                    required
                    label="Coins"
                    v-model="editedClassOps.coins"
                    :rules="codeAssignmentValidation.coinRules"
                    :disabled="editedIndex != -1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <span>Question</span>
              <quill-editor
                style="height: 10rem; margin-bottom: 3rem"
                :options="editorOptions"
                v-model="editedClassOps.question"
                @input="validateQuestion()"
              ></quill-editor>
              <span class="error--text text-caption">{{
                questionFieldError
              }}</span>
              <v-textarea
                outlined
                auto-grow
                class="pt-8"
                rows="1"
                row-height="15"
                v-model="editedClassOps.expectedOutput"
                label="Expected Output"
                :rules="codeAssignmentValidation.nameRules"
                required
              ></v-textarea>
              <span>Solution</span>
              <quill-editor
                style="height: 10rem; margin-bottom: 3rem"
                :options="editorOptions"
                v-model="editedClassOps.solution"
                @input="validateSolution()"
              ></quill-editor>
              <span class="error--text text-caption">{{
                solutionFieldError
              }}</span>
            </v-col>
          </v-container>
        </v-form>

        <v-form
          class="ml-3 mr-3"
          ref="form"
          v-model="textAssignmentValidation.valid"
          v-if="editedClassOps.assignmentType == 'TEXT'"
        >
          <v-container fluid>
            <v-col>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="editedClassOps.title"
                    label="Name"
                    :rules="textAssignmentValidation.nameRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    type="number"
                    required
                    label="Coins"
                    v-model="editedClassOps.coins"
                    :rules="textAssignmentValidation.coinRules"
                    :disabled="editedIndex != -1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <span>Question</span>
              <quill-editor
                style="height: 10rem; margin-bottom: 3rem"
                :options="editorOptions"
                class="mb-4"
                v-model="editedClassOps.question"
                @input="validateQuestion()"
              ></quill-editor>
              <span class="error--text text-caption">{{
                questionFieldError
              }}</span>
              <v-row>
                <v-col>
                  <span>Answer</span>
                  <quill-editor
                    style="height: 10rem; margin-bottom: 3rem"
                    :options="editorOptions"
                    v-model="editedClassOps.answer"
                    @input="validateAnswer()"
                  ></quill-editor>
                  <span class="error--text text-caption">{{
                    answerFieldError
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
        <v-form
          class="ml-3 mr-3"
          ref="form"
          v-model="codelyAssignmentValidation.valid"
          v-if="editedClassOps.assignmentType == 'CODELY'"
        >
          <v-container fluid>
            <v-col>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="editedClassOps.title"
                    label="Name"
                    :rules="codelyAssignmentValidation.nameRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    type="number"
                    required
                    label="Coins"
                    v-model="editedClassOps.coins"
                    :rules="codelyAssignmentValidation.coinRules"
                    :disabled="editedIndex != -1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span style="font-weight: bolder">QUESTION LINK </span>
                  <v-text-field
                    v-model="editedClassOps.codelyLink"
                    label="Enter Question Link"
                    :rules="codelyAssignmentValidation.nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span style="font-weight: bolder">SOLUTION LINK </span>
                  <v-text-field
                    v-model="editedClassOps.solutionLink"
                    :rules="codelyAssignmentValidation.nameRules"
                    label="Enter solution Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeCreateUpdateAssignmentDialog()"
            >Cancel</v-btn
          >
          <!-- <v-btn
            color="primary"
            v-if="editedIndex != 0"
            :disabled="!editedClassOps.assignmentType"
            text
            @click="createAssignment()"
            >Create</v-btn
          > -->
          <v-btn
            color="primary"
            text
            @click="openUpdateAssignmentConfirmDialog()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="viewCodeDialog" max-width="40rem">
      <v-card>
        <v-toolbar dark dense flat color="primary">
          <v-toolbar-title>Submission</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip class="ma-2" color="Black" label text-color="white">
            {{ assignmentLangForSubmission }}
          </v-chip>
        </v-toolbar>
        <v-row class="ma-1">
          <span class="mt-6 ml-5 error--text text-body-1 font-weight-medium"
            >Question:
          </span>
          <span
            class="mt-6 ml-1 text-body-1"
            v-html="editedClassOps.question"
          ></span>
        </v-row>
        <span class="mt-6 ml-6 error--text text-body-1 font-weight-medium"
          >Student Submissions:</span
        >
        <v-card-text class="mt-2">
          <v-textarea
            v-model="submittedCode"
            rows="10"
            outlined
            :readonly="true"
          ></v-textarea>
          <span
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            class="mt-6 ml-0 error--text text-body-1 font-weight-medium"
            >Assignment Solution:</span
          >
          <div
            class="overflow-auto my-2"
            style="border: 0.1rem solid #ccc; padding: 0.3rem; height: 13rem"
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            v-html="codeAssignmentEvl.solution"
          ></div>
          <!-- <v-slider
            class="mt-4"
            v-if="userType == USER_TYPE_TEACHER"
            v-model="codeAssignmentEvl.coins"
            :thumb-size="24"
            thumb-label="always"
            label="Coins"
            max="10"
            min="0"
          ></v-slider> -->
          <v-col
            cols="2"
            class="ma-1 pa-0"
            v-if="userType == USER_TYPE_TEACHER"
          >
            <v-text-field
              v-model="codeAssignmentEvl.coins"
              :rules="codeAssignmentValidation.coinRules"
              class="mt-4"
              type="number"
              required
              label="Coins"
              :hint="'Max Coins: ' + editedClassOps.coins"
            >
            </v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSubmittedCodeDialog()"
            >Close</v-btn
          >
          <v-btn
            v-if="userType == USER_TYPE_TEACHER"
            color="primary"
            text
            @click="codeEvaluate()"
            >Evaluate</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="viewTextDialog" max-width="40rem">
      <v-card>
        <v-toolbar dark dense flat color="primary">
          <v-toolbar-title>Submission</v-toolbar-title>
        </v-toolbar>
        <v-col>
          <span class="mt-4 ml-0 error--text text-body-1 font-weight-medium"
            >Question:</span
          >
          <div
            class="overflow-auto my-2"
            style="border: 0.1rem solid #ccc; padding: 0.3rem"
            v-html="editedTextAssignment.question"
          ></div>

          <span class="mt-4 ml-0 error--text text-body-1 font-weight-medium"
            >Student Submissions:</span
          >
          <div
            class="overflow-auto my-2"
            style="border: 0.1rem solid #ccc; padding: 0.3rem"
            v-html="submittedText"
          ></div>
        </v-col>
        <v-col>
          <span
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            class="mt-4 ml-0 error--text text-body-1 font-weight-medium"
            >Assignment Answer:</span
          >
          <div
            class="overflow-auto my-2"
            style="border: 0.1rem solid #ccc; padding: 0.3rem"
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            v-html="textAssignmentEvl.answer"
          ></div>
        </v-col>
        <v-card-text>
          <!-- <v-slider
            v-if="userType == USER_TYPE_TEACHER"
            v-model="textAssignmentEvl.coins"
            :thumb-size="24"
            thumb-label="always"
            label="Coins"
            max="10"
            min="0"
          ></v-slider> -->
          <v-col class="pa-0" cols="2" v-if="userType == USER_TYPE_TEACHER">
            <v-text-field
              v-model="textAssignmentEvl.coins"
              :rules="textAssignmentValidation.coinRules"
              type="number"
              required
              label="Coins"
              :hint="'Max Coins: ' + editedTextAssignment.coins"
            >
            </v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSubmittedTextDialog()"
            >Close</v-btn
          >
          <v-btn
            v-if="userType == USER_TYPE_TEACHER"
            color="primary"
            text
            @click="textEvaluate()"
            >Evaluate</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="viewCodelyDialog" width="55rem" height="100%">
      <v-card>
        <v-toolbar dark dense flat color="primary">
          <v-toolbar-title>Submission</v-toolbar-title>
        </v-toolbar>
        <!-- <v-col>
          <span class="mt-4 ml-0 blue--text" style="font-weight: 500; font-size: 1.3rem">
            {{ editedCodelyAssignment.title }}
          </span>
        </v-col> -->

        <v-col>
          <span class="mt-4 ml-0 error--text text-h5 font-weight-medium"
            >Question</span
          >

          <v-text-field
            v-model="editedCodelyAssignment.codelyLink"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="copyToClipboard(editedCodelyAssignment.codelyLink)"
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openInNewTab(editedCodelyAssignment.codelyLink)"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                </template>
                <span>Open</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <!-- <vue-friendly-iframe
                        class="iframe"
                        :src="editedCodelyAssignment.codelyLink"
                        allow="clipboard-read; clipboard-write"
                        
                    ></vue-friendly-iframe> -->
        </v-col>

        <v-col>
          <span class="mt-4 ml-0 error--text text-body-1 font-weight-medium"
            >Student Answer</span
          >
          <v-text-field
            v-model="submittedCodely"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="copyToClipboard(submittedCodely)"
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openInNewTab(submittedCodely)"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                </template>
                <span>Open</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- <vue-friendly-iframe
                  class="iframe"
                  :src="submittedCodely"
                  allow="clipboard-read; clipboard-write"
                      
              ></vue-friendly-iframe> -->
        </v-col>

        <v-col>
          <span
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            class="mt-4 ml-0 error--text text-body-1 font-weight-medium"
            >Actual Answer</span
          >
          <v-text-field
            v-if="
              userType == USER_TYPE_TEACHER || userType == USER_TYPE_STUDENT
            "
            v-model="codelyAssignmentEvl.solutionLink"
            prepend-inner-icon="mdi-link"
            readonly
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="copyToClipboard(codelyAssignmentEvl.solutionLink)"
                    color="primary"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="openInNewTab(codelyAssignmentEvl.solutionLink)"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  >
                </template>
                <span>Open</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-card-text>
          <v-col class="pa-0" cols="2" v-if="userType == USER_TYPE_TEACHER">
            <v-text-field
              v-model="codelyAssignmentEvl.coins"
              :rules="textAssignmentValidation.coinRules"
              type="number"
              required
              label="Coins"
              :hint="'Max Coins: ' + editedCodelyAssignment.coins"
            >
            </v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSubmittedCodelyDialog()"
            >Close</v-btn
          >
          <v-btn
            v-if="userType == USER_TYPE_TEACHER"
            color="primary"
            text
            @click="codelyEvaluate()"
            >Evaluate</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor" top>
        {{ snackbarText }}
      </v-snackbar>
    </v-dialog>

    <v-dialog v-model="completeQuizDialog" eager persistent max-width="600">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            v-if="userType == USER_TYPE_TEACHER || userType == USER_TYPE_ADMIN"
          >
            Submission
          </v-toolbar-title>

          <v-toolbar-title v-if="userType == USER_TYPE_STUDENT">
            Quiz Assignment
          </v-toolbar-title>
          <!-- {{ quizName }} -->
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openCompleteQuizConfirmDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <QuizAssignment
            v-if="completeQuizDialog"
            v-bind:classOpId="editedClassOps.classOpId"
            v-bind:assignmentNumber="assignmentNumber"
            v-bind:quizAssignment="editedClassOps.quizAssignment"
            @assignmentQuestionCompleted="updateQuizAssignmentProgress"
          />
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :dialog="completeQuizConfirmDialog"
      :subMessage1="'The Quiz is not yet complete. Are you sure you want to leave the quiz'"
      @close="closeCompleteQuizConfirmDialog()"
      @confirm="closecompleteQuizDialog()"
    />
    <!-- <v-dialog v-model="completeQuizConfirmDialog" persistent width="500">
      <v-card>
        <v-card-title
          >The Quiz is not yet complete. Are you sure you want to leave the
          quiz?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeCompleteQuizConfirmDialog()"
            >No</v-btn
          >
          <v-btn color="primary" text @click="closecompleteQuizDialog()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog persistent v-model="updateAssignmentConfirmDialog" width="35rem">
      <v-card>
        <v-card-title v-if="editedClassOps.assignmentType == 'QUIZ'"
          >All the assignments submitted by the student will be lost. Are you
          sure you want to update?
        </v-card-title>
        <v-card-title v-else
          >Do you want to remove the student's assignment submissions ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editedClassOps.assignmentType == 'QUIZ'"
            color="primary"
            text
            @click="closeUpdateAssignmentConfirmDialog()"
            >No</v-btn
          >
          <v-btn
            v-if="
              editedClassOps.assignmentType == 'CODE' ||
              editedClassOps.assignmentType == 'TEXT' ||
              editedClassOps.assignmentType == 'CODELY'
            "
            color="primary"
            text
            @click="updateAssignment(false)"
            >No</v-btn
          >
          <v-btn color="primary" text @click="updateAssignment(true)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      v-if="classOps.length > 0"
      :headers="classHeaders"
      :items="classOps"
      :hide-default-footer="classOps.length <= 8"
      sort-by="firstName"
      :items-per-page="8"
      :page="pageNo"
      :single-expand="true"
      show-expand
      item-key="classOpId"
      @item-expanded="expandClassOp"
      @contextmenu:row="rightClickHandler"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.classInfo.name }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDateTimeWithDay(item.classInfo.startDateTime) }}
      </template>
      <template v-slot:[`item.startDateTime`]="{ item }">
        {{ formattedTime(item.classInfo.startDateTime) }}
      </template>
      <template v-slot:[`item.endDateTime`]="{ item }">
        {{ formattedTime(item.classInfo.endDateTime) }}
      </template>
      <template v-slot:[`item.actualStartTime`]="{ item }">
        {{ item.actualStartTime ? formatDateTime(item.actualStartTime) : "--" }}
      </template>
      <template v-slot:[`item.actualEndTime`]="{ item }">
        {{ item.actualEndTime ? formatDateTime(item.actualEndTime) : "--" }}
      </template>
      <template v-slot:[`item.teacherName`]="{ item }">
        {{ item.teacherInfo.teacherName }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row justify="center">
          <v-col class="pa-0 ma-0 mt-2">
            <v-chip small :color="getClassStatusColor(item)" class="mb-1">
              {{ item.status }}
            </v-chip>
            <v-icon v-if="item.isExtra == true" class="pa-0 ma-0 mx-1"
              >mdi-alpha-e-circle-outline</v-icon
            >
          </v-col>
        </v-row>
        <v-row
          v-if="item.status == 'COMPLETED' && item.topicCovered.length != 0"
        >
          <v-col class="pa-0 ma-0 mb-2">
            <v-chip x-small color="orange-chip">
              {{ "C " + item.topicCovered[0].chapterNo }}
            </v-chip>
            <v-chip class="mx-1" x-small color="orange-chip">
              {{ "L " + item.topicCovered[0].lessonNo }}
            </v-chip>
          </v-col>
        </v-row>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card flat>
            <v-tabs v-model="tab" class="mb-1">
              <v-tab @click="expandedQuizAssignment = []">Quiz</v-tab>
              <v-tab @click="expandedCodeAssignment = []">Code</v-tab>
              <v-tab @click="expandedTextAssignment = []">Text</v-tab>
              <v-tab @click="expandedCodelyAssignment = []">Codely</v-tab>
              <v-tab
                v-if="
                  userType == USER_TYPE_TEACHER || userType == USER_TYPE_ADMIN
                "
                >Feedback</v-tab
              >
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table
                  v-if="item.quizAssignments.length > 0"
                  :headers="quizAssignmentHeaders"
                  :items="item.quizAssignments"
                  :expanded.sync="expandedQuizAssignment"
                  :hide-default-footer="item.quizAssignments.length <= 10"
                  sort-by="firstName"
                  :single-expand="true"
                  show-expand
                  item-key="assignmentNumber"
                  @item-expanded="getQuizSubmissionList"
                >
                  <template v-slot:expanded-item="{ headers }">
                    <td :colspan="headers.length">
                      <v-data-table
                        v-if="submissions.length > 0"
                        :headers="quizAssignmentSubmissionHeaders"
                        :items="submissions"
                        :hide-default-footer="submissions.length <= 10"
                      >
                        <template v-slot:[`item.studentId`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <!-- {{ studentFirstName }} {{ studentLastName }} -->
                              {{ fetchNameById(item.studentId) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.completed`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.completed == 100
                                    ? 'success-chip'
                                    : 'orange-chip'
                                "
                              >
                                {{ item.completed }}%
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.questions`]="{ item }">
                          <v-col class="pa-0 ma-0">
                            <v-chip small color="success-chip">
                              {{ fetchCorrect(item.questions) }}
                            </v-chip>
                          </v-col>
                          <v-col class="pa-0 ma-0 mt-1">
                            <v-chip small color="error-chip">
                              {{ fetchIncorrect(item.questions) }}
                            </v-chip>
                          </v-col>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <v-row justify="center">
                            <v-col v-if="item.completed == 100">
                              {{ formatDateTime(item.date) }}
                            </v-col>
                            <v-col v-else>--</v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            v-if="item.completed == 100"
                            color="primary"
                            text
                            @click="viewQuizAssignmentReport(item)"
                          >
                            view Full Report
                          </v-btn>
                          <v-col v-else>--</v-col>
                        </template>
                      </v-data-table>
                      <CustomDataTable
                        v-else
                        :headers="quizAssignmentSubmissionHeaders"
                        :items="submissions"
                        :hide-default-footer="submissions.length <= 10"
                      />
                    </td>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="warning"
                          @click="
                            openCreateUpdateAssignmentDialog(
                              item,
                              'UPDATE',
                              'QUIZ'
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          class="mr-2"
                          @click="getQuizByQuizId(item.quizId)"
                          >mdi-eye</v-icon
                        >
                      </template>
                      <span>Preview</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        model == NON_FRANCHISE_MODEL &&
                        userType == USER_TYPE_STUDENT &&
                        item.status != CLASS_STATUS_CANCELED &&
                        (item.progress.length == 0 ||
                          (item.progress[0] &&
                            item.progress[0].completed != 100))
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="secondary"
                          @click="completeQuizAssignment(item)"
                        >
                          mdi-application-braces-outline
                        </v-icon>
                      </template>
                      <span>Attempt</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <CustomDataTable
                  v-else
                  :headers="quizAssignmentHeaders"
                  :items="item.quizAssignments"
                  :hide-default-footer="item.quizAssignments.length <= 10"
                />
              </v-tab-item>

              <v-tab-item>
                <v-data-table
                  v-if="item.codeAssignments.length > 0"
                  :headers="assignmentHeaders"
                  :items="item.codeAssignments"
                  :expanded.sync="expandedCodeAssignment"
                  :hide-default-footer="item.codeAssignments.length <= 10"
                  sort-by="firstName"
                  :single-expand="true"
                  show-expand
                  item-key="assignmentNumber"
                  @item-expanded="getSubmissionList"
                >
                  <template v-slot:expanded-item="{ headers }">
                    <td :colspan="headers.length">
                      <CustomDataTable
                        :headers="submissionHeaders"
                        :items="submissions"
                        :hide-default-footer="submissions.length <= 10"
                        sort-by="firstName"
                      >
                        <template v-slot:[`item.studentId`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <!-- {{ studentFirstName }} {{ studentLastName }} -->
                              {{ fetchNameById(item.studentId) }}
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'success-chip'
                                    : 'primary-chip'
                                "
                              >
                                {{ item.status }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.earnedCoins`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'orange-chip'
                                    : 'secondary-chip'
                                "
                              >
                                {{ item.earnedCoins ? item.earnedCoins : 0 }}/{{
                                  editedClassOps.coins
                                }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              {{ formatDateTime(item.date) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            color="primary"
                            text
                            @click="openSubmittedCodeDialog(item)"
                          >
                            View Code
                          </v-btn>
                        </template>
                      </CustomDataTable>
                    </td>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="warning"
                          @click="
                            openCreateUpdateAssignmentDialog(
                              item,
                              'UPDATE',
                              'CODE'
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="success"
                          @click="previewCodeAssignment(item)"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Preview</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="
                        model == NON_FRANCHISE_MODEL &&
                        userType == USER_TYPE_STUDENT &&
                        item.status != CLASS_STATUS_CANCELED &&
                        item.submissions.length == 0
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="secondary"
                          @click="completeCodeAssignment(item)"
                        >
                          mdi-application-braces-outline
                        </v-icon>
                      </template>
                      <span>Attempt</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <CustomDataTable
                  v-else
                  :headers="assignmentHeaders"
                  :items="item.codeAssignments"
                  :hide-default-footer="item.codeAssignments.length <= 10"
                />
              </v-tab-item>

              <v-tab-item>
                <v-data-table
                  v-if="item.textAssignments.length > 0"
                  :headers="textassignmentHeaders"
                  :items="item.textAssignments"
                  :expanded.sync="expandedTextAssignment"
                  :hide-default-footer="item.textAssignments.length <= 10"
                  sort-by="firstName"
                  :single-expand="true"
                  show-expand
                  item-key="assignmentNumber"
                  @item-expanded="getSubmissionList"
                >
                  <template v-slot:expanded-item="{ headers }">
                    <td :colspan="headers.length">
                      <CustomDataTable
                        :headers="submissionHeaders"
                        :items="submissions"
                        :hide-default-footer="submissions.length <= 10"
                        sort-by="firstName"
                      >
                        <template v-slot:[`item.studentId`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <!-- {{ studentFirstName }} {{ studentLastName }} -->
                              {{ fetchNameById(item.studentId) }}
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'success-chip'
                                    : 'primary-chip'
                                "
                              >
                                {{ item.status }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.earnedCoins`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'orange-chip'
                                    : 'secondary-chip'
                                "
                              >
                                {{ item.earnedCoins ? item.earnedCoins : 0 }}/{{
                                  editedTextAssignment.coins
                                }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              {{ formatDateTime(item.date) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            color="primary"
                            text
                            @click="openSubmittedTextDialog(item)"
                          >
                            View Text
                          </v-btn>
                        </template>
                      </CustomDataTable>
                    </td>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="warning"
                          @click="
                            openCreateUpdateAssignmentDialog(
                              item,
                              'UPDATE',
                              'TEXT'
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="success"
                          @click="previewTextAssignment(item)"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Preview</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        model == NON_FRANCHISE_MODEL &&
                        userType == USER_TYPE_STUDENT &&
                        item.status != CLASS_STATUS_CANCELED &&
                        item.submissions.length == 0
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="secondary"
                          @click="previewTextAssignment(item)"
                        >
                          mdi-application-braces-outline
                        </v-icon>
                      </template>
                      <span>Attempt</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <CustomDataTable
                  v-else
                  :headers="textassignmentHeaders"
                  :items="item.textAssignments"
                  :hide-default-footer="item.textAssignments.length <= 10"
                />
              </v-tab-item>

              <v-tab-item>
                <v-data-table
                  v-if="item.codelyAssignments.length > 0"
                  :headers="codelyassignmentHeaders"
                  :items="item.codelyAssignments"
                  :hide-default-footer="submissions.length <= 10"
                  :expanded.sync="expandedCodelyAssignment"
                  sort-by="firstName"
                  :single-expand="true"
                  show-expand
                  item-key="assignmentNumber"
                  @item-expanded="getSubmissionList"
                >
                  <!-- :hide-default-footer="item.codelyAssignments.length <= 10" -->

                  <template v-slot:expanded-item="{ headers }">
                    <td :colspan="headers.length">
                      <CustomDataTable
                        :headers="submissionHeaders"
                        :items="submissions"
                        :hide-default-footer="submissions.length <= 10"
                        sort-by="firstName"
                      >
                        <template v-slot:[`item.studentId`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              {{ fetchNameById(item.studentId) }}
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'success-chip'
                                    : 'primary-chip'
                                "
                              >
                                {{ item.status }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.earnedCoins`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              <v-chip
                                small
                                :color="
                                  item.status == 'SUBMITTED'
                                    ? 'orange-chip'
                                    : 'secondary-chip'
                                "
                              >
                                {{ item.earnedCoins ? item.earnedCoins : 0 }}/{{
                                  editedClassOps.coins
                                }}
                              </v-chip>
                              <!-- {{ editedCodelyAssignment.coins }} -->
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <v-row justify="center">
                            <v-col>
                              {{ formatDateTime(item.date) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            color="primary"
                            text
                            @click="openSubmittedCodelyDialog(item)"
                          >
                            View Codely
                          </v-btn>
                        </template>
                      </CustomDataTable>
                    </td>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="warning"
                          @click="
                            openCreateUpdateAssignmentDialog(
                              item,
                              'UPDATE',
                              'CODELY'
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="
                        (userType == USER_TYPE_ADMIN ||
                          userType == USER_TYPE_TEACHER) &&
                        item.status != CLASS_STATUS_CANCELED
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="previewCodelyAssignment(item)"
                          class="mr-2"
                          color="success"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Preview</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        model == NON_FRANCHISE_MODEL &&
                        userType == USER_TYPE_STUDENT &&
                        item.status != CLASS_STATUS_CANCELED &&
                        item.submissions.length == 0
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="secondary"
                          @click="attemptCodelyAssignment(item)"
                        >
                          mdi-application-braces-outline
                        </v-icon>
                      </template>
                      <span>Attempt</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <CustomDataTable
                  v-else
                  :headers="codelyassignmentHeaders"
                  :items="item.codelyAssignments"
                  :hide-default-footer="submissions.length <= 10"
                />
              </v-tab-item>

              <v-dialog
                v-model="previewQuizAssignmentDialog"
                eager
                persistent
                max-width="700"
              >
                <v-card class="pb-3">
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>{{
                      quizSelected.quizName
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      @click="closepreviewQuizAssignmentDialog()"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <div
                    v-if="
                      quizSelected.questions &&
                      quizSelected &&
                      quizSelected.questions.length > 0
                    "
                  >
                    <v-col>
                      <span
                        class="text-body-1"
                        v-html="
                          formatQuestionTitle(
                            quizSelected.questions[index].questionNo,
                            quizSelected.questions[index].title,
                            quizSelected.questions.length
                          )
                        "
                      >
                      </span>
                      <div
                        class="mt-4"
                        v-if="quizSelected.questions[index].media"
                      >
                        <v-img
                          max-width="300"
                          max-height="300"
                          @click="viewImage(quizSelected.questions[index])"
                          :src="quizSelected.questions[index].media"
                        ></v-img>
                        <span
                          v-if="quizSelected.questions[index].media"
                          class="text-body-3"
                          >If image is not clear , click on image to see full
                          image</span
                        >
                      </div>
                      <v-col
                        class="pl-0"
                        v-for="option in quizSelected.questions[index].options"
                        :key="option.number"
                      >
                        <label :class="option.isAnswer ? 'success--text' : ''">
                          <input
                            type="radio"
                            name="option.value"
                            :value="option.number"
                            disabled
                            :checked="option.isAnswer"
                          />
                          {{ option.value }}</label
                        >
                      </v-col>
                    </v-col>
                    <v-row class="ma-4">
                      <span class="text-body-1 font-weight-medium">
                        Explanation :
                      </span>
                      <span
                        class="purple--text text-body-2"
                        v-html="quizSelected.questions[index].explaination"
                      >
                      </span>
                    </v-row>
                    <v-row class="ma-2">
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        small
                        :disabled="index == 0"
                        color="primary"
                        @click="backpage()"
                        >Back</v-btn
                      >
                      <v-btn
                        class="ma-2"
                        small
                        :disabled="index == quizSelected.questions.length - 1"
                        color="primary"
                        @click="newnextPage()"
                        >Next</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                </v-card>
              </v-dialog>

              <v-dialog
                persistent
                v-model="previewTextAssignmentDialog"
                width="60%"
                class="pa-0"
              >
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Text Assignment</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      @click="closepreviewTextAssignmentDialog()"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-col>
                    <!-- <span style="font-weight: 500; font-size: 1.3rem" class="error--text">Question:
            </span> -->
                    <!-- <span class="ml-1" style="font-size: 1.3rem" v-html="editedTextAssignment.question"></span> -->
                    <!-- <v-card-text class="mt-0 mr-2">
              <v-textarea v-model="editedTextAssignment.question" rows="6" outlined
                :readonly="true"></v-textarea></v-card-text> -->
                    <span
                      class="mt-4 ml-0 error--text text-h5 font-weight-medium"
                      >Question:</span
                    >
                    <div
                      class="overflow-auto my-2"
                      style="
                        border: 0.1rem solid #ccc;
                        padding: 0.3rem;
                        height: 13rem;
                      "
                      v-html="editedTextAssignment.question"
                    ></div>
                  </v-col>
                  <v-col
                    v-if="
                      userType == USER_TYPE_TEACHER ||
                      userType == USER_TYPE_ADMIN
                    "
                  >
                    <span
                      class="mt-4 ml-0 error--text text-h5 font-weight-medium"
                      >Answer:</span
                    >
                    <div
                      class="overflow-auto my-2"
                      style="
                        border: 0.1rem solid #ccc;
                        padding: 0.3rem;
                        height: 13rem;
                      "
                      v-html="editedTextAssignment.answer"
                    ></div>
                  </v-col>
                  <v-col>
                    <span
                      v-if="
                        userType == USER_TYPE_STUDENT &&
                        editedTextAssignment.submissions == 0
                      "
                      class="error--text text-h5 font-weight-medium"
                      >Answer:
                    </span>
                    <quill-editor
                      style="height: 10rem; margin-bottom: 3rem"
                      :options="editorOptions"
                      v-if="
                        userType == USER_TYPE_STUDENT &&
                        editedTextAssignment.submissions == 0
                      "
                      v-model="textAssignmentSubmission"
                    ></quill-editor>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        userType == USER_TYPE_STUDENT &&
                        editedTextAssignment.submissions == 0
                      "
                      color="primary"
                      text
                      @click="submitTextAssignment()"
                      >Submit</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                persistent
                v-model="previewCodeAssignmentDialog"
                width="75%"
                class="pa-0"
              >
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Code Assignment</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon dark @click="closepreviewCodeAssignmentDialog()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <PracticeCoding
                  :key="practiceCodingAssignment.assignmentNumber"
                  v-bind:classOpId="editedClassOps.classOpId"
                  v-bind:assignment="practiceCodingAssignment"
                  v-bind:isCompleteAssignment="true"
                />
              </v-dialog>

              <v-dialog
                persistent
                v-model="previewCodelyAssignmentDialog"
                width="55rem"
                height="100%"
                class="pa-0"
              >
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Codely Assignment</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      @click="closepreviewCodelyAssignmentDialog()"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-col>
                    <span
                      class="mt-4 ml-0 blue--text text-h5 font-weight-medium"
                    >
                      {{ editedCodelyAssignment.title }}
                    </span>
                  </v-col>

                  <v-col>
                    <span
                      class="mt-4 ml-0 error--text text-h5 font-weight-medium"
                      >Question</span
                    >
                    <v-text-field
                      v-model="editedCodelyAssignment.codelyLink"
                      prepend-inner-icon="mdi-link"
                      readonly
                      outlined
                      dense
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                copyToClipboard(
                                  editedCodelyAssignment.codelyLink
                                )
                              "
                              color="primary"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copy</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                openInNewTab(editedCodelyAssignment.codelyLink)
                              "
                              color="primary"
                              >mdi-open-in-new</v-icon
                            >
                          </template>
                          <span>Open</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <!-- <div
                      class="overflow-auto my-2"
                      style="border: .1rem solid #ccc; padding: .3rem; height: 5rem"
                      v-html="editedCodelyAssignment.codelyLink"
                    ></div> -->
                    <!-- <vue-friendly-iframe
                      class="iframe"
                      :src="editedCodelyAssignment.codelyLink"
                      allow="clipboard-read; clipboard-write"
                      
                      
                  ></vue-friendly-iframe> -->
                  </v-col>

                  <v-col
                    v-if="
                      userType == USER_TYPE_TEACHER ||
                      userType == USER_TYPE_ADMIN
                    "
                  >
                    <span
                      class="mt-4 ml-0 error--text text-h5 font-weight-medium"
                      >Actual Answer</span
                    >
                    <v-text-field
                      v-model="editedCodelyAssignment.solutionLink"
                      prepend-inner-icon="mdi-link"
                      readonly
                      outlined
                      dense
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                copyToClipboard(
                                  editedCodelyAssignment.solutionLink
                                )
                              "
                              color="primary"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copy</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                openInNewTab(
                                  editedCodelyAssignment.solutionLink
                                )
                              "
                              color="primary"
                              >mdi-open-in-new</v-icon
                            >
                          </template>
                          <span>Open</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <v-snackbar
                      v-model="snackbar"
                      :timeout="2000"
                      :color="snackbarColor"
                      top
                    >
                      {{ snackbarText }}
                    </v-snackbar>
                    <!-- <div
                      class="overflow-auto my-2"
                      style="border: .1rem solid #ccc; padding: .3rem; height: 5rem"
                      v-html="editedCodelyAssignment.solutionLink"
                    ></div> -->
                  </v-col>

                  <!-- <v-col cols="12">
                       <span
                         v-if="userType == USER_TYPE_STUDENT && editedCodelyAssignment.submissions == 0"
                         style="font-weight: 500; font-size: 1.3rem"
                         class="error--text"
                        >
                        Student Answer
                      </span>
                    </v-col>

                     <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        v-if="userType == USER_TYPE_STUDENT && editedCodelyAssignment.submissions == 0"
                        v-model="codelyAssignmentSubmission"
                        outlined
                      ></v-text-field>
                    </v-col>
         
                  <v-col> </v-col>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        userType == USER_TYPE_STUDENT &&
                        editedCodelyAssignment.submissions == 0
                      "
                      color="primary"
                      text
                      @click="submitCodelyAssignment()"
                      >Submit</v-btn
                    >
                  </v-card-actions> -->
                </v-card>
              </v-dialog>

              <v-dialog
                persistent
                v-model="attemptCodelyAssignmentDialog"
                fullscreen
              >
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{
                    editedCodelyAssignment.title
                  }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    dark
                    @click="closeattemptCodelyAssignmentDialog()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card flat>
                  <v-container fluid>
                    <!-- <v-row>
            <v-col cols="12">
              <span class="mt-4 ml-0 blue--text" style="font-weight: 500; font-size: 1.3rem">
                {{ editedCodelyAssignment.title }}
              </span>
            </v-col>
          </v-row> -->

                    <v-row class="mt-n6">
                      <v-col cols="12">
                        <span
                          class="mt-n3 error--text text-h5 font-weight-medium"
                          >Question</span
                        >
                        <div
                          class="mt-n1 overflow-auto"
                          style="border: 0.1rem solid #ccc"
                        >
                          <vue-friendly-iframe
                            class="iframe"
                            :key="editedCodelyAssignment.assignmentNumber"
                            :src="editedCodelyAssignment.codelyLink"
                            allow="clipboard-read; clipboard-write"
                          ></vue-friendly-iframe>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        userType == USER_TYPE_STUDENT &&
                        editedCodelyAssignment.submissions == 0
                      "
                      align="center"
                    >
                      <v-col cols="12" class="mt-n6">
                        <span class="error--text text-h5 font-weight-medium"
                          >Student Answer</span
                        >
                      </v-col>
                      <v-col cols="12" md="8" lg="7" class="mt-n7">
                        <v-text-field
                          v-model="codelyAssignmentSubmission"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="mt-n10">
                        <v-btn
                          class="mt-n1 text-body-2"
                          color="primary"
                          text
                          @click="submitCodelyAssignment()"
                          >Submit</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-dialog>

              <v-tab-item>
                <v-list-item v-if="item.feedback">
                  <v-list-item-title>{{ item.feedback }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-title>No data Available</v-list-item-title>
                </v-list-item>
              </v-tab-item>
            </v-tabs-items>

            <v-card-actions>
              <!-- <v-spacer></v-spacer>
              <v-tooltip
                bottom
                v-if="
                  (userType == USER_TYPE_ADMIN ||
                    userType == USER_TYPE_TEACHER) &&
                  item.status != CLASS_STATUS_CANCELED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="success"
                    @click="openCreateUpdateAssignmentDialog(item, 'CREATE')"
                  >
                    mdi-plus
                  </v-icon>
                </template>
                <span>Create Assignment</span>
              </v-tooltip> -->
            </v-card-actions>
          </v-card>
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          v-if="
            !(
              userType == USER_TYPE_STUDENT &&
              item.status == CLASS_STATUS_COMPLETED
            )
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :key="1"
              v-if="
                userType != USER_TYPE_ADMIN &&
                item.status != CLASS_STATUS_COMPLETED &&
                item.status != CLASS_STATUS_CANCELED
              "
              @click="openStartClassDialog(item)"
            >
              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_ADMIN &&
                  item.status != CLASS_STATUS_COMPLETED &&
                  item.status != CLASS_STATUS_CANCELED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="primary"
                    @click="openStartClassDialog(item)"
                  >
                    mdi-account-plus-outline
                  </v-icon>
                </template>
                <span>Join Class</span>
              </v-tooltip>
              <v-list-item-title
                v-if="
                  item.status != CLASS_STATUS_STARTED &&
                  userType == USER_TYPE_TEACHER
                "
                >{{ "Start Class" }}</v-list-item-title
              >
              <v-list-item-title v-else>{{ "Join Class" }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="2"
              v-if="
                userType == USER_TYPE_TEACHER &&
                item.status == CLASS_STATUS_STARTED
              "
              @click="OpenEndClassDialog(item)"
            >
              <v-tooltip
                bottom
                v-if="
                  userType == USER_TYPE_TEACHER &&
                  item.status == CLASS_STATUS_STARTED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="primary"
                    @click="OpenEndClassDialog(item)"
                  >
                    mdi-phone-hangup
                  </v-icon>
                </template>
                <span>End class</span>
              </v-tooltip>
              <v-list-item-title>{{ "End Class" }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="3"
              v-if="
                (userType == USER_TYPE_ADMIN ||
                  userType == USER_TYPE_TEACHER) &&
                item.status != CLASS_STATUS_CANCELED
              "
              @click="viewAttendance(item)"
            >
              <v-tooltip
                bottom
                v-if="
                  (userType == USER_TYPE_ADMIN ||
                    userType == USER_TYPE_TEACHER) &&
                  item.status != CLASS_STATUS_CANCELED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="success"
                    @click="viewAttendance(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View Attendance</span>
              </v-tooltip>
              <v-list-item-title>{{ "View Attendance" }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="4"
              v-if="
                false &&
                item.status != CLASS_STATUS_COMPLETED &&
                userType == USER_TYPE_ADMIN
              "
              @click="changeTeacher(item)"
            >
              <v-tooltip bottom v-if="userType == USER_TYPE_ADMIN">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="
                      false &&
                      item.status != CLASS_STATUS_COMPLETED &&
                      userType == USER_TYPE_ADMIN
                    "
                    v-on="on"
                    class="mr-2"
                    color="primary"
                    @click="changeTeacher(item)"
                  >
                    mdi-account-alert-outline
                  </v-icon>
                </template>
                <span>Change Teacher</span>
              </v-tooltip>
              <v-list-item-title>{{ "Change Teacher" }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="5"
              v-if="
                userType != USER_TYPE_STUDENT &&
                (item.status == CLASS_STATUS_CREATED ||
                  item.status == CLASS_STATUS_RESCHEDULED)
              "
              @click="openRescheduleDialog(item)"
            >
              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_STUDENT &&
                  (item.status == CLASS_STATUS_CREATED ||
                    item.status == CLASS_STATUS_RESCHEDULED)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="purple"
                    @click="openRescheduleDialog(item)"
                  >
                    mdi-clock-outline
                  </v-icon>
                </template>
                <span>Reschedule</span>
              </v-tooltip>
              <v-list-item-title>{{ "Reschedule" }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="6"
              v-if="
                userType != USER_TYPE_STUDENT &&
                (item.status == CLASS_STATUS_CREATED ||
                  item.status == CLASS_STATUS_RESCHEDULED)
              "
              @click="openCancelConfirmDialog(item)"
            >
              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_STUDENT &&
                  (item.status == CLASS_STATUS_CREATED ||
                    item.status == CLASS_STATUS_RESCHEDULED)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    color="error"
                    @click="openCancelConfirmDialog(item)"
                  >
                    mdi-cancel
                  </v-icon>
                </template>
                <span>Cancel</span>
              </v-tooltip>
              <v-list-item-title>{{ "Cancel" }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <CustomDataTable
      v-else
      :headers="classHeaders"
      :items="classOps"
      :hide-default-footer="classOps.length <= 8"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  BASE_URL,
  CANCEL_CLASS,
  END_CLASS,
  GET_COURSE_BY_ID,
  RESCHEDULE_CLASS,
  SEARCH_STUDENT,
  START_CLASS,
  GET_ALL_QUIZ,
  CREATE_QUIZ_ASSIGNMENT,
  CREATE_CODE_ASSIGNMENT,
  UPDATE_CODE_ASSIGNMENT,
  CREATE_TEXT_ASSIGNMENT,
  UPDATE_TEXT_ASSIGNMENT,
  SUBMIT_TEXT_ASSIGNMENT,
  SUBMIT_CODELY_ASSIGNMENT,
  EVALUATE_CODELY_ASSIGNMENT,
  UPDATE_QUIZ_ASSIGNMENT,
  EVALUATE_CODE_ASSIGNMENT,
  EVALUATE_TEXT_ASSIGNMENT,
  GET_CLASS_OPERATIONS_BY_ID,
  GET_PROFILE,
  GET_QUIZ_BY_ID,
} from "@/common/apiEndpoints";
import QuizAssignment from "@/components/QuizAssignment.vue";
import PracticeCoding from "@/components/PracticeCoding.vue";
import ContextMenu from "./ContextMenu.vue";

import axios from "axios";
import {
  formatDateTimeWithDay,
  formatDateTime,
  getAxiosOptions,
  formattedTime,
  rightClickHandler,
  formatQuestionTitle,
} from "@/common/utility";
import {
  GET_TOKEN,
  GET_USER_PROFILE,
  GET_USER_TYPE,
  GET_PARAMS_VALUE,
} from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import {
  CLASS_STATUS_CANCELED,
  CLASS_STATUS_COMPLETED,
  CLASS_STATUS_CREATED,
  CLASS_STATUS_RESCHEDULED,
  CLASS_STATUS_STARTED,
  FRANCHISE_MODEL,
  NON_FRANCHISE_MODEL,
  USER_TYPE_ADMIN,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from "@/common/constants";
import CustomDataTable from "./CustomDataTable.vue";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],

            ["link"],
          ],
        },
      },
      contextMenuObject: {},
      index: 0,
      tab: null,
      menu: false,
      attendanceDialog: false,
      rescheduleClassDialog: false,
      endClassDialog: false,
      startClassDialog: false,
      endClassConfirmDialog: false,
      cancelClassConfirmDialog: false,
      createUpdateAssignmentDialog: false,
      viewCodeDialog: false,
      viewTextDialog: false,

      viewCodelyDialog: false,

      completeQuizDialog: false,
      completeQuizConfirmDialog: false,
      updateAssignmentConfirmDialog: false,
      quizPreviewDialog: false,
      imgDialog: false,
      previewCodeAssignmentDialog: false,
      previewTextAssignmentDialog: false,

      previewQuizAssignmentDialog: false,

      previewCodelyAssignmentDialog: false,
      attemptCodelyAssignmentDialog: false,

      pageNo: 1,
      assignmentLangForSubmission: "",
      assignmentDialogTitle: "Create Assignment",
      editedIndex: -1,
      allowedLanguages: [
        { code: 50, name: "C" },
        { code: 54, name: "C++" },
        { code: 62, name: "Java" },
        { code: 71, name: "Python" },
        { code: 63, name: "Javascript" },
      ],
      codeAssignmentEvl: {},
      textAssignmentEvl: {},

      codelyAssignmentEvl: {},

      openTimeMenu: false,
      closeTimeMenu: false,
      startDateMenu: false,
      isOpenFeedback: false,
      expandedCodeAssignment: [],
      expandedQuizAssignment: [],
      expandedTextAssignment: [],

      expandedCodelyAssignment: [],

      searchStudent: "",
      quizName: "",
      classOpId: "",
      editedClassOps: {},
      courseCoverage: {},
      selectedChapterName: "",
      selectedLessonName: "",
      assignmentNumber: "",
      questionFieldError: "",
      solutionFieldError: "",
      answerFieldError: "",
      correct: 0,
      incorrect: 0,
      submittedCode: "",
      submittedText: "",

      submittedCodely: "",

      submissions: [],
      lessons: [],
      course: {},
      profile: {},
      quizes: [],
      filteredQuiz: [],
      searchTerm: "",

      selectedQuiz: { questions: [] },

      quizSelected: { questions: [] },

      questionMedia: "",
      editedTextAssignment: {},

      editedCodelyAssignment: {},
      practiceCodingAssignment: {},
      textAssignmentSubmission: "",
      codelyAssignmentSubmission: "",
      endClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        feedbackRules: [
          (v) => !!v || "Feedback is required",
          (v) =>
            (v && v.length >= 15) || "Feedback must be at least 15 characters",
        ],
      },
      startClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      quizAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      codeAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        coinRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Coin must be greater than 1",
          (v) => Number(1000) >= v || "Coin must be less than 1000",
        ],
      },
      codelyAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        coinRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Coin must be greater than 1",
          (v) => Number(1000) >= v || "Coin must be less than 1000",
        ],
      },
      textAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        coinRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Coin must be greater than 1",
          (v) => Number(1000) >= v || "Coin must be less than 1000",
        ],
      },
      rescheduleClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      cancelClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      classHeaders: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Date",
          value: "date",
          align: "start",
          sortable: false,
        },
        {
          text: "Start Time",
          value: "startDateTime",
          align: "center",
        },
        {
          text: "End Time",
          value: "endDateTime",
          align: "center",
        },
        {
          text: " Actual Start Time",
          value: "actualStartTime",
          align: "center",
        },
        {
          text: "Actual End Time",
          value: "actualEndTime",
          align: "center",
        },
        {
          text: "Teacher",
          value: "teacherName",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },

        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      studentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Mobile No",
          value: "mobileNo",
        },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        {
          text: "School Name",
          value: "schoolName",
        },
        { text: "Grade", value: "grade" },
      ],
      assignmentHeaders: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Assignment No",
          align: "start",
          sortable: false,
          value: "assignmentNumber",
        },
        {
          text: "Name",
          value: "title",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      textassignmentHeaders: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Assignment No",
          align: "start",
          sortable: false,
          value: "assignmentNumber",
        },
        {
          text: "Name",
          value: "title",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],

      codelyassignmentHeaders: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Assignment No",
          align: "start",
          sortable: false,
          value: "assignmentNumber",
        },
        {
          text: "Name",
          value: "title",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],

      quizAssignmentHeaders: [
        {
          text: "Assignment No",
          align: "start",
          sortable: false,
          value: "assignmentNumber",
        },
        {
          text: "Name",
          value: "quizName",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      submissionHeaders: [
        {
          text: "Submitted By",
          value: "studentId",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Date",
          align: "center",
          value: "date",
        },
        {
          text: "Coins",
          value: "earnedCoins",
          align: "center",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      quizAssignmentSubmissionHeaders: [
        {
          text: "Submitted By",
          value: "studentId",
        },
        {
          text: "Complete(%)",
          align: "center",
          value: "completed",
        },
        {
          text: "Question",
          value: "questions",
        },
        {
          text: "Date",
          align: "center",
          value: "date",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      studentsList: [],
      studentsListForSubmissions: [],
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      CLASS_STATUS_CREATED: CLASS_STATUS_CREATED,
      CLASS_STATUS_RESCHEDULED: CLASS_STATUS_RESCHEDULED,
      CLASS_STATUS_CANCELED: CLASS_STATUS_CANCELED,
      CLASS_STATUS_STARTED: CLASS_STATUS_STARTED,
      CLASS_STATUS_COMPLETED: CLASS_STATUS_COMPLETED,
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
      editorConfig: {
        height: 100,
      },
    };
  },
  // mounted: function () {
  //   if (this.userType != USER_TYPE_STUDENT) {
  //     this.getAllQuiz();
  //   }
  // },
  watch: {
    userType: {
      handler(userType) {
        if (userType != USER_TYPE_STUDENT) {
          this.getAllQuiz();
        }
      },
      immediate: true,
    },
    classOps: {
      handler(classOps) {
        if (classOps) {
          this.findPageNo();
          if (classOps.length > 0 && this.userType == USER_TYPE_TEACHER)
            this.getCourseById(classOps[0].courseId);
        }
      },
      immediate: true,
    },
  },
  props: ["classOps", "topicCovered", "classRoomId", "className", "model"],
  components: {
    ConfirmDialog,
    QuizAssignment,
    PracticeCoding,
    ContextMenu,
    CustomDataTable,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      userProfile: `schoolModule/${GET_USER_PROFILE}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    // console.log(this.paramsValue)
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackbar("Link copied to clipboard", "success");
        },
        () => {
          this.showSnackbar("Failed to copy link", "error");
        }
      );
    },
    showSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type === "success" ? "green" : "red";
      this.snackbar = true;
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    formatDateTimeWithDay(date) {
      return formatDateTimeWithDay(date);
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    formattedTime(date) {
      return formattedTime(date);
    },
    viewAttendance(item) {
      this.attendanceDialog = true;
      this.searchStudents(item.attendedStudents);
    },
    nextPage() {
      if (this.index < this.selectedQuiz.questions.length) {
        this.index++;
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
      }
    },

    newnextPage() {
      if (this.index < this.quizSelected.questions.length) {
        this.index++;
      }
    },

    validateQuestion() {
      if (this.editedClassOps.question.length == 0) {
        this.questionFieldError = "This is required";
      } else {
        this.questionFieldError = "";
      }
    },
    validateSolution() {
      if (this.editedClassOps.solution.length == 0) {
        this.solutionFieldError = "This is required";
      } else {
        this.solutionFieldError = "";
      }
    },
    validateAnswer() {
      if (this.editedClassOps.answer.length == 0) {
        this.answerFieldError = "This is required";
      } else {
        this.answerFieldError = "";
      }
    },
    openUpdateAssignmentConfirmDialog() {
      if (
        this.editedClassOps.assignmentType == "CODE" ||
        this.editedClassOps.assignmentType == "TEXT"
      ) {
        if (this.editedClassOps.question.length < 1) {
          this.questionFieldError = "This is required";
          return;
        }
      }
      this.updateAssignmentConfirmDialog = true;
    },
    closeUpdateAssignmentConfirmDialog() {
      this.updateAssignmentConfirmDialog = false;
      this.createUpdateAssignmentDialog = false;
    },
    getAllQuiz() {
      if (
        this.userType != USER_TYPE_STUDENT ||
        this.paramsValue.userType != USER_TYPE_STUDENT
      ) {
        let url = GET_ALL_QUIZ + "?status=PUBLISHED";
        this.setOverlayValue(true);
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.quizes = res.data.quizes;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    closeQuizPreviewDialog() {
      this.quizPreviewDialog = false;
    },
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    getUserProfile() {
      let url = GET_PROFILE;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.profile = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseById(courseId) {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          this.course.chapters = this.course.chapters.filter((chapter) => {
            if (
              !this.topicCovered.has(Number(chapter.chapterNo)) ||
              (this.topicCovered.has(Number(chapter.chapterNo)) &&
                chapter.lessons.length !=
                  this.topicCovered.get(Number(chapter.chapterNo)).length)
            ) {
              return true;
            }
          });
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    closeAttendanceDialog() {
      this.attendanceDialog = false;
      this.studentsList = [];
    },
    openRescheduleDialog(item) {
      this.rescheduleClassDialog = true;
      this.editedClassOps.classOpId = item.classOpId;
    },
    closeRescheduleDialog() {
      this.rescheduleClassDialog = false;
      this.editedClassOps = {};
      this.editedClassOps.classOpId = "";
    },
    openCreateUpdateAssignmentDialog(item, operationType, assignmentType) {
      this.createUpdateAssignmentDialog = true;
      if (assignmentType) {
        this.editedClassOps.assignmentType = assignmentType;
      }
      if (operationType == "UPDATE") {
        this.assignmentDialogTitle = "Update Assignment";
        this.editedIndex = 0;
        if (assignmentType == "CODE") {
          this.editedClassOps.title = item.title;
          this.editedClassOps.question = item.question;
          this.editedClassOps.expectedOutput = item.expectedOutput;
          this.editedClassOps.solution = item.solution;
          this.editedClassOps.coins = item.coins;
          this.editedClassOps.assignmentNumber = item.assignmentNumber;
        } else if (assignmentType == "QUIZ") {
          this.editedClassOps.quiz = item.quizId;
          this.editedClassOps.assignmentNumber = item.assignmentNumber;
        } else if (assignmentType == "TEXT") {
          this.editedClassOps.title = item.title;
          this.editedClassOps.question = item.question;
          this.editedClassOps.coins = item.coins;
          this.editedClassOps.answer = item.answer;
          this.editedClassOps.assignmentNumber = item.assignmentNumber;
        } else if (assignmentType == "CODELY") {
          this.editedClassOps.title = item.title;
          this.editedClassOps.codelyLink = item.codelyLink;
          this.editedClassOps.coins = item.coins;
          this.editedClassOps.solutionLink = item.solutionLink;
          this.editedClassOps.assignmentNumber = item.assignmentNumber;
        }
      }
    },
    closeCreateUpdateAssignmentDialog() {
      this.createUpdateAssignmentDialog = false;
      let classOpId = this.editedClassOps.classOpId;
      this.editedClassOps = {};
      this.editedClassOps.classOpId = classOpId;
      this.assignmentDialogTitle = "Create Assignment";
      this.editedIndex = -1;
    },
    fetchNameById(studentId) {
      if (this.userType == this.USER_TYPE_STUDENT) {
        return this.userProfile.firstName + " " + this.userProfile.lastName;
      } else if (studentId && this.studentsListForSubmissions.length > 0) {
        let student = this.studentsListForSubmissions.find(
          (std) => std.studentId == studentId
        );
        if (student) {
          return student.firstName + " " + student.lastName;
        }
      }
    },
    searchStudents(studentIds) {
      const payload = {};
      let flag = false;
      if (studentIds && studentIds.length > 0) {
        payload.studentIds = studentIds;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    JoinClass(item) {
      if (
        this.model == FRANCHISE_MODEL &&
        this.userType == USER_TYPE_TEACHER &&
        item.status != CLASS_STATUS_STARTED
      ) {
        this.$refs.form.validate();
      }
      const payload = {};
      if (
        this.userType == this.USER_TYPE_TEACHER &&
        item.status != CLASS_STATUS_STARTED
      ) {
        payload.classOpId = item.classOpId;
        if (this.model == FRANCHISE_MODEL) {
          if (
            this.startClassValidation.valid &&
            this.courseCoverage &&
            this.courseCoverage.chapterNo &&
            this.courseCoverage.lessonNo
          ) {
            payload.courseCoverage = this.courseCoverage;
          } else {
            return;
          }
        }
        let url = START_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.startClassValidation.valid = false;
            this.closeEndClassDialog();
            if (
              item.classInfo.classLink.startsWith("http://") ||
              item.classInfo.classLink.startsWith("https://")
            ) {
              window.open(item.classInfo.classLink, "_blank");
            } else {
              let x = "https://";
              item.classInfo.classLink = x.concat(item.classInfo.classLink);
              window.open(item.classInfo.classLink, "_blank");
            }
            this.updateClassOps(res.data);
            this.closeEndClassDialog();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeEndClassDialog();
            this.startClassValidation.valid = false;
            this.setOverlayValue(false);
          });
      } else {
        if (item.status == CLASS_STATUS_STARTED) {
          if (
            item.classInfo.classLink.startsWith("http://") ||
            item.classInfo.classLink.startsWith("https://")
          ) {
            window.open(item.classInfo.classLink, "_blank");
          } else {
            let x = "https://";
            item.classInfo.classLink = x.concat(item.classInfo.classLink);
            window.open(item.classInfo.classLink, "_blank");
          }
        } else {
          this.setApiError("Class Not Started Yet!");
        }
      }
      // } else {
      //   this.setApiError("Class Link Not Found");
      // }
    },
    changeTeacher(item) {
      console.log("teacher changed");
    },
    reScheduleClass() {
      this.$refs.form.validate();
      const payload = {};
      if (
        this.userType != this.USER_TYPE_STUDENT &&
        this.rescheduleClassValidation.valid
      ) {
        payload.reason = this.editedClassOps.reason;
        payload.classOpId = this.editedClassOps.classOpId;
        let startDateTime = new Date(this.editedClassOps.startDate);
        let endDateTime = new Date(this.editedClassOps.startDate);
        let startTime = this.editedClassOps.startTime.split(":");
        let endTime = this.editedClassOps.endTime.split(":");
        startDateTime.setHours(Number(startTime[0]));
        startDateTime.setMinutes(Number(startTime[1]));
        startDateTime.setSeconds(0);
        endDateTime.setHours(Number(endTime[0]));
        endDateTime.setMinutes(Number(endTime[1]));
        endDateTime.setSeconds(0);
        payload.startDateTime = startDateTime.toISOString();
        payload.endDateTime = endDateTime.toISOString();
        let url = RESCHEDULE_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.updateClassOps(res.data);
            this.rescheduleClassValidation.valid = false;
            this.closeRescheduleDialog();
            this.setApiSuccess("Class Rescheduled Successfully");
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeRescheduleDialog();
            this.setOverlayValue(false);
          });
      }
    },
    openEndClassConfirm() {
      this.$refs.form.validate();
      if (this.endClassValidation.valid) {
        if (
          this.courseCoverage &&
          this.courseCoverage.chapterNo &&
          this.courseCoverage.lessonNo
        ) {
          this.endClassConfirmDialog = true;
          this.fetchLessons(this.courseCoverage.chapterNo);
        } else {
          this.endClass();
        }
      }
    },
    closeEndClassConfirm() {
      this.endClassConfirmDialog = false;
      this.selectedChapterName = "";
      this.selectedLessonName = "";
    },
    createAssignment() {
      if (this.editedClassOps.assignmentType == "QUIZ") {
        this.quizAssignment();
      } else if (this.editedClassOps.assignmentType == "CODE") {
        this.codeAssignment();
      } else if (this.editedClassOps.assignmentType == "TEXT") {
        this.textAssignment();
      }
    },
    quizAssignment() {
      this.$refs.form.validate();
      const payload = {};
      if (
        this.userType == this.USER_TYPE_TEACHER ||
        (this.USER_TYPE_ADMIN && this.quizAssignmentValidation.valid)
      ) {
        payload.classOpId = this.editedClassOps.classOpId;
        payload.quizId = this.editedClassOps.quiz.trim();
        let url = CREATE_QUIZ_ASSIGNMENT;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.quizAssignmentValidation.valid = false;
            this.updateClassOps(res.data);
            this.closeCreateUpdateAssignmentDialog();
            this.setApiSuccess("Quiz Assignment created successfully");
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
          });
      }
    },
    codeAssignment() {
      this.$refs.form.validate();
      const payload = {};
      if (
        (this.userType == this.USER_TYPE_TEACHER ||
          this.userType == this.USER_TYPE_ADMIN) &&
        this.codeAssignmentValidation.valid &&
        this.editedClassOps.question &&
        this.editedClassOps.solution
      ) {
        payload.classOpId = this.editedClassOps.classOpId;
        payload.title = this.editedClassOps.title.trim();
        payload.question = this.editedClassOps.question;
        payload.expectedOutput = this.editedClassOps.expectedOutput;
        payload.solution = this.editedClassOps.solution;
        payload.coins = this.editedClassOps.coins;
        let url = CREATE_CODE_ASSIGNMENT;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.codeAssignmentValidation.valid = false;
            this.updateClassOps(res.data);
            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Code Assignment created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
          });
      }
    },
    textAssignment() {
      this.$refs.form.validate();
      const payload = {};
      if (
        (this.userType == this.USER_TYPE_TEACHER ||
          this.userType == this.USER_TYPE_ADMIN) &&
        this.textAssignmentValidation.valid &&
        this.editedClassOps.question &&
        this.editedClassOps.answer
      ) {
        payload.classOpId = this.editedClassOps.classOpId;
        payload.title = this.editedClassOps.title.trim();
        payload.question = this.editedClassOps.question;
        payload.answer = this.editedClassOps.answer;
        payload.coins = this.editedClassOps.coins;
        let url = CREATE_TEXT_ASSIGNMENT;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.textAssignmentValidation.valid = false;
            this.updateClassOps(res.data);
            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Text Assignment created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
          });
      }
    },

    updateClassOps(updatedClassOps) {
      var foundIndex = this.classOps.findIndex(
        (clsOp) => clsOp.classOpId == updatedClassOps.classOpId
      );
      Object.assign(this.classOps[foundIndex], updatedClassOps);
    },

    endClass() {
      this.$refs.form.validate();
      if (this.endClassValidation.valid) {
        const payload = {};
        if (this.userType == this.USER_TYPE_TEACHER) {
          payload.classOpId = this.editedClassOps.classOpId;
          if (
            this.courseCoverage &&
            this.courseCoverage.chapterNo &&
            this.courseCoverage.lessonNo
          ) {
            payload.courseCoverage = this.courseCoverage;
          }
          payload.feedback = this.editedClassOps.feedback;
          // payload.courseCoverage.chapterNo = this.courseCoverage.selectedChapter.chapterNo;
          // payload.courseCoverage.lessonNo = this.courseCoverage.selectedLesson.lessonNo;
          let url = END_CLASS;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.endClassValidation.valid = false;
              this.closeEndClassConfirm();
              this.closeEndClassDialog();
              this.updateClassOps(res.data);
              this.$emit("updateTopicCovered");
              this.setApiSuccess("Class Ended Successfully");
              this.setOverlayValue(false);
            })
            .catch((error) => {
              error = error.errorMessage;
              this.closeEndClassDialog();
              this.closeEndClassConfirm();
              this.setOverlayValue(false);
            });
        }
      }

      //   }
      //   else {
      //   this.setApiError("class room ended");
      // }
    },

    OpenEndClassDialog(item) {
      this.endClassDialog = true;
      this.editedClassOps = Object.assign({}, item);
      this.getCourseById(item.courseId);
    },
    openStartClassDialog(item) {
      let otherClassStarted = false;
      if (item.status != CLASS_STATUS_STARTED) {
        // Check if any ohter class has started
        for (const clsOp of this.classOps) {
          if (clsOp.status === CLASS_STATUS_STARTED) {
            this.setApiError("Please complete started class first");
            otherClassStarted = true;
            break;
          }
        }
        if (!otherClassStarted) {
          this.getCourseById(item.courseId);
          this.editedClassOps = Object.assign({}, item);
          if (
            this.model === FRANCHISE_MODEL &&
            this.userType === USER_TYPE_TEACHER &&
            item.status !== CLASS_STATUS_STARTED
          ) {
            this.startClassDialog = true;
          } else {
            this.JoinClass(item);
          }
        }
      } else {
        this.JoinClass(item);
      }
    },
    closeEndClassDialog() {
      this.endClassDialog = false;
      this.startClassDialog = false;
      this.editedClassOps = {};
      this.courseCoverage = {};
    },
    fetchLessons(chapterNo) {
      let Chapter = this.course.chapters.filter((chpt) => {
        return chpt.chapterNo == chapterNo;
      });
      if (Chapter[0].lessons) {
        this.lessons = Chapter[0].lessons;
        if (this.topicCovered.has(Number(chapterNo)))
          this.lessons = this.lessons.filter((lesson) => {
            return !this.topicCovered
              .get(Number(chapterNo))
              .includes(lesson.lessonNo);
          });
        this.selectedChapterName = Chapter[0].chapterName;
      }
      if (this.courseCoverage.lessonNo) {
        let lessons = this.lessons.filter((lesson) => {
          return lesson.lessonNo == this.courseCoverage.lessonNo;
        });
        this.selectedLessonName = lessons[0].lessonName;
      }
    },
    getClassStatusColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "primary-chip";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success-chip";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "secondary-chip";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error-chip";
      } else {
        return "orange-chip";
      }
    },
    openCancelConfirmDialog(item) {
      this.cancelClassConfirmDialog = true;
      this.editedClassOps.classOpId = item.classOpId;
    },
    closeCancelConfirmDialog() {
      this.cancelClassConfirmDialog = false;
      this.editedClassOps = {};
    },
    cancelClass() {
      this.$refs.form.validate();
      const payload = {};
      if (
        this.userType != this.USER_TYPE_STUDENT &&
        this.cancelClassValidation.valid
      ) {
        payload.classOpId = this.editedClassOps.classOpId;
        payload.reason = this.editedClassOps.reason;
        let url = CANCEL_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.cancelClassValidation.valid = false;
            this.closeCancelConfirmDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Class Canceled Successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCancelConfirmDialog();
            this.setOverlayValue(false);
          });
      }
    },
    updateAssignment(deleteSubmissions) {
      if (this.$refs.form.validate()) {
        const payload = {};
        let url = "";
        payload.classOpId = this.editedClassOps.classOpId;
        payload.assignmentNumber = this.editedClassOps.assignmentNumber;
        if (this.editedClassOps.assignmentType == "CODE") {
          if (
            this.editedClassOps.question.length < 1 ||
            this.editedClassOps.solution.length < 1
          ) {
            this.setOverlayValue(false);
            this.updateAssignmentConfirmDialog = false;
            return;
          }
          if (this.codeAssignmentValidation.valid) {
            payload.title = this.editedClassOps.title;
            payload.question = this.editedClassOps.question;
            payload.expectedOutput = this.editedClassOps.expectedOutput;
            payload.coins = this.editedClassOps.coins;
            payload.solution = this.editedClassOps.solution;
            payload.deleteSubmissions = deleteSubmissions;
            url = UPDATE_CODE_ASSIGNMENT;
          }
        } else if (this.editedClassOps.assignmentType == "TEXT") {
          if (
            this.editedClassOps.question.length < 1 ||
            this.editedClassOps.answer.length < 1
          ) {
            this.setOverlayValue(false);
            this.updateAssignmentConfirmDialog = false;
            return;
          }
          if (this.textAssignmentValidation.valid) {
            payload.title = this.editedClassOps.title;
            payload.question = this.editedClassOps.question;
            payload.answer = this.editedClassOps.answer;

            payload.deleteSubmissions = deleteSubmissions;
            url = UPDATE_TEXT_ASSIGNMENT;
          }
        } else if (this.editedClassOps.assignmentType == "QUIZ") {
          if (this.quizAssignmentValidation.valid) {
            payload.quizId = this.editedClassOps.quiz;
            url = UPDATE_QUIZ_ASSIGNMENT;
          }
        } else if (assignmentType == "CODELY") {
          this.editedClassOps.title = item.title;
          this.editedClassOps.codelyLink = item.codelyLink;
          this.editedClassOps.coins = item.coins;
          this.editedClassOps.solutionLink = item.solutionLink;
          this.editedClassOps.assignmentNumber = item.assignmentNumber;
        } else this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            if (this.editedClassOps.assignmentType == "CODE") {
              this.codeAssignmentValidation.valid = false;
              this.closeUpdateAssignmentConfirmDialog();
              this.setApiSuccess("Code assignment updated successfully");
            } else if (this.editedClassOps.assignmentType == "TEXT") {
              this.textAssignmentValidation.valid = false;
              this.closeUpdateAssignmentConfirmDialog();
              this.setApiSuccess("Text assignment updated successfully");
            } else if (this.editedClassOps.assignmentType == "QUIZ") {
              this.quizAssignmentValidation.valid = false;
              this.closeUpdateAssignmentConfirmDialog();
              this.submissions = [];
              this.setApiSuccess("Quiz assignment updated successfully");
            } else if (this.editedClassOps.assignmentType == "CODELY") {
              this.coldelyAssignmentValidation.valid = false;
              this.closeUpdateAssignmentConfirmDialog();
              this.submissions = [];
              this.setApiSuccess("Codely assignment updated successfully");
            }

            this.closeCreateUpdateAssignmentDialog();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCreateUpdateAssignmentDialog();
            this.closeUpdateAssignmentConfirmDialog();
            this.setOverlayValue(false);
          });
      }
    },
    previewCodeAssignment(item) {
      this.practiceCodingAssignment.question = item.question;
      this.practiceCodingAssignment.expectedOutput = item.expectedOutput;
      this.practiceCodingAssignment.assignmentNumber = item.assignmentNumber;
      this.previewCodeAssignmentDialog = true;
    },

    closepreviewCodeAssignmentDialog() {
      this.previewCodeAssignmentDialog = false;
    },

    previewTextAssignment(item) {
      this.editedTextAssignment = item;
      this.previewTextAssignmentDialog = true;
    },

    closepreviewTextAssignmentDialog() {
      this.previewTextAssignmentDialog = false;
    },

    closepreviewQuizAssignmentDialog() {
      this.previewQuizAssignmentDialog = false;
    },

    previewCodelyAssignment(item) {
      this.editedCodelyAssignment = item;
      this.previewCodelyAssignmentDialog = true;
    },
    closepreviewCodelyAssignmentDialog() {
      this.previewCodelyAssignmentDialog = false;
    },

    attemptCodelyAssignment(item) {
      this.editedCodelyAssignment = item;
      this.attemptCodelyAssignmentDialog = true;
    },
    closeattemptCodelyAssignmentDialog() {
      this.attemptCodelyAssignmentDialog = false;
    },

    completeCodeAssignment(item) {
      var codingParams = {};
      codingParams.classOpId = this.editedClassOps.classOpId;
      codingParams.question = item.question;
      codingParams.expectedOutput = item.expectedOutput;
      codingParams.assignmentNumber = item.assignmentNumber;
      codingParams.isCompleteAssignment = true;
      codingParams.classRoomId =
        this.classRoomId || this.$route.params.classRoomId;
      codingParams.className = this.className || this.$route.params.className;
      codingParams.userType = this.paramsValue.userType;
      this.setParams(codingParams);
      this.$router.push({
        name: "PracticeCoding",
        params: {
          classOpId: this.editedClassOps.classOpId,
          isCompleteAssignment: true,
        },
      });
    },

    submitTextAssignment() {
      const url = SUBMIT_TEXT_ASSIGNMENT;
      this.setOverlayValue(true);
      const payload = {};
      payload.assignmentNumber = this.editedTextAssignment.assignmentNumber;
      payload.classOpId = this.editedClassOps.classOpId;
      payload.submission = this.textAssignmentSubmission;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getClassOpById();
          this.closepreviewTextAssignmentDialog();
          this.setApiSuccess("Assignment Submitted Successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.closepreviewTextAssignmentDialog();
        });
    },

    submitCodelyAssignment() {
      const url = SUBMIT_CODELY_ASSIGNMENT;
      this.setOverlayValue(true);
      const payload = {};
      payload.assignmentNumber = this.editedCodelyAssignment.assignmentNumber;
      payload.classOpId = this.editedClassOps.classOpId;
      payload.submission = this.codelyAssignmentSubmission;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getClassOpById();
          this.closeattemptCodelyAssignmentDialog();
          this.setApiSuccess("Assignment Submitted Successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          console.log(error);
          this.closeattemptCodelyAssignmentDialog();
        });
    },

    completeQuizAssignment(item) {
      this.quizName = item.quizName;
      this.completeQuizDialog = true;
      this.classOpId = this.editedClassOps.classOpId;
      this.assignmentNumber = item.assignmentNumber;
      this.editedClassOps.quizAssignment = item;
    },
    closecompleteQuizDialog() {
      this.completeQuizDialog = false;
      this.completeQuizConfirmDialog = false;
      this.expandedCodeAssignment = [];
      this.expandedTextAssignment = [];
      this.expandedQuizAssignment = [];
    },
    openCompleteQuizConfirmDialog() {
      if (
        this.editedClassOps.quizAssignment.progress.length == 0 ||
        this.editedClassOps.quizAssignment.progress[0].completed != 100
      ) {
        this.completeQuizConfirmDialog = true;
      } else {
        // this.completeQuizConfirmDialog = false;
        // this.expandedCodeAssignment = [];
        // this.expandedTextAssignment = [];
        // this.expandedQuizAssignment = [];
        this.closecompleteQuizDialog();
      }
    },
    closeCompleteQuizConfirmDialog() {
      this.completeQuizConfirmDialog = false;
      this.expandedCodeAssignment = [];
      this.expandedTextAssignment = [];
      this.expandedQuizAssignment = [];
    },
    getClassOpById() {
      this.getUserProfile();
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = this.editedClassOps.classOpId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassOps(res.data);
          this.editedClassOps = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    expandClassOp({ item }) {
      this.editedClassOps.classOpId = item.classOpId;
      this.expandedCodeAssignment = [];
      this.expandedTextAssignment = [];
      this.expandedQuizAssignment = [];

      this.expandedCodelyAssignment = [];
    },
    getSubmissionList({ item }) {
      this.submissions = item.submissions;
      // if(this.userType==this.USER_TYPE_STUDENT){
      //   this.submissions = this.submissions.filter(sbmsn=>sbmsn.studentId==this.userProfile.student.studentId);
      // }
      if (this.userType != this.USER_TYPE_STUDENT) {
        this.SearchStudentsByIds();
      }
      this.codeAssignmentEvl.assignmentNumber = item.assignmentNumber;
      this.textAssignmentEvl.assignmentNumber = item.assignmentNumber;

      this.codelyAssignmentEvl.assignmentNumber = item.assignmentNumber;
      this.codelyAssignmentEvl.solutionLink = item.solutionLink;
      this.editedCodelyAssignment.coins = item.coins;
      this.editedCodelyAssignment.codelyLink = item.codelyLink;

      this.editedClassOps.question = item.question;
      this.codeAssignmentEvl.solution = item.solution;
      this.textAssignmentEvl.answer = item.answer;
      this.editedTextAssignment.question = item.question;
      this.editedTextAssignment.coins = item.coins;
      this.editedClassOps.coins = item.coins;
    },

    getQuizSubmissionList({ item }) {
      this.submissions = item.progress;
      // if(this.userType==this.USER_TYPE_STUDENT){
      //   this.submissions = this.submissions.filter(sbmsn=>sbmsn.studentId==this.userProfile.student.studentId);
      // }
      this.editedClassOps.quizAssignment = item;
      this.classOpId = item.classOpId;
      this.assignmentNumber = item.assignmentNumber;
      if (this.userType != this.USER_TYPE_STUDENT) {
        this.SearchStudentsByIds();
      }
    },

    SearchStudentsByIds() {
      const payload = {};
      let flag = false;
      if (this.submissions.length > 0) {
        payload.studentIds = [];
        this.submissions.forEach((element) => {
          payload.studentIds.push(element.studentId);
        });
        flag = true;
        this.setOverlayValue(true);
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsListForSubmissions = res.data.students;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    openSubmittedCodeDialog(item) {
      this.viewCodeDialog = true;
      this.submittedCode = item.submission;
      let assignmentLang = this.allowedLanguages.find(
        (lang) => lang.code == item.language
      );
      this.assignmentLangForSubmission = assignmentLang.name;
      this.codeAssignmentEvl.studentId = item.studentId;
      this.codeAssignmentEvl.coins = item.earnedCoins;
    },
    openSubmittedTextDialog(item) {
      this.viewTextDialog = true;
      this.submittedText = item.submission;
      this.textAssignmentEvl.studentId = item.studentId;
      this.textAssignmentEvl.coins = item.earnedCoins;
    },

    openSubmittedCodelyDialog(item) {
      this.viewCodelyDialog = true;
      this.submittedCodely = item.submission;
      this.codelyAssignmentEvl.studentId = item.studentId;
      this.codelyAssignmentEvl.coins = item.earnedCoins;
      this.codelyAssignmentEvl.codelyLink = item.codelyLink;
    },

    closeSubmittedCodelyDialog() {
      this.viewCodelyDialog = false;
    },

    closeSubmittedCodeDialog() {
      this.viewCodeDialog = false;
    },
    fetchCorrect(questions) {
      let correct = 0;
      if (questions && questions.length > 0) {
        questions.forEach((question) => {
          if (question.isCorrect == true) correct++;
        });
      }
      return "Correct : " + correct;
    },
    fetchIncorrect(questions) {
      let incorrect = 0;
      if (questions && questions.length > 0) {
        questions.forEach((question) => {
          if (question.isCorrect == false) incorrect++;
        });
      }
      return "Incorrect : " + incorrect;
    },
    closeSubmittedTextDialog() {
      this.viewTextDialog = false;
    },

    codeEvaluate() {
      let payload = {};
      let flag = false;
      this.setOverlayValue(true);
      if (this.codeAssignmentEvl) {
        this.codeAssignmentEvl.classOpId = this.editedClassOps.classOpId;
        payload = this.codeAssignmentEvl;
        flag = true;
      }
      let url = EVALUATE_CODE_ASSIGNMENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.closeSubmittedCodeDialog();
            this.setOverlayValue(false);
            this.expandedCodeAssignment = [];
            this.setApiSuccess("Assignment Evaluated Successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeSubmittedCodeDialog();
            this.setOverlayValue(false);
          });
      }
    },
    textEvaluate() {
      let payload = {};
      let flag = false;
      this.setOverlayValue(true);
      if (this.textAssignmentEvl) {
        this.textAssignmentEvl.classOpId = this.editedClassOps.classOpId;
        payload = this.textAssignmentEvl;
        flag = true;
      }
      let url = EVALUATE_TEXT_ASSIGNMENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.closeSubmittedTextDialog();
            this.setOverlayValue(false);
            this.expandedTextAssignment = [];
            this.setApiSuccess("Assignment Evaluated Successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeSubmittedTextDialog();
            this.setOverlayValue(false);
          });
      }
    },

    codelyEvaluate() {
      let payload = {};
      let flag = false;
      this.setOverlayValue(true);
      if (this.codelyAssignmentEvl) {
        this.codelyAssignmentEvl.classOpId = this.editedClassOps.classOpId;
        payload = this.codelyAssignmentEvl;
        flag = true;
      }
      let url = EVALUATE_CODELY_ASSIGNMENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.closeSubmittedCodelyDialog();
            this.setOverlayValue(false);
            this.expandedTextAssignment = [];
            this.setApiSuccess("Assignment Evaluated Successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeSubmittedCodelyDialog();
            this.setOverlayValue(false);
          });
      }
    },

    viewQuizAssignmentReport(item) {
      this.completeQuizDialog = true;
    },
    findPageNo() {
      var totalPages = 0;
      if (this.classOps.length % 8 == 0) {
        totalPages = parseInt(this.classOps.length / 8);
      } else {
        totalPages = parseInt(this.classOps.length / 8) + 1;
      }
      if (totalPages > 1) {
        var date = new Date();
        var currentWeek = this.getWeekOfMonth(date);
        var currentMonth = date.getMonth();

        for (let i = 0; i < this.classOps.length; i++) {
          var d = new Date(this.classOps[i].date);
          var classMonth = d.getMonth();
          var classWeek = this.getWeekOfMonth(d);
          if (classMonth == currentMonth && classWeek == currentWeek) {
            this.pageNo = i % 8 == 0 ? parseInt(i / 8) : parseInt(i / 8) + 1;
            break;
          }
        }
      }
    },
    getWeekOfMonth(date) {
      let adjustedDate = date.getDate() + date.getDay();
      let prefixes = ["0", "1", "2", "3", "4", "5"];
      return parseInt(prefixes[0 | (adjustedDate / 8)]) + 1;
    },
    getQuizByQuizId(quiz) {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = quiz;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          // this.selectedQuiz = res.data;
          // this.quizPreviewDialog = true;

          this.quizSelected = res.data;
          this.previewQuizAssignmentDialog = true;

          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    updateQuizAssignmentProgress(progress) {
      this.editedClassOps.quizAssignment.progress[0] = progress;
    },
    searchQuiz() {
      if (this.searchTerm.length >= 3) {
        let url = SEARCH_QUIZ;
        let payload = {};
        payload.quizName = this.searchTerm;
        payload.status = "PUBLISHED";
        // this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.filteredQuiz = res.data.quizes;
            // this.quizes = res.data.quizes;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      } else {
        if (this.searchTerm.length == 0) {
          this.filteredQuiz = this.quizes;
        } else {
          this.filteredQuiz = this.quizes.filter((quiz) => {
            return (
              quiz.quizName
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) > -1
            );
          });
        }
      }
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
  },
};
</script>

<style scoped>
.iframe {
  width: 100%;
  overflow: hidden;
  height: 72vh;
}
</style>
