<template>
  <div>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title
        v-if="paramsValue.teacherId && userType == USER_TYPE_ADMIN"
      >
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)">mdi-arrow-left</v-icon
        >{{ paramsValue.firstName + paramsValue.lastName }}</v-toolbar-title
      ><v-toolbar-title v-else>My Calendar</v-toolbar-title>
    </v-toolbar>
    <v-card class="ma-2">
      <v-sheet height="64" style="position: sticky">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text color="grey darken-2" @click="prev">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text color="grey darken-2" @click="next">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ viewTypeArray[viewType] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewType = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewType = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewType = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet style="position: sticky; height: 70vh;">
        <v-calendar
          ref="calendar"
          v-model="calendarDate"
          color="primary"
          :events="teacherClasses"
          :event-color="getEventColor"
          :type="viewType"
          @click:event="showClassDetails"
          @click:more="viewDay"
          @click:date="viewDay"
          event-overlap-mode="stack"
          event-overlap-threshold="0"
          interval-height="25"
          style="max-height: 100%;"
        >
          <template v-slot:event="{ eventParsed, event }">
            <span class="text-body-3 font-weight-medium">{{
              event.color == "error"
                ? eventParsed.start.time + " - " + eventParsed.end.time
                : ""
            }}</span>
          </template>
        </v-calendar>
        <v-menu
          v-model="openSelectedClass"
          :close-on-content-click="false"
          :activator="selectedClass"
          offset-y
        >
          <v-card min-width="22rem" flat v-if="selectedClass.classInfo">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>{{
                selectedClass.classInfo.name
              }}</v-toolbar-title>
            </v-toolbar>
            <v-simple-table class="scheduleTable">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>Course Name</th>
                    <td>{{ selectedClass.courseName }}</td>
                  </tr>
                  <tr>
                    <th>Total Students</th>
                    <td>{{ selectedClass.totalStudents.length }}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <v-chip
                        small
                        :color="getClassStatusChipColor(selectedClass)"
                      >
                        {{ selectedClass.status }}
                      </v-chip>
                      <v-icon
                        v-if="selectedClass.isExtra == true"
                        class="pa-0 ml-1 mb-1"
                        medium
                        >mdi-alpha-e-circle-outline</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn text color="primary" @click="openSelectedClass = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card>
  </div>
</template>
<script>
import {
  BASE_URL,
  TEACHER_CALENDAR,
  GET_CLASS_OPERATIONS_BY_ID,
  GET_TEACHER_COURSES,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import { GET_USER_TYPE, GET_PARAMS_VALUE, GET_TOKEN } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { USER_TYPE_ADMIN } from "@/common/constants";
export default {
  data() {
    return {
      openSelectedClass: false,
      selectedClass: {},
      teacherCalendar: [],
      courses: [],
      calendarDate: "",
      viewType: "week",
      viewTypeArray: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      teacherClasses: [],
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    };
  },
  mounted: function () {
    this.getTeacherCalender();
    this.fetchCourses();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.calendarDate = date;
      this.viewType = "day";
    },
    setToday() {
      this.calendarDate = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getTeacherCalender() {
      let url = TEACHER_CALENDAR;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacherCalendar = res.data.calendar;
          this.updateRange();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    showClassDetails({ nativeEvent, event }) {
      const open = () => {
        if (event.classOpsId) {
          this.getClassOpById(event);
          this.selectedClass = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.openSelectedClass = true))
          );
        }
      };
      if (this.openSelectedClass) {
        this.openSelectedClass = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    getClassStatusChipColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "primary-chip";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success-chip";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "secondary-chip";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error-chip";
      } else {
        return "orange-chip";
      }
    },
    getClassOpById(event) {
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = event.classOpsId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedClass = res.data;
          this.selectedClass.courseName = this.getCourseNameById(
            this.selectedClass.courseId
          );
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    updateRange() {
      this.teacherClasses = [];
      const allDay = false;

      for (let i = 0; i < this.teacherCalendar.slots.length; i++) {
        let slot = this.teacherCalendar.slots[i];
        let startTime = new Date(slot.startDateTime);
        let endTime = new Date(slot.endDateTime);
        let isAvailable = slot.isAvailable;
        let eventColor = isAvailable ? "success" : "error";
        let classOpsId = slot.classOpsId || null; // Assuming classOpsId might be optional

        this.teacherClasses.push({
          start: startTime,
          end: endTime,
          timed: !allDay,
          color: eventColor,
          classOpsId: classOpsId,
        });
      }
    },
    fetchCourses() {
      let url = GET_TEACHER_COURSES;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          this.getClasses();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getCourseNameById(courseId) {
      if (courseId) {
        let c = this.courses.filter((course) => {
          return course.courseId == courseId;
        });
        if (c.length && c[0].name) return c[0].name;
        return "No Course";
      }
    },
  },
};
</script>
<style>
div.v-event-timed.error.white--text {
  z-index: 2 !important;
}
</style>
