<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)">mdi-arrow-left</v-icon
        >{{ course.name }}</v-toolbar-title
      >
      <v-col v-if="course.status != STATUS_PUBLISHED" align="end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                course.status == STATUS_DRAFT &&
                course.courseType == COURSE_TYPE_PRIVATE
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="ReviewCourseConfirm()"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <v-btn
              v-if="
                course.status == STATUS_REVIEW ||
                course.courseType == COURSE_TYPE_PUBLIC
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="publishCourseConfirm()"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>{{
            course.status == STATUS_REVIEW ||
            course.courseType == COURSE_TYPE_PUBLIC
              ? "Publish"
              : "Review"
          }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="openEditCourseDialog()"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip v-if="course.status != STATUS_REVIEW" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="deleteCourseConfirmDialog()"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
        <v-tooltip v-if="course.status == STATUS_REVIEW" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="resetCourseReview()">
              <v-icon>mdi-restart</v-icon>
            </v-btn>
          </template>
          <span>Reset Review</span>
        </v-tooltip>
      </v-col>
    </v-toolbar>
    <v-container fluid class="pa-0 pt-3">
      <EditCourse
        v-if="editCourseDailog"
        :course="course"
        @updateCourseDialog="closeEditCourseDialog"
      />
      <v-card
        class="ma-2"
        :style="expandCard ? 'height: 15rem' : 'height: 3.5rem'"
      >
        <v-row>
          <v-dialog v-model="imgDialog" width="450">
            <v-card>
              <v-img
                v-if="!selectedItem.preview && !selectedItem.thumbnail"
                class="align-start text-white"
                height="50"
                src="@/assets/Avatar_sales.png"
                contain
              ></v-img>
              <v-img
                v-if="!selectedItem.preview && selectedItem.thumbnail"
                :src="selectedItem.thumbnail"
              ></v-img>
              <v-img
                v-if="selectedItem.preview"
                :src="selectedItem.preview"
              ></v-img>
            </v-card>
          </v-dialog>
          <!-- <v-col cols="3" class="ml-2 mt-1 mb-1">
            <v-img
              :src="course.thumbnail"
              aspect-ratio="1.7"
              contain
            ></v-img>
            {{course}}
          </v-col> -->
          <v-card flat class="ml-3 pl-5 mt-5 mb-7 mr-1" v-if="expandCard">
            <v-avatar
              v-if="!course.preview && !course.thumbnail"
              style="border: 0.1rem solid #195d80"
              size="150"
              @click="openImageDialog(course)"
            >
              <v-img
                class="align-start text-white"
                src="@/assets/Avatar_sales.png"
                contain
              >
              </v-img>
            </v-avatar>
            <v-avatar
              v-else-if="!course.preview && course.thumbnail"
              style="border: 0.1rem solid #195d80"
              size="150"
              @click="openImageDialog(course)"
            >
              <v-img :src="course.thumbnail"> </v-img>
            </v-avatar>
            <v-avatar
              style="border: 0.1rem solid #195d80"
              v-else
              size="150"
              @click="openImageDialog(course)"
            >
              <v-img :src="course.preview"> </v-img>
            </v-avatar>
            <v-icon
              class="camera-icon primary"
              small
              color="white"
              @click="addThumbnail(course)"
              >mdi-camera</v-icon
            >
            <input
              :ref="'uploader-' + course.courseId"
              class="d-none"
              accept="image/png, image/gif, image/jpeg"
              type="file"
              @change="onFileChanged($event, course)"
            />
            <v-row
              class="ml-n8 mt-2"
              justify="center"
              v-if="course.showUploadBtns"
            >
              <v-btn color="primary" small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="uploadThumbnail(course)"
                      >mdi-upload</v-icon
                    >
                  </template>
                  <span>Upload</span>
                </v-tooltip>
              </v-btn>
              <v-btn color="primary" class="ml-3" small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="error"
                      @click="cancelUpload(course)"
                      >mdi-cancel</v-icon
                    >
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
              </v-btn>
            </v-row>
          </v-card>

          <v-col class="pt-0 mr-3">
            <v-row>
              <v-col :class="expandCard ? 'ml-n8' : 'ml-4'" cols="4 pt-1">
                <v-card-title>{{ course.name }}</v-card-title>
              </v-col>
              <v-col align="end" class="mt-3">
                <v-chip
                  v-if="course.courseType == COURSE_TYPE_PRIVATE"
                  small
                  class="mr-3"
                  :color="course.studentReview ? 'success-chip' : 'error-chip'"
                  >Student Review
                  <v-icon small>{{
                    course.studentReview
                      ? "mdi-check-circle"
                      : "mdi-close-circle"
                  }}</v-icon></v-chip
                >
                <v-chip
                  v-if="course.courseType == COURSE_TYPE_PRIVATE"
                  small
                  class="mr-3"
                  :color="course.teacherReview ? 'success-chip' : 'error-chip'"
                  >Teacher Review
                  <v-icon small>{{
                    course.teacherReview
                      ? "mdi-check-circle"
                      : "mdi-close-circle"
                  }}</v-icon></v-chip
                >
                <v-chip small class="mr-3" :color="getStatusColor(course)">{{
                  course.status
                }}</v-chip>
                <v-chip
                  small
                  class="mr-3"
                  :color="
                    course.courseType === COURSE_TYPE_PRIVATE
                      ? 'secondary-chip'
                      : 'orange-chip'
                  "
                  >{{ course.courseType }}</v-chip
                >
                <v-icon v-if="expandCard == true" @click="toggleCard()"
                  >mdi-chevron-up</v-icon
                >
                <v-icon v-else @click="toggleCard()">mdi-chevron-down</v-icon>
              </v-col>
              <!-- <v-col align="end" class="mt-3">
                <v-menu v-if="this.course.status != STATUS_PUBLISHED">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-upload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Publish</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> -->
            </v-row>
            <v-row v-if="expandCard == true" no-gutters class="ml-n4">
              <v-card-subtitle
                class="pa-0"
                style="word-break: break-word"
                v-html="course.description"
              ></v-card-subtitle>
            </v-row>

            <!-- 
                Grades
                <v-col cols="1" v-for="grade in course.grades" :key="grade"> {{ grade }} </v-col>
                <v-chip
                class="ma-1"
                small
                color="primary"
                v-for="grade in course.grades"
                :key="grade"
              >
                Grade {{ grade }}
              </v-chip> -->
            <v-row v-if="expandCard == true" class="ml-n4 mt-0">
              Grades :
              <v-chip
                class="ml-1 secondary-chip"
                small
                v-for="grade in course.grades"
                :key="grade"
              >
                {{ grade }}
              </v-chip>
            </v-row>

            <v-row v-if="expandCard == true" class="mt-2 mb-2 ml-n8">
              <v-col cols="2" v-if="course.noOfClasses">
                <v-icon color="primary" class="mt-n1">mdi-home-modern</v-icon>
                <span class="mx-1">{{ course.noOfClasses }}&nbsp;Classes</span>
              </v-col>
              <v-col v-if="course.buyable">
                <v-icon color="primary">mdi-currency-inr</v-icon>
                <span class="mx-1">{{ course.price }}</span>
              </v-col>

              <!-- <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-col cols="1" align="end">
                <v-img
                  v-if="
                    this.course.courseType == COURSE_TYPE_PRIVATE &&
                    !this.course.buyable
                  "
                  src="../assets/alpha-u-circle-outline.svg"
                  max-height="30"
                  max-width="30"
                ></v-img>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat class="ma-2">
        <v-row class="small-chapter-row">
          <v-col cols="6" sm="3">
            <v-select
              v-if="dropdownOfChapters && dropdownOfChapters.length > 0"
              v-model="selectedChapter"
              :items="dropdownOfChapters"
              @change="onChapterChange()"
              item-text="chapterName"
              item-value="chapterNo"
              label="Select Chapter"
              solo
              dense
            ></v-select>
          </v-col>
          <v-col v-if="course.status != STATUS_PUBLISHED" align="end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click="openAddChapterDialog"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Add Chapter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="selectedChapter < 1"
                  color="warning"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openEditChapterDialog"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Chapter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="selectedChapter < 1"
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="openDeleteChapterDialog()"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Chapter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="selectedChapter <= 1"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    color="blue"
                    @click="openMoveChapterDialog(selectedChapter, 'UP')"
                    >mdi-arrow-up-bold</v-icon
                  >
                </v-btn>
              </template>
              <span>Move Up</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="
                    selectedChapter == dropdownOfChapters.length ||
                    selectedChapter < 1
                  "
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    color="blue"
                    @click="openMoveChapterDialog(selectedChapter, 'DOWN')"
                    >mdi-arrow-down-bold</v-icon
                  >
                </v-btn>
              </template>
              <span>Move Down</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-card v-if="selectedChapter" flat class="ma-2">
          <Lessons
            :course="course"
            :selectedChapter="selectedChapter"
            :lessonNo="paramsValue.lastClickedLessonNo || 1"
            :key="selectedChapter"
            :selectedNav="paramsValue.lastClickedNav || 'contents'"
          />
        </v-card>
      </v-card>
      <ConfirmDialog
        v-if="reviewConfirmDialog"
        :dialog="reviewConfirmDialog"
        :subMessage1="'Are you sure you want to review'"
        :subMessage2="course.name"
        @close="closeReviewDailog()"
        @confirm="reviewCourse()"
      />
      <!-- <v-dialog persistent v-model="reviewConfirmDialog" max-width="35rem">
        <v-card>
          <v-card-title
            >Are you sure you want to review
            {{ course.name }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeReviewDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="reviewCourse()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- <v-dialog persistent v-model="publishConfirmDialog" max-width="35rem">
        <v-card>
          <v-card-title
            >Are you sure you want to publish {{ course.name }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closePublishDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="publishCourse()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <ConfirmDialog
        :dialog="publishConfirmDialog"
        :subMessage1="'Are you sure you want to publish'"
        :subMessage2="course.name"
        @close="closePublishDailog()"
        @confirm="publishCourse()"
      />
      <ConfirmDialog
        :dialog="resetCourseReviewConfirmDialog"
        :subMessage1="'Are you sure you want to reset review of'"
        :subMessage2="course.name"
        @close="closeResetCourseReviewConfirmDialog()"
        @confirm="resetCourse()"
      />
      <ConfirmDialog
        :dialog="deleteCourseDialog"
        :subMessage1="'Are you sure you want to delete'"
        :subMessage2="course.name"
        @close="closeDeleteDailog()"
        @confirm="deleteCourse()"
      />

      <!-- <v-dialog persistent v-model="deleteCourseDialog" max-width="35rem">
        <v-card>
          <v-card-title
            >Are you sure you want to delete "{{ course.name }}"</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDeleteDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="deleteCourse()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-dialog persistent v-model="addChapterDialog" width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="course.status != STATUS_PUBLISHED"
                  medium
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="addChapterDialog()"
                  icon
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="success">mdi-plus</v-icon>
                    </template>
                    <span>Add Chapter </span>
                  </v-tooltip>
                </v-btn>
              </template> -->
        <v-card>
          <v-card-title class="primary--text">New Chapter</v-card-title>
          <v-form ref="form" v-model="createChapterValidation.valid">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedChapter.chapterName"
                      label="Chapter Name"
                      :rules="createChapterValidation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeAddChapterDialog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="saveCourseChapter()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="editChapterDailog" width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="course.status != STATUS_PUBLISHED
                " color="primary" v-bind="attrs" v-on="on" @click="editChapter" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Chapter</span>
                </v-tooltip>
              </v-btn>
            </template> -->
        <v-card>
          <v-card-title class="primary--text">Edit Chapter</v-card-title>
          <v-form ref="form" v-model="createChapterValidation.valid">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedChapter.chapterName"
                      label="chapter Name"
                      :rules="createChapterValidation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeEditChapterDailog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="saveEditChapter()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <ConfirmDialog
        :dialog="deleteChapterDialog"
        :subMessage1="'Are you sure you want to delete'"
        :subMessage2="selectedChapterName"
        @close="closeDeleteChapterDialog()"
        @confirm="deleteChapter()"
      />
      <!-- <v-dialog persistent v-model="deleteChapterDialog" max-width="35rem">
        <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="course.status != STATUS_PUBLISHED
                " color="primary" dark v-bind="attrs" v-on="on" @click="deleteChapterConfirmDialog()" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Chapter</span>
                </v-tooltip>
              </v-btn>
            </template>
        <v-card>
          <v-card-title
            >Are you sure you want to delete this chapter?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDeleteChapterDialog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="deleteChapter()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <v-dialog persistent v-model="moveChapterDialog" width="500">
        <v-card>
          <v-card-title class="primary--text"
            >Move Chapter {{ direction }}</v-card-title
          >
          <v-form ref="form" v-model="moveChapterValidation.valid">
            <v-card-text>
              <v-chip class="primary ml-1"
                >Chapter {{ editedChapter.chapterNo }}</v-chip
              >
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :items="moveOptions"
                      dense
                      outlined
                      v-model="editedChapter.destinationIndex"
                      :rules="moveChapterValidation.nameRules"
                      label="Move Chapter to"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeMoveChapterDialog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="moveChapter()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  COURSE_VIEW,
  BASE_URL,
  ADD_CHAPTER,
  DELETE_CHAPTER,
  UPDATE_CHAPTER,
  MOVE_CHAPTER,
  PUBLISH_COURSE,
  RESET_REVIEW_COURSE,
  COURSE_DELETE,
  ADD_COURSE_THUMBNAIL,
  REVIEW_COURSE,
} from "@/common/apiEndpoints.js";
import {
  COURSE_TYPE_PRIVATE,
  COURSE_TYPE_PUBLIC,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  grades,
  STATUS_DRAFT,
  STATUS_REVIEW,
} from "@/common/constants";
import axios from "axios";
import Lessons from "@/components/Lessons.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
  SET_API_ERROR,
} from "@/store/mutations";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { getAxiosOptions, rightClickHandler } from "@/common/utility";
import EditCourse from "./EditCourse.vue";
export default {
  data() {
    return {
      imgDialog: false,
      selectedItem: {},
      file: "",
      preview: "",
      expandCard: false,
      showUploadBtns: false,
      editCourseDailog: false,
      dropdownOfChapters: [],
      publishConfirmDialog: false,
      resetCourseReviewConfirmDialog: false,
      reviewConfirmDialog: false,
      deleteCourseDialog: false,
      tab: null,
      selectedChapter: null,
      selectedChapterName: "",
      course: {},
      addChapterDialog: false,
      editChapterDailog: false,
      deleteChapterDialog: false,
      itemsPerPage: -1,
      editedIndex: -1,
      defaultItem: {
        grades: [],
      },
      createChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_REVIEW: STATUS_REVIEW,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      COURSE_TYPE_PUBLIC: COURSE_TYPE_PUBLIC,
      expandedRow: [1],
      moveChapterDialog: false,
      editedChapter: {},
      direction: "",
      moveChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],

            ["link"],
          ],
        },
      },
    };
  },
  components: { ConfirmDialog, Lessons, EditCourse },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
      // lastClickedPosition: `schoolModule/${GET_}`,
    }),
    moveOptions() {
      if (!this.dropdownOfChapters || this.dropdownOfChapters.length === 0) {
        return [];
      }
      return Array.from(
        { length: this.dropdownOfChapters.length },
        (_, i) => i + 1
      );
    },
  },
  mounted: function () {
    this.course = this.paramsValue;
    this.dropdownOfChapters = this.course.chapters;
    if (this.dropdownOfChapters.length > 0) {
      this.selectedChapter = this.paramsValue.lastClickedChapterNo || 1;
      this.courseView(this.selectedChapter);
    }
  },
  watch: {
    showUploadBtns: {
      handler(value) {
        this.showUploadBtns = value;
        // this.getAllLessons(this.selectedChapter);
        // this.assignContentsLists();
      },
    },
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    toggleCard() {
      this.expandCard = !this.expandCard;
    },
    openImageDialog(course) {
      // console.log(course);
      this.selectedItem = course;
      this.imgDialog = true;
    },
    addThumbnail(course) {
      const uploader = this.$refs["uploader-" + course.courseId];
      if (uploader) {
        uploader.click();
      }
    },
    openEditCourseDialog() {
      this.editCourseDailog = true;
    },
    closeEditCourseDialog() {
      this.editCourseDailog = false;
    },
    getStatusColor(item) {
      if (item.status === STATUS_PUBLISHED) {
        return "success-chip";
      } else if (item.status === STATUS_REVIEW) {
        return "primary-chip";
      } else if (item.status === STATUS_DRAFT) {
        return "error-chip";
      } else {
        return "secondary-chip";
      }
    },
    onFileChanged(e, course) {
      if (e.target && e.target.files && e.target.files.length > 0) {
        course.file = e.target.files[0];
        if (course.file) {
          course.preview = URL.createObjectURL(course.file);
        } else {
          course.preview = "";
        }
        // course.showUploadBtns = true;
        this.$set(course, "showUploadBtns", true);
        e.target.value = null;
      }
    },
    uploadThumbnail(course) {
      let payload = {};
      payload.file = course.file;
      payload.courseId = course.courseId;
      let url = ADD_COURSE_THUMBNAIL;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.setOverlayValue(false);
          this.setApiSuccess("Thumbnail updated successfully");
          this.courseView(this.selectedChapter);
          course.file = "";
          course.preview = "";
          course.showUploadBtns = false;
          // this.getAllCourses();
        })
        .catch((error) => {
          this.setOverlayValue(false);
          console.error("Error uploading thumbnail", error);
        });
    },
    cancelUpload(course) {
      course.showUploadBtns = false;
      course.file = "";
      course.preview = "";
    },
    onChapterChange() {
      this.courseView(this.selectedChapter);
    },
    courseView(chapterNo) {
      const url = COURSE_VIEW;
      const payload = {
        courseId: this.course.courseId,
      };
      if (chapterNo) {
        payload.chapterNo = chapterNo;
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          if (
            this.course &&
            this.course.chapters &&
            this.course.chapters.length > 0
          ) {
            this.selectedChapter = this.course.chapters[0].chapterNo;
            const lessonNo = this.paramsValue.lastClickedLessonNo || 1;
            const tabType = this.paramsValue.lastClickedNav || "contents";
            if (this.course.chapters?.[0]?.lessons?.length > 0) {
              this.courseViewWithLesson(
                this.selectedChapter,
                lessonNo,
                tabType
              );
            }
          }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          console.error("Error fetching chapter data:", error);
          this.setOverlayValue(false);
        });
    },
    courseViewWithLesson(chapterNo, lessonNo, tabType) {
      const url = COURSE_VIEW;
      const payload = {
        courseId: this.course.courseId,
        chapterNo: chapterNo,
      };
      if (lessonNo) {
        payload.lessonNo = lessonNo;
      }
      if (tabType) {
        switch (tabType) {
          case "contents":
            payload.showContent = true;
            break;
          case "notes":
            payload.showNotes = true;
            break;
          case "quizAssignments":
            payload.showQuizAssignments = true;
            break;
          case "codeAssignments":
            payload.showCodeAssignments = true;
            break;
          case "textAssignments":
            payload.showTextAssignments = true;
            break;
          case "codelyAssignments":
            payload.showCodelyAssignments = true;
            break;
        }
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          console.error("Error fetching chapter data:", error);
          this.setOverlayValue(false);
        });
    },
    ReviewCourseConfirm() {
      this.reviewConfirmDialog = true;
    },
    closeReviewDailog() {
      this.reviewConfirmDialog = false;
    },
    reviewCourse() {
      const url = REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeReviewDailog();
          this.setApiSuccess("Course sent for review successfully");
          // this.course.status = STATUS_REVIEW;
          // this.courseView(this.selectedChapter);
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeReviewDailog();
        });
    },
    resetCourseReview() {
      this.resetCourseReviewConfirmDialog = true;
    },
    closeResetCourseReviewConfirmDialog() {
      this.resetCourseReviewConfirmDialog = false;
    },
    resetCourse() {
      const url = RESET_REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeResetCourseReviewConfirmDialog();
          this.setApiSuccess("Course review reset successfully");
          this.courseView(this.selectedChapter);
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeResetCourseReviewConfirmDialog();
        });
    },
    publishCourseConfirm() {
      if (this.course.courseType == COURSE_TYPE_PRIVATE) {
        if (!(this.course.studentReview || this.course.teacherReview)) {
          this.setApiError("Course Review is Pending with Student and Teacher");
        } else if (!this.course.studentReview) {
          this.setApiError("Course Review is Pending with Student");
        } else if (!this.course.teacherReview) {
          this.setApiError("Course Review is Pending with Teacher");
        } else {
          this.publishConfirmDialog = true;
        }
      } else {
        this.publishConfirmDialog = true;
      }
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
    },
    publishCourse() {
      const url = PUBLISH_COURSE;
      let payload = {};
      payload.courseId = this.course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.setApiSuccess("Course published successfully");
          this.courseView(this.selectedChapter);
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closePublishDailog();
        });
    },
    deleteCourseConfirmDialog() {
      this.deleteCourseDialog = true;
    },
    closeDeleteDailog() {
      this.deleteCourseDialog = false;
    },
    deleteCourse() {
      const url = COURSE_DELETE;
      let payload = {};
      payload.courseId = this.course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.setOverlayValue(false);
          this.closeDeleteDailog();
          this.setApiSuccess("Course Deleted successfully");
          this.$router.push({
            name: "Courses",
          });
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeDeleteDailog();
          this.setOverlayValue(false);
        });
    },

    openMoveChapterDialog(selectedChapter, direction) {
      this.moveChapterDialog = true;
      this.editedChapter.chapterNo = selectedChapter;
      this.editedChapter.courseId = this.course.courseId;
      this.direction = direction;
      if (direction == "UP") {
        this.editedChapter.direction = "UP";
      } else {
        this.editedChapter.direction = "DOWN";
      }
    },
    closeMoveChapterDialog() {
      this.moveChapterDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveChapter() {
      if (this.moveChapterValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.editedChapter.courseId;
        payload.chapterNo = this.editedChapter.chapterNo;
        payload.direction = this.editedChapter.direction;
        payload.destinationIndex = this.editedChapter.destinationIndex;
        let url = MOVE_CHAPTER;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveChapterValidation.valid = false;
            this.editedIndex = -1;
            this.course = res.data;
            this.dropdownOfChapters = this.course.chapters;
            this.courseView(this.editedChapter.chapterNo);
            // this.getCourseById();
            // this.close();
            this.setParams(this.course);
            this.setOverlayValue(false);
            this.closeMoveChapterDialog();
            this.setApiSuccess("Chapter moved successfully");

            // this.updateDropdownOfChapters();
            // this.dropdownOfChapters = this.course.chapters;
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveChapterDialog();
          });
      }
    },
    openAddChapterDialog() {
      this.addChapterDialog = true;
      this.editedChapter = Object.assign({}, this.defaultItem);
    },
    closeAddChapterDialog() {
      this.addChapterDialog = false;
      this.createChapterValidation.valid = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveCourseChapter() {
      this.$refs.form.validate();
      if (this.createChapterValidation.valid) {
        this.setOverlayValue(true);
        let payload = {};
        let url = ADD_CHAPTER;
        payload.courseId = this.course.courseId;
        payload.chapterName = this.editedChapter.chapterName;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.course = res.data;
            this.dropdownOfChapters = this.course.chapters;
            this.setParams(this.course);
            this.closeAddChapterDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Course chapter saved successfully");
            // setTimeout(()=>{this.setOverlayValue(false);},500);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },

    openEditChapterDialog(item) {
      this.editChapterDailog = true;
      this.editedIndex = 0;
      this.editedChapter = Object.assign({}, item);
      this.editedChapter.chapterName = this.dropdownOfChapters.find(
        (chapter) => chapter.chapterNo == this.selectedChapter
      ).chapterName;
    },
    closeEditChapterDailog() {
      this.editChapterDailog = false;
    },
    saveEditChapter() {
      if (this.createChapterValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          payload.courseId = this.course.courseId;
          payload.chapterName = this.editedChapter.chapterName;
          payload.chapterNo = this.selectedChapter;
          let url = UPDATE_CHAPTER;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.course = res.data;
              this.dropdownOfChapters = res.data.chapters;
              this.setParams(this.course);
              this.closeEditChapterDailog();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Updated successfully");
              // this.initialize();
            })
            .catch((error) => {
              this.setOverlayValue(false);
            });
        }
      }
    },

    openDeleteChapterDialog() {
      const selected = this.dropdownOfChapters.find(
        (chapters) => chapters.chapterNo === this.selectedChapter
      );
      this.selectedChapterName = selected ? selected.chapterName : "";
      this.deleteChapterDialog = true;
    },
    // closeDeleteDailog() {
    //   this.deleteChapterDialog = false;
    //   this.$nextTick(() => {
    //     this.editedChapter = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
    closeDeleteChapterDialog() {
      this.deleteChapterDialog = false;
    },
    deleteChapter() {
      const url = DELETE_CHAPTER;
      let payload = {};
      payload.courseId = this.course.courseId;
      payload.chapterNo = this.selectedChapter;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          this.dropdownOfChapters = this.course.chapters;
          if (this.selectedChapter - 1 != 0) {
            this.courseView(this.selectedChapter - 1);
          } else if (
            this.selectedChapter - 1 === 0 &&
            this.dropdownOfChapters.length != 0
          ) {
            this.courseView(this.selectedChapter);
          } else if (this.dropdownOfChapters.length === 0) {
            this.selectedChapter = null;
          }
          this.setParams(this.course);
          this.closeDeleteChapterDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
<style>
.small-chapter-row {
  max-height: 4rem;
  overflow: hidden;
  margin: 0;
  color: #e3ed60e3;
}
.camera-icon {
  top: 3.6rem;
  left: -2.3rem;
  border-radius: 50%;
  padding: 0.3rem;
}
</style>
