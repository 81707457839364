<template>
  <v-dialog persistent v-model="confirmDialog" max-width="40rem">
    <v-card>
      <v-card-title class="wrapText">
          {{ subMessage1 }}
          <span class="highlighted">&nbsp;{{ subMessage2 }}&nbsp;</span>?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="onCancel">
          {{ deleteBtnText || "Cancel" }}
        </v-btn>
        <v-btn color="primary" text @click="onOk">
          {{ okBtnText || "OK" }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "subMessage1", "subMessage2", "deleteBtnText", "okBtnText"],
  data() {
    return {
      confirmDialog: this.dialog,
    };
  },
  watch: {
    dialog(val) {
      this.confirmDialog = val;
    },
    confirmDialog(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    onCancel() {
      this.confirmDialog = false;
      this.$emit("close");
    },
    onOk() {
      this.confirmDialog = false;
      this.$emit("confirm");
    },
  },
};
</script>
<style scoped>
.highlighted {
  font-weight: bold;
  color: #714eff;
}
.wrapText {
  display: block;
  word-break: normal;
}
</style>