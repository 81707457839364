<template>
  <v-layout>
    <v-dialog v-model="courseCompletedDialog" width="35rem">
      <v-card width="35rem">
        <v-row class="ma-0 pt-4 pb-4">
          <v-col cols="2" class="ma-0 mt-0 pa-0"
            ><v-avatar color="#EEFFEF" size="3.6rem" class="ml-4">
              <v-icon color="#11D800">mdi-check</v-icon>
            </v-avatar></v-col
          >
          <v-col cols="10" class="mt-1 pa-0">
            <v-col class="pa-0"
              ><span style="color: #5edc11" class="text-h5 font-weight-medium"
                >Course Completed Successfully</span
              ></v-col
            ><v-col class="pa-0 mt-2"
              >Congratulations on finishing the course!</v-col
            >
            <v-col cols="10" class="pa-0 mt-4"
              ><v-btn
                width="100%"
                dark
                color="#5EDC11"
                @click="closeCourseCompletedDialog()"
                >OK</v-btn
              ></v-col
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- <AppBarAuth /> -->
    <v-app-bar flat dark app clipped-left height="50" color="primary">
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == true"
        >mdi-menu-open</v-icon
      >
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == false"
        >mdi-menu-close</v-icon
      >
      <v-toolbar-title>{{ courseData.courseName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="showCertificate" text @click="openCertificateDialog()"
        >View Certificate</v-btn
      >
      <v-icon size="30" @click="$router.push('/home/student-courses')"
        >mdi-close-circle</v-icon
      >
    </v-app-bar>
    <!-- <v-dialog v-model="certificateDialog" width="50%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Course Certificate</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCertificateDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col class="ma-1">
            <span style="font-size: large">
              You are awarded this certificate for successfully completeing the
              <span style="font-weight: 500">{{
                certificateDetails.courseName
              }}</span>
              course.
            </span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col cols="4" class="ml-1 pl-0">
            <span style="font-size: large" class="mr-1"> No of Downloads </span>
            <span
              style="font-size: large; font-weight: 500"
              class="primary--text"
              >{{ certificateDetails.downloads }}</span
            >
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-btn text color="primary" @click="verifyCertificate()"
                >Verify Certificate</v-btn
              >
              <v-btn text color="primary" @click="downloadCertificate()"
                >Download Certificate</v-btn
              >
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <CertificateVerification
      v-if="certificateDialog"
      :certificateDetails="certificateDetails"
      @update="certificateDialog = false"
    />
    <v-sheet>
      <v-navigation-drawer app clipped width="22%" v-model="drawer">
        <v-list class="ml-n2 mr-1">
          <!-- :value="isChapterExpanded(chapter)" -->
          <v-list-group
            value="true"
            v-for="chapter in courseData.chapters"
            :key="chapter.chapterNo"
            prepend-icon="$expand"
            sub-group
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content class="ml-n7">
                  <v-list-item-title class="text-h6 font-weight-medium">{{
                    chapter.chapterName
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="chapter.completed != null && chapter.completed != 0"
                    :color="chapter.completed == 100 ? 'success' : ''"
                    small
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="chapter.completed == null || chapter.completed == 0"
                    small
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </template>
            <!-- :value="isLessonExpanded(lesson)" -->
            <v-list-group
              value="true"
              v-for="lesson in chapter.lessons"
              :key="lesson.lessonNo"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    class="text-wrap error--text text-body-1"
                    >{{ lesson.lessonName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="lesson.completed != null && lesson.completed != 0"
                    :color="lesson.completed == 100 ? 'success' : ''"
                    small
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="lesson.completed == null || lesson.completed == 0"
                    small
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </template>
              <v-list-item-group
                :value="true"
                v-if="lesson.content.length != 0"
                :active-class="'disable-current-active-element'"
              >
                <v-list-item
                  v-for="content in lesson.content"
                  :key="content.contentNo"
                  :disabled="
                    content.source == 'Content locked' ||
                    content.locked != false
                  "
                  :ref="
                    contentRef(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      content.contentNo
                    )
                  "
                  class="ml-3"
                  link
                  @click="
                    showContent(
                      content,
                      chapter.chapterNo,
                      lesson.lessonNo,
                      lesson.classOpId
                    )
                  "
                  :input-value="
                    highLightCurrentContent(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      content.contentNo,
                      content.assignmentNumber,
                      content.contentType
                    )
                  "
                >
                  <v-list-item-icon>
                    <v-icon small color="black">
                      {{ `mdi-${getContentIcon(content)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-row>
                      <v-col>
                        <v-list-item-title class="text-wrap text-body-3">
                          {{ content.title || content.quizName }}
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="4">
                        <v-chip
                          v-if="content.isHomework == true"
                          x-small
                          color="error"
                          >HW</v-chip
                        >
                        <v-chip
                          v-else-if="content.isHomework == false"
                          x-small
                          color="primary"
                          >CW</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="getMdiCheckAll(content)"
                      :color="
                        content.completed == 100 ||
                        (content.submissions &&
                          content.submissions.length > 0) ||
                        (content.progress &&
                          content.progress.length > 0 &&
                          content.progress[0].completed == 100)
                          ? 'success'
                          : ''
                      "
                      small
                      >mdi-check-all</v-icon
                    >
                    <v-icon v-if="getMdiCheck(content)" small>mdi-check</v-icon>
                    <v-icon v-if="content.source == 'Content locked'" small
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
              <v-list-item v-if="lesson.notes && lesson.notes.length != 0">
                <v-list-item-title
                  class="ml-n8 text-body-2 font-weight-medium accent--text"
                  >Notes</v-list-item-title
                >
              </v-list-item>
              <v-list-item-group
                :value="true"
                v-if="lesson.notes && lesson.notes.length != 0"
                :active-class="'disable-current-active-element'"
              >
                <v-list-item
                  v-for="note in lesson.notes"
                  :key="note.noteNo"
                  link
                  class="ml-3"
                  :value="note"
                  :disabled="note.source == 'Notes locked'"
                  :input-value="
                    highLightCurrentNote(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      note.noteNo
                    )
                  "
                  @click="showNotes(note, chapter.chapterNo, lesson.lessonNo)"
                >
                  <v-list-item-icon>
                    <v-icon small color="black">
                      {{ `mdi-${getNoteIcon(note)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title class="text-wrap text-body-3">{{
                      note.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon v-if="note.source == 'Notes locked'" small
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-main
      style="
        background-color: #f4f7fe;
        overflow: hidden !important;
        overflow-y: auto;
      "
      class="pb-1"
    >
      <div
        class="px-3 pt-3"
        style="max-height: calc(100% - 2.3rem) !important; height: 100%"
      >
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          persistent
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="expand_chatbot ? 'chatbot-container' : 'closed-container'"
            >
              <v-btn
                fab
                @click="minimiseChatbot($event)"
                small
                class="mt-3"
                text
              >
                <v-icon v-if="!expand_chatbot"> mdi-step-backward </v-icon>
                <v-icon v-else size="30"> mdi-step-forward </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
                class="mr-3 fixed-btn"
                style="bottom: 4.2rem"
                v-if="expand_chatbot"
              >
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-avatar v-else size="42">
                  <img src="../assets/Icons/Group_227.svg" />
                </v-avatar>
              </v-btn>
            </div>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
        <v-card
          flat
          style="min-height: calc(100% - 1.4rem) !important"
          class="mb-7"
          v-if="
            currentActiveElement.source != 'Notes locked' &&
            currentActiveElement.source != 'Content locked'
          "
        >
          <div
            v-if="
              ((currentActiveElement.source != 'Content locked' ||
                currentActiveElement.source != 'Notes locked') &&
                currentActiveElement.type == CONTENT_TYPE_TEXT) ||
              ((currentActiveElement.source != 'Notes locked' ||
                currentActiveElement.source != 'Content locked') &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status == 'BLOCKED')
            "
          >

          <CourseTextView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :htmlContent="currentActiveElement.source"
            />
          </div>

          <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
          <!-- class="d-flex flex-column" -->
          <div
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <VideoPlayer
              style="position: absolute; inset: 0; margin: 0.35rem"
              :options="{
                autoplay: true,
                controls: true,
                playbackRates: [0.5, 1, 1.5, 2],
                sources: [
                  {
                    src: `${currentActiveElement.source}`,
                    type: 'video/mp4',
                  },
                ],
              }"
              :key="currentActiveElement.title"
            ></VideoPlayer>
          </div>
          <!-- Vue friendly Iframe will be shown if 
            content.resource.mimeType  does not contain 'video' string -->
          <div
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <div v-if="imagesArray">
              <ImageArray
                style="position: absolute; inset: 0; margin: 0.35rem"
                :title="currentActiveElement.title"
                :images="currentActiveElement.images"
              />
            </div>
            <div v-else>
              <CourseMediaView
                style="position: absolute; inset: 0; margin: 0.35rem"
                :scaleFactor="0.9"
                :src="
                  currentActiveElement.resource?.mimeType?.includes(
                    'application'
                  )
                    ? currentActiveElement.source + '#toolbar=0'
                    : currentActiveElement.source
                "
              />
            </div>
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_CODELY
            "
          >
            <CourseCodelyContent
              style="position: absolute; inset: 0; margin: 0.35rem"
              :src="currentActiveElement.source"
              :scaleFactor="0.9"
            />
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_QUIZ
            "
          >
            <StudentQuizes
              style="position: absolute; inset: 0; margin: 0.35rem"
              v-bind:content="currentActiveElement"
              v-bind:chapterNo="selectedChapterNo"
              v-bind:lessonNo="selectedLessonNo"
              v-bind:courseId="courseData.courseId"
              v-bind:studentCourseProgress="studentCourseProgress"
              :key="currentActiveElement.contentNo"
              @questionCompleted="questionUpdate"
            />
          </div>
          <div v-if="showQuizAssignment == true">
            <QuizAssignment
              style="position: absolute; inset: 0; margin: 0.35rem"
              :key="assignmentNumber"
              v-bind:classOpId="classOpId"
              v-bind:quizAssignment="currentActiveElement"
              @assignmentQuestionCompleted="updateQuizAssignmentProgress"
            />
          </div>
          <div v-if="showCodeAssignment == true">
            <div
              v-if="
                showCodeAssignment == true &&
                currentActiveElement.submissions.length == 0
              "
            >
              <PracticeCoding
                style="position: absolute; inset: 0;"
                :key="currentActiveElement.assignmentNumber"
                v-bind:classOpId="classOpId"
                v-bind:assignment="currentActiveElement"
                v-bind:isCompleteAssignment="true"
                @assignmentCompleted="
                  getClassOpById(classOpId, 'codeAssignment')
                "
              />
            </div>
            <div class="pa-8" v-else>
              <CourseCodeAssignmentView
                style="position: absolute; inset: 0; margin: 0.35rem"
                :key="currentActiveElement.assignmentNumber"
                :assignment="currentActiveElement"
              />
            </div>
          </div>
          <div v-if="showCodelyAssignment == true">
            <CourseCodelyAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :scaleFactor="0.8"
              v-if="showCodelyAssignment == true"
              :key="currentActiveElement.assignmentNumber"
              :classOpId="classOpId"
              :assignment="currentActiveElement"
              @assignmentCompleted="
                getClassOpById(classOpId, 'codelyAssignment')
              "
            />
          </div>
          <div v-if="showTextAssignment == true">
            <CourseTextAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              v-if="showTextAssignment == true"
              :key="currentActiveElement.assignmentNumber"
              :classOpId="classOpId"
              :assignment="currentActiveElement"
              @assignmentCompleted="getClassOpById(classOpId, 'textAssignment')"
            />
          </div>
        </v-card>
        <v-card
          v-else
          flat
          style="min-height: calc(100% - 1.4rem) !important"
          class="d-flex justify-center align-center"
        >
          <div>
            <v-icon>mdi-lock-outline</v-icon>
            <span
              class="ma-2 text-body-1"
              v-if="currentActiveElement.source != 'Notes locked'"
              >Content will be unlocked after the class starts
            </span>
            <span class="ma-2 text-body-1" v-else>
              Notes will be unlocked after the class ends
            </span>
          </div>
        </v-card>
      </div>
      <v-card flat color="mainContentColor" class="ma-1">
        <v-row
          :style="
            drawer == true
              ? 'justify-content: center;'
              : 'justify-content: space-between;'
          "
        >
          <v-col cols="1" class="pa-0 ma-1 ml-6">
            <v-btn
              :disabled="disablePreviousButton"
              class="ml-2 mr-2 error-btn"
              outlined
              @click="goToPrevious()"
              >« Pre</v-btn
            >
          </v-col>
          <v-col cols="1" class="pa-0 ma-1 mr-6">
            <v-btn
              :disabled="disableNextButton || loading"
              class="ml-2 primary-btn"
              outlined
              @click="goToNext()"
              >Next »</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
import confetti from "canvas-confetti";
import {
  BASE_URL,
  GET_STUDENT_COURSE_PROGRESS,
  GET_STUDENT_LESSON_PROGRESS,
  GET_ALL_CLASS_OPERATIONS,
  GET_COURSE_BY_ID,
  GET_CLASS_OPERATIONS_BY_ID,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
  GET_CLASSROOM_BY_ID,
} from "@/common/apiEndpoints.js";
import {
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
  CLASS_STATUS_COMPLETED,
  FRANCHISE_MODEL,
  NON_FRANCHISE_MODEL,
} from "@/common/constants";
import Vue from "vue";
import AppBarAuth from "./AppBarAuth.vue";
import StudentQuizes from "@/components/StudentQuizes.vue";
import QuizAssignment from "@/components/QuizAssignment.vue";
import CourseCodelyAssignmentView from "@/components/CourseViewComponents/CourseCodelyAssignmentView.vue";
import CourseCodeAssignmentView from "@/components/CourseViewComponents/CourseCodeAssignmentView.vue";
import CourseTextView from "@/components/CourseViewComponents/CourseTextView.vue";
import CourseTextAssignmentView from "@/components/CourseViewComponents/CourseTextAssignmentView.vue";
import CourseCodelyContent from "@/components/CourseViewComponents/CourseCodelyContent.vue";
import CourseMediaView from "@/components/CourseViewComponents/CourseMediaView.vue";
import PracticeCoding from "@/components/PracticeCoding.vue";
import CertificateVerification from "./CertificateVerification.vue";

import VideoPlayer from "./VideoPlayer.vue";
import ChatBox from "./ChatBox.vue";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import ImageArray from "./ImageArray2.vue";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
export default Vue.extend({
  data() {
    return {
      courseCompletedDialog: false,
      expand_chatbot: true,
      loading: false,
      courseId: "",
      classRoomId: "",
      studentCourses: [],
      studentCourseProgress: [],
      drawer: true,
      disableNextButton: false,
      disablePreviousButton: false,
      courseData: {},
      content: {},
      note: {},
      showQuizAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
      showCodelyAssignment: false,
      showCertificate: false,
      certificateDialog: false,
      selectedChapterNo: "",
      selectedLessonNo: "",
      classOpId: "",
      assignmentNumber: "",
      textAssignmentSubmission: "",
      codelyAssignmentSubmission: "",
      showDescArr: [],
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
      model: "",
      fab: false,
      top: false,
      left: false,
      currentActiveElement: {},
      certificateDetails: {},
      lastCompletedContent: {
        chapterNo: 1,
        lessonNo: 1,
        contentNo: 1,
        content: {},
      },
      ukCourseContentArray: [],
      imagesArray: false,
    };
  },
  components: {
    AppBarAuth,
    QuizAssignment,
    StudentQuizes,
    VideoPlayer,
    ChatBox,
    CourseCodelyAssignmentView,
    CourseCodeAssignmentView,
    CourseCodelyContent,
    CourseMediaView,
    CourseTextAssignmentView,
    CourseTextView,
    PracticeCoding,
    ImageArray,
    CertificateVerification,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if (this.paramsValue.courseData) {
      this.courseData = JSON.parse(JSON.stringify(this.paramsValue.courseData));
      this.courseId = this.paramsValue.courseData.courseId;
      this.classRoomId = this.paramsValue.courseData.classRoomId;
      this.getAllClassOps(this.classRoomId);
    }
    // setTimeout(() => {
    //   this.scrollToLastActiveContent();
    // }, 1000);
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    openCourseCompletedDialog() {
      this.courseCompletedDialog = true;
      this.triggerConfetti();
    },
    closeCourseCompletedDialog() {
      this.courseCompletedDialog = false;
    },
    triggerConfetti() {
      confetti({
        particleCount: 2500,
        spread: 500,
        origin: { y: 0.35 },
      });
    },
    isChapterExpanded(chapter) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (chapter.completed == 100) {
        return false;
      }
      return true;
    },
    isLessonExpanded(lesson) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (lesson.completed == 100) {
        return false;
      }
      return true;
    },
    openCertificateDialog() {
      this.searchCertificate();
      this.certificateDialog = true;
    },

    searchCertificate() {
      const url = SEARCH_CERTIFICATE;
      const payload = {};
      payload.courseProgressId =
        this.studentCourseProgress[0].progress.progressId;
      payload.type = "course";
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          if (res) {
            this.certificateDetails = res.data;
            this.certificateDialog = true;
          }
        })
        .catch((error) => {
          this.setApiError("Certificate is not generated.");
          this.setOverlayValue(false);
        });
    },
    // verifyCertificate() {
    //   var url = BASE_URL + VERIFY_CERTIFICATE;
    //   url = url + "?secureKey=" + this.certificateDetails.certId;
    //   window.open(url);
    //   // axios
    //   //   .get(url, {
    //   //     params: {
    //   //       secureKey: certId,
    //   //     },
    //   //     responseType: "text",
    //   //     ...getAxiosOptions(BASE_URL, this.token),
    //   //   })
    //   //   .then((res) => {
    //   //     this.closeCertificateDialog();
    //   //     const htmlContent = res.data;
    //   //     const newWindow = window.open();
    //   //     newWindow.document.open();
    //   //     newWindow.document.write(htmlContent);
    //   //     newWindow.document.close();
    //   //   })
    //   //   .catch((error) => {
    //   //     error = error.errorMessage;
    //   //     this.closeCertificateDialog();
    //   //   });
    // },
    // downloadCertificate() {
    //   const url = DOWNLOAD_CERTIFICATE;
    //   const payload = { certId: this.certificateDetails.certId };
    //   axios
    //     .post(url, payload, {
    //       responseType: "arraybuffer",
    //       ...getAxiosOptions(BASE_URL, this.token),
    //     })
    //     .then((res) => {
    //       const blob = new Blob([res.data], { type: "application/pdf" });
    //       const pdfUrl = URL.createObjectURL(blob);
    //       this.closeCertificateDialog();
    //       window.open(pdfUrl, "_blank");
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.setOverlayValue(false);
    //     });
    // },
    // getCourseById() {
    //   const url = GET_COURSE_BY_ID;
    //   const payload = {};
    //   payload.courseId = this.courseId;
    //   axios
    //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.courseData = res.data;
    //       this.courseOpen();
    //       setTimeout(() => {
    //         this.scrollToLastActiveContent();
    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //     });
    // },
    highLightCurrentContent(
      chapterNo,
      lessonNo,
      contentNo,
      assignmentNumber,
      contentType
    ) {
      if (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        contentType
      ) {
        if (contentType == "content") {
          return contentNo == this.currentActiveElement.contentNo;
        } else if (contentType == this.currentActiveElement.contentType) {
          return assignmentNumber == this.currentActiveElement.assignmentNumber;
        }
      }
    },
    highLightCurrentNote(chapterNo, lessonNo, noteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        noteNo == this.currentActiveElement.noteNo
      );
    },
    getMdiCheckAll(content) {
      return (
        (content.contentType &&
          content.contentType == "content" &&
          content.completed == 100 &&
          content.source != "Content locked") ||
        (content.contentType &&
          content.contentType.includes("assignment") &&
          ((content.submissions && content.submissions.length != 0) ||
            (content.progress &&
              content.progress.length != 0 &&
              content.progress[0].completed == 100)))
      );
    },
    getMdiCheck(content) {
      return (
        (content.locked != false &&
          content.source != "Content locked" &&
          content.contentType == "content" &&
          content.completed != 100) ||
        (content.contentType &&
          content.contentType.includes("assignment") &&
          !(
            (content.progress &&
              content.progress.length != 0 &&
              content.progress[0].completed == 100) ||
            (content.submissions && content.submissions.length != 0)
          ))
      );
    },
    getContentIcon(content) {
      if (content.contentType == "content") {
        if (content.type == CONTENT_TYPE_QUIZ) {
          return "lightbulb-on-10";
        } else if (content.type == CONTENT_TYPE_TEXT) {
          return "text-box-outline";
        } else if (content.type == CONTENT_TYPE_CODELY) {
          return "code-block-braces";
        } else if (content.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("application")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        }
      } else if (content.contentType == "codeassignment") {
        return "code-array";
      } else if (content.contentType == "textassignment") {
        return "text-box-edit-outline";
      } else if (content.contentType == "quizassignment") {
        return "lightbulb-on-50";
      } else if (content.contentType == "codelyassignment") {
        return "code-block-braces";
      }
    },
    getNoteIcon(note) {
      if (note.type == CONTENT_TYPE_MEDIA) {
        if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("video")
        ) {
          return "video";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("image")
        ) {
          return "image";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("application")
        ) {
          return "file-pdf-box";
        } else {
          return "table-of-contents";
        }
      } else {
        return "text-box-outline";
      }
    },
    scrollToLastActiveContent() {
      var activeContentFound = false;
      if (this.courseData.chapters[0].lessons[0].content.length == 0) {
        this.setApiError("There is no Content in the Course");
      } else {
        this.courseData.chapters.forEach((chapter) => {
          // Iterate through lessons

          chapter.lessons.forEach((lesson) => {
            // Iterate through content

            lesson.content.forEach((content) => {
              // Check if content is completed and has value 100
              if (
                content.contentType == "content" &&
                content.completed == 100
              ) {
                // Update lastCompletedContent if this content is the latest completed one
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              } else if (
                content.contentType == "quizassignment" &&
                content.progress &&
                content.progress.length > 0 &&
                content.progress[0].completed == 100
              ) {
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              } else if (
                content.contentType.includes("assignment") &&
                content.contentType != "quizassignment" &&
                content.submissions &&
                content.submissions.length > 0
              ) {
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              }
            });
          });
        });
        if (activeContentFound) {
          this.showContent(
            this.lastCompletedContent.content,
            this.lastCompletedContent.chapterNo,
            this.lastCompletedContent.lessonNo,
            this.lastCompletedContent.content.classOpId
          );
        } else {
          this.showContent(
            this.courseData.chapters[0].lessons[0].content[0],
            1,
            1,
            this.courseData.chapters[0].lessons[0].content[0].classOpId
          );
        }
        const contentRef =
          this.$refs[
            `contentref_${this.lastCompletedContent.chapterNo}_${this.lastCompletedContent.lessonNo}_${this.lastCompletedContent.contentNo}`
          ];
        if (contentRef) {
          contentRef[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    },
    contentRef(chapterNo, lessonNo, contentNo) {
      return `contentref_${chapterNo}_${lessonNo}_${contentNo}`;
    },
    getClassOpById(classOpId, assignmentType) {
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = classOpId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          var classOp = res.data;
          if (classOp.topicCovered.length > 0) {
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].textAssignments =
              Object.assign([], classOp.textAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codeAssignments =
              Object.assign([], classOp.codeAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codelyAssignments =
              Object.assign([], classOp.codelyAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].quizAssignments =
              Object.assign([], classOp.quizAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
              classOp.classOpId;
          }
          if (
            assignmentType == "textAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.textAssignments.find(
                (txtAsgnmnt) =>
                  txtAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codeAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codeAssignments.find(
                (codeAsgnmnt) =>
                  codeAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codelyAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codelyAssignments.find(
                (codelyAsgnmnt) =>
                  codelyAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          this.checkForCompletion();
          this.checkForDisableNext();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAllClassOps(classRoomId) {
      if (classRoomId) {
        let url = GET_ALL_CLASS_OPERATIONS;
        this.setOverlayValue(true);
        let payload = {};
        payload.classRoomId = classRoomId;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            res.data.classOperations.forEach((classOp) => {
              if (classOp.topicCovered.length > 0) {
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].textAssignments = Object.assign([], classOp.textAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codeAssignments = Object.assign([], classOp.codeAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codelyAssignments = Object.assign(
                  [],
                  classOp.codelyAssignments
                );
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].quizAssignments = Object.assign([], classOp.quizAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
                  classOp.classOpId;
              }
            });
            this.mergeAssignmentsIntoContnet();
            this.fetchCourseProgress(this.courseData.courseId);
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    mergeAssignmentsIntoContnet() {
      this.ukCourseContentArray = [];
      for (var c = 0; c < this.courseData.chapters.length; c++) {
        for (var l = 0; l < this.courseData.chapters[c].lessons.length; l++) {
          var contentArray = [];
          for (
            var cnt = 0;
            cnt < this.courseData.chapters[c].lessons[l].content.length;
            cnt++
          ) {
            contentArray.push(
              this.courseData.chapters[c].lessons[l].content[cnt]
            );
            contentArray[contentArray.length - 1].contentType = "content";
            contentArray[contentArray.length - 1].chapterNo = c + 1;
            contentArray[contentArray.length - 1].lessonNo = l + 1;
            for (
              var ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].codeAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].codeAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].codeAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "codeassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].quizAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].quizAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].quizAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "quizassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].textAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].textAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].textAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "textassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].codelyAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "codelyassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
          }
          var homeworkContentArray = [];
          homeworkContentArray = this.mergeHomeWorkAssignments(c, l);
          contentArray = [...contentArray, ...homeworkContentArray];
          this.courseData.chapters[c].lessons[l].content = [];
          this.courseData.chapters[c].lessons[l].content = Object.assign(
            [],
            contentArray
          );
          this.ukCourseContentArray = [
            ...this.ukCourseContentArray,
            ...contentArray,
          ];
          if (this.courseData.chapters[c].lessons[l].notes.length > 0)
            this.courseData.chapters[c].lessons[l].notes.forEach((note) => {
              note.contentType = "note";
              note.chapterNo = c + 1;
              note.lessonNo = l + 1;
              this.ukCourseContentArray.push(note);
            });
        }
      }
    },
    mergeHomeWorkAssignments(c, l) {
      var homeworkContentArray = [];
      for (
        var ca = 0;
        ca < this.courseData.chapters[c].lessons[l].codeAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].codeAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].codeAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].codeAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].codeAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "codeassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].quizAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].quizAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].quizAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].quizAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].quizAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "quizassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].textAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].textAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].textAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].textAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].textAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "textassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].codelyAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "codelyassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }

      return homeworkContentArray;
    },
    fetchCourseProgress(courseId) {
      let url = GET_STUDENT_COURSE_PROGRESS;
      let payload = {};
      payload.courseIds = [];
      payload.courseIds.push(courseId);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourseProgress = res.data.progresses;
          this.checkForCompletion();
          this.scrollToLastActiveContent();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },

    showContent(content, chapterNo, lessonNo, classOpId) {
      if (content.contentType == "content") {
        this.showActualContent(content, chapterNo, lessonNo);
      } else if (content.contentType == "codeassignment") {
        this.showAssignment(content, "CODE", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "quizassignment") {
        this.showAssignment(content, "QUIZ", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "textassignment") {
        this.showAssignment(content, "TEXT", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "codelyassignment") {
        this.showAssignment(content, "CODELY", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "note") {
        this.showNotes(content, chapterNo, lessonNo);
      }
      this.checkForDisableNext();
    },
    showActualContent(content, chapterNo, lessonNo) {
      this.currentActiveElement = content;
      if (content.images && content.images.length > 0) {
        this.imagesArray = true;
      } else {
        this.imagesArray = false;
      }
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      // content.value = true;
      this.showQuizAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.showTextAssignment = false;
      if (
        (this.currentActiveElement.type == CONTENT_TYPE_QUIZ &&
          this.currentActiveElement.completed != 100) ||
        this.currentActiveElement.source == "Content locked"
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      // this.checkForCompletion();
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.currentActiveElement.chapterNo == 1 &&
        this.currentActiveElement.lessonNo == 1 &&
        this.currentActiveElement.contentNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    lessonProgress() {
      let url = GET_STUDENT_LESSON_PROGRESS;
      let payload = {};
      payload.courseId = this.courseData.courseId;
      payload.lessonNo = Number(this.selectedLessonNo);
      payload.chapterNo = Number(this.selectedChapterNo);
      payload.contentNo = Number(this.currentActiveElement.contentNo);
      this.loading = true;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.loading = false;
          this.studentCourseProgress[0].progress = res.data;
          this.checkForCompletion();
          if (this.studentCourseProgress[0].progress.completed == 100) {
            this.openCourseCompletedDialog();
          }
          // this.goToNext();
        })
        .catch((error) => {
          this.loading = false;
          error = error.errorMessage;
        });
    },
    showNotes(note, chapterNo, lessonNo) {
      this.currentActiveElement = note;
      if (note.images && note.images.length > 0) {
        this.imagesArray = true;
      } else {
        this.imagesArray = false;
      }
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.showQuizAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.showTextAssignment = false;
      if (note.source == "Notes locked") {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.selectedChapterNo == 1 &&
        this.selectedLessonNo == 1 &&
        this.content.length == 0 &&
        note.noteNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    showAssignment(assignment, assignmentType, classOpId, chapterNo, lessonNo) {
      this.assignmentNumber = assignment.assignmentNumber;
      this.classOpId = classOpId;
      this.currentActiveElement = assignment;
      if (assignmentType == "QUIZ") {
        this.showQuizAssignment = true;
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showTextAssignment = false;
        if (
          this.currentActiveElement.progress &&
          this.currentActiveElement.progress.length > 0 &&
          this.currentActiveElement.progress[0].completed == 100
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "TEXT") {
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showQuizAssignment = false;
        this.showTextAssignment = true;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "CODELY") {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.showCodelyAssignment = true;
        this.showCodeAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.codelyAssignment = false;
        this.showCodeAssignment = true;
        this.showCodelyAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      this.disablePreviousButton = false;
    },
    checkForCompletion() {
      if (
        this.studentCourseProgress[0].progress &&
        this.studentCourseProgress[0].progress.completed == 100
      ) {
        this.showCertificate = true;
      }
      if (this.studentCourseProgress[0].progress) {
        this.courseData.completed =
          this.studentCourseProgress[0].progress.completed;
        if (this.studentCourseProgress[0].progress.chapters.length > 1) {
          this.studentCourseProgress[0].progress.chapters.sort((c1, c2) => {
            return c1.chapterNo - c2.chapterNo;
          });
        }
        for (
          let i = 0;
          i < this.studentCourseProgress[0].progress.chapters.length;
          i++
        ) {
          var progressChapterNo =
            this.studentCourseProgress[0].progress.chapters[i].chapterNo;
          this.courseData.chapters[progressChapterNo - 1].completed =
            this.studentCourseProgress[0].progress.chapters[i].completed;
          if (
            this.studentCourseProgress[0].progress.chapters[i].lessons.length >
            1
          ) {
            this.studentCourseProgress[0].progress.chapters[i].lessons.sort(
              (l1, l2) => {
                return l1.lessonNo - l2.lessonNo;
              }
            );
          }
          for (
            let j = 0;
            j <
            this.studentCourseProgress[0].progress.chapters[i].lessons.length;
            j++
          ) {
            var progressLessonNo =
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .lessonNo;
            this.courseData.chapters[progressChapterNo - 1].lessons[
              progressLessonNo - 1
            ].completed =
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].completed;
            if (
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length > 1
            ) {
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].contentProgress.sort((cp1, cp2) => {
                return cp1.contentNo - cp2.contentNo;
              });
            }
            for (
              let k = 0;
              k <
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length;
              k++
            ) {
              var contentIndex;
              var progressContentNo =
                this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                  .contentProgress[k].contentNo;
              contentIndex = this.courseData.chapters[
                progressChapterNo - 1
              ].lessons[progressLessonNo - 1].content.findIndex(
                (cnt) =>
                  cnt.contentType == "content" &&
                  cnt.contentNo == progressContentNo
              );
              if (
                this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                  .contentProgress[k].completed == 100
              ) {
                Vue.set(
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[contentIndex],
                  "locked",
                  false
                );
                if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[contentIndex + 1]
                ) {
                  Vue.set(
                    this.courseData.chapters[progressChapterNo - 1].lessons[
                      progressLessonNo - 1
                    ].content[contentIndex + 1],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ] &&
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ].content[0]
                ) {
                  Vue.set(
                    this.courseData.chapters[i].lessons[j + 1].content[0],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo] &&
                  this.courseData.chapters[progressChapterNo].lessons[0] &&
                  this.courseData.chapters[progressChapterNo].lessons[0]
                    .content[0]
                ) {
                  Vue.set(
                    this.courseData.chapters[progressChapterNo].lessons[0]
                      .content[0],
                    "locked",
                    false
                  );
                }
              }
              this.courseData.chapters[progressChapterNo - 1].lessons[
                progressLessonNo - 1
              ].content[contentIndex].completed =
                this.studentCourseProgress[0].progress.chapters[i].lessons[
                  j
                ].contentProgress[k].completed;
            }
          }
        }
      } else if (
        this.courseData &&
        this.courseData.chapters[0] &&
        this.courseData.chapters[0].lessons[0] &&
        this.courseData.chapters[0].lessons[0].content[0]
      ) {
        Vue.set(
          this.courseData.chapters[0].lessons[0].content[0],
          "locked",
          false
        );
        this.disablePreviousButton = true;
      }
      this.checkForCompletionForAssignments();
    },
    checkForCompletionForAssignments() {
      if (
        this.courseData &&
        this.courseData.chapters &&
        this.courseData.chapters.length > 0
      ) {
        for (var i = 0; i < this.courseData.chapters.length; i++) {
          if (this.courseData.chapters[i].lessons.length > 0) {
            for (
              var j = 0;
              j < this.courseData.chapters[i].lessons.length;
              j++
            ) {
              if (this.courseData.chapters[i].lessons[j].content.length > 0) {
                for (
                  var k = 0;
                  k < this.courseData.chapters[i].lessons[j].content.length;
                  k++
                ) {
                  if (
                    this.courseData.chapters[i].lessons[j].content[
                      k
                    ].contentType.includes("assignment")
                  ) {
                    if (
                      this.courseData.chapters[i].lessons[j].content[k]
                        .contentType == "quizassignment"
                    ) {
                      if (
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress &&
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress.length > 0 &&
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress[0].completed == 100
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j].content[k],
                          "locked",
                          false
                        );
                        if (
                          this.courseData.chapters[i].lessons[j].content[k + 1]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i].lessons[j].content[
                              k + 1
                            ],
                            "locked",
                            false
                          );
                        } else if (
                          this.courseData.chapters[i].lessons[j + 1] &&
                          this.courseData.chapters[i].lessons[j + 1].content[0]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i].lessons[j + 1]
                              .content[0],
                            "locked",
                            false
                          );
                        } else if (
                          this.courseData.chapters[i + 1] &&
                          this.courseData.chapters[i + 1].lessons[0] &&
                          this.courseData.chapters[i + 1].lessons[0].content[0]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i + 1].lessons[0]
                              .content[0],
                            "locked",
                            false
                          );
                        }
                      }
                    } else if (
                      this.courseData.chapters[i].lessons[j].content[k]
                        .submissions &&
                      this.courseData.chapters[i].lessons[j].content[k]
                        .submissions.length > 0
                    ) {
                      Vue.set(
                        this.courseData.chapters[i].lessons[j].content[k],
                        "locked",
                        false
                      );
                      if (
                        this.courseData.chapters[i].lessons[j].content[k + 1]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j].content[k + 1],
                          "locked",
                          false
                        );
                      } else if (
                        this.courseData.chapters[i].lessons[j + 1] &&
                        this.courseData.chapters[i].lessons[j + 1].content[0]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j + 1].content[0],
                          "locked",
                          false
                        );
                      } else if (
                        this.courseData.chapters[i + 1] &&
                        this.courseData.chapters[i + 1].lessons[0] &&
                        this.courseData.chapters[i + 1].lessons[0].content[0]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i + 1].lessons[0].content[0],
                          "locked",
                          false
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    updateQuizAssignmentProgress(progress) {
      this.currentActiveElement.progress[0] = progress;
      this.checkForCompletion();
      if (this.currentActiveElement.progress[0].completed == 100) {
        this.disableNextButton = false;
      } else {
        this.disableNextButton = true;
      }
    },
    questionUpdate(studentCourseProgress) {
      this.studentCourseProgress = studentCourseProgress;
      this.checkForCompletion();
      // this.currentActiveElement =
      //   this.courseData.chapters[this.selectedChapterNo - 1].lessons[
      //     this.selectedLessonNo - 1
      //   ].content[this.currentActiveElement.contentNo - 1];
      if (this.currentActiveElement.completed == 100) {
        this.disableNextButton = false;
      }
      if (this.studentCourseProgress[0].progress.completed == 100) {
        this.openCourseCompletedDialog();
      }
    },
    checkForDisableNext() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (
        index + 1 == this.ukCourseContentArray.length &&
        this.currentActiveElement.contentType != "content"
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "note" &&
        this.ukCourseContentArray[index].source == "Notes locked"
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "content" &&
        (this.ukCourseContentArray[index].source == "Content locked" ||
          (this.ukCourseContentArray[index].type == CONTENT_TYPE_QUIZ &&
            this.ukCourseContentArray[index].completed != 100))
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "quizassignment" &&
        !(
          this.ukCourseContentArray[index].progress &&
          this.ukCourseContentArray[index].progress[0] &&
          this.ukCourseContentArray[index].progress[0].completed == 100
        )
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType.includes("assignment") &&
        this.ukCourseContentArray[index].contentType != "quizassignment" &&
        this.ukCourseContentArray[index].submissions &&
        this.ukCourseContentArray[index].submissions.length == 0
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
    },
    goToNext() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (
        index != -1 &&
        this.currentActiveElement.contentType == "content" &&
        this.currentActiveElement.type != CONTENT_TYPE_QUIZ &&
        this.currentActiveElement.completed != 100
      ) {
        this.lessonProgress();
      }
      if (index < this.ukCourseContentArray.length) {
        var content = this.ukCourseContentArray[index + 1];
        var chapterNo = this.ukCourseContentArray[index + 1].chapterNo;
        var lessonNo = this.ukCourseContentArray[index + 1].lessonNo;
        var classOpId = this.ukCourseContentArray[index + 1].classOpId;
        this.showContent(content, chapterNo, lessonNo, classOpId);
        // this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index + 1 == this.ukCourseContentArray.length) {
        this.disableNextButton = true;
      }
    },
    goToPrevious() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (index > 0) {
        var content = this.ukCourseContentArray[index - 1];
        var chapterNo = this.ukCourseContentArray[index - 1].chapterNo;
        var lessonNo = this.ukCourseContentArray[index - 1].lessonNo;
        var classOpId = this.ukCourseContentArray[index - 1].classOpId;
        this.showContent(content, chapterNo, lessonNo, classOpId);
        this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index - 1 == 0) {
        this.disablePreviousButton = true;
      }
    },
    minimiseChatbot(event) {
      this.expand_chatbot = !this.expand_chatbot;
      event.currentTarget.blur();
    },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
}
.chatbot-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 8rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.closed-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 2rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.disable-current-active-element {
  pointer-events: none;
  border: 1px solid #714eff !important;
}
</style>
