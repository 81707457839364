<template>
  <v-layout>
    <!-- <AppBarAuth /> -->
    <v-app-bar flat dark app clipped-left height="50" color="primary">
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == true"
        >mdi-menu-open</v-icon
      >
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == false"
        >mdi-menu-close</v-icon
      >
      <v-toolbar-title>{{ courseData.courseName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn v-if="showCertificate" text @click="openCertificateDialog()"
        >View Certificate</v-btn
      > -->
      <v-icon size="30" @click="$router.push('/home/student-courses')"
        >mdi-close-circle</v-icon
      >
    </v-app-bar>
    <v-dialog v-model="certificateDialog" width="50%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Course Certificate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCertificateDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col class="ma-1">
            <span style="font-size: large">
              You are awarded this certificate for successfully completeing the
              <span style="font-weight: 500">{{
                certificateDetails.courseName
              }}</span>
              course.
            </span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col cols="4" class="ml-1 pl-0">
            <span style="font-size: large" class="mr-1"> No of Downloads </span>
            <span
              style="font-size: large; font-weight: 500"
              class="primary--text"
              >{{ certificateDetails.downloads }}</span
            >
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-btn text color="primary" @click="verifyCertificate()"
                >Verify Certificate</v-btn
              >
              <v-btn text color="primary" @click="downloadCertificate()"
                >Download Certificate</v-btn
              >
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet>
      <v-navigation-drawer app clipped width="22%" v-model="drawer">
        <v-list class="ml-n2 mr-1">
          <!-- :value="isChapterExpanded(chapter)" -->
          <v-list-group
            value="true"
            v-for="chapter in courseData.chapters"
            :key="chapter.chapterNo"
            prepend-icon="$expand"
            sub-group
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content class="ml-n7">
                  <v-list-item-title class="text-h5 font-weight-medium">{{
                    chapter.chapterName
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- :value="isLessonExpanded(lesson)" -->
            <v-list-group
              value="true"
              v-for="lesson in chapter.lessons"
              :key="lesson.lessonNo"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    class="text-wrap error--text text-body-1"
                    >{{ lesson.lessonName }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item v-if="lesson.notes && lesson.notes.length != 0">
                <v-list-item-title
                  class="ml-n8 text-body-2 font-weight-medium accent--text"
                  >Notes</v-list-item-title
                >
              </v-list-item>
              <v-list-item-group
                :value="true"
                v-if="lesson.notes && lesson.notes.length != 0"
                :active-class="'disable-current-active-element'"
              >
                <v-list-item
                  v-for="note in lesson.notes"
                  :key="note.noteNo"
                  link
                  class="ml-3"
                  :value="note"
                  :disabled="note.source == 'Notes locked'"
                  :ref="
                    contentRef(chapter.chapterNo, lesson.lessonNo, note.noteNo)
                  "
                  :input-value="
                    highLightCurrentNote(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      note.noteNo
                    )
                  "
                  @click="showNotes(note, chapter.chapterNo, lesson.lessonNo)"
                >
                  <v-list-item-icon>
                    <v-icon size="20" color="black">
                      {{ `mdi-${getNoteIcon(note)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title class="text-wrap text-body-3">{{
                      note.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon v-if="note.source == 'Notes locked'" size="20"
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
              <v-list-item
                v-if="lesson.assignments && lesson.assignments.length != 0"
              >
                <v-list-item-title
                  class="ml-n8 text-body-2 font-weight-medium accent--text"
                  >Assignments</v-list-item-title
                >
              </v-list-item>
              <v-list-item-group
                :value="true"
                v-if="lesson.assignments && lesson.assignments.length != 0"
                :active-class="'border'"
              >
                <v-list-item
                  v-for="assignment in lesson.assignments"
                  :key="assignment.keyNo"
                  link
                  class="ml-3"
                  :disabled="assignment.assignmentLocked"
                  :ref="
                    contentRef(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      assignment.keyNo
                    )
                  "
                  :input-value="
                    highLightCurrentAssignment(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      assignment.keyNo
                    )
                  "
                  @click="
                    showAsgnmt(
                      assignment,
                      chapter.chapterNo,
                      lesson.lessonNo,
                      lesson.classOpId
                    )
                  "
                >
                  <v-list-item-icon>
                    <v-icon size="20" color="black">
                      {{ `mdi-${getAssignmentIcon(assignment)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title class="text-wrap text-body-3">{{
                      assignment.title || assignment.quizName
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="getMdiCheckAll(assignment)"
                      :color="
                        assignment.completed == 100 ||
                        (assignment.submissions &&
                          assignment.submissions.length > 0) ||
                        (assignment.progress &&
                          assignment.progress.length > 0 &&
                          assignment.progress[0].completed == 100)
                          ? 'success'
                          : ''
                      "
                      small
                      >mdi-check-all</v-icon
                    >
                    <v-icon v-if="assignment.assignmentLocked" size="20"
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-main
      style="
        background-color: #f4f7fe;
        overflow: hidden !important;
        overflow-y: auto;
      "
      class="pb-1"
    >
      <div
        class="px-3 pt-3"
        style="max-height: calc(100% - 2.3rem) !important; height: 100%"
      >
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          persistent
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="expand_chatbot ? 'chatbot-container' : 'closed-container'"
            >
              <v-btn
                fab
                @click="minimiseChatbot($event)"
                small
                class="mt-3"
                text
              >
                <v-icon v-if="!expand_chatbot"> mdi-step-backward </v-icon>
                <v-icon v-else size="30"> mdi-step-forward </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
                class="mr-3 fixed-btn"
                style="bottom: 4.2rem"
                v-if="expand_chatbot"
              >
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-avatar v-else size="42">
                  <img src="../assets/Icons/Group_227.svg" />
                </v-avatar>
              </v-btn>
            </div>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
        <v-card
          flat
          style="min-height: calc(100% - 1.4rem) !important"
          class="mb-7"
          v-if="currentActiveElement.source != 'Notes locked'"
        >
          <div
            v-if="
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_TEXT) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status == 'BLOCKED')
            "
          >
            <CourseTextView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :htmlContent="currentActiveElement.source"
            />
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Notes locked' &&
              currentActiveElement.type == CONTENT_TYPE_MEDIA &&
              currentActiveElement.resource?.status != 'BLOCKED' &&
              currentActiveElement.resource?.mimeType?.includes('video')
            "
          >
            <VideoPlayer
              style="position: absolute; inset: 0; margin: 0.35rem"
              :options="{
                autoplay: true,
                controls: true,
                playbackRates: [0.5, 1, 1.5, 2],
                sources: [
                  {
                    src: `${currentActiveElement.source}`,
                    type: 'video/mp4',
                  },
                ],
              }"
              :key="currentActiveElement.title"
            ></VideoPlayer>
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Notes locked' &&
              currentActiveElement.type == CONTENT_TYPE_MEDIA &&
              currentActiveElement.resource?.status != 'BLOCKED' &&
              !currentActiveElement.resource?.mimeType?.includes('video')
            "
          >
            <div v-if="imagesArray">
              <ImageArray
                style="position: absolute; inset: 0; margin: 0.35rem"
                :title="currentActiveElement.title"
                :images="currentActiveElement.images"
              />
            </div>
            <div v-else>
              <CourseMediaView
                style="position: absolute; inset: 0; margin: 0.35rem"
                :scaleFactor="0.9"
                :src="
                  currentActiveElement.resource?.mimeType?.includes(
                    'application'
                  )
                    ? currentActiveElement.source + '#toolbar=0'
                    : currentActiveElement.source
                "
              />
            </div>
          </div>
          <div v-if="showQuizAssignment == true">
            <QuizAssignment
              style="position: absolute; inset: 0; margin: 0.35rem"
              :key="assignmentNumber"
              v-bind:classOpId="classOpId"
              v-bind:quizAssignment="currentActiveElement"
              @assignmentQuestionCompleted="updateQuizAssignmentProgress"
            />
          </div>
          <div v-if="showCodeAssignment == true">
            <div
              v-if="
                showCodeAssignment == true &&
                currentActiveElement.submissions.length == 0
              "
            >
              <PracticeCoding
                style="position: absolute; inset: 0"
                :key="currentActiveElement.assignmentNumber"
                v-bind:classOpId="classOpId"
                v-bind:assignment="currentActiveElement"
                v-bind:isCompleteAssignment="true"
                @assignmentCompleted="
                  getClassOpById(classOpId, 'codeAssignment')
                "
              />
            </div>
            <v-div class="pa-8" v-else>
              <CourseCodeAssignmentView
                style="position: absolute; inset: 0; margin: 0.35rem"
                :key="currentActiveElement.assignmentNumber"
                :assignment="currentActiveElement"
              />
            </v-div>
          </div>
          <div class="pa-1" v-if="showCodelyAssignment == true">
            <CourseCodelyAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :scaleFactor="0.8"
              v-if="showCodelyAssignment == true"
              :key="currentActiveElement.assignmentNumber"
              :classOpId="classOpId"
              :assignment="currentActiveElement"
              @assignmentCompleted="
                getClassOpById(classOpId, 'codelyAssignment')
              "
            />
          </div>
          <div class="pa-12" v-if="showTextAssignment == true">
            <CourseTextAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              v-if="showTextAssignment == true"
              :key="currentActiveElement.assignmentNumber"
              :classOpId="classOpId"
              :assignment="currentActiveElement"
              @assignmentCompleted="getClassOpById(classOpId, 'textAssignment')"
            />
          </div>
        </v-card>
        <v-card
          v-else
          flat
          style="min-height: calc(100% - 1.4rem) !important"
          class="d-flex justify-center align-center"
        >
          <div>
            <v-icon>mdi-lock-outline</v-icon>
            <span class="ma-2 text-body-1"
              >Notes will be unlocked after the class is over</span
            >
          </div>
        </v-card>
      </div>
      <v-card flat color="mainContentColor" class="ma-1">
        <v-row
          :style="
            drawer == true
              ? 'justify-content: center;'
              : 'justify-content: space-between;'
          "
        >
          <v-col cols="1" class="pa-0 ma-1 ml-6">
            <v-btn
              :disabled="disablePreviousButton"
              class="ml-2 mr-2 error-btn"
              outlined
              @click="goToPrevious()"
              >« Pre</v-btn
            >
          </v-col>
          <v-col cols="1" class="pa-0 ma-1 mr-6">
            <v-btn
              :disabled="disableNextButton || loading"
              class="ml-2 primary-btn"
              outlined
              @click="goToNext()"
              >Next »</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
import {
  BASE_URL,
  GET_STUDENT_COURSE_PROGRESS,
  GET_STUDENT_LESSON_PROGRESS,
  GET_ALL_CLASS_OPERATIONS,
  GET_COURSE_BY_ID,
  GET_CLASS_OPERATIONS_BY_ID,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
} from "@/common/apiEndpoints.js";
import { CONTENT_TYPE_TEXT, CONTENT_TYPE_MEDIA } from "@/common/constants";
import Vue from "vue";
import AppBarAuth from "./AppBarAuth.vue";
import QuizAssignment from "@/components/QuizAssignment.vue";
import CourseCodelyAssignmentView from "@/components/CourseViewComponents/CourseCodelyAssignmentView.vue";
import CourseCodeAssignmentView from "@/components/CourseViewComponents/CourseCodeAssignmentView.vue";
import CourseTextView from "@/components/CourseViewComponents/CourseTextView.vue";
import CourseTextAssignmentView from "@/components/CourseViewComponents/CourseTextAssignmentView.vue";
import CourseMediaView from "@/components/CourseViewComponents/CourseMediaView.vue";
import PracticeCoding from "@/components/PracticeCoding.vue";
import VideoPlayer from "./VideoPlayer.vue";
import ChatBox from "./ChatBox.vue";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import ImageArray from "./ImageArray2.vue";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
export default Vue.extend({
  data() {
    return {
      expand_chatbot: true,
      loading: false,
      courseId: "",
      classRoomId: "",
      studentCourses: [],
      studentCourseProgress: [],
      drawer: true,
      disableNextButton: false,
      disablePreviousButton: false,
      courseData: {},
      content: {},
      note: {},
      showQuizAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
      showCodelyAssignment: false,
      showCertificate: false,
      certificateDialog: false,
      selectedChapterNo: "",
      selectedLessonNo: "",
      classOpId: "",
      assignmentNumber: "",
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      model: "",
      fab: false,
      currentActiveElement: {},
      certificateDetails: {},
      lastCompletedContent: {
        chapterNo: 1,
        lessonNo: 1,
        contentNo: 1,
        content: {},
      },
      courseArray: [],
      imagesArray: false,
    };
  },
  components: {
    AppBarAuth,
    QuizAssignment,
    VideoPlayer,
    ChatBox,
    CourseCodelyAssignmentView,
    CourseCodeAssignmentView,
    CourseMediaView,
    CourseTextAssignmentView,
    CourseTextView,
    PracticeCoding,
    ImageArray,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if (this.paramsValue.courseData) {
      this.courseData = JSON.parse(JSON.stringify(this.paramsValue.courseData));
      this.courseId = this.paramsValue.courseData.courseId;
      this.classRoomId = this.paramsValue.courseData.classRoomId;
      this.courseOpen();
    }
    // setTimeout(() => {
    //   this.scrollToLastActiveContent();
    // }, 1000);
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    isChapterExpanded(chapter) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (chapter.completed == 100) {
        return false;
      }
      return true;
    },
    isLessonExpanded(lesson) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (lesson.completed == 100) {
        return false;
      }
      return true;
    },
    // openCertificateDialog() {
    //   this.searchCertificate();
    // },
    // closeCertificateDialog() {
    //   this.certificateDialog = false;
    // },
    // searchCertificate() {
    //   const url = SEARCH_CERTIFICATE;
    //   const payload = {};
    //   payload.courseProgressId =
    //     this.studentCourseProgress[0].progress.progressId;
    //   payload.type = "course";
    //   axios
    //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       if (res) {
    //         this.certificateDetails = res.data;
    //         this.certificateDialog = true;
    //       }
    //     })
    //     .catch((error) => {
    //       this.setApiError("Certificate is not generated.");
    //       this.setOverlayValue(false);
    //     });
    // },
    // verifyCertificate() {
    //   var url = BASE_URL + VERIFY_CERTIFICATE;
    //   url = url + "?secureKey=" + this.certificateDetails.certId;
    //   window.open(url);
    // },
    // downloadCertificate() {
    //   const url = DOWNLOAD_CERTIFICATE;
    //   const payload = { certId: this.certificateDetails.certId };
    //   axios
    //     .post(url, payload, {
    //       responseType: "arraybuffer",
    //       ...getAxiosOptions(BASE_URL, this.token),
    //     })
    //     .then((res) => {
    //       const blob = new Blob([res.data], { type: "application/pdf" });
    //       const pdfUrl = URL.createObjectURL(blob);
    //       this.closeCertificateDialog();
    //       window.open(pdfUrl, "_blank");
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.setOverlayValue(false);
    //     });
    // },
    highLightCurrentAssignment(chapterNo, lessonNo, keyNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        keyNo == this.currentActiveElement.keyNo
      );
    },
    highLightCurrentNote(chapterNo, lessonNo, noteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        noteNo == this.currentActiveElement.noteNo
      );
    },
    getAssignmentIcon(assignment) {
      if (assignment.assignmentType == "codeassignment") {
        return "code-array";
      } else if (assignment.assignmentType == "textassignment") {
        return "text-box-edit-outline";
      } else if (assignment.assignmentType == "quizassignment") {
        return "lightbulb-on-50";
      } else if (assignment.assignmentType == "codelyassignment") {
        return "code-block-braces";
      }
    },
    getMdiCheckAll(assignment) {
      return (
        (assignment.assignmentType && assignment.completed == 100) ||
        (assignment.submissions && assignment.submissions.length != 0) ||
        (assignment.progress &&
          assignment.progress.length != 0 &&
          assignment.progress[0].completed == 100)
      );
    },
    getNoteIcon(note) {
      if (note.type == CONTENT_TYPE_MEDIA) {
        if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("video")
        ) {
          return "video";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("image")
        ) {
          return "image";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("application")
        ) {
          return "file-pdf-box";
        } else {
          return "table-of-contents";
        }
      } else {
        return "text-box-outline";
      }
    },
    courseOpen() {
      this.getAllClassOps(this.classRoomId);
    },
    scrollToLastActiveContent() {
      var activeContentFound = false;
      if (this.courseData.chapters[0].lessons[0].notes.length == 0) {
        this.setApiError("There is no Notes in the Course");
      } else {
        this.courseData.chapters.forEach((chapter) => {
          // Iterate through lessons
          chapter.lessons.forEach((lesson) => {
            // Iterate through content
            lesson.notes.forEach((content) => {
              // Check if content is completed and has value 100
              if (content.assignmentType == "note") {
                // Update lastCompletedContent if this content is the latest completed one
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              } else if (
                content.assignmentType == "quizassignment" &&
                content.progress &&
                content.progress.length > 0 &&
                content.progress[0].completed == 100
              ) {
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              } else if (
                content.assignmentType.includes("assignment") &&
                content.assignmentType != "quizassignment" &&
                content.submissions &&
                content.submissions.length > 0
              ) {
                this.lastCompletedContent.chapterNo = chapter.chapterNo;
                this.lastCompletedContent.lessonNo = lesson.lessonNo;
                this.lastCompletedContent.content = content;
                activeContentFound = true;
              }
            });
          });
        });
        if (activeContentFound) {
          this.showAsgnmt(
            this.lastCompletedContent.content,
            this.lastCompletedContent.chapterNo,
            this.lastCompletedContent.lessonNo,
            this.lastCompletedContent.content.classOpId
          );
        } else {
          this.showAsgnmt(
            this.courseData.chapters[0].lessons[0].content[0],
            1,
            1,
            this.courseData.chapters[0].lessons[0].content[0].classOpId
          );
        }
        const contentRef =
          this.$refs[
            `contentref_${this.lastCompletedContent.chapterNo}_${this.lastCompletedContent.lessonNo}_${this.lastCompletedContent.contentNo}`
          ];
        if (contentRef) {
          contentRef[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    },
    contentRef(chapterNo, lessonNo, contentNo) {
      return `contentref_${chapterNo}_${lessonNo}_${contentNo}`;
    },
    getClassOpById(classOpId, assignmentType) {
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = classOpId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          var classOp = res.data;
          if (classOp.topicCovered.length > 0) {
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].textAssignments =
              Object.assign([], classOp.textAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codeAssignments =
              Object.assign([], classOp.codeAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codelyAssignments =
              Object.assign([], classOp.codelyAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].quizAssignments =
              Object.assign([], classOp.quizAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
              classOp.classOpId;
          }
          if (
            assignmentType == "textAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.textAssignments.find(
                (txtAsgnmnt) =>
                  txtAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codeAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codeAssignments.find(
                (codeAsgnmnt) =>
                  codeAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codelyAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codelyAssignments.find(
                (codelyAsgnmnt) =>
                  codelyAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          this.checkForDisableNext();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAllClassOps(classRoomId) {
      if (classRoomId) {
        let url = GET_ALL_CLASS_OPERATIONS;
        this.setOverlayValue(true);
        let payload = {};
        payload.classRoomId = classRoomId;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            res.data.classOperations.forEach((classOp) => {
              if (classOp.topicCovered.length > 0) {
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].textAssignments = Object.assign([], classOp.textAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codeAssignments = Object.assign([], classOp.codeAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codelyAssignments = Object.assign(
                  [],
                  classOp.codelyAssignments
                );
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].quizAssignments = Object.assign([], classOp.quizAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
                  classOp.classOpId;
              }
            });
            this.mergeAssignmentsIntoCourse();
            this.scrollToLastActiveContent();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    mergeAssignmentsIntoCourse() {
      this.courseArray = [];
      let keyNo = 1;
      let assignmentLocked = false;
      for (var c = 0; c < this.courseData.chapters.length; c++) {
        for (var l = 0; l < this.courseData.chapters[c].lessons.length; l++) {
          if (this.courseData.chapters[c].lessons[l].notes.length > 0)
            this.courseData.chapters[c].lessons[l].notes.forEach((note) => {
              note.assignmentType = "note";
              note.chapterNo = c + 1;
              note.lessonNo = l + 1;
              if (note.source == "Notes locked") {
                assignmentLocked = true;
              }
              this.courseArray.push(note);
            });
          var assignmentArray = [];
          for (
            var ca = 0;
            ca < this.courseData.chapters[c].lessons[l].codeAssignments.length;
            ca++
          ) {
            assignmentArray.push(
              this.courseData.chapters[c].lessons[l].codeAssignments[ca]
            );
            assignmentArray[assignmentArray.length - 1].assignmentType =
              "codeassignment";
            assignmentArray[assignmentArray.length - 1].keyNo = keyNo++;
            assignmentArray[assignmentArray.length - 1].assignmentLocked =
              assignmentLocked;
            assignmentArray[assignmentArray.length - 1].chapterNo = c + 1;
            assignmentArray[assignmentArray.length - 1].lessonNo = l + 1;
            assignmentArray[assignmentArray.length - 1].classOpId =
              this.courseData.chapters[c].lessons[l].classOpId;
          }
          for (
            ca = 0;
            ca < this.courseData.chapters[c].lessons[l].quizAssignments.length;
            ca++
          ) {
            assignmentArray.push(
              this.courseData.chapters[c].lessons[l].quizAssignments[ca]
            );
            assignmentArray[assignmentArray.length - 1].assignmentType =
              "quizassignment";
            assignmentArray[assignmentArray.length - 1].keyNo = keyNo++;
            assignmentArray[assignmentArray.length - 1].assignmentLocked =
              assignmentLocked;
            assignmentArray[assignmentArray.length - 1].chapterNo = c + 1;
            assignmentArray[assignmentArray.length - 1].lessonNo = l + 1;
            assignmentArray[assignmentArray.length - 1].classOpId =
              this.courseData.chapters[c].lessons[l].classOpId;
          }
          for (
            ca = 0;
            ca < this.courseData.chapters[c].lessons[l].textAssignments.length;
            ca++
          ) {
            assignmentArray.push(
              this.courseData.chapters[c].lessons[l].textAssignments[ca]
            );
            assignmentArray[assignmentArray.length - 1].assignmentType =
              "textassignment";
            assignmentArray[assignmentArray.length - 1].keyNo = keyNo++;
            assignmentArray[assignmentArray.length - 1].assignmentLocked =
              assignmentLocked;
            assignmentArray[assignmentArray.length - 1].chapterNo = c + 1;
            assignmentArray[assignmentArray.length - 1].lessonNo = l + 1;
            assignmentArray[assignmentArray.length - 1].classOpId =
              this.courseData.chapters[c].lessons[l].classOpId;
          }
          for (
            ca = 0;
            ca <
            this.courseData.chapters[c].lessons[l].codelyAssignments.length;
            ca++
          ) {
            assignmentArray.push(
              this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            );
            assignmentArray[assignmentArray.length - 1].assignmentType =
              "codelyassignment";
            assignmentArray[assignmentArray.length - 1].keyNo = keyNo++;
            assignmentArray[assignmentArray.length - 1].assignmentLocked =
              assignmentLocked;
            assignmentArray[assignmentArray.length - 1].chapterNo = c + 1;
            assignmentArray[assignmentArray.length - 1].lessonNo = l + 1;
            assignmentArray[assignmentArray.length - 1].classOpId =
              this.courseData.chapters[c].lessons[l].classOpId;
          }
          this.courseData.chapters[c].lessons[l].assignments = Object.assign(
            [],
            assignmentArray
          );
          this.courseArray = [...this.courseArray, ...assignmentArray];
        }
      }
    },

    showAsgnmt(assignment, chapterNo, lessonNo, classOpId) {
      if (assignment.assignmentType == "codeassignment") {
        this.showAssignment(assignment, "CODE", classOpId, chapterNo, lessonNo);
      } else if (assignment.assignmentType == "quizassignment") {
        this.showAssignment(assignment, "QUIZ", classOpId, chapterNo, lessonNo);
      } else if (assignment.assignmentType == "textassignment") {
        this.showAssignment(assignment, "TEXT", classOpId, chapterNo, lessonNo);
      } else if (assignment.assignmentType == "codelyassignment") {
        this.showAssignment(
          assignment,
          "CODELY",
          classOpId,
          chapterNo,
          lessonNo
        );
      } else if (assignment.assignmentType == "note") {
        this.showNotes(assignment, chapterNo, lessonNo);
      }
      this.checkForDisableNext();
    },
    showNotes(note, chapterNo, lessonNo) {
      this.currentActiveElement = note;
      if (note.images && note.images.length > 0) {
        this.imagesArray = true;
      } else {
        this.imagesArray = false;
      }
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.showQuizAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.showTextAssignment = false;
      if (note.source == "Notes locked") {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.selectedChapterNo == 1 &&
        this.selectedLessonNo == 1 &&
        note.noteNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    showAssignment(assignment, assignmentType, classOpId, chapterNo, lessonNo) {
      this.assignmentNumber = assignment.assignmentNumber;
      this.classOpId = classOpId;
      this.currentActiveElement = assignment;
      if (assignmentType == "QUIZ") {
        this.showQuizAssignment = true;
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showTextAssignment = false;
        if (
          this.currentActiveElement.progress &&
          this.currentActiveElement.progress.length > 0 &&
          this.currentActiveElement.progress[0].completed == 100
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "TEXT") {
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showQuizAssignment = false;
        this.showTextAssignment = true;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "CODELY") {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.showCodelyAssignment = true;
        this.showCodeAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.codelyAssignment = false;
        this.showCodeAssignment = true;
        this.showCodelyAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      this.disablePreviousButton = false;
    },
    updateQuizAssignmentProgress(progress) {
      this.currentActiveElement.progress[0] = progress;
      if (this.currentActiveElement.progress[0].completed == 100) {
        this.disableNextButton = false;
      } else {
        this.disableNextButton = true;
      }
    },
    checkForDisableNext() {
      var index = this.courseArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.assignmentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.assignmentType == "note" &&
              this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.assignmentType.includes("assignment") &&
              this.currentActiveElement.assignmentType ==
                content.assignmentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (
        index + 1 == this.courseArray.length &&
        this.currentActiveElement.assignmentType != "content"
      ) {
        this.disableNextButton = true;
      } else if (
        this.courseArray[index].assignmentType == "note" &&
        this.courseArray[index].source == "Notes locked"
      ) {
        this.disableNextButton = true;
      } else if (
        this.courseArray[index].assignmentType == "quizassignment" &&
        !(
          this.courseArray[index].progress &&
          this.courseArray[index].progress[0] &&
          this.courseArray[index].progress[0].completed == 100
        )
      ) {
        this.disableNextButton = true;
      } else if (
        this.courseArray[index].assignmentType.includes("assignment") &&
        this.courseArray[index].assignmentType != "quizassignment" &&
        this.courseArray[index].submissions &&
        this.courseArray[index].submissions.length == 0
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
    },
    goToNext() {
      var index = this.courseArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.assignmentType == "note" &&
            this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.assignmentType.includes("assignment") &&
              this.currentActiveElement.assignmentType ==
                content.assignmentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (index < this.courseArray.length) {
        var content = this.courseArray[index + 1];
        var chapterNo = this.courseArray[index + 1].chapterNo;
        var lessonNo = this.courseArray[index + 1].lessonNo;
        var classOpId = this.courseArray[index + 1].classOpId;
        this.showAsgnmt(content, chapterNo, lessonNo, classOpId);
        // this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index + 2 == this.courseArray.length) {
        this.disableNextButton = true;
      }
    },
    goToPrevious() {
      var index = this.courseArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.assignmentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.assignmentType == "note" &&
              this.currentActiveElement.noteNo == content.noteNo) ||
            (this.currentActiveElement.assignmentType.includes("assignment") &&
              this.currentActiveElement.assignmentType ==
                content.assignmentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (index > 0) {
        var content = this.courseArray[index - 1];
        var chapterNo = this.courseArray[index - 1].chapterNo;
        var lessonNo = this.courseArray[index - 1].lessonNo;
        var classOpId = this.courseArray[index - 1].classOpId;
        this.showAsgnmt(content, chapterNo, lessonNo, classOpId);
        this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index - 1 == 0) {
        this.disablePreviousButton = true;
      }
    },
    minimiseChatbot(event) {
      this.expand_chatbot = !this.expand_chatbot;
      event.currentTarget.blur();
    },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
}
.chatbot-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 8rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.closed-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 2rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.disable-current-active-element {
  pointer-events: none;
  border: 1px solid #714eff !important;
}
</style>
