<template>
  <v-footer app class="footer">
    <span>{{ new Date().getFullYear() }} — <strong> APTCODER</strong></span
    ><span style="color: black" class="ma-1"> (v.5.3)</span>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      api1: {},
      api2: {},
    };
  },
};
</script>

<style scoped>
.footer {
  /* background-color: #f7f7f7;
  border: 0.1rem solid #d5d7e2;
  border-radius: 0.5rem !important; */
  justify-content: center;
  /* margin-bottom: 0.3rem !important;
  margin-right: 0.5rem !important;
  margin-left: 0.3rem !important; */
  padding: .6rem;
}
</style>
