<template>
  <div>
    <v-toolbar dark color="primary" enlevation="2">
      <v-toolbar-title>
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)"
          >mdi-arrow-left</v-icon
        >
        Create Classroom</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="pa-0">
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-dialog persistent v-model="enrollStdDialog" max-width="70rem">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title> Student Enrollment </v-toolbar-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-switch
                    class="mt-4 pa-2"
                    v-model="deEnrollMode"
                    @change="changeEnrollMode()"
                    label=""
                  ></v-switch>
                </div>
              </template>
              <span>Remove Enrollment</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
              >mdi-magnify</v-icon
            >
            <v-text-field
              v-if="deEnrollMode"
              v-model="searchStudent"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>

          <v-container fluid v-model="panel" v-if="!deEnrollMode">
            <v-form ref="form">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="studentFilter.mobileNo"
                    label="Mobile No"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="studentFilter.email"
                    label="Email"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedInstitute"
                    :items="institute"
                    label="Institute Name"
                    item-text="instituteName"
                    item-value="instituteId"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="studentFilter.firstName"
                    label="First Name"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="studentFilter.lastName"
                    label="Last Name"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-1">
                  <v-combobox
                    v-model="studentFilter.grade"
                    :items="gradesArray"
                    label="Grades"
                    dense
                    deletable-chips
                    chips
                    item-text="desc"
                    item-value="id"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="searchStudents()"
                  >Apply</v-btn
                >
              </v-row>
            </v-form>
          </v-container>

          <v-container fluid style="max-height: 22rem; overflow-y: auto">
            <v-data-table
              v-if="studentsList.length > 0"
              v-model="selectedStudents"
              :headers="studentHeadersForEnroll"
              :items="studentsList"
              :hide-default-footer="studentsList.length <= 10"
              show-select
              item-key="studentId"
              item-value="studentId"
              :search="searchStudent"
              @toggle-select-all="selectAllStudents"
              @contextmenu:row="rightClickHandler"
            >
              <template v-slot:[`item.email`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      item.email
                        ? item.email.length > 20
                          ? item.email.substring(0, 20) + "..."
                          : item.email
                        : "---"
                    }}</span>
                  </template>
                  <span>{{ item.email ? item.email : "---" }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-row justify="center">
                  <v-col>
                    <v-chip
                      small
                      :color="
                        item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                      "
                    >
                      {{ item.status }}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <v-icon
                      v-if="!deEnrollMode && item.isEnrolled"
                      color="primary"
                      >mdi-account-check</v-icon
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.createdOn`]="{ item }">
                {{ formatDateTime(item.createdOn) }}
              </template>
              <template
                v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  :value="isSelected"
                  :disabled="
                    item.status != 'ACTIVE' ||
                    (!deEnrollMode && item.isEnrolled)
                  "
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>
            </v-data-table>
            <CustomDataTable
              v-else
              :headers="studentHeadersForEnroll"
              :items="studentsList"
              :hide-default-footer="studentsList.length <= 10"
            />
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeEnrollDialog()"
              >Cancel</v-btn
            >
            <v-btn
              v-if="!deEnrollMode"
              :disabled="selectedStudents.length == 0"
              color="primary"
              text
              @click="selectStudents()"
              >Enroll</v-btn
            >
            <v-btn
              v-if="deEnrollMode"
              :disabled="selectedStudents.length == 0"
              color="primary"
              text
              @click="enrollDeEnrollStudents('UnAssign')"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card flat>
        <v-form ref="form" v-model="createClassRoomValidation.valid">
          <v-card-text class="mt-1">
            <v-row>
              <v-col class="pt-0">
                <v-text-field
                  :disabled="editedClassRoom.status == 'SCHEDULED'"
                  v-model="editedClassRoom.name"
                  :rules="createClassRoomValidation.nameRules"
                  label="Classroom Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="pt-0">
                <v-text-field
                  v-model="studentNames"
                  :rules="createClassRoomValidation.nameRules"
                  label="Assign Students"
                  required
                  readonly
                  append-outer-icon="mdi-account-multiple-plus-outline"
                  @click="openEnrollStudentDialog()"
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-1">
                <v-select
                  :disabled="editedClassRoom.status == 'SCHEDULED'"
                  v-model="editedClassRoom.courseId"
                  :rules="createClassRoomValidation.nameRules"
                  :items="filteredCourses"
                  label="Course"
                  dense
                  item-text="name"
                  item-value="courseId"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchTerm"
                          placeholder="Search"
                          @input="searchCourse"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list>
                      <v-list-item class="pa-0 ma-0">
                        <v-list-item-content style="display: block !important">
                          <v-list-item-title class="text-body-2">{{
                            data.item.name
                          }}</v-list-item-title>
                          <span class="text-body-3"
                            >No of Classes :
                            <span class="error--text">{{
                              data.item.noOfClasses
                            }}</span></span
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedClassRoom.startDate"
                      label="Start date"
                      :rules="createClassRoomValidation.nameRules"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="pt-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedClassRoom.startDate"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedTeacherId"
                  :items="filteredTeachers"
                  :rules="createClassRoomValidation.nameRules"
                  label="Select Teacher"
                  dense
                  item-value="teacherId"
                  @change="ShowAvailability()"
                  >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchTerm"
                          placeholder="Search"
                          @input="searchTeacher"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template slot="selection" slot-scope="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list>
                      <v-list-item class="pa-0 ma-0">
                        <v-list-item-avatar>
                          <v-img
                            v-if="data.item.profilePhoto"
                            :src="data.item.profilePhoto"
                          ></v-img>
                          <img
                            v-else-if="data.item.gender == 'MALE'"
                            src="../assets/Male Avatar.png"
                          />
                          <img
                            v-else
                            src="../assets/Female Avatar.png"
                            alt="Jill"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content style="display: block !important">
                          <v-list-item-title class="text-body-2"
                            >{{ data.item.firstName }} {{ data.item.lastName }}
                            {{
                              "(" + data.item.highestQualification.qName + ")"
                            }}</v-list-item-title
                          >
                          <span class="text-body-3">{{
                            data.item.gender +
                            " (" +
                            data.item.experience +
                            " year)"
                          }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-select
                  v-model="editedClassRoom.model"
                  :rules="createClassRoomValidation.nameRules"
                  :items="classroomModel"
                  label="Model"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-1">
              <v-col cols="3" class="pt-0 pr-0">
                <p
                  v-if="editedClassRoom.courseId"
                  class="pa-0 ma-1 text-body-1"
                >
                  No of Classes :
                  <span class="font-weight-bold error--text">{{
                    selectedCourse.noOfClasses
                  }}</span>
                </p>
              </v-col>
              <v-col class="pt-0 pl-0">
                <p
                  v-if="editedClassRoom.courseId"
                  class="pa-0 ma-1 text-body-1"
                >
                  Grades :
                  <span
                    class="font-weight-bold error--text"
                    v-for="(grade, index) in selectedCourse.grades"
                    :key="index"
                    >{{ grade
                    }}{{
                      index != selectedCourse.grades.length - 1 ? ", " : ""
                    }}
                  </span>
                </p>
              </v-col>
            </v-row>
            <v-col
              class="pt-0"
              v-if="
                selectedTeacherId &&
                editedClassRoom.startDate &&
                selectedCourse &&
                editedClassRoom.model
              "
            >
              <TeacherAvailabilityCalender
                :startDate="editedClassRoom.startDate"
                :selectedTeacherId="selectedTeacherId"
                :noOfClasses="selectedCourse.noOfClasses"
                :teachers="teachers"
                :model="editedClassRoom.model"
                @update-selected-slots="handleSelectedSlots"
              />
            </v-col>
            <v-card-actions class="pa-0">
              <v-btn color="primary" outlined @click="saveClassRoom()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  BASE_URL,
  CLASSROOM_ASSIGN_STUDENTS,
  CLASSROOM_ASSIGN_TEACHER,
  CLASSROOM_UN_ASSIGN_STUDENTS,
  CREATE_CLASSROOM,
  GET_ALL_COURSES,
  SEARCH_STUDENT,
  SEARCH_TEACHER,
  GET_ALL_TEACHER_AVAILABILITY,
  GET_ALL_INSTITUTES,
} from "@/common/apiEndpoints";
import {
  SET_API_SUCCESS,
  SET_API_ERROR,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  getAxiosOptions,
  formatDateTime,
  rightClickHandler,
  formatDateNumeric,
} from "@/common/utility";
import { GET_TOKEN, GET_PARAMS_VALUE } from "@/store/getters";
import {
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
  grades,
  FRANCHISE_MODEL,
  NON_FRANCHISE_MODEL,
} from "@/common/constants";
import ContextMenu from "./ContextMenu.vue";
import CustomDataTable from "./CustomDataTable.vue";
import TeacherAvailabilityCalender from "./TeacherAvailabilityCalender.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      startDateMenu: false,
      enrollStdDialog: false,
      enrollTeacherDialog: false,
      searchStudent: "",
      classRooms: [],
      panel: [],
      deEnrollMode: false,
      selectedStudents: [],
      selectedInstitute: "",
      studentsList: [],
      studentFilter: {},
      institute: [],
      enrolledStudentsId: [],
      gradesArray: grades,
      teachers: [],
      selectedTeacher: [],
      studentNames: [],
      classLink: "",
      searchTerm: "",
      teacherAssignmentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "emailId" },
        { text: "Status", value: "status" },
      ],
      studentHeadersForEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "Institute Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      editedClassRoom: {
        courseId: "",
      },
      defaultItem: {},
      courses: [],
      notBuyableCourses: [],
      filteredCourses: [],
      selectedCourse: null,
      createClassRoomValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      selectedTeacherId: "",
      schedule: [],
      filteredTeachers: [],
      selectedSlots: [],
      teacherAvailability: [],
      teacherSchedule: [],
      events: [],
      eventId: 0,
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
      classroomModel: [FRANCHISE_MODEL, NON_FRANCHISE_MODEL],
    };
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  watch: {
    "editedClassRoom.courseId": function (newCourseId) {
      this.selectedCourse =
        this.notBuyableCourses.find(
          (course) => course.courseId === newCourseId
        ) || null;
    },
  },
  mounted: function () {
    this.instituteId = this.paramsValue.instituteId;
    this.getAllCourses();
    this.getTeacherAvailability();
  },
  components: { ContextMenu, CustomDataTable, TeacherAvailabilityCalender },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getTeacherAvailability() {
      let url = GET_ALL_TEACHER_AVAILABILITY;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teachers = res.data.teachers;
          this.filteredTeachers = this.teachers;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    searchTeacher() {
      if (!this.searchTerm) {
        this.filteredTeachers = this.teachers;
      } else {
        this.filteredTeachers = this.teachers.filter((teacher) => {
          return (
            teacher.firstName
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1
          );
        });
      }
      this.searchTerm = "";
    },
    searchCourse() {
      if (!this.searchTerm) {
        this.filteredCourses = this.notBuyableCourses;
      } else {
        this.filteredCourses = this.notBuyableCourses.filter((course) => {
          return (
            course.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
            -1
          );
        });
      }
      this.searchTerm = "";
    },
    getAllCourses() {
      let url = GET_ALL_COURSES;
      this.setOverlayValue(true);
      url = url + "?status=" + this.STATUS_PUBLISHED;
      url = url + "&courseType=" + this.COURSE_TYPE_PRIVATE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          this.notBuyableCourses = this.courses.filter(
            (course) =>
              course.courseType == "PRIVATE" &&
              (course.buyable == false || course.buyable == null)
          );
          this.filteredCourses = this.notBuyableCourses;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    formatDateNumeric(date) {
      return formatDateNumeric(date);
    },
    saveClassRoom() {
      this.$refs.form.validate();
      if (this.createClassRoomValidation.valid) {
        const payload = {};
        let url = "";
        payload.name = this.editedClassRoom.name.trim();
        payload.courseId = this.editedClassRoom.courseId;
        payload.instituteId = this.instituteId;
        payload.teacherId = this.selectedTeacherId;
        payload.students = this.editedClassRoom.students;
        payload.model = this.editedClassRoom.model;
        this.extendSchedule();
        payload.schedule = this.schedule.map((slot) => {
          return {
            startDateTime: slot.startDateTime,
            endDateTime: slot.endDateTime,
          };
        });
        payload.startDate = new Date(this.editedClassRoom.startDate);
        url = CREATE_CLASSROOM;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createClassRoomValidation.valid = false;
            this.$router.go(-1);
            this.setOverlayValue(false);
            this.setApiSuccess("Classroom created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    handleSelectedSlots(newSelectedSlots) {
      this.selectedSlots = newSelectedSlots;
    },
    extendSchedule() {
      const extendedSchedule = [...this.selectedSlots];
      const totalClassesRequired = this.selectedCourse.noOfClasses;
      const selectedSlotsCount = this.selectedSlots.length;

      if (selectedSlotsCount >= totalClassesRequired) {
        this.schedule = extendedSchedule;
        return;
      }

      const slotsNeeded = totalClassesRequired - selectedSlotsCount;

      // Loop through each selected slot to extend it for the required number of slots
      for (
        let addedSlotsCount = 0;
        addedSlotsCount < slotsNeeded;
        addedSlotsCount++
      ) {
        this.selectedSlots.forEach((slot) => {
          if (extendedSchedule.length >= totalClassesRequired) return;

          const startDateTime = new Date(slot.startDateTime);
          const nextSlotDate = new Date(startDateTime);
          nextSlotDate.setDate(
            nextSlotDate.getDate() + 7 * (addedSlotsCount + 1)
          ); // Extend by weeks

          const interval = {
            date: this.formatDateNumeric(nextSlotDate),
            hour: nextSlotDate.getHours(),
            minute: nextSlotDate.getMinutes(),
          };

          // Check if the slot is available
          if (this.isSlotAvailable(interval)) {
            const newSlot = {
              startDateTime: nextSlotDate.toISOString(),
              endDateTime: new Date(
                nextSlotDate.getTime() + 60 * 60 * 1000
              ).toISOString(),
            };
            extendedSchedule.push(newSlot);
          }
        });
      }

      this.schedule = extendedSchedule;
    },
    ShowAvailability(teacherId) {
      this.selectedTeacher = this.teachers.find(
        (teacher) => teacher.teacherId == this.selectedTeacherId
      );
      if (this.selectedTeacher) {
        this.teacherAvailability = this.selectedTeacher.availability;
        this.teacherSchedule = this.selectedTeacher.schedule;
        const allDay = false;
        this.selectedSlots = [];
        this.events = [];
        this.eventId = 0;

        this.teacherAvailability.forEach((dayAvailability) => {
          dayAvailability.slots.forEach((slot) => {
            const startDateTime = new Date(slot.startDateTime);
            const endDateTime = new Date(slot.endDateTime);

            if (!slot.isAvailable) {
              this.events.push({
                start: startDateTime,
                end: endDateTime,
                color: "error",
                timed: true,
              });
            }
          });
        });
      }
    },
    isSlotAvailable(interval) {
      let isAvailableSlot = false;

      for (let i = 0; i < this.teacherAvailability.length; i++) {
        const availability = this.teacherAvailability[i];

        for (let k = 0; k < availability.slots.length; k++) {
          const slot = availability.slots[k];
          const startDateTime = new Date(slot.startDateTime);
          const endDateTime = new Date(slot.endDateTime);

          // Check if the interval is within the available slot time range
          const intervalStartTime = new Date(
            interval.date +
              "T" +
              String(interval.hour).padStart(2, "0") +
              ":" +
              String(interval.minute).padStart(2, "0") +
              ":00"
          );
          const intervalEndTime = new Date(
            intervalStartTime.getTime() + 60 * 60 * 1000
          );

          if (
            intervalStartTime >= startDateTime &&
            intervalEndTime <= endDateTime
          ) {
            // if (this.editedClassRoom.model === FRANCHISE_MODEL) {
            //   return true;
            // }

            if (slot.isAvailable) {
              isAvailableSlot = true;

              for (let e = 0; e < this.events.length; e++) {
                const eventStart = new Date(this.events[e].start);
                const eventEnd = new Date(this.events[e].end);

                if (
                  intervalStartTime < eventEnd &&
                  intervalEndTime > eventStart
                ) {
                  isAvailableSlot = false;
                  break;
                }
              }
              if (isAvailableSlot) return true;
            }
          }
        }
      }
      return isAvailableSlot;
    },
    closeEnrollDialog() {
      this.deEnrollMode = false;
      this.selectedStudents = [];
      this.studentsList = [];
      this.studentFilter = {};
      this.selectedInstitute = "";
      this.selectedTeacher = [];
      this.enrollStdDialog = false;
      this.enrollTeacherDialog = false;
      this.teachers = [];
    },
    openEnrollTeacherDialog() {
      this.enrollTeacherDialog = true;
      this.panel = [0];
    },
    openEnrollStudentDialog() {
      this.enrollStdDialog = true;
      this.studentFilter.studentIds = [];
      this.getAllInstitutes();
      this.panel = [0];
    },
    getAllInstitutes() {
      let url = GET_ALL_INSTITUTES;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institute = res.data.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    selectTeacher() {
      this.editedClassRoom.teacherId = this.selectedTeacher[0].teacherId;
      this.editedClassRoom.teacherName =
        this.selectedTeacher[0].firstName +
        " " +
        this.selectedTeacher[0].lastName;
      this.enrollTeacherDialog = false;
      this.selectedTeacher = [];
    },
    selectStudents() {
      this.editedClassRoom.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      this.studentNames = [];
      this.selectedStudents.forEach((element) => {
        this.studentNames.push(element.firstName + " " + element.lastName);
      });
      this.enrollStdDialog = false;
    },
    // openEnrollDialog(item, assigneeType) {
    //   if (assigneeType === "Student") {
    //     this.enrollStdDialog = true;
    //     this.studentFilter.studentIds = item.students;
    //     this.getAllInstitutes();
    //   } else if (assigneeType === "Teacher") {
    //     this.enrollTeacherDialog = true;
    //   }
    //   this.editedClassRoom.classRoomId = item.classRoomId;
    //   this.editedClassRoom.teacherId = item.teacherId;
    //   this.editedClassRoom.courseId = item.courseId;
    //   this.panel = [0];
    // },
    enrollDeEnrollStudents(action) {
      let url = "";
      if (action == "Assign") url = CLASSROOM_ASSIGN_STUDENTS;
      else if (action == "UnAssign") {
        url = CLASSROOM_UN_ASSIGN_STUDENTS;
      }
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          if (action == "Assign")
            this.setApiSuccess("Students Assigned Succesfully");
          else if (action == "UnAssign") {
            this.setApiSuccess("Students Un-Assigned Succesfully");
          }
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    searchStudents() {
      const payload = {};
      let flag = false;
      if (
        this.deEnrollMode &&
        this.studentFilter.studentIds &&
        this.studentFilter.studentIds.length > 0
      ) {
        payload.studentIds = this.studentFilter.studentIds;
        this.panel = [];
        flag = true;
      }
      if (this.studentFilter.mobileNo) {
        payload.mobileNo = this.studentFilter.mobileNo.trim();
        flag = true;
      }
      if (this.studentFilter.email) {
        payload.email = this.studentFilter.email.trim();
        flag = true;
      }
      if (this.studentFilter.firstName) {
        payload.firstName = this.studentFilter.firstName.trim();
        flag = true;
      }
      if (this.studentFilter.lastName) {
        payload.lastName = this.studentFilter.lastName.trim();
        flag = true;
      }
      if (this.selectedInstitute) {
        payload.instituteId = this.selectedInstitute;
        flag = true;
      }
      if (this.studentFilter.grade) {
        payload.grade = this.studentFilter.grade;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
            const self = this;
            self.disabledCount = 0;
            if (!this.deEnrollMode) {
              this.studentsList.map((item) => {
                if (item.status != "ACTIVE") {
                  self.disabledCount += 1;
                }
                if (
                  this.studentFilter.studentIds.includes(item.studentId) ||
                  item.enrolledCourses.includes(this.editedClassRoom.courseId)
                ) {
                  item.isEnrolled = true;
                  self.disabledCount += 1;
                } else {
                  item.isEnrolled = false;
                }
              });
            }
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
      if (!this.deEnrollMode) {
        this.panel = [0];
      }
    },
    openCloseSearchPanel() {
      this.panel.length == 0 ? (this.panel = [0]) : (this.panel = []);
    },
    selectAllStudents(props) {
      if (
        this.selectedStudents.length !=
        this.studentsList.length - this.disabledCount
      ) {
        this.selectedStudents = [];
        const self = this;
        props.items.forEach((item) => {
          //  if(item.status=='ACTIVE') {
          if (item.status == "ACTIVE" && !item.isEnrolled) {
            self.selectedStudents.push(item);
          }
        });
      } else this.selectedStudents = [];
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
      if (c.length && c[0].name) return c[0].name;
      return "No Course";
    },
    enrollTeacher() {
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      let url = CLASSROOM_ASSIGN_TEACHER;
      payload.teacherId = this.selectedTeacher[0].teacherId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          this.closeEnrollDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Teacher Assigned Successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    changeEnrollMode() {
      this.selectedStudents = [];
      this.studentsList = [];
      this.searchStudents();
    },
    updateClassRoomData(classRoom) {
      var foundIndex = this.classRooms.findIndex(
        (cls) => cls.classRoomId == classRoom.classRoomId
      );
      this.classRooms[foundIndex] = Object.assign(
        this.classRooms[foundIndex],
        classRoom
      );
    },
  },
};
</script>
<style>
.v-event-timed.error {
  z-index: 0 !important;
}
.v-event-timed.success {
  z-index: 2 !important;
}
</style>
