<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title> My Competitions </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchQuery"
        append-icon="mdi-magnify"
        label="Search"
        style="max-width: 15rem"
        left
        hide-details
        class="ml-3 mt-2"
      ></v-text-field>
      <v-select
        :items="options"
        v-model="selectedOption"
        class="ml-4 mt-7"
        style="max-width: 11rem; cursor: pointer"
      ></v-select>
      <!-- <v-tabs  right class="ml-0">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item" @click="tab = item">
          {{ item }}
        </v-tab>
      </v-tabs> -->
    </v-toolbar>

    <v-container
      fluid
      id="all-competitions"
      v-if="!loadingLive && selectedOption == 'All'"
    >
      <v-row class="ma-1" v-if="filteredAllCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredAllCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pa-4">
            <v-img width="100%" height="100" :src="comp.thumbnail"></v-img>
            <v-chip label :class="`fixedbtn ${getChipColor(comp.state)}`"
              >{{ comp.state }}
            </v-chip>
            <v-row class="ma-1">
              <v-col cols="12" class="pa-0">
                <v-card-title class="pa-0">
                  <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                  <v-tooltip bottom v-if="comp.name.length > 20">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        comp.name.substring(0, 20) + "..."
                      }}</span>
                    </template>
                    <span>{{ comp.name }}</span>
                  </v-tooltip>
                </v-card-title>
              </v-col>
              <v-col cols="12" class="pa-0 mt-1">
                <span class="grey--text"
                  >Prize Pool
                  <span class="primary--text font-weight-bold"
                    >{{
                      comp.coinsPerQuestion *
                      comp.quizes[0].quizQuestions.length
                    }}
                  </span>
                  <img
                    style="height: 1.2rem"
                    class="ml-1 mb-n1"
                    src="../assets/Coin.svg"
                  />
                </span>
              </v-col>
              <span class="mt-1" style="font-size: 0.8rem; color: grey">
                <v-col v-if="comp.state === COMP_STATE_LIVE" class="pa-0">
                  <span>Ends in: </span>
                  <span class="font-weight-bold">
                    {{ comp.timeEndDifference }}
                  </span>
                </v-col>
                <v-col v-if="comp.state === COMP_STATE_UPCOMING" class="pa-0">
                  <span>Starts in: </span>
                  <span class="font-weight-bold">
                    {{ comp.timeStartDifference }}
                  </span>
                </v-col>
                <v-col v-if="comp.state === 'FINISHED'" class="pa-0">
                  <span> Ended on: </span>
                  <span class="font-weight-bold">
                    {{ formatDateTime(comp.endDate) }}
                  </span>
                </v-col>
              </span>
            </v-row>
            <v-card-actions class="pa-0">
              <v-row
                class="ma-1"
                v-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == false
                "
              >
                <v-btn
                  width="100%"
                  color="primary"
                  @click="openTestDialog(comp)"
                >
                  Attempt
                </v-btn>
              </v-row>
              <v-row
                class="ma-1"
                v-else-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == true
                "
              >
                <v-chip
                  label
                  class="white--text d-flex justify-center"
                  color="grey"
                  style="width: 100%"
                >
                  ATTEMPTED
                </v-chip>
              </v-row>
              <v-row
                class="ma-1"
                v-else-if="
                  comp.state != COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == true
                "
              >
                <v-btn width="100%" color="primary" @click="analyseComp(comp)">
                  <v-icon class="mr-1">mdi-poll</v-icon>
                  View Analytics
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="loading == false">
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span style="font-size: 1.8rem; font-weight: 500">
                No Competitions found
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      id="live-competitions"
      v-if="!loadingLive && selectedOption == 'Live'"
    >
      <v-row class="ma-1" v-if="filteredLiveCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredLiveCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pa-4">
            <v-img width="100%" height="100" :src="comp.thumbnail"></v-img>
            <v-chip label :class="`fixedbtn ${getChipColor(comp.state)}`"
              >{{ comp.state }}
            </v-chip>
            <v-row class="ma-1">
              <v-col cols="12" class="pa-0">
                <v-card-title class="pa-0">
                  <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                  <v-tooltip bottom v-if="comp.name.length > 20">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        comp.name.substring(0, 20) + "..."
                      }}</span>
                    </template>
                    <span>{{ comp.name }}</span>
                  </v-tooltip>
                </v-card-title>
              </v-col>
              <v-col cols="12" class="pa-0 mt-1">
                <span class="grey--text"
                  >Prize Pool
                  <span class="primary--text font-weight-bold"
                    >{{
                      comp.coinsPerQuestion *
                      comp.quizes[0].quizQuestions.length
                    }}
                  </span>
                  <img
                    style="height: 1.2rem"
                    class="ml-1 mb-n1"
                    src="../assets/Coin.svg"
                  />
                </span>
              </v-col>
              <span class="mt-1" style="font-size: 0.8rem; color: grey">
                <v-col class="pa-0">
                  <span>Ends in: </span>
                  <span class="font-weight-bold">
                    {{ comp.timeEndDifference }}
                  </span>
                </v-col>
              </span>
            </v-row>
            <v-card-actions class="pa-0">
              <v-row
                class="ma-1"
                v-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == false
                "
              >
                <v-btn
                  width="100%"
                  color="primary"
                  @click="openTestDialog(comp)"
                >
                  Attempt
                </v-btn>
              </v-row>
              <v-row
                class="ma-1 d-flex justify-center"
                v-else-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == true
                "
              >
                <v-chip
                  label
                  class="white--text d-flex justify-center"
                  color="grey"
                  style="width: 100%"
                >
                  ATTEMPTED
                </v-chip>
                <!-- <v-col class="ml-3 pa-1 mt-1">
                  <div>
                    <span style="font-size: 0.9rem; font-weight: 500; color: grey">{{
                      comp.timeResultDifference }}
                      <span style="font-size: 0.7rem; color: grey">for results</span></span>
                  </div>
                </v-col> -->
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="loading == false">
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span class="text-h2 font-weight-medium">
                No <span style="color: red">Live</span> Competitions found
              </span>
              <br />
              <span class="grey--text toxt-body-1">
                Check for
                <span style="color: green">Upcoming</span> Competitions
                <span
                  style="color: blue; cursor: pointer"
                  @click="selectedOption = 'Upcoming'"
                >
                  <u>here</u></span
                >
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      id="upcoming-competitions"
      v-if="selectedOption == 'Upcoming'"
    >
      <v-row class="ma-1" v-if="filteredUpcomingCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredUpcomingCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pa-4">
            <v-img width="100%" height="100" :src="comp.thumbnail"></v-img>
            <v-chip label :class="`fixedbtn ${getChipColor(comp.state)}`"
              >{{ comp.state }}
            </v-chip>
            <v-row class="ma-1">
              <v-col cols="12" class="pa-0">
                <v-card-title class="pa-0">
                  <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                  <v-tooltip bottom v-if="comp.name.length > 20">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        comp.name.substring(0, 20) + "..."
                      }}</span>
                    </template>
                    <span>{{ comp.name }}</span>
                  </v-tooltip>
                </v-card-title>
              </v-col>
              <v-col cols="12" class="pa-0 mt-1">
                <span class="grey--text"
                  >Prize Pool
                  <span class="primary--text font-weight-bold"
                    >{{
                      comp.coinsPerQuestion *
                      comp.quizes[0].quizQuestions.length
                    }}
                  </span>
                  <img
                    style="height: 1.1rem"
                    class="ml-1"
                    src="../assets/Coin.svg"
                  />
                </span>
              </v-col>

              <span class="mt-1" style="font-size: 0.8rem; color: grey">
                <v-col class="pa-0">
                  <span>Starts in: </span>
                  <span class="font-weight-bold">
                    {{ comp.timeStartDifference }}
                  </span>
                </v-col>
              </span>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="loading == false">
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span class="text-h2 font-weight-medium">
                No <span style="color: green">Upcoming</span> Competitions found
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      id="finished-competitions"
      v-if="selectedOption == 'Finished'"
    >
      <v-row class="ma-1" v-if="filteredFinishedCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredFinishedCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pa-4">
            <v-img width="100%" height="100" :src="comp.thumbnail"></v-img>
            <v-chip label :class="`fixedbtn ${getChipColor(comp.state)}`"
              >{{ comp.state }}
            </v-chip>
            <v-row class="ma-1">
              <v-col cols="12" class="pa-0">
                <v-card-title class="pa-0">
                  <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                  <v-tooltip bottom v-if="comp.name.length > 20">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        comp.name.substring(0, 20) + "..."
                      }}</span>
                    </template>
                    <span>{{ comp.name }}</span>
                  </v-tooltip>
                </v-card-title>
              </v-col>
              <v-col cols="12" class="pa-0 mt-1">
                <span class="grey--text"
                  >Prize Pool
                  <span class="primary--text font-weight-bold"
                    >{{
                      comp.coinsPerQuestion *
                      comp.quizes[0].quizQuestions.length
                    }}
                  </span>
                  <img
                    style="height: 1.1rem"
                    class="ml-1"
                    src="../assets/Coin.svg"
                  />
                </span>
              </v-col>
              <span class="mt-1" style="font-size: 0.8rem; color: grey">
                <v-col class="pa-0">
                  <span> Ended on: </span>
                  <span class="font-weight-bold">
                    {{ formatDateTime(comp.endDate) }}
                  </span>
                </v-col>
              </span>
            </v-row>
            <v-card-actions class="pa-0">
              <v-btn
                v-if="comp.isAlreadyAttempted"
                width="100%"
                color="primary"
                @click="analyseComp(comp)"
              >
                <v-icon class="mr-1">mdi-poll</v-icon>
                View Analytics
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center text-h2 font-weight-medium">
          No finished history found.
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="competitionFinishedDialog" width="35rem">
      <v-card width="35rem">
        <v-row class="ma-0 pt-4 pb-4">
          <v-col cols="2" class="ma-0 mt-0 pa-0"
            ><v-avatar color="#EEFFEF" size="3.6rem" class="ml-4">
              <v-icon color="#11D800">mdi-check</v-icon>
            </v-avatar></v-col
          >
          <v-col cols="10" class="mt-1 pa-0">
            <v-col class="pa-0"
              ><span style="color: #5edc11; font-weight: 500; font-size: 1.3rem"
                >Submit Successfully</span
              ></v-col
            ><v-col class="pa-0 mt-2"
              >Congratulations on finishing the test. Your answers have been
              submit successfully. Thanks!</v-col
            >
            <v-col cols="10" class="pa-0 mt-4"
              ><v-btn
                width="100%"
                dark
                color="#5EDC11"
                @click="closeCompetiitonFinishedDialog()"
                >OK</v-btn
              ></v-col
            >
          </v-col>
          <!-- <v-col cols="1" class="pa-0 ma-0"><v-icon @click="closeCompetiitonFinishedDialog()">mdi-close</v-icon></v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openTest" max-width="44rem">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Test Instructions</v-card-title
        >
        <v-card-text>
          <v-subheader>General Instructions</v-subheader>
          <v-list>
            <v-list-item>
              1. The test duration is
              <span
                class="mr-1 ml-1"
                style="font-weight: 700"
                v-if="testComp.duration != null"
                >{{ testComp.duration }}</span
              ><span class="mr-1 ml-1" style="font-weight: 700" v-else>5</span
              >minutes. The test will automatically submit when the time ends.
            </v-list-item>
            <v-list-item>
              2. Do not refresh the page or use the back button. Doing so will
              result in automatic submission of the test.
            </v-list-item>
            <v-list-item>
              3. Ensure you have a stable internet connection throughout the
              test.
            </v-list-item>
            <v-list-item>
              3. Navigation between the questions is possible throughout the
              test.
            </v-list-item>
          </v-list>
          <v-subheader>Rules and Regulations</v-subheader>
          <v-list>
            <v-list-item>
              1. Any form of cheating or violation of rules can result in a
              permanent ban from future tests.
            </v-list-item>
            <v-list-item>
              2. Do not open other tabs or applications during the test.
            </v-list-item>
            <v-list-item>
              3. Your webcam and microphone may be monitored during the test.
            </v-list-item>
          </v-list>

          <v-subheader>Technical Instructions</v-subheader>
          <v-list>
            <v-list-item>
              1. Make sure your device is fully charged or plugged in.
            </v-list-item>
            <v-list-item>
              2. Use an updated browser for the best experience.
            </v-list-item>
            <v-list-item>
              3. If you face any technical issues, contact support immediately.
            </v-list-item>
          </v-list>

          <div class="text-center mt-4">
            <v-alert type="info" dense class="mb-4" border="left">
              Note: The test will automatically submit if the time ends, or if
              you refresh or navigate away from this page.
            </v-alert>
            <v-alert type="error" border="left">
              Warning: Any cheating violation will result in a permanent ban
              from attempting future tests.
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="attemptCompetiton(testComp)"
            >Proceed</v-btn
          >
          <v-btn color="error" text @click="openTest = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import confetti from "canvas-confetti";
import {
  GET_ALL_COMPETITIONS,
  QUIZ_COMPETITION_START,
  BASE_URL,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { COMP_STATE_LIVE, COMP_STATE_UPCOMING } from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
export default {
  data() {
    return {
      loading: true,
      selectedTab: "all-competitions",
      tabs: [
        { id: "all-competitions", label: "Live" },
        { id: "live-competitions", label: "Live" },
        { id: "upcoming-competitions", label: "Upcoming" },
        { id: "finished-competitions", label: "inished" },
      ],
      studentCompetitions: {},
      loadingLive: true,
      compLeaderboard: {},
      options: ["All", "Live", "Upcoming", "Finished"],
      selectedOption: "All",
      compAnalysis: {},
      coinValue: 70,
      studentPreviousCompetitions: {},
      COMP_STATE_LIVE: COMP_STATE_LIVE,
      COMP_STATE_UPCOMING: COMP_STATE_UPCOMING,
      competitionFinishedDialog: false,
      quizComp: { quizes: [] },
      answered: 0,
      openTest: false,
      testComp: {},
      notAnswered: 0,
      quizContent: { quizQuestions: [] },
      quizID: "",
      quizScore: 0,
      tab: "LIVE",
      items: ["LIVE", "UPCOMING", "Finished"],
      earnedCoins: 2,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    // progress() {
    //   return (this.earnedCoins / this.compAnalysis.totalCoins) * 100;
    // },
    filteredAllCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      // const currentTime = new Date();
      return competitionsArray.filter((comp) => {
        const nameMatchesQuery = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        return nameMatchesQuery;
        // if (comp.state === "LIVE") {
        //   return nameMatchesQuery && currentTime < new Date(comp.endDate);
        // } else if (comp.state === "UPCOMING") {
        //   return nameMatchesQuery && currentTime >= new Date(comp.startDate);
        // }
        // return false;
      });
    },
    filteredLiveCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();
      return competitionsArray.filter((comp) => {
        const nameMatchesQuery = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        if (comp.state === "LIVE") {
          return nameMatchesQuery && currentTime < new Date(comp.endDate);
        } else if (comp.state === "UPCOMING") {
          return nameMatchesQuery && currentTime >= new Date(comp.startDate);
        }
        return false;
      });
    },
    filteredFinishedCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();

      return competitionsArray.filter((comp) => {
        const isFinished =
          comp.state === "FINISHED" ||
          (comp.state === "LIVE" && currentTime >= new Date(comp.endDate));
        const matchesSearch = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        return isFinished && matchesSearch;
      });
    },

    filteredUpcomingCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();
      return competitionsArray.filter((comp) => {
        const isUpcoming =
          comp.state === "UPCOMING" && currentTime < new Date(comp.startDate);
        const matchesSearch = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        return isUpcoming && matchesSearch;
      });
    },
  },
  mounted: function () {
    this.fetchCompetition();
    if (this.$route.params.compFinished) {
      this.openCompetiitonFinishedDialog();
    }
  },
  methods: {
    updateCompetitions() {
      const currentTime = new Date();
      this.studentCompetitions.forEach((comp) => {
        if (
          comp.state === "UPCOMING" &&
          currentTime >= new Date(comp.startDate)
        ) {
          comp.state = "LIVE";
        } else if (
          comp.state === "LIVE" &&
          currentTime >= new Date(comp.endDate)
        ) {
          comp.state = "FINISHED";
        }
      });
    },

    updateTimeDifferences() {
      this.studentCompetitions = this.studentCompetitions.map((comp) => ({
        ...comp,
        timeStartDifference: this.getTimeStartDifference(comp.startDate),
        timeEndDifference: this.getTimeEndDifference(comp.endDate),
        timeResultDifference: this.getTimeResultDifference(comp.endDate),
      }));
    },
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    openTestDialog(comp) {
      this.testComp = comp;
      this.openTest = true;
    },
    analyseComp(comp) {
      var compParams = {};
      compParams.competition = comp;
      this.setParams(compParams);
      this.$router.push({
        name: "CompetitionAnalytics",
        params: { durationTime: comp.duration },
      });

      // this.compAnalysis = comp;
      // await this.getCompProgress2(comp.quizCompId);
      // await this.getCompLeaderboard(comp.quizCompId);

      // for (let i = 0; i < comp.quizes.length; i++) {
      //   this.quizId = comp.quizes[i].quizId;
      //   this.quizContent = comp.quizes[i];
      // }

      // console.log(this.quizContent.quizQuestions);

      // this.answered = 0;
      // this.notAnswered = 0;
      // this.quizScore = 0;

      // for (let i = 0; i < this.quizContent.quizQuestions.length; i++) {
      //   const questionNo = this.quizContent.quizQuestions[i].questionNo;
      //   console.log("exe");
      //   if (this.quizComp && this.quizComp.quizes.length > 0) {
      //     for (let j = 0; j < this.quizComp.quizes.length; j++) {
      //       let question = this.quizComp.quizes[j].questions.find(
      //         (question) => question.questionNo === questionNo
      //       );
      //       if (question) {
      //         this.answered += 1;
      //         if (question.isCorrect) {
      //           this.quizScore = this.quizScore + 1;
      //         }
      //       } else {
      //         this.notAnswered += 1;
      //       }
      //     }
      //   }
      // }

      // this.analyseDialog = true;
    },
    openCompetiitonFinishedDialog() {
      this.competitionFinishedDialog = true;
      this.triggerConfetti();
    },
    triggerConfetti() {
      confetti({
        particleCount: 2500,
        spread: 500,
        origin: { y: 0.35 },
      });
    },
    closeCompetiitonFinishedDialog() {
      this.competitionFinishedDialog = false;
    },
    getTimeEndDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days ${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins ${seconds} secs`;
      }
      return `${seconds} secs`;
    },
    getTimeResultDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins`;
      }
      return `${seconds} secs`;
    },
    getTimeStartDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days ${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins ${seconds} secs`;
      }
      return `${seconds} secs`;
    },
    fetchCompetition() {
      this.setOverlayValue(true);
      this.loading = true;
      let url = GET_ALL_COMPETITIONS;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCompetitions = res.data.competitions;
          // this.studentCompetitions.forEach((comp) => {
          //   comp.isAlreadyAttempted = false;
          //   // this.getCompProgress(comp.quizCompId);
          // });
          this.updateTimeDifferences();

          setInterval(() => {
            this.updateTimeDifferences();
          }, 1000);

          this.updateCompetitions();

          setInterval(() => {
            this.updateCompetitions();
          }, 1000);

          this.setOverlayValue(false);
          this.loadingLive = false;
          this.loading = false;
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.loadingLive = false;
          this.loading = false;
        });
      this.loadingLive = false;
    },
    getChipColor(state) {
      if (state == COMP_STATE_LIVE) {
        return "error-chip";
      } else if (state == COMP_STATE_UPCOMING) {
        return "success-chip";
      } else {
        return "secondary-chip";
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    attemptCompetiton(comp) {
      const url = QUIZ_COMPETITION_START;
      const payload = {};
      payload.competitionId = comp.quizCompId;
      payload.quizId = comp.quizes[0].quizId;
      var compParams = {};
      compParams.competition = comp;
      compParams.isFinished = false;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.openTest = false;
          this.setParams(compParams);
          this.$router.push({
            name: "QuizCompetition",
            params: { durationTime: comp.duration },
          });
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.openTest = false;
          this.setApiError("Already Attempted , Please refresh");
        });
    },
  },
};
</script>
<style>
.fixedbtn {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  z-index: 1;
}
.fixed-tab {
  position: fixed;
  top: 5.5rem;
  right: 3rem;
  z-index: 1000;
  background-color: transparent !important;
}

.tabs {
  display: flex;
}

.v-tab {
  min-width: auto;
  padding: 0 0.3rem;
  margin: 0 0.2rem;
  border-radius: 2rem;
  background-color: transparent !important;
  color: white;
}
</style>
