<template>
  <div v-bind="$props">
    <v-col class="pa-5 srollContainer">
      <v-row
        v-if="
          assignmentProblem.submissions &&
          assignmentProblem.submissions.length != 0
        "
        class="ma-2 justify-end"
      >
        <v-chip
          v-if="assignmentProblem.submissions[0].status == 'EVALUATED'"
          class="mr-3 font-weight-bold text-body-1"
          color="primary-chip"
          small
        >
          <v-avatar left>
            <v-img
              :src="require('@/assets/Coin.svg')"
              style="width: 1rem; height: 1rem"
              contain
            ></v-img>
          </v-avatar>
          {{ assignmentProblem.submissions[0].earnedCoins }}/{{
            assignmentProblem.coins
          }}
        </v-chip>
        <v-chip small class="secondary-chip">
          {{ assignmentProblem.submissions[0].status }}
        </v-chip>
      </v-row>
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span class="font-weight-medium text-h6 error--text">Question: </span>
        </v-col>
        <v-col class="pt-1 ma-1 pa-0">
          <span class="text-body-1" v-html="assignmentProblem.question"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span class="font-weight-medium text-h6 error--text">Answer: </span>
        </v-col>
        <v-col
          class="mr-2"
          v-if="
            assignmentProblem.submissions &&
            assignmentProblem.submissions.length == 0
          "
        >
          <span>
            <quill-editor
              style="height: 11rem; margin-bottom: 3rem"
              @input="validateTextAssignemnt()"
              :options="editorOptions"
              v-model="textAssignmentSubmission"
            ></quill-editor>
          </span>
          <span class="error--text">{{ textAssignmentError }}</span>
        </v-col>
        <v-col class="ma-1 pb-0 pa-0" v-else>
          <span class="text-body-1" v-html="assignmentProblem.answer"></span>
        </v-col>
      </v-row>
      <v-row
        v-if="
          assignmentProblem.submissions &&
          assignmentProblem.submissions.length != 0
        "
      >
        <v-col cols="2" class="ma-1 pa-0">
          <span class="font-weight-medium text-h6 error--text"
            >Submission:
          </span>
        </v-col>
        <v-col class="ma-1 mb-0 pa-0">
          <span
            class="text-body-1"
            v-html="assignmentProblem.submissions[0].submission"
          ></span>
        </v-col>
      </v-row>
      <v-row class="mt-8 ma-1">
        <v-btn
          v-if="
            assignmentProblem.submissions && assignmentProblem.submissions == 0
          "
          color="error"
          @click="submitTextAssignment()"
          >Submit</v-btn
        >
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { BASE_URL, SUBMIT_TEXT_ASSIGNMENT } from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COIN_BALANCE,
} from "@/store/mutations";

export default {
  data() {
    return {
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],

            ["link"],
          ],
        },
      },
      assignmentProblem: {},
      textAssignmentSubmission: "",
      textAssignmentError: "",
    };
  },
  props: ["classOpId", "assignment"],
  watch: {
    assignment: {
      handler(assignment) {
        if (assignment) {
          this.assignmentProblem = assignment;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.assignmentProblem = this.assignment;
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
    validateTextAssignemnt() {
      if (this.textAssignmentSubmission.length == 0) {
        this.textAssignmentError = "This is required";
      } else {
        this.textAssignmentError = "";
      }
    },
    submitTextAssignment() {
      if (this.textAssignmentSubmission.length > 0) {
        this.textAssignmentError = "";
        const url = SUBMIT_TEXT_ASSIGNMENT;
        const payload = {};
        payload.assignmentNumber = this.assignmentProblem.assignmentNumber;
        payload.classOpId = this.classOpId;
        payload.submission = this.textAssignmentSubmission;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.$emit("assignmentCompleted", this.classOpId, "textAssignment");
            this.setApiSuccess("Assignment Submitted Successfully");
            this.textAssignmentSubmission = "";
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      } else {
        this.textAssignmentError = "This is required";
      }
    },
  },
};
</script>
<style scoped>
.srollContainer {
  height: 100%;
  overflow-y: auto;
}
</style>
