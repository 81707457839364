<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)"
          >mdi-arrow-left</v-icon
        >
        Competition Analytics
      </v-toolbar-title>
    </v-toolbar>
    <v-card class="ma-2">
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <span class="text-h5 font-weight-medium">{{
              paramsValue.name
            }}</span>
          </v-col>
          <v-col align="end">
            <v-btn small color="secondary" @click="openCompLeaderboard()">
              Leaderboard
            </v-btn>
          </v-col>
          <v-col align="end" class="mr-4">
            <span>
              <v-chip class="ml-2" label v-if="paramsValue.grades.length < 2">
                Grade: {{ paramsValue.grades[0] }}
              </v-chip>
              <v-chip class="ml-2" label v-else>
                Grades: {{ paramsValue.grades.join(", ") }}
              </v-chip>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" class="d-flex flex-column justify-center">
            <v-card flat>
              <div v-if="comp2Analytics && Object.keys(comp2Analytics).length">
                <chart-component
                  :data="chartData"
                  :options="chartOptions"
                ></chart-component>
              </div>
              <div
                v-else
                class="d-flex flex-column align-center justify-center"
              >
                <v-img
                  max-width="1.5rem"
                  contain
                  src="../assets/No-Data.png"
                ></v-img>
                <span class="mt-1 font-weight-bold"
                  >Uh! No Data Available At The Moment</span
                >
              </div>
              <v-row class="ma-1 pt-5">
                <v-col cols="5" class="d-flex align-center flex-column">
                  <v-progress-circular
                    :rotate="-90"
                    :size="70"
                    :width="9"
                    :value="getPercenTageCompletion"
                    color="teal"
                    class="mb-2"
                  >
                    {{ getPercenTageCompletion + "%" }}
                  </v-progress-circular>
                  <div class="text-center">
                    <span class="text-body-3 grey--text"
                      >Completion percent</span
                    >
                  </div>
                </v-col>

                <v-col>
                  <div>
                    <v-row>
                      <v-col
                        ><span class="text-body-3 grey--text"
                          >Duration:</span
                        ></v-col
                      >
                      <v-col
                        ><span class="text-body-3 font-weight-medium">
                          {{ paramsValue.duration }} mins
                        </span></v-col
                      >
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col
                        ><span class="text-body-3 grey--text"
                          >Average Score:</span
                        ></v-col
                      >
                      <v-col
                        ><span class="text-body-3 font-weight-medium">
                          {{ formattedAverageScore }}
                        </span></v-col
                      >
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col
                        ><span class="text-body-3 grey--text"
                          >Average Time:</span
                        ></v-col
                      >
                      <v-col
                        ><span class="text-body-3 font-weight-medium">
                          {{ formattedAverageTime }}
                        </span></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="7">
            <v-card elevation="0">
              <v-simple-table class="ma-1 mytable">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Total Participants:
                      </th>
                      <td>
                        <span> {{ comp2Analytics.totalStudents ?? "-" }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Highest Score:
                      </th>
                      <td>
                        <span> {{ comp2Analytics.highestScore ?? "-" }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Lowest Score:
                      </th>
                      <td>
                        <span>{{ comp2Analytics.lowestScore ?? "-" }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Max Time:
                      </th>
                      <td>
                        <span>{{ formattedMaximumTime }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <v-icon class="ml-1 profileIcon" small>
                          mdi-circle
                        </v-icon>
                        Min Time:
                      </th>
                      <td>
                        <span>{{ formattedMinimumTime }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="leaderboard" max-width="35rem">
      <v-card elevation="0" class="mt-0">
        <v-simple-table class="mt-0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <v-icon color="warning">mdi-trophy</v-icon>
                </th>
                <th class="text-left">Leaderboard</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in compRanks" :key="item.name">
                <td>
                  <v-avatar size="30" class="mr-2" v-if="item.profile">
                    <img :src="item.profile" alt="Profile Picture" />
                  </v-avatar>
                  <v-avatar v-else size="30" class="mr-2">
                    <img
                      src="../assets/Male Avatar.png"
                      alt="Profile Picture"
                    />
                  </v-avatar>
                  {{ item.name }}
                </td>
                <td>{{ item.rank }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions class="mt-0">
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="leaderboard = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChartComponent from "./ChartComponent.vue";
import {
  BASE_URL,
  QUIZ_COMPETITION_COMP_LEADERBOARD,
  QUIZ_COMPETITION_ADMIN_ANALYTICS,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import axios from "axios";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      compAnalytics: {},
      comp2Analytics: {},
      compRanks: [],
      leaderboard: false,
    };
  },
  components: {
    ChartComponent,
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
    formattedAverageTime() {
      if (this.comp2Analytics.averageTime) {
        const totalSeconds = this.comp2Analytics.averageTime ?? 300;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },
    formattedAverageScore() {
      if (this.comp2Analytics.averageScore) {
        const score = Math.floor(this.comp2Analytics.averageScore);

        return score ?? "-";
      }
      return "-";
    },
    formattedMaximumTime() {
      if (this.comp2Analytics.maximumTime) {
        const totalSeconds = this.comp2Analytics.maximumTime;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },
    formattedMinimumTime() {
      if (this.comp2Analytics.minimumTime) {
        const totalSeconds = this.comp2Analytics.minimumTime ?? 300;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },

    chartData() {
      const timePercentages = new Array(11).fill(0);
      const correctPercentages = new Array(11).fill(0);
      const incorrectPercentages = new Array(11).fill(0);

      const totalDurationSeconds = Math.floor(this.paramsValue.duration * 60);

      this.compAnalytics.students.forEach((student) => {
        const totalQuestions = student.totalQuestions;

        const startTime = new Date(student.startDateTime).getTime();
        const endTime = new Date(student.endDateTime).getTime();
        const timeSpentSeconds = Math.floor((endTime - startTime) / 1000);

        const timePercentage = (timeSpentSeconds / totalDurationSeconds) * 100;
        const correctPercentage =
          totalQuestions === 0
            ? 0
            : (student.correctQuestions / totalQuestions) * 100;
        const incorrectPercentage =
          totalQuestions === 0
            ? 0
            : (student.incorrectQuestions / totalQuestions) * 100;

        const timeIndex = Math.min(Math.floor(timePercentage / 10), 10);
        const correctIndex = Math.min(Math.floor(correctPercentage / 10), 10);
        const incorrectIndex = Math.min(
          Math.floor(incorrectPercentage / 10),
          10
        );

        timePercentages[timeIndex]++;
        correctPercentages[correctIndex]++;
        incorrectPercentages[incorrectIndex]++;
      });

      return {
        labels: [
          "0%",
          "10%",
          "20%",
          "30%",
          "40%",
          "50%",
          "60%",
          "70%",
          "80%",
          "90%",
          "100%",
        ],
        datasets: [
          {
            label: "Time",
            backgroundColor: "#ffcd56",
            borderColor: "#ffcd56",
            fill: false,
            data: timePercentages,
          },
          {
            label: "Correct Questions",
            backgroundColor: "#4bc0c0",
            borderColor: "#4bc0c0",
            fill: false,
            data: correctPercentages,
          },
          {
            label: "Incorrect Questions",
            backgroundColor: "#ff6384",
            borderColor: "#ff6384",
            fill: false,
            data: incorrectPercentages,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              callback: function (value) {
                return value;
              },
            },
            title: {
              display: true,
              text: "Number of Students",
            },
          },
          x: {
            title: {
              display: true,
              text: "Percentage Intervals",
            },
          },
        },
      };
    },

    getPercenTageCompletion() {
      if (this.comp2Analytics.completion) {
        return Math.floor(
          (this.comp2Analytics.completion / this.comp2Analytics.totalStudents) *
            100
        );
      }
      return 0;
    },
  },
  mounted() {
    this.getCompAnalytics();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    openCompLeaderboard() {
      const url = QUIZ_COMPETITION_COMP_LEADERBOARD;
      const payload = { compId: this.paramsValue.quizCompId };

      return axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          const leaderboard = res.data.leaderboard;
          this.compRanks = [];

          leaderboard.forEach((item, index) => {
            if (index < 10) {
              this.compRanks.push({
                name: `${item.firstName} ${item.lastName}`,
                profile: item.profilePic,
                rank: item.rank,
                userId: item.userId,
              });
            }
            this.leaderboard = true;
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.errorMessage || error.message;
          this.setOverlayValue(false);
          throw new Error(errorMessage);
        });
    },

    getCompAnalytics() {
      const url = QUIZ_COMPETITION_ADMIN_ANALYTICS;
      const payload = { compId: this.paramsValue.quizCompId };

      if (this.paramsValue.status != "DRAFT") {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((response) => {
            const data = response.data;
            this.compAnalytics = data;

            if (data.students.length === 0) {
              this.compAnalytics = {
                totalStudents: 0,
                highestScore: "-",
                lowestScore: "-",
                maximumTime: "-",
                minimumTime: "-",
                averageScore: "-",
                averageTime: "-",
                completion: 0,
              };
              return;
            }

            const scores = data.students.map(
              (student) => student.correctQuestions
            );
            const times = data.students.map(
              (student) =>
                (new Date(student.endDateTime) -
                  new Date(student.startDateTime)) /
                1000
            );

            const getValidNumber = (value) => {
              if (Array.isArray(value) && value.length === 0) {
                return "-";
              } else if (isFinite(value) && !isNaN(value)) {
                return Math.floor(value);
              } else {
                return "-";
              }
            };

            const highestScore = getValidNumber(Math.max(...scores));
            const lowestScore = getValidNumber(Math.min(...scores));

            const maxTime = Math.max(...times);
            const minTime = Math.min(...times);

            const maximumTime = getValidNumber(maxTime);
            const minimumTime = getValidNumber(minTime);

            const avgScore =
              scores.reduce((acc, score) => acc + score, 0) /
              data.students.length;
            const avgTime =
              times.reduce((acc, time) => acc + time, 0) / data.students.length;

            const averageScore = getValidNumber(avgScore);
            const averageTime = getValidNumber(avgTime);

            const completion = data.students.filter(
              (student) => student.attemptedQuestions === student.totalQuestions
            ).length;

            const comp2Analytics = {
              totalStudents: data.students.length,
              highestScore,
              lowestScore,
              maximumTime,
              minimumTime,
              averageScore,
              averageTime,
              completion,
            };

            this.comp2Analytics = comp2Analytics;
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.errorMessage || error.message;
            this.setOverlayValue(false);
            throw new Error(errorMessage);
          });
      }
    },
  },
};
</script>
