<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text color="grey darken-2" @click="prev">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text color="grey darken-2" @click="next">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ viewTypeArray[viewType] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewType = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewType = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewType = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="calendarDate"
          color="primary"
          :events="classes"
          :event-color="getEventColor"
          :type="viewType"
          @click:event="showClassDetails"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <!-- @change="updateRange" -->
        <v-dialog persistent v-model="attendanceDialog" max-width="70rem">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchStudent"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
            <CustomDataTable
              :headers="studentHeaders"
              :items="studentsList"
              item-key="studentId"
              item-value="studentId"
              :search="searchStudent"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-row justify="center">
                  <v-col>
                    <v-chip
                      x-small
                      :color="item.status == 'ACTIVE' ? 'success' : 'error'"
                      label
                      outlined
                    >
                      {{ item.status }}
                    </v-chip>
                  </v-col>
                </v-row>
              </template>
            </CustomDataTable>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeAttendanceDialog()"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="endClassConfirmDialog" max-width="50rem">
          <v-card>
            <v-card-title>Please verify Chapter and Lesson Name</v-card-title>
            <v-card-subtitle class="ma-2"
              >Chapter : {{ selectedChapterName }}</v-card-subtitle
            >
            <v-card-subtitle class="ma-2"
              >Lesson : {{ selectedLessonName }}</v-card-subtitle
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeEndClassConfirm()"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="endClass()">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="rescheduleClassDialog" max-width="55rem">
          <v-card>
            <v-card-title class="primary--text">Reschedule Class</v-card-title>
            <v-form ref="form" v-model="rescheduleClassValidation.valid">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      v-model="editedClassOps.reason"
                      label="Reason"
                      :rules="rescheduleClassValidation.nameRules"
                      required
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="20rem"
                        min-width="20rem"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedClassOps.startDate"
                            label="Start date"
                            :rules="rescheduleClassValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedClassOps.startDate"
                          full-width
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="startDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="4">
                      <v-menu
                        v-model="openTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="20rem"
                        min-width="20rem"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedClassOps.startTime"
                            label="Start Time"
                            :rules="rescheduleClassValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="editedClassOps.startTime"
                          :allowed-minutes="[0, 30]"
                          full-width
                          @input="openTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="closeTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="20rem"
                        min-width="20rem"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedClassOps.endTime"
                            label="End Time"
                            :rules="rescheduleClassValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          full-width
                          :allowed-minutes="[0, 30]"
                          v-model="editedClassOps.endTime"
                          @input="closeTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeRescheduleDialog()"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="reScheduleClass()"
                      >Reschedule</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog
          persistent
          v-model="cancelClassConfirmDialog"
          max-width="35rem"
        >
          <v-card>
            <v-card-title>Are you sure you want to cancel class ? </v-card-title
            ><v-form ref="form" v-model="cancelClassValidation.valid">
              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="editedClassOps.reason"
                    label="Reason"
                    :rules="cancelClassValidation.nameRules"
                    required
                  ></v-text-field>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeCancelConfirmDialog()"
                >No</v-btn
              >
              <v-btn color="primary" text @click="cancelClass()">Yes</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="endClassDialog" max-width="37rem">
          <v-card>
            <v-card-title class="primary--text">End Class</v-card-title>
            <v-form ref="form" v-model="endClassValidation.valid">
              <v-card-text>
                <v-container>
                  <v-card-title v-if="this.model == NON_FRANCHISE_MODEL"
                    >Please select the notes you want to release</v-card-title
                  >
                  <v-row class="ma-1" v-if="this.model == NON_FRANCHISE_MODEL">
                    <v-col>
                      <v-select
                        v-model="courseCoverage.chapterNo"
                        :items="course.chapters"
                        label="Chapter Name"
                        outlined
                        dense
                        item-text="chapterName"
                        item-value="chapterNo"
                        @change="fetchLessons(courseCoverage.chapterNo)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="courseCoverage.lessonNo"
                        :items="lessons"
                        label="Lesson Name"
                        outlined
                        dense
                        item-text="lessonName"
                        item-value="lessonNo"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-card-title
                      >Can you give us some feedback of the class?</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-text-field
                          v-model="editedClassOps.feedback"
                          label="Feedback"
                          :rules="endClassValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-container>
                    </v-card-text>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeEndClassDialog()"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="openEndClassConfirm()"
                      >End Class</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="startClassDialog" width="40rem">
          <v-card>
            <v-card-title class="primary--text">Start Class</v-card-title>
            <v-form ref="form" v-model="startClassValidation.valid">
              <v-container>
                <v-card-title
                  >Please select the Chapter and Lesson name for which you want
                  to release the content</v-card-title
                >
                <v-row class="ma-1">
                  <v-col>
                    <v-select
                      v-model="courseCoverage.chapterNo"
                      :items="course.chapters"
                      label="Chapter Name"
                      required
                      outlined
                      dense
                      item-text="chapterName"
                      item-value="chapterNo"
                      @change="fetchLessons(courseCoverage.chapterNo)"
                      :rules="startClassValidation.nameRules"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="courseCoverage.lessonNo"
                      :items="lessons"
                      label="Lesson Name"
                      outlined
                      dense
                      item-text="lessonName"
                      item-value="lessonNo"
                      required
                      :rules="startClassValidation.nameRules"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeEndClassDialog()"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="JoinClass(editedClassOps)"
                    >Start Class</v-btn
                  >
                </v-card-actions>
              </v-container>
            </v-form>
          </v-card>
        </v-dialog>

        <v-menu
          v-model="openSelectedClass"
          :close-on-content-click="false"
          :activator="selectedClass"
          offset-y
        >
          <v-card min-width="23rem" flat>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip
                bottom
                v-if="
                  userType == USER_TYPE_ADMIN ||
                  (userType == USER_TYPE_TEACHER &&
                    selectedClass.status != CLASS_STATUS_CANCELED)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="viewAttendance(selectedClass)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View Attendance</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_STUDENT &&
                  (selectedClass.status == CLASS_STATUS_CREATED ||
                    selectedClass.status == CLASS_STATUS_RESCHEDULED)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openRescheduleDialog(selectedClass)"
                  >
                    mdi-clock-outline
                  </v-icon>
                </template>
                <span>Reschedule</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_STUDENT &&
                  (selectedClass.status == CLASS_STATUS_CREATED ||
                    selectedClass.status == CLASS_STATUS_RESCHEDULED)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openCancelConfirmDialog(selectedClass)"
                  >
                    mdi-cancel
                  </v-icon>
                </template>
                <span>Cancel</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="
                  false &&
                  userType == USER_TYPE_ADMIN &&
                  selectedClass.status != CLASS_STATUS_COMPLETED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="changeTeacher(selectedClass)"
                  >
                    mdi-account-alert-outline
                  </v-icon>
                </template>
                <span>Change Teacher</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="
                  userType != USER_TYPE_ADMIN &&
                  selectedClass.status != CLASS_STATUS_COMPLETED &&
                  selectedClass.status != CLASS_STATUS_CANCELED
                "
                @click="openStartClassDialog(selectedClass)"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openStartClassDialog(selectedClass)"
                  >
                    mdi-account-plus-outline
                  </v-icon>
                </template>
                <span>Join class</span>
              </v-tooltip>

              <!-- <v-list-item :key="5" v-if="isCurrentSession &&
                userType != USER_TYPE_ADMIN &&
                selectedClass.status != CLASS_STATUS_COMPLETED &&
                selectedClass.status != CLASS_STATUS_CANCELED
                " @click="openStartClassDialog(selectedClass)">
                <v-tooltip bottom v-if="isCurrentSession &&
                  userType != USER_TYPE_ADMIN &&
                  selectedClass.status != CLASS_STATUS_COMPLETED &&
                  selectedClass.status != CLASS_STATUS_CANCELED
                  ">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2" color="primary" @click="openStartClassDialog(selectedClass)">
                      mdi-account-plus-outline
                    </v-icon>
                  </template>
                  <span>Join Class</span>
                </v-tooltip>
                <v-list-item-title>{{ "Join Class" }}</v-list-item-title>
              </v-list-item> -->

              <v-tooltip
                bottom
                v-if="
                  userType == USER_TYPE_TEACHER &&
                  selectedClass.status == CLASS_STATUS_STARTED
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="OpenEndClassDialog(selectedClass)"
                  >
                    mdi-phone-hangup
                  </v-icon>
                </template>
                <span>End class</span>
              </v-tooltip>
            </v-toolbar>
            <v-simple-table class="scheduleTable">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>Classname</th>
                    <td>{{ selectedClass.classInfo.name }}</td>
                  </tr>
                  <tr>
                    <th>Teacher Name</th>
                    <td>{{ selectedClass.teacherInfo.teacherName }}</td>
                  </tr>
                  <tr>
                    <th>Class Timings</th>
                    <td>
                      {{ formattedTime(selectedClass.classInfo.startDateTime) }}
                      to
                      {{ formattedTime(selectedClass.classInfo.endDateTime) }}
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <v-chip
                        small
                        :color="getClassStatusChipColor(selectedClass)"
                      >
                        {{ selectedClass.status }}
                      </v-chip>
                      <v-icon
                        v-if="selectedClass.isExtra == true"
                        class="pa-0 ml-1 mb-1"
                        medium
                        >mdi-alpha-e-circle-outline</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn text color="primary" @click="openSelectedClass = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { getAxiosOptions, formattedTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import {
  CLASS_STATUS_COMPLETED,
  CLASS_STATUS_CREATED,
  CLASS_STATUS_STARTED,
  CLASS_STATUS_CANCELED,
  CLASS_STATUS_RESCHEDULED,
  USER_TYPE_ADMIN,
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  FRANCHISE_MODEL,
  NON_FRANCHISE_MODEL,
} from "@/common/constants";
import {
  BASE_URL,
  END_CLASS,
  GET_COURSE_BY_ID,
  RESCHEDULE_CLASS,
  SEARCH_STUDENT,
  START_CLASS,
  CANCEL_CLASS,
} from "@/common/apiEndpoints";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      calendarDate: "",
      viewType: "month",
      viewTypeArray: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      rescheduleClassDialog: false,
      endClassDialog: false,
      startClassDialog: false,
      attendanceDialog: false,
      openTimeMenu: false,
      closeTimeMenu: false,
      startDateMenu: false,
      cancelClassConfirmDialog: false,
      endClassConfirmDialog: false,
      selectedChapterName: "",
      selectedLessonName: "",
      searchStudent: "",
      courseCoverage: {},
      editedClassOps: {},
      lessons: [],
      course: {},
      className: "",
      teacherName: "",
      status: "",
      endClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      rescheduleClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      startClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      cancelClassValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      selectedEvent: {},
      selectedClass: {},
      openSelectedClass: false,
      classes: [],
      colors: ["green"],
      names: ["Class"],
      studentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "School Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      studentsList: [],
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      CLASS_STATUS_CREATED: CLASS_STATUS_CREATED,
      CLASS_STATUS_RESCHEDULED: CLASS_STATUS_RESCHEDULED,
      CLASS_STATUS_CANCELED: CLASS_STATUS_CANCELED,
      CLASS_STATUS_STARTED: CLASS_STATUS_STARTED,
      CLASS_STATUS_COMPLETED: CLASS_STATUS_COMPLETED,
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
    };
  },
  watch: {
    classOps: {
      handler(classOps) {
        if (classOps) {
          this.findPageNo();
          if (classOps.length > 0 && this.userType == USER_TYPE_TEACHER)
            this.getCourseById(classOps[0].courseId);
        }
      },
      immediate: true,
    },
  },
  props: ["classOps", "topicCovered", "model"],
  mounted: function () {
    this.updateRange();
  },
  components: { CustomDataTable },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getClassStatusColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "primary";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "secondary";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error";
      } else {
        return "orange";
      }
    },
    getClassStatusChipColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "primary-chip";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success-chip";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "secondary-chip";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error-chip";
      } else {
        return "orange-chip";
      }
    },
    getEventColor(event) {
      return event.color;
    },
    formattedTime(date) {
      return formattedTime(date);
    },
    viewDay({ date }) {
      this.calendarDate = date;
      this.viewType = "day";
    },
    setToday() {
      this.calendarDate = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showClassDetails({ nativeEvent, event }) {
      // this.selectedEvent = event;
      // this.selectedClass = this.classOps.find((cls)=> {return cls.classOpId == this.selectedEvent.classOpId});
      // this.openSelectedClass = true;
      const open = () => {
        this.selectedEvent = event;
        // this.selectedClass = nativeEvent.target
        this.selectedClass = this.classOps.find((cls) => {
          return cls.classOpId == this.selectedEvent.classOpId;
        });
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.openSelectedClass = true))
        );
      };
      if (this.openSelectedClass) {
        this.openSelectedClass = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange() {
      this.classes = [];
      for (let i = 0; i < this.classOps.length; i++) {
        const allDay = false;
        let date = new Date(this.classOps[i].classInfo.startDateTime);
        let startTime = new Date(this.classOps[i].classInfo.startDateTime);
        let endTime = new Date(this.classOps[i].classInfo.endDateTime);
        this.classes.push({
          classOpId: this.classOps[i].classOpId,
          name: this.names[0],
          start: startTime,
          end: endTime,
          color: this.getClassStatusColor(this.classOps[i]),
          timed: !allDay,
        });
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    viewAttendance(item) {
      this.attendanceDialog = true;
      this.searchStudents(item.attendedStudents);
    },
    getCourseById(courseId) {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          this.course.chapters = this.course.chapters.filter((chapter) => {
            if (
              !this.topicCovered.has(Number(chapter.chapterNo)) ||
              (this.topicCovered.has(Number(chapter.chapterNo)) &&
                chapter.lessons.length !=
                  this.topicCovered.get(Number(chapter.chapterNo)).length)
            ) {
              return true;
            }
          });
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    closeAttendanceDialog() {
      this.attendanceDialog = false;
      this.studentList = [];
    },
    openRescheduleDialog(item) {
      this.rescheduleClassDialog = true;
      this.editedClassOps.classOpId = item.classOpId;
    },
    closeRescheduleDialog() {
      this.rescheduleClassDialog = false;
      this.editedClassOps = {};
      this.editedClassOps.classOpId = "";
    },
    reScheduleClass() {
      this.$refs.form.validate();
      const payload = {};
      if (
        this.userType != this.USER_TYPE_STUDENT &&
        this.rescheduleClassValidation.valid
      ) {
        payload.reason = this.editedClassOps.reason;
        payload.classOpId = this.editedClassOps.classOpId;
        let startDateTime = new Date(this.editedClassOps.startDate);
        let endDateTime = new Date(this.editedClassOps.startDate);
        let startTime = this.editedClassOps.startTime.split(":");
        let endTime = this.editedClassOps.endTime.split(":");
        startDateTime.setHours(Number(startTime[0]));
        startDateTime.setMinutes(Number(startTime[1]));
        startDateTime.setSeconds(0);
        endDateTime.setHours(Number(endTime[0]));
        endDateTime.setMinutes(Number(endTime[1]));
        endDateTime.setSeconds(0);
        payload.startDateTime = startDateTime.toISOString();
        payload.endDateTime = endDateTime.toISOString();

        let url = RESCHEDULE_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.updateRange();
            this.rescheduleClassValidation.valid = false;
            this.closeRescheduleDialog();
            this.setApiSuccess("Class Rescheduled Successfully");
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeRescheduleDialog();
            this.setOverlayValue(false);
          });
      }
    },
    findPageNo() {
      var totalPages = 0;
      if (this.classOps.length % 8 == 0) {
        totalPages = parseInt(this.classOps.length / 8);
      } else {
        totalPages = parseInt(this.classOps.length / 8) + 1;
      }
    },
    searchStudents(studentIds) {
      const payload = {};
      let flag = false;
      if (studentIds && studentIds.length > 0) {
        payload.studentIds = studentIds;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    // JoinClass(item) {
    //   // if (item.classInfo.classLink) {
    //   let flag = false;
    //   const payload = {};
    //   if (
    //     this.userType == this.USER_TYPE_TEACHER &&
    //     item.status != CLASS_STATUS_STARTED
    //   ) {
    //     payload.classOpId = item.classOpId;
    //     let url = START_CLASS;
    //     this.setOverlayValue(true);
    //     axios
    //       .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //       .then((res) => {
    //         window.open(item.classInfo.classLink, "_blank");
    //         this.updateClassOps(res.data);
    //         this.updateRange();
    //         this.setOverlayValue(false);
    //       })
    //       .catch((error) => {
    //         error = error.errorMessage;
    //         this.setOverlayValue(false);
    //       });
    //   } else {
    //     if (item.status == CLASS_STATUS_STARTED) {
    //       window.open(item.classInfo.classLink, "_blank");
    //     } else {
    //       this.setApiError("Class Not Started Yet !");
    //     }
    //   }
    //   // } else {
    //   //   this.setApiError("Class Link Not Found");
    //   // }
    // },
    JoinClass(item) {
      if (
        this.model == FRANCHISE_MODEL &&
        this.userType == this.USER_TYPE_TEACHER &&
        item.status != CLASS_STATUS_STARTED
      ) {
        this.$refs.form.validate();
      }
      const payload = {};
      if (
        this.userType == this.USER_TYPE_TEACHER &&
        item.status != CLASS_STATUS_STARTED
      ) {
        payload.classOpId = item.classOpId;
        if (this.model == FRANCHISE_MODEL) {
          if (
            this.startClassValidation.valid &&
            this.courseCoverage &&
            this.courseCoverage.chapterNo &&
            this.courseCoverage.lessonNo
          ) {
            payload.courseCoverage = this.courseCoverage;
          } else {
            return;
          }
        }
        let url = START_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.startClassValidation.valid = false;
            this.closeEndClassDialog();
            if (
              item.classInfo.classLink.startsWith("http://") ||
              item.classInfo.classLink.startsWith("https://")
            ) {
              window.open(item.classInfo.classLink, "_blank");
            } else {
              let x = "https://";
              item.classInfo.classLink = x.concat(item.classInfo.classLink);
              window.open(item.classInfo.classLink, "_blank");
            }
            this.updateClassOps(res.data);
            this.closeEndClassDialog();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeEndClassDialog();
            this.startClassValidation.valid = false;
            this.setOverlayValue(false);
          });
      } else {
        if (item.status == CLASS_STATUS_STARTED) {
          if (
            item.classInfo.classLink.startsWith("http://") ||
            item.classInfo.classLink.startsWith("https://")
          ) {
            window.open(item.classInfo.classLink, "_blank");
          } else {
            let x = "https://";
            item.classInfo.classLink = x.concat(item.classInfo.classLink);
            window.open(item.classInfo.classLink, "_blank");
          }
        } else {
          this.setApiError("Class Not Started Yet !");
        }
      }
      // } else {
      //   this.setApiError("Class Link Not Found");
      // }
    },
    changeTeacher(item) {
      console.log("teacher changed");
    },
    openEndClassConfirm() {
      this.$refs.form.validate();
      if (this.endClassValidation.valid) {
        this.endClassConfirmDialog = true;
        this.fetchLessons(this.courseCoverage.chapterNo);
      }
    },
    closeEndClassConfirm() {
      this.endClassConfirmDialog = false;
      this.selectedChapterName = "";
      this.selectedLessonName = "";
    },
    // endClass() {
    //   this.$refs.form.validate();
    //   if (this.endClassValidation.valid) {
    //     const payload = {};
    //     if (
    //       this.userType == this.USER_TYPE_TEACHER &&
    //       this.endClassValidation.valid
    //     ) {
    //       payload.classOpId = this.editedClassOps.classOpId;
    //       payload.courseCoverage = this.courseCoverage;
    //       payload.feedback = this.editedClassOps.feedback;
    //       let url = END_CLASS;
    //       this.setOverlayValue(true);
    //       axios
    //         .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //         .then((res) => {
    //           this.updateClassOps(res.data);
    //           this.updateRange();
    //           this.endClassValidation.valid = true;
    //           this.closeEndClassDialog();
    //           this.closeEndClassConfirm();
    //           this.setOverlayValue(false);
    //         })
    //         .catch((error) => {
    //           error = error.errorMessage;
    //           this.closeEndClassDialog();
    //           this.closeEndClassConfirm();
    //           this.setOverlayValue(false);
    //         });
    //     }

    //     //   }
    //     //   else {
    //     //   this.setApiError("class room ended");
    //     // }
    //   }
    // },
    endClass() {
      this.$refs.form.validate();
      if (this.endClassValidation.valid) {
        const payload = {};
        if (this.userType == this.USER_TYPE_TEACHER) {
          payload.classOpId = this.editedClassOps.classOpId;
          if (
            this.courseCoverage &&
            this.courseCoverage.chapterNo &&
            this.courseCoverage.lessonNo
          ) {
            payload.courseCoverage = this.courseCoverage;
          }
          payload.feedback = this.editedClassOps.feedback;
          // payload.courseCoverage.chapterNo = this.courseCoverage.selectedChapter.chapterNo;
          // payload.courseCoverage.lessonNo = this.courseCoverage.selectedLesson.lessonNo;
          let url = END_CLASS;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.endClassValidation.valid = false;
              this.closeEndClassConfirm();
              this.closeEndClassDialog();
              this.updateClassOps(res.data);
              this.$emit("updateTopicCovered");
              this.setApiSuccess("Class Ended Successfully");
              this.setOverlayValue(false);
            })
            .catch((error) => {
              error = error.errorMessage;
              this.closeEndClassDialog();
              this.closeEndClassConfirm();
              this.setOverlayValue(false);
            });
        }
      }

      //   }
      //   else {
      //   this.setApiError("class room ended");
      // }
    },
    openStartClassDialog(item) {
      let classStarted = false;

      // Check if any class has started
      for (const clsOp of this.classOps) {
        if (clsOp.status === CLASS_STATUS_STARTED) {
          this.setApiError("Please complete started class first");
          classStarted = true;
          break;
        }
      }

      if (!classStarted) {
        this.getCourseById(item.courseId);
        this.editedClassOps = Object.assign({}, item);
        if (
          this.model === FRANCHISE_MODEL &&
          this.userType === USER_TYPE_TEACHER &&
          item.status !== CLASS_STATUS_STARTED
        ) {
          this.startClassDialog = true;
        } else {
          this.JoinClass(item);
        }
      }
    },
    updateClassOps(updatedClassOps) {
      var foundIndex = this.classOps.findIndex(
        (clsOp) => clsOp.classOpId == updatedClassOps.classOpId
      );
      Object.assign(this.classOps[foundIndex], updatedClassOps);
    },
    OpenEndClassDialog(item) {
      this.endClassDialog = true;
      this.editedClassOps = Object.assign({}, item);
      this.getCourseById(item.courseId);
    },
    closeEndClassDialog() {
      this.endClassDialog = false;
      this.startClassDialog = false;
      this.editedClassOps = {};
      this.courseCoverage = {};
    },
    openCancelConfirmDialog(item) {
      this.cancelClassConfirmDialog = true;
      this.editedClassOps.classOpId = item.classOpId;
    },
    closeCancelConfirmDialog() {
      this.cancelClassConfirmDialog = false;
      this.editedClassOps = {};
    },
    fetchLessons(chapterNo) {
      let Chapter = this.course.chapters.filter((chpt) => {
        return chpt.chapterNo == chapterNo;
      });
      if (Chapter[0].lessons) {
        this.lessons = Chapter[0].lessons;
        if (this.topicCovered.has(Number(chapterNo)))
          this.lessons = this.lessons.filter((lesson) => {
            return !this.topicCovered
              .get(Number(chapterNo))
              .includes(lesson.lessonNo);
          });
        this.selectedChapterName = Chapter[0].chapterName;
      }
      if (this.courseCoverage.lessonNo) {
        let lessons = this.lessons.filter((lesson) => {
          return lesson.lessonNo == this.courseCoverage.lessonNo;
        });
        this.selectedLessonName = lessons[0].lessonName;
      }
    },
    cancelClass() {
      const payload = {};
      if (
        this.userType != this.USER_TYPE_STUDENT &&
        this.cancelClassValidation.valid
      ) {
        payload.classOpId = this.editedClassOps.classOpId;
        payload.reason = this.editedClassOps.reason;
        let url = CANCEL_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.updateClassOps(res.data);
            this.updateRange();
            this.cancelClassValidation.valid = false;
            this.closeCancelConfirmDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Class Canceled Successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCancelConfirmDialog();
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
