<template>
  <v-card flat class="ml-1 mt-2">
    <CustomDataTable
      :headers="headers"
      :items="allCodelyAssignments"
      :searchEnabled="true"
      :items-per-page="10"
      disable-sort
    >
      <!-- <template v-slot:[`header.title`]="{ header }">
        <v-text-field
          v-model="searchText"
          style="width: 60%"
          flat
          dense
          solo
          :append-icon="searchText.length > 0 ? '' : 'mdi-magnify'"
          label="Search Codely"
          hide-details
          @input="searchCodely"
          clearable
        ></v-text-field>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editCodelyAssignment(item)">
              <v-icon class="mr-2" color="warning"> mdi-pencil </v-icon>
              <span>Edit</span>
            </v-list-item>
            <v-list-item @click="deleteAssignmentConfirmDialog(item)">
              <v-icon class="mr-2" color="error"> mdi-delete </v-icon>
              <span>Delete</span>
            </v-list-item>
            <v-list-item
              :disabled="item.assignmentNumber == 1"
              v-if="status != STATUS_PUBLISHED"
              @click="openMoveAssignmentDialog(item, 'UP')"
            >
              <v-icon
                class="mr-2"
                color="blue"
                :disabled="item.assignmentNumber == 1"
              >
                mdi-arrow-up-bold
              </v-icon>
              <span>move up</span>
            </v-list-item>
            <v-list-item
              v-if="status != STATUS_PUBLISHED"
              @click="openMoveAssignmentDialog(item, 'DOWN')"
              :disabled="item.assignmentNumber == allCodelyAssignments.length"
            >
              <v-icon
                class="mr-2"
                color="blue"
                :disabled="item.assignmentNumber == allCodelyAssignments.length"
              >
                mdi-arrow-down-bold
              </v-icon>
              <span>move down</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.preview`]="{ item }">
        <v-btn
          class="gradient-btn"
          small
          @click="previewCodelyAssignment(item)"
        >
          View
        </v-btn>
      </template>
      <template v-slot:[`item.coins`]="{ item }">
        <v-chip color="primary-chip font-weight-bold text-body-2" small>
          <v-avatar left>
            <v-img
              src="../assets/Coin.svg"
              style="height: 1.2rem"
              contain
            ></v-img>
          </v-avatar>
          {{ item.coins }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          small
          :color="item.afterContent == null ? 'success-chip' : 'secondary-chip'"
        >
          {{ item.afterContent == null ? "Homework" : "After Content" }}
          <span v-if="item.afterContent != null"
            >&nbsp;{{ item.afterContent }}</span
          >
        </v-chip>
      </template>
    </CustomDataTable>

    <v-dialog persistent v-model="moveAssignmentDialog" width="500">
      <v-card>
        <v-card-title class="primary--text"
          >Move Codely {{ direction }}</v-card-title
        >
        <v-form ref="form" v-model="moveAssignmentValidation.valid">
          <v-card-text>
            <v-chip class="primary ml-1"
              >Codely {{ editedAssignment.assignmentNumber }}</v-chip
            >
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="moveOptions"
                    dense
                    outlined
                    v-model="editedAssignment.destinationIndex"
                    :rules="moveAssignmentValidation.nameRules"
                    label="Move Codely to"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeMoveAssignmentDialog()"
                  >Cancel
                </v-btn>
                <v-btn color="primary" text @click="moveAssignment()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card-actions>
      <v-row align="end">
        <v-dialog persistent v-model="assignmentDialog" width="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="mt-2 ml-1"
              label
              outlined
              v-bind="attrs"
              v-on="on"
              @click="addAssignmentDialog()"
            >
              <v-icon class="mr-2"> mdi-plus-circle</v-icon>
              <span>Add Codely </span>
            </v-chip>
          </template>
          <v-card>
            <v-card-title class="primary--text"
              >New Codely Assignment</v-card-title
            >
            <v-card-text>
              <v-chip-group column>
                <v-chip class="primary">Chapter {{ chapterNo }}</v-chip>
                <v-chip class="primary">Lesson {{ lessonNo }}</v-chip>
              </v-chip-group>
              <v-form ref="form" v-model="createAssignmentValidation.valid">
                <v-container>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        v-model="editedAssignment.title"
                        outlined
                        label="Title"
                        :rules="createAssignmentValidation.nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        type="number"
                        required
                        label="Coins"
                        v-model="editedAssignment.coins"
                        :rules="createAssignmentValidation.coinRules"
                      >
                        <!-- :disabled="editedIndex != -1" -->
                      </v-text-field>
                    </v-col>
                    <v-col
                      col="2"
                      v-if="!editedAssignment.isHomework"
                      class="mt-3"
                    >
                      <!-- :disabled="true" -->
                      <v-select
                        :items="contentOptions"
                        :rules="
                          !editedAssignment.afterContent && editedIndex != -1
                            ? []
                            : createAssignmentValidation.contentRules
                        "
                        v-model="editedAssignment.afterContent"
                        label="After content"
                        dense
                        outlined
                        no-data-text="Content Not Present"
                      >
                        <!-- :disabled="
                          !editedAssignment.afterContent && editedIndex != -1
                        " -->
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        :value="true"
                        v-model="editedAssignment.isHomework"
                        label="Home Work"
                      >
                        <!-- :disabled="editedIndex != -1" -->
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span style="font-weight: bolder">QUESTION LINK </span>
                      <v-text-field
                        v-model="editedAssignment.codelyLink"
                        label="Enter Question Link"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span style="font-weight: bolder">SOLUTION LINK </span>
                      <v-text-field
                        v-model="editedAssignment.solutionLink"
                        label="Enter solution Link"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="close()">Cancel </v-btn>
                    <v-btn color="primary" text @click="saveCodelyAssignment()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          v-model="previewCodelyAssignmentDialog"
          width="60%"
          class="pa-0"
        >
          <v-toolbar dark color="primary">
            <v-toolbar-title>Codely Assignment</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closePreviewCodelyAssignmentDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card v-model="editedAssignment">
            <v-col>
              <v-row class="mt-1 pl-3">
                <span class="error--text text-h5 font-weight-medium"
                  >Title:
                </span>
                <span
                  class="ml-2 text-h5"
                  v-html="editedAssignment.title"
                ></span>
              </v-row>
            </v-col>
            <v-col>
              <span>Question Link</span>
              <v-text-field
                class="mt-n2"
                v-model="editedAssignment.codelyLink"
                required
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <span>Solution Link</span>
              <v-text-field
                class="mt-n2"
                v-model="editedAssignment.solutionLink"
                required
                readonly
              ></v-text-field>
            </v-col>
          </v-card>
        </v-dialog>
        <ConfirmDialog
          :dialog="deleteAssignmentDialog"
          :subMessage1="'Are you sure you want to delete'"
          :subMessage2="editedAssignment.title"
          @close="closeDeleteAssignmentDialog()"
          @confirm="deleteAssignment()"
        />
        <!-- <v-dialog persistent v-model="deleteAssignmentDialog" max-width="35rem">
          <v-card>
            <v-card-title>Are you sure you want to delete this ? </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeDeleteAssignmentDialog"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="deleteAssignment">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  DELETE_ASSIGNMENT,
  UPDATE_CODELYASSIGNMENT,
  ADD_CODELYASSIGNMENT,
  BASE_URL,
  DELETE_LESSON,
  GET_COURSE_BY_ID,
  MOVE_ASSIGNMENT,
  UPDATE_LESSON,
  COURSE_VIEW,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import { SET_API_SUCCESS } from "@/store/mutations";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      allCodelyAssignments: [],
      editorConfig: {
        height: 90,
      },
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          text: "Assignment No",
          value: "assignmentNumber",
        },
        { text: "Type", value: "type", align: "center" },
        { text: "Preview", value: "preview", align: "center" },
        { text: "Coins", value: "coins", align: "center" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      searchText: "",
      assignmentDialog: false,
      moveAssignmentDialog: false,
      deleteAssignmentDialog: false,
      deleteLessonDialog: false,
      previewCodelyAssignmentDialog: false,
      previewData: {},
      editedIndex: -1,
      itemsPerPage: -1,
      editedAssignment: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      direction: "",
      quizes: [],
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      moveAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        coinRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Coins must be greater than 0",
          (v) => Number(1000) >= v || "Coin must be less than 1000",
        ],
        contentRules: [
          (v) => !!v || "This is required",
          // (v) =>
          //   (Number(0) < v && Number(this.noOfContents) >= v) ||
          //   "content must be greater than 0 and less than " +
          //     (this.noOfContents + 1),
        ],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      menu: false,
    };
  },
  components: { ConfirmDialog, ResourceSearch, CustomDataTable },
  props: [
    "codelyAssignments",
    "courseId",
    "chapterNo",
    "lessonNo",
    "status",
    "noOfContents",
  ],
  mounted: function () {
    this.allCodelyAssignments = this.codelyAssignments;
  },
  watch: {
    codelyAssignments: {
      handler(value) {
        this.allCodelyAssignments = value;
      },
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    contentOptions() {
      return Array.from({ length: this.noOfContents }, (_, i) => i + 1);
    },
    moveOptions() {
      if (
        !this.allCodelyAssignments ||
        this.allCodelyAssignments.length === 0
      ) {
        return [];
      }
      return Array.from(
        { length: this.allCodelyAssignments.length },
        (_, i) => i + 1
      );
    },
  },
  methods: {
    courseViewWithType(chapterNo, lessonNo, type) {
      const url = COURSE_VIEW;
      const payload = {
        courseId: this.courseId,
        chapterNo: chapterNo,
        lessonNo: lessonNo,
      };
      if (type) {
        switch (type) {
          case "content":
            payload.showContent = true;
            break;
          case "notes":
            payload.showNotes = true;
            break;
          case "quizAssignments":
            payload.showQuizAssignments = true;
            break;
          case "codeAssignments":
            payload.showCodeAssignments = true;
            break;
          case "textAssignments":
            payload.showTextAssignments = true;
            break;
          case "codelyAssignments":
            payload.showCodelyAssignments = true;
            break;
        }
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.allCodelyAssignments =
            res.data.chapters[0].lessons[0].codelyAssignments;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          console.error("Error fetching type data:", error);
          this.setOverlayValue(false);
        });
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    // searchCodely() {
    //   if (this.searchText) {
    //     this.codelyAssignments.filter((codelyAssignment) => {
    //       return codelyAssignment.title
    //         .toLowerCase()
    //         .includes(this.searchText.toLowerCase());
    //     });
    //   }
    // },
    validate() {
      this.$refs.form.validate();
    },
    addAssignmentDialog() {
      this.assignmentDialog = true;
      this.editedAssignment = Object.assign({}, this.defaultItem);
    },
    close() {
      this.deleteLessonDialog = false;
      this.assignmentDialog = false;
      this.$nextTick(() => {
        this.editedLesson = Object.assign({}, this.defaultItem);
        this.editedAssignment = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLessonConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
      this.deleteLessonDialog = true;
    },
    closeDeleteAssignmentDialog() {
      this.deleteAssignmentDialog = false;
    },
    previewCodelyAssignment(item) {
      this.editedAssignment = item;
      this.previewCodelyAssignmentDialog = true;
    },
    closePreviewCodelyAssignmentDialog() {
      this.previewCodelyAssignmentDialog = false;
    },

    editCodelyAssignment(item) {
      this.assignmentDialog = true;
      this.editedIndex = 0;
      this.editedAssignment = Object.assign({}, item);
    },
    openMoveAssignmentDialog(item, direction) {
      this.moveAssignmentDialog = true;
      this.editedAssignment.assignmentNumber = item.assignmentNumber;
      this.direction = direction;
      if (direction == "UP") {
        this.editedAssignment.direction = "UP";
      } else {
        this.editedAssignment.direction = "DOWN";
      }
    },
    closeMoveAssignmentDialog() {
      this.moveAssignmentDialog = false;
      this.$nextTick(() => {
        this.editedAssignment = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveAssignment() {
      if (this.moveAssignmentValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        payload.assignmentNumber = this.editedAssignment.assignmentNumber;
        payload.direction = this.editedAssignment.direction;
        payload.destinationIndex = this.editedAssignment.destinationIndex;
        payload.assignmentType = "CODELY";
        let url = MOVE_ASSIGNMENT;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.allCodelyAssignments=res.data.chapters[this.chapterNo-1]?.lessons[this.lessonNo-1]?.codelyAssignments;
            this.courseViewWithType(
              this.chapterNo,
              this.lessonNo,
              "codelyAssignments"
            );
            this.moveAssignmentValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
            this.setApiSuccess("Codely Assignment moved successfully");
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
          });
      }
    },
    saveCodelyAssignment() {
      this.validate();
      if (this.createAssignmentValidation.valid) {
        const payload = {};
        this.setOverlayValue(true);
        let url = ADD_CODELYASSIGNMENT;
        if (this.editedIndex > -1) {
          url = UPDATE_CODELYASSIGNMENT;
          payload.assignmentNumber = this.editedAssignment.assignmentNumber;
        }
        payload.title = this.editedAssignment.title.trim();
        payload.coins = this.editedAssignment.coins;
        payload.codelyLink = this.editedAssignment.codelyLink;
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        if (this.editedAssignment.isHomework) {
          this.editedAssignment.afterContent = null;
        }
        payload.afterContent = this.editedAssignment.afterContent;
        payload.isHomework = this.editedAssignment.isHomework;
        payload.solutionLink = this.editedAssignment.solutionLink;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.allCodelyAssignments=res.data.chapters[this.chapterNo-1]?.lessons[this.lessonNo-1]?.codelyAssignments;
            this.courseViewWithType(
              this.chapterNo,
              this.lessonNo,
              "codelyAssignments"
            );
            this.createAssignmentValidation.valid = false;
            this.setOverlayValue(false);
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setApiSuccess("Assignment saved successfully");
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    deleteAssignmentConfirmDialog(item) {
      this.editedAssignment = Object.assign({}, item);
      this.deleteAssignmentDialog = true;
    },
    deleteAssignment() {
      let url = DELETE_ASSIGNMENT;
      let payload = {};
      this.setOverlayValue(true);
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.assignmentNumber = this.editedAssignment.assignmentNumber;
      payload.assingmentType = "CODELY";
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          // this.allCodelyAssignments=res.data.chapters[this.chapterNo-1]?.lessons[this.lessonNo-1]?.codelyAssignments;
          this.courseViewWithType(
            this.chapterNo,
            this.lessonNo,
            "codelyAssignments"
          );
          this.closeDeleteAssignmentDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Assignment deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
