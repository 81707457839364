<template>
  <div>
    <vue-friendly-iframe
      :src="src"
      allow="clipboard-read; clipboard-write"
      :style="iframeStyles"
    ></vue-friendly-iframe>
  </div>
</template>
<script>
export default {
  props: ["src", "scaleFactor"],
  computed: {
    iframeStyles() {
      return {
        width: `${100 / this.scaleFactor}%`,
        height: `${100 / this.scaleFactor}%`,
        transform: `scale(${this.scaleFactor})`,
        transformOrigin: "top left",
      };
    },
  },
};
</script>
