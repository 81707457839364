<template>
  <div
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      background: linear-gradient(to right, #f4f7fe 70%, #afb3ff57 30%);
    "
  >
    <v-row class="ma-1 d-flex align-center">
      <v-col cols="5" class="pt-8">
        <v-container
          class="pa-0"
          style="display: flex; justify-content: right"
          v-if="!sentForValidation && !studentRegistration"
        >
          <v-card flat width="75%" color="mainContentColor">
            <v-img max-height="8rem" src="../assets/APT_LogoR.png"></v-img>
            <v-card-title class="ma-1 justify-center font-weight-bold"
              >Welcome to APTCODER!</v-card-title
            >
            <v-card-text class="pb-0 ma-1 text-center">
              Please sign-in to your account and start the adventure
            </v-card-text>
            <!-- <v-card-text v-if ="loginError" >
           {{ errorMessage }} 
          </v-card-text> -->
            <v-card-text>
              <v-form v-model="form" @submit.prevent="onSubmit">
                <v-checkbox
                  dense
                  v-model="loginWithOtp"
                  label="Login with OTP"
                ></v-checkbox>
                <v-row>
                  <!-- <v-col class="pb-0" cols="3">
                <v-text-field v-model="countryCode" :rules="[required]" 
                  outlined dense></v-text-field>
              </v-col> -->
                  <v-col class="pb-0">
                    <v-text-field
                      v-model="username"
                      :rules="[required]"
                      clearable
                      :placeholder="
                        loginWithOtp
                          ? 'Enter Email/Phone Number'
                          : 'Enter Email'
                      "
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row v-else>
              <v-col class="pb-0">
                <v-text-field v-model="email" :rules="[required]" clearable placeholder="Enter Email"
                  outlined dense></v-text-field>
              </v-col>
            </v-row> -->
                <v-row v-if="!loginWithOtp">
                  <v-col class="pt-0 pb-0">
                    <v-text-field
                      v-model="password"
                      :type="showPswrd ? 'text' : 'password'"
                      placeholder="Enter Password"
                      outlined
                      dense
                      :append-icon="!showPswrd ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="showPswrd = !showPswrd"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-1 d-flex justify-end">
                  <a
                    ><span
                      class="text-subtitle-1"
                      v-if="!loginWithOtp"
                      color="primary"
                      @click="forgetPassword()"
                      >Forget Password ?</span
                    ></a
                  >
                </v-row>
                <v-btn
                  :disabled="!username || (!loginWithOtp && !password)"
                  class="mt-2 gradient-btn"
                  block
                  type="submit"
                >
                  Login
                </v-btn>
              </v-form>

              <v-row class="mt-4 d-flex justify-center">
                <span class="black--text text-body-1"
                  >Need an account?&nbsp;</span
                >
                <a
                  ><span
                    class="error--text font-weight-bold text-body-1"
                    @click="openStudentRegistration()"
                  >
                    Register</span
                  ></a
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container
          class="pa-0"
          style="display: flex; justify-content: right"
          v-if="sentForValidation"
        >
          <v-card flat width="75%" color="mainContentColor">
            <v-row class="ma-1">
              <v-btn class="ma-1" @click="backToLogin()" icon
                ><v-icon large>mdi-arrow-left</v-icon></v-btn
              >
            </v-row>
            <v-img height="8rem" src="../assets/APT_LogoR.png"> </v-img>
            <v-card-title>Verify OTP</v-card-title>
            <v-card-subtitle>OTP is sent to your mobile no</v-card-subtitle>
            <v-card-text>
              <v-form v-model="form" @submit.prevent="validateOtp">
                <v-otp-input
                  length="4"
                  v-model="otp"
                  :rules="[required]"
                  placeholder="-"
                  outlined
                  dense
                  type="number"
                ></v-otp-input>

                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  size="large"
                  type="submit"
                >
                  Validate
                </v-btn>
              </v-form>

              <v-row class="mt-2 ml-1 mb-2">
                <span class="text-caption mt-1"
                  >Didn't receive otp? Resend in {{ otpCountdown }}</span
                >
                <v-btn
                  color="primary"
                  class="mt-1"
                  x-small
                  v-model="resend"
                  :disabled="!resend"
                  text
                  @click="studentRegistration ? register() : login()"
                >
                  Resend
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container
          class="pa-0"
          style="display: flex; justify-content: right"
          v-if="studentRegistration && !sentForValidation"
        >
          <v-card flat width="75%" color="mainContentColor">
            <v-row class="ma-1">
              <v-btn @click="loginPage()" icon
                ><v-icon large>mdi-arrow-left</v-icon></v-btn
              >
            </v-row>
            <v-img height="8rem" src="../assets/APT_LogoR.png"> </v-img>
            <v-row justify="center">
              <v-card-title>Create your account</v-card-title>
            </v-row>
            <v-card-text>
              <v-form v-model="form" @submit.prevent="register">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      v-model="username"
                      :rules="[required]"
                      clearable
                      placeholder="Enter Email/Phone Number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12">
                <v-select
                  :items="loginTypes"
                  v-model="studentLoginType"
                  label="Role Type"
                  dense
                  disabled
                  outlined
                  :rules="[required]"
                ></v-select>
              </v-col> -->
                </v-row>
                <v-btn
                  :disabled="!form"
                  class="mt-2 gradient-btn"
                  type="submit"
                  block
                >
                  Register
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          color="primary"
          :timeout="3000"
          rounded="pill"
          max-height="1rem"
          style="bottom: 26%; left: -24%"
        >
          <div class="d-flex justify-space-between align-center">
            <span class="font-weight-bold text-body-1">{{ errorText }}</span>
            <v-btn text icon color="white" @click="snackbar = false">
              <v-icon color="white">mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </v-snackbar>
      </v-col>
      <v-col cols="7">
        <v-container class="pa-0 d-flex justify-center">
          <v-img
            style="max-width: 60%"
            contain
            src="../assets/login.png"
          ></v-img>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="ma-0 d-flex align-end">
      <v-img
        style="max-width: 8%"
        contain
        src="../assets/login-page-1.png"
      ></v-img>
      <v-img
        contain
        src="../assets/login-page-2.png"
        style="max-width: 26%; height: auto; left: 51%"
      ></v-img>
    </v-row>
  </div>
</template>

<script>
import {
  BASE_URL,
  LOGIN_URL,
  REGISTER_URL,
  VALIDATE_OTP_URL,
} from "@/common/apiEndpoints.js";
import { FETCH_USER_PROFILE, SAVE_TOKEN } from "@/store/actions";
import { SET_TOKEN_EXPIRED } from "@/store/mutations";

import { mapActions, mapMutations } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      sentForValidation: false,
      loginWithOtp: false,
      studentRegistration: false,
      phoneNumber: "",
      password: "",
      showPswrd: false,
      username: "",
      selectedLoginType: "",
      loginWith: "MOBILE",
      countryCode: "+91",
      otpId: "",
      loginTypes: [
        "Content Manager",
        "Admin",
        "Resource Manager",
        "Student",
        "Teacher",
        "Institute",
      ],
      studentLoginType: "Student",
      otp: "",
      form: false,
      loginError: false,
      errorMessage: "",
      snackbar: false,
      errorText: "",
      otpCountdown: 60,
      resend: false,
      timer: "",
    };
  },
  watch: {
    // setTokenExpired(tokenExpired) {
    //   this.tokenExpired = tokenExpired;
    // },
  },
  methods: {
    ...mapActions({
      saveToken: `schoolModule/${SAVE_TOKEN}`,
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),
    ...mapMutations({
      setTokenExpired: `schoolModule/${SET_TOKEN_EXPIRED}`,
    }),

    openStudentRegistration() {
      this.studentRegistration = true;
    },
    validateOtp() {
      const oThis = this;
      const url = BASE_URL + VALIDATE_OTP_URL;
      const payload = {};
      if (this.username.includes("@")) {
        payload.email = this.username.trim();
        payload.type = "EMAIL";
      } else {
        payload.mobileNo = this.username.trim();
        payload.type = "MOBILE";
      }
      payload.countryCode = this.countryCode;
      payload.otpId = this.otpId;
      payload.otp = this.otp;
      if (this.studentRegistration) payload.register = true;
      else {
        payload.register = false;
      }
      axios
        .post(url, payload)
        .then((res) => {
          console.log(res.data);
          const storePayload = {};
          storePayload.token = res.data.token;
          this.saveToken(storePayload).then(() => {
            this.setTokenExpired(false);
            oThis.fetchProfile();
            oThis.$router.push({ path: "/home" });
          });
        })
        .catch((error) => {
          error = error.response.data;
          oThis.errorText = error.errorMessage;
          oThis.snackbar = true;
        });
    },
    parseJwt(token) {
      var base64Url = token.split(".")[0];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload).USER_ROLES[0];
    },

    login() {
      const oThis = this;
      const url = BASE_URL + LOGIN_URL;
      let payload = {};
      payload.countryCode = this.countryCode;
      if (this.username.includes("@")) {
        payload.email = this.username.trim();
        payload.otpType = "EMAIL";
      } else {
        payload.mobileNo = this.username.trim();
        payload.otpType = "MOBILE";
      }
      if (this.loginWithOtp) {
        payload.loginWith = "OTP";
      } else {
        payload.password = this.password;
        payload.loginWith = "PASSWORD";
      }

      payload.token = "string";
      axios
        .post(url, payload)
        .then((res) => {
          console.log(res.data);
          if (this.loginWithOtp) {
            this.sentForValidation = true;
            this.otpId = res.data.otpId;
            this.form = false;
            this.otpCountdown = 60;
            this.resend = false;
            this.countDownTimer();
          } else {
            const storePayload = {};
            storePayload.token = res.data.token;
            this.saveToken(storePayload).then(() => {
              this.setTokenExpired(false);
              oThis.fetchProfile();
              oThis.$router.push({ path: "/home" });
            });
          }
        })
        .catch((error) => {
          oThis.loginError = true;
          oThis.errorMessage = error.response.data.errorMessage;
          error = error.response.data;
          oThis.errorText = error.errorMessage;
          oThis.snackbar = true;
        });
    },
    register() {
      const oThis = this;
      const url = BASE_URL + REGISTER_URL;
      let payload = {};
      payload.countryCode = this.countryCode;
      if (this.username.includes("@")) {
        payload.email = this.username.trim();
        payload.otpType = "EMAIL";
      } else {
        payload.mobileNo = this.username.trim();
        payload.otpType = "MOBILE";
      }
      payload.registerType = "OTP";
      payload.token = "string";
      axios
        .post(url, payload)
        .then((res) => {
          console.log(res.data);
          this.sentForValidation = true;
          this.otpId = res.data.otpId;
          this.form = false;
          this.otpCountdown = 60;
          this.resend = false;
          this.countDownTimer();
        })
        .catch((error) => {
          oThis.loginError = true;
          oThis.errorMessage = error.response.data.errorMessage;
          error = error.response.data;
          oThis.errorText = error.errorMessage;
          oThis.snackbar = true;
        });
    },
    onSubmit() {
      if (!this.form) return;
      this.login();
    },
    required(v) {
      return !!v || "Field is required";
    },
    backToLogin() {
      this.sentForValidation = !this.sentForValidation;
      this.otpCountdown = 60;
      this.resend = false;
      clearTimeout(this.timer);
    },
    loginPage() {
      this.studentRegistration = !this.studentRegistration;
    },
    countDownTimer() {
      if (this.otpCountdown > 0) {
        this.timer = setTimeout(() => {
          this.otpCountdown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.resend = true;
      }
    },
    forgetPassword() {
      this.$router.push({ path: "/reset-password" });
    },
  },
};
</script>
