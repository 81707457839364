var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({},'div',_vm.$props,false),[_c('v-dialog',{attrs:{"width":"44rem"},model:{value:(_vm.imgDialog),callback:function ($$v) {_vm.imgDialog=$$v},expression:"imgDialog"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{staticClass:"fixed-button",attrs:{"color":"red","dark":"","width":"1.3rem"},on:{"click":function($event){return _vm.closeImage()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-img',{attrs:{"src":_vm.questionMedia}})],1)]),(_vm.quizContent && _vm.quizContent.questions.length != 0)?_c('v-col',{staticClass:"srollContainer"},[_c('v-col',{staticClass:"ma-2"},[_c('span',{staticClass:"text-h5",domProps:{"innerHTML":_vm._s(
          _vm.formatQuestionTitle(
            _vm.quizContent.questions[_vm.index].questionNo,
            _vm.quizContent.questions[_vm.index].title,
            _vm.quizContent.questions.length
          )
        )}}),(_vm.quizContent.questions[_vm.index].media)?_c('div',{staticClass:"ma-2"},[_c('v-img',{attrs:{"width":"300","height":"300","src":_vm.quizContent.questions[_vm.index].media},on:{"click":function($event){return _vm.viewImage(_vm.quizContent.questions[_vm.index])}}}),(_vm.quizContent.questions[_vm.index].media)?_c('span',{staticClass:"text-caption"},[_vm._v("If image is not clear , click on image to see full image")]):_vm._e()],1):_vm._e(),_vm._l((_vm.quizContent.questions[_vm.index].options),function(option){return _c('v-col',{key:option.number,staticClass:"pl-0"},[_c('label',{class:option.isAnswer ? 'success--text' : ''},[_c('input',{attrs:{"type":"radio","name":"option.value","disabled":""},domProps:{"value":option.number,"checked":option.isAnswer}}),_c('span',{staticClass:"ml-2 pt-0"},[_vm._v(_vm._s(option.value))])])])})],2),_c('v-row',{staticClass:"ma-4"},[_c('span',{staticClass:"font-weight-medium text-body-1"},[_vm._v(" Explanation :  ")]),_c('span',{staticClass:"purple--text text-body-2",domProps:{"innerHTML":_vm._s(_vm.quizContent.questions[_vm.index].explaination)}})]),_c('v-row',{staticClass:"ma-2"},[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","disabled":_vm.index == 0,"color":"primary"},on:{"click":function($event){return _vm.backpage()}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","disabled":_vm.index == _vm.quizContent.questions.length - 1,"color":"primary"},on:{"click":function($event){return _vm.nextPage()}}},[_vm._v("Next")]),_c('v-spacer')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }