<template>
  <v-card>
    <v-toolbar dark color="#7A52CC" elevation="2">
      <v-toolbar-title>Plans</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog persistent v-model="planDialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" class="text-body-2"
            ><v-img
              max-height="25"
              max-width="25"
              class="ma-1"
              src="../assets/Icons/Group 1.svg"
            ></v-img
            >Create Plans</v-btn
          >
        </template>

        <v-card style="border-radius: 1.3rem">
          <v-toolbar dark color="#7A52CC" elevation="2">
            <v-toolbar-title>Create Plan</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="close()">mdi-close-circle</v-icon>
          </v-toolbar>
          <v-form ref="form" v-model="createPlanValidation.valid">
            <v-container class="pb-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="3"> <span>Plan Type:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-select
                    v-model="editedPlan.type"
                    :items="planTypeOptions"
                    class="ma-0 pa-0"
                    :rules="createPlanValidation.nameRules"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 mt-n2">
                <v-col cols="3"> <span>Plan Id:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedPlan.planId"
                    :rules="createPlanValidation.nameRules"
                    class="ma-0 pa-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 mt-n2">
                <v-col cols="3"> <span>Plan Cost:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedPlan.price"
                    :rules="createPlanValidation.nameRules"
                    class="ma-0 pa-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 mt-n2">
                <v-col cols="3"> <span>Name:</span></v-col>
                <v-col cols="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="editedPlan.name"
                    :rules="createPlanValidation.nameRules"
                    class="ma-0 pa-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0 mt-n2">
                <v-col cols="3"> <span>Parameters:</span></v-col>
                <v-col class="pl-0 pb-0">
                  <v-row class="ma-0 pa-0">
                    <v-col
                      cols="5"
                      class="pa-0 ma-0 mr-8"
                      v-for="(parameter, index) in editedPlan.parameters"
                      :key="index"
                    >
                      <v-text-field
                        v-model="parameter.value"
                        type="number"
                        :label="parameter.key"
                        :rules="createPlanValidation.nameRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions class="ma-0 pa-0">
            <v-row justify="center" class="ma-0">
              <v-col cols="2" class="pt-0">
                <v-btn
                  color="#7A52CC"
                  text
                  @click="savePlan()"
                  style="
                    font-size: 1rem;
                    font-weight: 400;
                    outline-color: #7a52cc;
                    outline-width: 0.0625rem;
                    outline-style: solid;
                  "
                >
                  Done
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-container fluid>
      <v-row class="ma-1" justify="center">
        <v-col cols="4" v-for="(plan, index) in orgPlans" :key="index">
          <v-card
            width="100%"
            style="border-radius: 1.3rem"
            min-height="280"
            class="ma-1"
          >
            <v-row justify="center" class="ma-1">
              <v-card
                width="80%"
                height="100"
                style="
                  border-top-left-radius: 0rem;
                  border-top-right-radius: 0rem;
                  border-bottom-left-radius: 1.3rem;
                  border-bottom-right-radius: 1.3rem;
                "
                :color="getColor(plan)"
              >
                <v-row class="ma-2" justify="center" align-content="center">
                  <v-col>
                    <v-row justify="center">
                      <span
                        class="white--text"
                        style="font-size: 2.25rem; font-weight: 600"
                        >{{ plan.name }}
                      </span>
                    </v-row>
                    <v-row justify="center">
                      <span class="white--text">{{ plan.type }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row class="ma-1" justify="center">
              <span style="font-size: 2rem; font-weight: 600">
                <span :style="{ color: getColor(plan) }"
                  >₹ {{ plan.price }}</span
                ></span
              >
            </v-row>
            <v-container>
              <v-row justify="center" class="pl-2">
                <v-col
                  cols="6"
                  class="pa-0"
                  v-for="(parameter, index) in plan.parameters"
                  :key="index"
                  style="font-size: 0.7rem"
                  ><v-icon size="8" class="ma-1 mt-0" :color="getColor(plan)"
                    >mdi-circle</v-icon
                  >{{ parameter.key }} -
                  <span style="font-weight: 600">{{ parameter.value }}</span>
                </v-col>
              </v-row>

              <v-row class="ma-2" justify="center">
                <v-col cols="2">
                  <v-img
                    max-height="30"
                    max-width="30"
                    :src="getIcons(plan)"
                  ></v-img
                ></v-col>
                <!-- <v-col cols="2">
                <v-img max-height="30" max-width="30" :src="getDelIcons(plan)"></v-img>
              </v-col> -->
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { BASE_URL2, CREATE_PLAN, GET_ALL_PLANS } from "@/common/apiEndpoints";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { getAxiosOptions, rightClickHandler } from "@/common/utility";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      planTypeOptions: ["MONTHLY", "HALF_YEARLY", "YEARLY"],
      contextMenuObject: {},
      planDialog: false,
      orgPlans: [],
      editedPlan: {
        parameters: [
          { key: "ORG_ADMIN" },
          { key: "STUDENT" },
          { key: "TEACHER" },
          { key: "CONTENT_MANAGER" },
          { key: "RESOURCE_MANAGER" },
          { key: "COURSE" },
          { key: "CLASS" },
          { key: "CLASS_OP" },
        ],
      },
      defaultItem: {
        type: "",
        planId: "",
        price: "",
        name: "",
        parameters: [
          { key: "ORG_ADMIN" },
          { key: "STUDENT" },
          { key: "TEACHER" },
          { key: "CONTENT_MANAGER" },
          { key: "RESOURCE_MANAGER" },
          { key: "COURSE" },
          { key: "CLASS" },
          { key: "CLASS_OP" },
        ],
      },
      createPlanValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.getAllPlans();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getAllPlans() {
      this.menu = false;
      let url = GET_ALL_PLANS;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL2, this.token))
        .then((res) => {
          this.orgPlans = res.data.plans;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },

    close() {
      this.planDialog = false;
      this.$nextTick(() => {
        this.editedPlan = Object.assign({}, this.defaultItem);
      });
    },

    validate() {
      this.$refs.form.validate();
    },
    getIcons(plan) {
      if (plan.type == "MONTHLY")
        return require("../assets/Icons/Group 40.svg");
      else if (plan.type == "HALF_YEARLY")
        return require("../assets/Icons/Group 37.svg");
      else if (plan.type == "YEARLY")
        return require("../assets/Icons/Group 34.svg");
    },
    // getDelIcons(plan) {
    //   if (plan.type == "MONTHLY")
    //     return require("../assets/Icons/Group 41.svg");
    //   else if (plan.type == "HALF_YEARLY")
    //     return require("../assets/Icons/Group 38.svg");
    //   else if (plan.type == "YEARLY")
    //     return require("../assets/Icons/Group 35.svg");
    // },
    getColor(plan) {
      if (plan.type == "MONTHLY") return "#FFBB33";
      else if (plan.type == "HALF_YEARLY") return "#7A52CC";
      else if (plan.type == "YEARLY") return "#FF6666";
      else return "primary";
    },
    savePlan() {
      this.validate();
      if (this.createPlanValidation.valid) {
        let payload = {};
        let url = CREATE_PLAN;
        payload = this.editedPlan;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL2, this.token))
          .then((res) => {
            this.createPlanValidation.valid = false;
            this.getAllPlans();
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Plan created successfully");
          })
          .catch((error) => {
            console.log(error);
            this.createPlanValidation.valid = false;
            this.setOverlayValue(false);
            this.close();
          });
      }
    },
  },

  components: { ContextMenu },
};
</script>
