<template>
    <div v-bind="$props">
      <v-col class="pa-5 srollContainer">
        <v-row>
          <v-col cols="2" class="pa-0 ma-1">
            <span class="font-weight-medium text-h6 error--text">Question: </span>
          </v-col>
          <v-col class="pa-0 ma-1">
            <span class="text-body-1" v-html="codeAssignment.question"></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="pa-0 ma-1">
            <span class="font-weight-medium text-h6 error--text">Solution: </span>
          </v-col>
          <v-col class="pa-0 ma-1">
            <span class="text-body-1" v-html="codeAssignment.solution"></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="pa-0 ma-1">
            <span class="font-weight-medium text-h6 error--text"
              >Expected Output:
            </span>
          </v-col>
          <v-col class="pa-0 ma-1">
            <span
              class="text-body-1"
              v-html="codeAssignment.expectedOutput"
            ></span>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        codeAssignment: this.assignment,
      };
    },
  
    props: ["assignment"],
  };
  </script>
  <style scoped>
  .srollContainer {
    height: 100%;
    overflow-y: auto;
  }
  </style>
  