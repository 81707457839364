var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({},'div',_vm.$props,false),[_c('v-col',{staticClass:"pa-5 srollContainer"},[(
        _vm.assignmentProblem.submissions &&
        _vm.assignmentProblem.submissions.length != 0
      )?_c('v-row',{staticClass:"ma-2 justify-end"},[(_vm.assignmentProblem.submissions[0].status == 'EVALUATED')?_c('v-chip',{staticClass:"mr-3 font-weight-bold text-body-1",attrs:{"color":"primary-chip","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"src":require('@/assets/Coin.svg'),"contain":""}})],1),_vm._v(" "+_vm._s(_vm.assignmentProblem.submissions[0].earnedCoins)+"/"+_vm._s(_vm.assignmentProblem.coins)+" ")],1):_vm._e(),_c('v-chip',{staticClass:"secondary-chip",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.assignmentProblem.submissions[0].status)+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"font-weight-medium text-h6 error--text"},[_vm._v("Question: ")])]),_c('v-col',{staticClass:"pt-1 ma-1 pa-0"},[_c('span',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.question)}})])],1),_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"font-weight-medium text-h6 error--text"},[_vm._v("Answer: ")])]),(
          _vm.assignmentProblem.submissions &&
          _vm.assignmentProblem.submissions.length == 0
        )?_c('v-col',{staticClass:"mr-2"},[_c('span',[_c('quill-editor',{staticStyle:{"height":"11rem","margin-bottom":"3rem"},attrs:{"options":_vm.editorOptions},on:{"input":function($event){return _vm.validateTextAssignemnt()}},model:{value:(_vm.textAssignmentSubmission),callback:function ($$v) {_vm.textAssignmentSubmission=$$v},expression:"textAssignmentSubmission"}})],1),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.textAssignmentError))])]):_c('v-col',{staticClass:"ma-1 pb-0 pa-0"},[_c('span',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.answer)}})])],1),(
        _vm.assignmentProblem.submissions &&
        _vm.assignmentProblem.submissions.length != 0
      )?_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"font-weight-medium text-h6 error--text"},[_vm._v("Submission: ")])]),_c('v-col',{staticClass:"ma-1 mb-0 pa-0"},[_c('span',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.submissions[0].submission)}})])],1):_vm._e(),_c('v-row',{staticClass:"mt-8 ma-1"},[(
          _vm.assignmentProblem.submissions && _vm.assignmentProblem.submissions == 0
        )?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.submitTextAssignment()}}},[_vm._v("Submit")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }