<template v-bind="$props">
  <div>
    <v-row>
      <v-col cols="3">
        <v-list v-if="selectedSlots.length != 0" color="transparent">
          <v-list-item class="pa-0">
            <v-list-item-title class="text-h5"
              >Selected Slots</v-list-item-title
            >
          </v-list-item>
          <ul class="ml-3 pa-1">
            <v-list-item
              class="pa-0 mt-n6"
              v-for="(slot, index) in selectedSlots"
              :key="index"
            >
              <li>
                <v-list-item-subtitle class="text-body-1"
                  ><span class="font-weight-bold error--text"
                    >{{ slot.day.day }} -</span
                  >
                  {{ slot.openTime }} to
                  {{ slot.closeTime }}</v-list-item-subtitle
                >
              </li>
              <v-list-item-icon>
                <v-icon
                  color="error"
                  @click="removeSelectedSlot(slot.eventId, index)"
                  >mdi-delete</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
          </ul>
        </v-list>
      </v-col>
      <v-col>
        <v-sheet height="400" v-if="startDate">
          <v-calendar
            ref="calendar"
            type="week"
            color="primary"
            :events="events"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            :event-height="intervals.height"
            :interval-style="intervalStyle"
            :interval-format="intervalFormat"
            :now="startDate"
            :value="startDate"
            @mousedown:time="bookSlot"
            :weekdays="weekDaysFormat"
          >
            <template v-slot:event="{ eventParsed }">
              {{ eventParsed.start.time }} -
              {{ eventParsed.end.time }}
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { formatDateNumeric } from "@/common/utility";
import { SET_API_ERROR } from "@/store/mutations";
import { mapMutations } from "vuex";
import { FRANCHISE_MODEL, NON_FRANCHISE_MODEL } from "@/common/constants";
const intervalsDefault = {
  first: 0,
  minutes: 30,
  count: 48,
  height: 16,
};
const stylings = {
  workday(interval) {
    const inactive = this.isScheduleAvailable(interval);
    // interval.weekday === 0 ||
    //   interval.weekday === 6 ||
    //   interval.hour < 9 ||
    //   interval.hour >= 17
    return {
      backgroundColor: !inactive ? "rgba(0,0,0,0.05)" : undefined,
    };
  },
};
export default {
  data() {
    return {
      teacherAvailability: [],
      teacherSchedule: [],
      events: [],
      eventId: 0,
      selectedSlots: [],
      filteredAvailability: [],
      schedule: [],
      intervals: intervalsDefault,
      weekDaysFormat: [],
      createEvent: null,
      createStart: null,
      createEnd: null,
      weekDays: [
        { id: 0, day: "SUN" },
        { id: 1, day: "MON" },
        { id: 2, day: "TUE" },
        { id: 3, day: "WED" },
        { id: 4, day: "THU" },
        { id: 5, day: "FRI" },
        { id: 6, day: "SAT" },
      ],
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
    };
  },
  props: [
    "startDate",
    "selectedTeacherId",
    "noOfClasses",
    "teachers",
    "model",
    "extraClass",
  ],
  watch: {
    noOfClasses(newNoOfClasses) {
      this.ShowAvailability();
    },
    model: {
      handler(model) {
        if (this.model) {
          this.ShowAvailability();
        }
      },
      immediate: true,
    },
    selectedTeacherId(newTeacherId) {
      this.ShowAvailability(newTeacherId);
    },
    startDate: {
      handler(startDate) {
        if (this.startDate) {
          this.filterTeacherAvailability();
          this.getWeekDaysFormat();
        }
      },
      immediate: true,
    },
    teachers: {
      handler(teachers) {
        if (this.teachers) {
          this.ShowAvailability();
        }
      },
      immediate: true,
    },
  },
  computed: {
    intervalStyle() {
      return stylings["workday"].bind(this);
    },
  },
  mounted() {
    this.ShowAvailability();
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
    }),
    formatDateNumeric(date) {
      return formatDateNumeric(date);
    },
    intervalFormat(interval) {
      if (interval.time.split(":")[1] != "30") return interval.time;
    },
    ShowAvailability(teacherId) {
      this.selectedTeacher = this.teachers.find(
        (teacher) => teacher.teacherId == this.selectedTeacherId
      );
      if (this.selectedTeacher) {
        this.teacherAvailability = this.selectedTeacher.availability;
        this.teacherSchedule = this.selectedTeacher.schedule;
        const allDay = false;
        this.selectedSlots = [];
        this.events = [];
        this.eventId = 0;

        this.teacherAvailability.forEach((dayAvailability) => {
          dayAvailability.slots.forEach((slot) => {
            const startDateTime = new Date(slot.startDateTime);
            const endDateTime = new Date(slot.endDateTime);

            if (!slot.isAvailable) {
              this.events.push({
                start: startDateTime,
                end: endDateTime,
                color: "error",
                timed: true,
              });
            }
          });
        });
        this.filterTeacherAvailability();
      }
    },
    isSlotAvailable(interval) {
      let isAvailableSlot = false;

      for (let i = 0; i < this.teacherAvailability.length; i++) {
        const availability = this.teacherAvailability[i];

        for (let k = 0; k < availability.slots.length; k++) {
          const slot = availability.slots[k];
          const startDateTime = new Date(slot.startDateTime);
          const endDateTime = new Date(slot.endDateTime);

          // Check if the interval is within the available slot time range
          const intervalStartTime = new Date(
            interval.date +
              "T" +
              String(interval.hour).padStart(2, "0") +
              ":" +
              String(interval.minute).padStart(2, "0") +
              ":00"
          );
          const intervalEndTime = new Date(
            intervalStartTime.getTime() + 60 * 60 * 1000
          );

          if (
            intervalStartTime >= startDateTime &&
            intervalEndTime <= endDateTime
          ) {
            // if (this.editedClassRoom.model === FRANCHISE_MODEL) {
            //   return true;
            // }

            if (slot.isAvailable) {
              isAvailableSlot = true;

              for (let e = 0; e < this.events.length; e++) {
                const eventStart = new Date(this.events[e].start);
                const eventEnd = new Date(this.events[e].end);

                if (
                  intervalStartTime < eventEnd &&
                  intervalEndTime > eventStart
                ) {
                  isAvailableSlot = false;
                  break;
                }
              }
              if (isAvailableSlot) return true;
            }
          }
        }
      }
      return isAvailableSlot;
    },
    bookSlot(tms) {
      const mouseTimestamp = this.toTime(tms);
      const roundedTime = this.roundTime(mouseTimestamp, true);
      const endTime = new Date(roundedTime.getTime() + 60 * 60 * 1000);

      const interval = {
        date: this.formatDateNumeric(roundedTime),
        hour: roundedTime.getHours(),
        minute: roundedTime.getMinutes(),
        end: endTime,
      };

      if (
        !this.isSlotAvailable(interval) ||
        this.isSlotAlreadyBookedOnThisDay(tms)
      ) {
        return;
      }

      if (this.noOfClasses) {
        if (this.selectedSlots.length < this.noOfClasses) {
          this.createStart = roundedTime;
          this.createEnd = endTime;

          this.createEvent = {
            id: this.eventId,
            color: "success",
            start: this.createStart,
            end: this.createEnd,
            timed: true,
          };

          let selectedSlot = {
            day: this.weekDays.find((wd) => wd.id == tms.weekday),
            startDateTime: this.createStart.toISOString(),
            endDateTime: this.createEnd.toISOString(),
            openTime: this.createStart.toTimeString().substring(0, 5),
            closeTime: this.createEnd.toTimeString().substring(0, 5),
            eventId: this.eventId,
          };

          this.selectedSlots.push(selectedSlot);
          this.selectedSlots.sort((a, b) => a.day.id - b.day.id);
          this.events.push(this.createEvent);
          this.eventId++;
          this.$emit("update-selected-slots", this.selectedSlots);
        } else {
          this.setApiError(
            "Slots should be less than or equal to the number of classes"
          );
        }
      } else if (this.extraClass == true) {
        this.createStart = roundedTime;
        this.createEnd = endTime;

        this.createEvent = {
          id: this.eventId,
          color: "success",
          start: this.createStart,
          end: this.createEnd,
          timed: true,
        };

        let selectedSlot = {
          day: this.weekDays.find((wd) => wd.id == tms.weekday),
          startDateTime: this.createStart.toISOString(),
          endDateTime: this.createEnd.toISOString(),
          openTime: this.createStart.toTimeString().substring(0, 5),
          closeTime: this.createEnd.toTimeString().substring(0, 5),
          eventId: this.eventId,
        };

        this.selectedSlots.push(selectedSlot);
        this.selectedSlots.sort((a, b) => a.day.id - b.day.id);
        this.events.push(this.createEvent);
        this.eventId++;
        this.$emit("update-selected-slots", this.selectedSlots);
      } else {
        if (this.selectedSlots.length < 4) {
          this.createStart = roundedTime;
          this.createEnd = endTime;

          this.createEvent = {
            id: this.eventId,
            color: "success",
            start: this.createStart,
            end: this.createEnd,
            timed: true,
          };

          let selectedSlot = {
            day: this.weekDays.find((wd) => wd.id == tms.weekday),
            startDateTime: this.createStart.toISOString(),
            endDateTime: this.createEnd.toISOString(),
            openTime: this.createStart.toTimeString().substring(0, 5),
            closeTime: this.createEnd.toTimeString().substring(0, 5),
            eventId: this.eventId,
          };

          this.selectedSlots.push(selectedSlot);
          this.selectedSlots.sort((a, b) => a.day.id - b.day.id);
          this.events.push(this.createEvent);
          this.eventId++;
          this.$emit("update-selected-slots", this.selectedSlots);
        } else {
          this.setApiError("Minimum 2 and Maximum 4 slots can be booked");
        }
      }
    },
    removeSelectedSlot(eventId, slotIndex) {
      let eventIndex = this.events.findIndex((event) => event.id == eventId);
      this.events.splice(eventIndex, 1);
      this.selectedSlots.splice(slotIndex, 1);
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      let roundedTime = down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));

      return new Date(roundedTime);
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    isScheduleAvailable(interval) {
      for (let k = 0; k < this.filteredAvailability.length; k++) {
        let slot = this.filteredAvailability[k];

        // Parse startDateTime and endDateTime
        let startTime = new Date(slot.startDateTime);
        const year = startTime.getFullYear();
        const month = String(startTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(startTime.getDate()).padStart(2, "0");

        const startDate = `${year}-${month}-${day}`;
        let endTime = new Date(slot.endDateTime);

        let startHour = startTime.getHours();
        let startMinute = startTime.getMinutes();
        let endHour = endTime.getHours();
        let endMinute = endTime.getMinutes();

        if (
          interval.date == startDate &&
          ((interval.hour > startHour && interval.hour < endHour) ||
            (interval.hour == startHour && interval.minute >= startMinute) ||
            (interval.hour == endHour && interval.minute < endMinute))
        ) {
          return true;
        }
      }
      return false;
    },
    isSlotAlreadyBookedOnThisDay(interval) {
      let day = this.weekDays.find((wd) => wd.id == interval.weekday).day;
      let index = this.selectedSlots.findIndex((ss) => ss.day.day == day);
      return index != -1;
    },
    getWeekDaysFormat() {
      const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
      let dateObj = new Date(this.startDate);
      let dayName = dayNames[dateObj.getDay()];

      switch (dayName) {
        case "Sun":
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
          break;
        case "Mon":
          this.weekDaysFormat = [1, 2, 3, 4, 5, 6, 0];
          break;
        case "Tues":
          this.weekDaysFormat = [2, 3, 4, 5, 6, 0, 1];
          break;
        case "Wed":
          this.weekDaysFormat = [3, 4, 5, 6, 0, 1, 2];
          break;
        case "Thu":
          this.weekDaysFormat = [4, 5, 6, 0, 1, 2, 3];
          break;
        case "Fri":
          this.weekDaysFormat = [5, 6, 0, 1, 2, 3, 4];
          break;
        case "Sat":
          this.weekDaysFormat = [6, 0, 1, 2, 3, 4, 5];
          break;
        default:
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
      }
    },
    filterTeacherAvailability() {
      this.filteredAvailability = [];
      if (this.startDate) {
        for (let i = 0; i < this.teacherAvailability.length; i++) {
          let availability = this.teacherAvailability[i];
          for (let k = 0; k < availability.slots.length; k++) {
            let slot = availability.slots[k];

            // Parse startDateTime and endDateTime
            let startTime = new Date(slot.startDateTime);
            let endTime = new Date(slot.endDateTime);
            let classStartDate = new Date(this.startDate);
            classStartDate.setDate(classStartDate.getDate() - 1);
            let dateAfter7Days = new Date(classStartDate);
            dateAfter7Days.setDate(dateAfter7Days.getDate() + 8);
            if (startTime >= classStartDate && endTime <= dateAfter7Days)
              this.filteredAvailability.push(slot);
          }
        }
      }
    },
  },
};
</script>
<style>
.v-event-timed.error {
  z-index: 0 !important;
}
.v-event-timed.success {
  z-index: 2 !important;
}
</style>
