<template v-bind="$props">
  <div>
    <v-toolbar dark color="primary" enlevation="2">
      <v-icon class="mr-3" @click="$router.go(-1)">mdi-arrow-left</v-icon>
      <v-toolbar-title v-if="instituteName">
        {{ instituteName }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn dark text class="text-body-2" @click="openCreateClassroom()">
        New ClassRoom
      </v-btn>
    </v-toolbar>
    <v-dialog persistent v-model="extraClassDialog" max-width="60rem">
      <v-card>
        <v-card-title
          >Select the slots of extra classes you want to create</v-card-title
        >
        <v-form ref="form" v-model="extraClassValidation.valid">
          <!-- <v-card-text>
            <v-container>
              <v-text-field
                v-model="editedClassRoom.extraClass"
                type="number"
                label="No of Classes"
                :rules="extraClassValidation.noOfClassesRules"
                required
              ></v-text-field>
            </v-container>
          </v-card-text> -->
          <v-container fluid>
            <TeacherAvailabilityCalender
              :startDate="startDate"
              :selectedTeacherId="editedClassRoom.teacherId"
              :teachers="teachers"
              :extraClass="true"
              @update-selected-slots="handleSelectedSlots"
            />
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeExtraClassDialog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="extraClass()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollStdDialog" max-width="70rem">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title> Student Enrollment </v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-4 pa-2"
                  v-model="deEnrollMode"
                  @change="changeEnrollMode()"
                  label=""
                ></v-switch>
              </div>
            </template>
            <span>Remove Enrollment</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
          <v-text-field
            v-if="deEnrollMode"
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>

        <v-container fluid v-model="panel" v-if="!deEnrollMode">
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="studentFilter.mobileNo"
                  label="Mobile No"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="studentFilter.email"
                  label="Email"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedInstitute"
                  :items="institute"
                  label="Institute Name"
                  item-text="instituteName"
                  item-value="instituteId"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="studentFilter.firstName"
                  label="First Name"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="studentFilter.lastName"
                  label="Last Name"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col class="mt-1">
                <v-combobox
                  v-model="studentFilter.grade"
                  :items="gradesArray"
                  label="Grades"
                  dense
                  deletable-chips
                  chips
                  item-text="desc"
                  item-value="id"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="searchStudents()"
                >Apply</v-btn
              >
            </v-row>
          </v-form>
        </v-container>

        <v-container fluid style="max-height: 22rem; overflow-y: auto">
          <v-data-table
            v-if="studentsList.length > 0"
            v-model="selectedStudents"
            :headers="studentHeadersForEnroll"
            :items="studentsList"
            :hide-default-footer="studentsList.length <= 10"
            show-select
            item-key="studentId"
            item-value="studentId"
            :search="searchStudent"
            @toggle-select-all="selectAllStudents"
            @contextmenu:row="rightClickHandler"
          >
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    item.email
                      ? item.email.length > 20
                        ? item.email.substring(0, 20) + "..."
                        : item.email
                      : "---"
                  }}</span>
                </template>
                <span>{{ item.email ? item.email : "---" }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-row justify="center">
                <v-col>
                  <v-chip
                    small
                    :color="
                      item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                    "
                  >
                    {{ item.status }}
                  </v-chip>
                </v-col>
                <v-col>
                  <v-icon
                    v-if="!deEnrollMode && item.isEnrolled"
                    color="primary"
                    >mdi-account-check</v-icon
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
              {{ formatDateTime(item.createdOn) }}
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :disabled="
                  item.status != 'ACTIVE' || (!deEnrollMode && item.isEnrolled)
                "
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
          <CustomDataTable
            v-else
            :headers="studentHeadersForEnroll"
            :items="studentsList"
            :hide-default-footer="studentsList.length <= 10"
          />
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEnrollDialog()">Cancel</v-btn>
          <v-btn
            v-if="!deEnrollMode"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="selectStudents()"
            >Enroll</v-btn
          >
          <v-btn
            v-if="deEnrollMode"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="enrollDeEnrollStudents('UnAssign')"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollTeacherDialog" max-width="65rem">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Teacher Enrollment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
        </v-toolbar>
        <v-container fluid v-if="!deEnrollMode" v-model="panel">
          <v-form ref="form" class="p-2 m-2">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="teacherFilter.mobileNo"
                  label="Mobile No"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="teacherFilter.email"
                  label="Email"
                  color="primary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="teacherFilter.firstName"
                  label="First Name"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="teacherFilter.lastName"
                  label="Last Name"
                  color="primary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="searchTeachers()">
                Apply
              </v-btn>
            </v-row>
          </v-form>
        </v-container>
        <v-container fluid style="max-height: 22rem; overflow-y: auto">
          <v-data-table
            v-if="teachers.length > 0"
            v-model="selectedTeacher"
            :headers="teacherAssignmentHeaders"
            :items="teachers"
            :hide-default-footer="teachers.length <= 10"
            show-select
            item-key="teacherId"
            item-value="teacherId"
            :single-select="true"
            @contextmenu:row="rightClickHandler"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-row justify="center">
                <v-col>
                  <v-chip
                    small
                    :color="
                      item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                    "
                  >
                    {{ item.status }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
              {{ formatDateTime(item.createdOn) }}
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :disabled="item.status != 'ACTIVE'"
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
          <CustomDataTable
            v-else
            :headers="teacherAssignmentHeaders"
            :items="teachers"
            :hide-default-footer="teachers.length <= 10"
          />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEnrollDialog()">Cancel</v-btn>
          <v-btn
            v-if="addClassRoomDialog"
            color="primary"
            text
            @click="selectTeacher()"
            >Assign</v-btn
          >
          <v-btn v-else color="primary" text @click="enrollTeacher()"
            >Assign</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <CustomDataTable
        :headers="ClassRoomheaders"
        :items="classRooms"
        :hide-default-footer="classRooms.length < 10"
        item-key="classRoomId"
        item-value="classRoomId"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDateTime(item.startDate) }}
        </template>
        <template v-slot:[`item.teacherName`]="{ item }">
          {{ item.teacherInfo.teacherName }}
        </template>
        <template v-slot:[`item.courseId`]="{ item }">
          <router-link
            style="text-decoration: none"
            class="text-h6"
            to="/home/courses"
            >{{ getCourseNameById(item.courseId) }}</router-link
          >
        </template>
        <template v-slot:[`item.model`]="{ item }">
          <v-chip
            small
            :color="
              item.model == 'FRANCHISE' ? 'orange-chip' : 'secondary-chip'
            "
            >{{ item.model ? item.model : "--" }}</v-chip
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-row>
            <v-col class="pa-0 ma-0">
              <v-chip
                small
                :color="
                  item.status == 'SCHEDULED' ? 'success-chip' : 'secondary-chip'
                "
              >
                {{ item.status }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-0">
              <v-chip
                x-small
                :color="item.status == 'SCHEDULED' ? 'error-chip' : 'info'"
              >
                {{ "C : " + item.classes
                }}{{
                  item.extraClasses != 0 && item.extraClasses != null
                    ? " + " + item.extraClasses
                    : ""
                }}
              </v-chip>
              <v-chip
                class="mx-1"
                x-small
                :color="item.status == 'SCHEDULED' ? 'error-chip' : 'info'"
              >
                {{ "S : " + item.students.length }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-item
                :key="1"
                @click="openAddClassRoomDialog(item)"
              >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="success"  class="mr-2">mdi-eye</v-icon>
                    </template>
                    <span>Classroom Details</span>
                  </v-tooltip>
                <v-list-item-title>{{ "Classroom Details" }}</v-list-item-title>
              </v-list-item> -->
              <v-list-item :key="1" @click="openEnrollDialog(item, 'Student')">
                <v-icon class="mr-2" color="primary">
                  mdi-account-multiple-plus-outline
                </v-icon>
                <v-list-item-title>{{ " Enroll Student" }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="2"
                v-if="item.status == 'SCHEDULED'"
                @click="viewSchedule(item)"
              >
                <v-icon class="mr-2" color="success"> mdi-eye </v-icon>
                <v-list-item-title>{{ "View Schedule" }}</v-list-item-title>
              </v-list-item>
              <v-list-item :key="3" @click="openEnrollDialog(item, 'Teacher')">
                <v-icon class="mr-2" color="secondary">
                  mdi-account-plus-outline
                </v-icon>
                <v-list-item-title>{{ " Change Teacher" }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="4"
                v-if="item.status == 'SCHEDULED'"
                @click="openExtraClassDialog(item)"
              >
                <v-icon class="mr-2" color="warning">
                  mdi-google-classroom
                </v-icon>
                <v-list-item-title>{{ "Extra Class" }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </CustomDataTable>
    </v-container>
  </div>
</template>

<script>
import {
  BASE_URL,
  CLASSROOM_ASSIGN_STUDENTS,
  CLASSROOM_ASSIGN_TEACHER,
  CLASSROOM_CREATE_SCHEDULE,
  CLASSROOM_UN_ASSIGN_STUDENTS,
  CLASSROOM_UN_ASSIGN_TEACHER,
  CREATE_CLASSROOM,
  GET_ALL_CLASSROOMS,
  GET_ALL_COURSES,
  GET_ALL_INSTITUTES,
  SEARCH_STUDENT,
  SEARCH_TEACHER,
  UPDATE_CLASSROOM,
  EXTRA_CLASS,
  GET_ALL_TEACHER_AVAILABILITY,
  GET_ALL_CLASS_OPERATIONS,
} from "@/common/apiEndpoints";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  getAxiosOptions,
  formatDateTime,
  rightClickHandler,
} from "@/common/utility";
import { GET_TOKEN, GET_PARAMS_VALUE } from "@/store/getters";
import {
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
  grades,
} from "@/common/constants";
import ContextMenu from "./ContextMenu.vue";
import CustomDataTable from "./CustomDataTable.vue";
import TeacherAvailabilityCalender from "./TeacherAvailabilityCalender.vue";
export default {
  data() {
    return {
      instituteId: "",
      instituteName: "",
      contextMenuObject: {},
      addClassRoomDialog: false,
      enrollStdDialog: false,
      enrollTeacherDialog: false,
      startDateMenu: false,
      extraClassDialog: false,
      searchStudent: "",
      classRoomDialogTitle: "New Classroom",
      editedIndex: -1,
      classRooms: [],
      panel: [],
      deEnrollMode: false,
      selectedStudents: [],
      selectedInstitute: "",
      studentsList: [],
      studentFilter: {},
      institute: [],
      teacherFilter: {},
      enrolledStudentsId: [],
      gradesArray: grades,
      teachers: [],
      selectedTeacher: [],
      studentNames: [],
      classLink: "",
      teacherAssignmentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo", align: "center" },
        { text: "Email ID", value: "emailId", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      studentHeadersForEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "Institute Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      ClassRoomheaders: [
        {
          text: "Classroom Name",
          align: "start",
          value: "name",
          width: "20%",
        },
        { text: "StartDate", value: "startDate" },
        {
          text: "Teacher",
          value: "teacherName",
        },
        { text: "CourseName", value: "courseId", width: "20%" },
        {
          text: "Model",
          value: "model",
          align: "center",
        },
        { text: "Status", value: "status", align: "center" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedClassRoom: {
        courseId: "",
      },
      defaultItem: {},
      courses: [],
      notBuyableCourses: [],
      createClassRoomValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      extraClassValidation: {
        valid: false,
        noOfClassesRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "No of classes must be greater than 0",
        ],
      },
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      selectedSlots: [],
      startDate: "",
    };
  },
  mounted: function () {
    this.instituteId = this.paramsValue.instituteId;
    this.instituteName = this.paramsValue.instituteName;
    this.getAllCourses();
    this.getAllClassRoomByInstituteId();
  },

  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  components: { ContextMenu, CustomDataTable, TeacherAvailabilityCalender },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    handleSelectedSlots(newSelectedSlots) {
      this.selectedSlots = newSelectedSlots;
    },
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    openCreateClassroom() {
      this.$router.push({
        name: "CreateClassroom",
      });
    },
    viewSchedule(item) {
      var classroomParams = {};
      classroomParams.instituteId = this.instituteId;
      classroomParams.instituteName = this.instituteName;
      classroomParams.classRoomId = item.classRoomId;
      classroomParams.model = item.model;
      classroomParams.className = item.name;
      this.setParams(classroomParams);
      this.$router.push({
        name: "ClassSchedule",
      });
    },
    getAllClassOps(classRoomId) {
      let url = GET_ALL_CLASS_OPERATIONS;
      let payload = {};
      payload.classRoomId = classRoomId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let lastOperation =
            res.data.classOperations[res.data.classOperations.length - 1];
          const startDateTime = new Date(lastOperation.classInfo.startDateTime);
          startDateTime.setDate(startDateTime.getDate() + 1);
          const today = new Date();

          // Check if startDateTime is in the past
          if (startDateTime < today) {
            this.startDate = today.toISOString().substr(0, 10);
          } else {
            this.startDate = startDateTime.toISOString().substr(0, 10);
          }
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getTeacherAvailability() {
      let url = GET_ALL_TEACHER_AVAILABILITY;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teachers = res.data.teachers;
          this.filteredTeachers = this.teachers;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getAllClassRoomByInstituteId() {
      let url = GET_ALL_CLASSROOMS;
      this.setOverlayValue(true);
      let payload = {};
      payload.instituteId = this.instituteId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classRooms = res.data.classrooms;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAllCourses() {
      let url = GET_ALL_COURSES;
      this.setOverlayValue(true);
      url = url + "?status=" + this.STATUS_PUBLISHED;
      url = url + "&courseType=" + this.COURSE_TYPE_PRIVATE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          this.notBuyableCourses = this.courses.filter(
            (course) => course.buyable == false || course.buyable == null
          );
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    openAddClassRoomDialog(classRoom) {
      this.addClassRoomDialog = true;
      this.studentNames = [];
      if (classRoom) {
        this.editedIndex = 0;
        this.editedClassRoom = Object.assign({}, classRoom);
        this.editedClassRoom.startDate =
          this.editedClassRoom.startDate.substring(0, 10);
        this.classRoomDialogTitle = "Classroom Details";
      }
    },
    closeAddClassRoomDialog() {
      this.addClassRoomDialog = false;
      this.selectedTeacher = [];
      this.selectedStudents = [];
      this.editedClassRoom = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.classRoomDialogTitle = "New Classroom";
    },
    openExtraClassDialog(item) {
      this.getAllClassOps(item.classRoomId);
      this.getTeacherAvailability();
      this.extraClassDialog = true;
      this.editedClassRoom.classRoomId = item.classRoomId;
      this.editedClassRoom.teacherId = item.teacherInfo.teacherId;
    },
    closeExtraClassDialog() {
      this.extraClassDialog = false;
    },
    extraClass() {
      this.$refs.form.validate();
      if (this.extraClassValidation.valid) {
        const payload = {};
        payload.classRoomId = this.editedClassRoom.classRoomId;
        payload.schedule = this.selectedSlots.map((slot) => {
          return {
            startDateTime: slot.startDateTime,
            endDateTime: slot.endDateTime,
          };
        });
        let url = EXTRA_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.extraClassValidation.valid = false;
            this.closeExtraClassDialog();
            this.setApiSuccess("Extra Class created Successfully");
            this.setOverlayValue(false);
            this.getAllClassRoomByInstituteId();
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeExtraClassDialog();
            this.setOverlayValue(false);
          });
      }
    },

    saveClassRoom() {
      if (this.createClassRoomValidation.valid) {
        const payload = {};
        let url = "";
        payload.name = this.editedClassRoom.name.trim();
        payload.courseId = this.editedClassRoom.courseId;
        if (this.editedIndex > -1) {
          payload.classRoomId = this.editedClassRoom.classRoomId;
          url = UPDATE_CLASSROOM;
        } else {
          payload.instituteId = this.instituteId;
          payload.batchNo = this.batchNo;
          payload.teacherId = this.editedClassRoom.teacherId;
          payload.students = this.editedClassRoom.students;
          let startDate = new Date(this.editedClassRoom.startDate);
          payload.startDate = startDate.toISOString();
          url = CREATE_CLASSROOM;
        }
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createClassRoomValidation.valid = false;
            this.editedClassRoom = res.data;
            this.setOverlayValue(false);
            if (this.editedIndex > -1) {
              this.updateClassRoomData(this.editedClassRoom);
              this.setApiSuccess("Classroom updated successfully");
            } else {
              this.setApiSuccess("Classroom created successfully");
              this.getAllClassRoomByInstituteId();
            }
            this.closeAddClassRoomDialog();
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
            this.closeAddClassRoomDialog();
          });
      }
    },
    closeEnrollDialog() {
      this.deEnrollMode = false;
      this.selectedStudents = [];
      this.studentsList = [];
      this.studentFilter = {};
      this.selectedInstitute = "";
      this.teacherFilter = {};
      this.selectedTeacher = [];
      this.enrollStdDialog = false;
      this.enrollTeacherDialog = false;
      this.teachers = [];
    },
    openEnrollTeacherDialog() {
      this.enrollTeacherDialog = true;
      this.panel = [0];
    },
    // openEnrollStudentDialog() {
    //   this.enrollStdDialog = true;
    //   this.studentFilter.studentIds = [];
    //   this.getAllInstitutes();
    //   this.panel = [0];
    // },
    selectTeacher() {
      this.editedClassRoom.teacherId = this.selectedTeacher[0].teacherId;
      this.editedClassRoom.teacherName =
        this.selectedTeacher[0].firstName +
        " " +
        this.selectedTeacher[0].lastName;
      this.enrollTeacherDialog = false;
      this.selectedTeacher = [];
    },
    selectStudents() {
      this.editedClassRoom.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      console.log(this.selectedStudents);
      this.enrollDeEnrollStudents("Assign");
      this.studentNames = [];
      this.selectedStudents.forEach((element) => {
        this.studentNames.push(element.firstName + " " + element.lastName);
      });
      this.enrollStdDialog = false;
    },
    openEnrollDialog(item, assigneeType) {
      if (assigneeType === "Student") {
        this.enrollStdDialog = true;
        this.studentFilter.studentIds = item.students;
        this.getAllInstitutes();
      } else if (assigneeType === "Teacher") {
        this.enrollTeacherDialog = true;
      }
      this.editedClassRoom.classRoomId = item.classRoomId;
      this.editedClassRoom.teacherId = item.teacherId;
      this.editedClassRoom.courseId = item.courseId;
      this.panel = [0];
    },
    enrollDeEnrollStudents(action) {
      let url = "";
      if (action == "Assign") url = CLASSROOM_ASSIGN_STUDENTS;
      else if (action == "UnAssign") {
        url = CLASSROOM_UN_ASSIGN_STUDENTS;
      }
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          if (action == "Assign")
            this.setApiSuccess("Students Assigned Succesfully");
          else if (action == "UnAssign") {
            this.setApiSuccess("Students Un-Assigned Succesfully");
          }
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    searchStudents() {
      const payload = {};
      let flag = false;
      if (
        this.deEnrollMode &&
        this.studentFilter.studentIds &&
        this.studentFilter.studentIds.length > 0
      ) {
        payload.studentIds = this.studentFilter.studentIds;
        this.panel = [];
        flag = true;
      }
      if (this.studentFilter.mobileNo) {
        payload.mobileNo = this.studentFilter.mobileNo.trim();
        flag = true;
      }
      if (this.studentFilter.email) {
        payload.email = this.studentFilter.email.trim();
        flag = true;
      }
      if (this.studentFilter.firstName) {
        payload.firstName = this.studentFilter.firstName.trim();
        flag = true;
      }
      if (this.studentFilter.lastName) {
        payload.lastName = this.studentFilter.lastName.trim();
        flag = true;
      }
      if (this.selectedInstitute) {
        payload.instituteId = this.selectedInstitute;
        flag = true;
      }
      if (this.studentFilter.grade) {
        payload.grade = this.studentFilter.grade;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
            const self = this;
            self.disabledCount = 0;
            if (!this.deEnrollMode) {
              this.studentsList.map((item) => {
                if (item.status != "ACTIVE") {
                  self.disabledCount += 1;
                }
                if (
                  this.studentFilter.studentIds.includes(item.studentId) ||
                  item.enrolledCourses.includes(this.editedClassRoom.courseId)
                ) {
                  item.isEnrolled = true;
                  self.disabledCount += 1;
                } else {
                  item.isEnrolled = false;
                }
              });
            }
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
      if (!this.deEnrollMode) {
        this.panel = [0];
      }
    },
    getAllInstitutes() {
      let url = GET_ALL_INSTITUTES;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institute = res.data.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    searchTeachers() {
      const payload = {};
      let flag = false;
      if (this.teacherFilter.mobileNo) {
        payload.mobileNo = this.teacherFilter.mobileNo.trim();
        flag = true;
      }
      if (this.teacherFilter.email) {
        payload.emailId = this.teacherFilter.email.trim();
        flag = true;
      }
      if (this.teacherFilter.firstName) {
        payload.firstName = this.teacherFilter.firstName.trim();
        flag = true;
      }
      if (this.teacherFilter.lastName) {
        payload.lastName = this.teacherFilter.lastName.trim();
        flag = true;
      }
      let url = SEARCH_TEACHER;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.teachers = res.data.teachers;
            const self = this;
            self.disabledCount = 0;
            this.teachers.map((item) => {
              if (item.status != "ACTIVE") {
                self.disabledCount += 1;
              }
            });
            this.panel = [];
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      } else {
        this.panel = [];
      }
    },
    openCloseSearchPanel() {
      this.panel.length == 0 ? (this.panel = [0]) : (this.panel = []);
    },
    selectAllStudents(props) {
      if (
        this.selectedStudents.length !=
        this.studentsList.length - this.disabledCount
      ) {
        this.selectedStudents = [];
        const self = this;
        props.items.forEach((item) => {
          //  if(item.status=='ACTIVE') {
          if (item.status == "ACTIVE" && !item.isEnrolled) {
            self.selectedStudents.push(item);
          }
        });
      } else this.selectedStudents = [];
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
      if (c.length && c[0].name) return c[0].name;
      return "No Course";
    },
    enrollTeacher() {
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      let url = CLASSROOM_ASSIGN_TEACHER;
      payload.teacherId = this.selectedTeacher[0].teacherId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          this.closeEnrollDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Teacher Assigned Successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    changeEnrollMode() {
      this.selectedStudents = [];
      this.studentsList = [];
      this.searchStudents();
    },
    CloseCreateClassSchedule() {
      this.createScheduleDialog = false;
      this.editedClassRoom = Object.assign({}, this.defaultItem);
    },
    updateClassRoomData(classRoom) {
      var foundIndex = this.classRooms.findIndex(
        (cls) => cls.classRoomId == classRoom.classRoomId
      );
      this.classRooms[foundIndex] = Object.assign(
        this.classRooms[foundIndex],
        classRoom
      );
    },
  },
};
</script>
