<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>My Courses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchCourse"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row class="ma-1" v-if="teacherCourses.length > 0">
        <v-col cols="12" v-for="(course, index) in teacherCourses" :key="index">
          <v-card class="ma-1">
            <v-row class="ma-1">
              <v-col cols="3">
                <v-img
                  width="100%"
                  max-height="170"
                  style="border-radius: 0.5rem"
                  :src="course.thumbnail"
                ></v-img>
              </v-col>
              <v-col cols="6">
                <v-card-title class="pa-0">
                  <span v-if="course.name.length <= 50">{{ course.name }}</span>
                  <v-tooltip bottom v-if="course.name.length > 50">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        course.name.substring(0, 50) + "..."
                      }}</span>
                    </template>
                    <span>{{ course.name }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-container class="pa-0 mt-1">
                  <v-card-text class="pa-1">
                    <p class="pa-0 text-subtitle-2 black--text">
                      Grades :
                      <span
                        class="primary--text font-weight-medium text-body-1"
                        v-for="(grade, index) in course.grades"
                        :key="index"
                      >
                        <span v-if="course.grades.length <= 8"
                          >{{ grade
                          }}{{
                            index != course.grades.length - 1 ? ", " : ""
                          }}</span
                        >
                      </span>
                      <v-tooltip bottom v-if="course.grades.length > 4">
                        <template v-slot:activator="{ on }">
                          <span
                            v-on="on"
                            class="primary--text font-weight-medium text-body-1"
                            >{{
                              course.grades.slice(0, 8).join(", ") + "..."
                            }}</span
                          >
                        </template>
                        <span
                          v-for="(grade, index) in course.grades"
                          :key="index"
                          >{{ grade
                          }}{{
                            index != course.grades.length - 1 ? ", " : ""
                          }}</span
                        >
                      </v-tooltip>
                    </p>
                  </v-card-text>
                  <v-card-text class="pa-1 pt-0">
                    <div
                      class="pa-0"
                      v-if="
                        course.description && course.description.length < 160
                      "
                    >
                      <span v-html="course.description"> </span>
                    </div>
                    <div
                      class="pa-0"
                      v-if="
                        course.description &&
                        course.description.length >= 160 &&
                        showDescArr.length > 0 &&
                        !showDescArr[index].showFullDesc
                      "
                    >
                      <span
                        v-html="course.description.substring(0, 160) + '...'"
                      ></span>
                      <v-row justify="center">
                        <v-btn
                          color="primary"
                          small
                          text
                          @click="showDescArr[index].showFullDesc = true"
                        >
                          Read more
                        </v-btn>
                      </v-row>
                    </div>
                    <div
                      class="pa-0 ma-1"
                      v-if="
                        course.description &&
                        course.description.length >= 160 &&
                        showDescArr.length > 0 &&
                        showDescArr[index].showFullDesc
                      "
                    >
                      <span v-html="course.description"> </span>
                      <v-row justify="center">
                        <v-btn
                          color="primary"
                          small
                          text
                          @click="showDescArr[index].showFullDesc = false"
                        >
                          Read less
                        </v-btn>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-container>
              </v-col>
              <v-col
                class="d-flex flex-column justify-space-between"
                style="justify-content: center"
              >
                <v-row class="justify-center" style="align-content: center">
                  <v-btn
                    class="font-weight-bold text-body-1"
                    color="primary"
                    @click="getCourseData(course)"
                  >
                    Open
                  </v-btn>
                </v-row>
                <v-row
                  v-if="course.status == STATUS_REVIEW && !course.teacherReview"
                  class="justify-center"
                >
                  <v-btn
                    class="font-weight-bold text-body-1 mt-4"
                    color="primary-btn"
                    @click="ReviewCourseConfirm(course)"
                  >
                    Compelete Review
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <!-- <v-img
              width="100%"
              max-height="170"
              :src="course.thumbnail"
              @click="courseDialogOpen(course)"
            ></v-img> -->
            <!-- <v-row class="ma-1 pa-0">
              <v-col cols="2" class="pa-0">
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE && course.buyable
                  "
                  src="../assets/premium-quality 1.svg"
                  max-height="30"
                  max-width="30"
                ></v-img>
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE &&
                    !course.buyable &&
                    !course.isForUK
                  "
                  src="../assets/school.png"
                  max-height="30"
                  max-width="30"
                ></v-img>
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE &&
                    !course.buyable &&
                    course.isForUK == true
                  "
                  class="mt-1 ml-1"
                  src="../assets/alpha-u-circle-outline.svg"
                  max-height="30"
                  max-width="30"
                ></v-img>
              </v-col>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="loading == false">
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center text-h2 font-weight-medium">
              You don't have any course yet.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
        v-model="courseDialog"
        eager
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          persistent
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="expand_chatbot ? 'chatbot-container' : 'closed-container'"
            >
              <v-btn
                fab
                @click="minimiseChatbot($event)"
                small
                class="mt-3"
                text
              >
                <v-icon v-if="!expand_chatbot"> mdi-step-backward </v-icon>
                <v-icon v-else size="30"> mdi-step-forward </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
                class="mr-3 fixed-btn"
                style="bottom: 4.2rem"
                v-if="expand_chatbot"
              >
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-avatar v-else size="42">
                  <img src="../assets/Icons/Group_227.svg" />
                </v-avatar>
              </v-btn>
            </div>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
        <v-card class="pl-2 pr-4">
          <v-toolbar dark flat color="primary">
            <v-btn icon dark @click="closeCourseDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ courseData.name }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-title>Full Screen</v-toolbar-title>
            <v-switch
              v-model="fullScreen"
              label=""
              class="mt-6 pa-2"
              @change="makeFullScreen()"
            ></v-switch>
          </v-toolbar>
          <v-row class="pl-2 pr-5 pt-5">
            <v-col cols="3" v-if="!collapse">
              <v-list class="overflow-y-auto" height="85dvh">
                <v-list-group
                  :value="true"
                  v-for="chapter in courseData.chapters"
                  :key="chapter.chapterNo"
                  prepend-icon="$expand"
                  sub-group
                  class="ml-n6"
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-list-item-content class="ml-n7">
                        <v-list-item-title class="text-h5 font-weight-medium">{{
                          chapter.chapterName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-group
                    :value="true"
                    v-for="lesson in chapter.lessons"
                    :key="lesson.lessonNo"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content class="ml-n4">
                        <v-list-item-title
                          class="text-wrap error--text text-body-1"
                          >{{ lesson.lessonName }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Content</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="content in lesson.content"
                          :key="content.contentNo"
                          link
                          class="ml-4"
                          @click="
                            showContent(
                              content,
                              chapter.chapterNo,
                              lesson.lessonNo
                            )
                          "
                          :input-value="
                            highLightCurrentContent(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              content.contentNo
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <!-- :class="{
                            active: activeContentElement === content.source,
                          }" -->
                          <v-list-item-icon>
                            <v-icon small color="black">
                              {{ `mdi-${getIcon(content)}` }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">
                              {{ content.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.notes.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Notes</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="note in lesson.notes"
                          :key="note.noteNo"
                          link
                          class="ml-4"
                          @click="
                            showNotes(note, chapter.chapterNo, lesson.lessonNo)
                          "
                          :input-value="
                            highLightCurrentNote(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              note.noteNo
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon small color="black">
                              {{ `mdi-${getIcon(note)}` }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">{{
                              note.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.codeAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Code Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.codeAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showCode(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentCode(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon small style="color: black">
                              mdi-code-array
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">{{
                              assignment.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.quizAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Quiz Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.quizAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showQuiz(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentQuiz(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon small style="color: black">
                              mdi-lightbulb-on-50
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">{{
                              assignment.quizName
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.textAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Text Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.textAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showText(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentText(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon small style="color: black">
                              mdi-text-box-edit-outline
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">{{
                              assignment.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.codelyAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            class="text-body-2 font-weight-medium accent--text"
                            >Codely Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.codelyAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showCodely(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentCodely(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon small style="color: black">
                              mdi-code-block-brackets
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title class="text-wrap text-body-3">{{
                              assignment.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                  </v-list-group>
                </v-list-group>
              </v-list>
            </v-col>
            <!-- <v-divider vertical></v-divider> -->
            <v-col class="mainContentColor" :cols="contentViewCol">
              <div class="right-portion">
                <!-- <p v-if="content.type === CONTENT_TYPE_TEXT || (note.source != 'Notes locked' && note.type === CONTENT_TYPE_TEXT)"
                                class="ma-2">{{ content.source || note.source }}</p> -->
                <p
                  v-if="
                    content.type === CONTENT_TYPE_TEXT ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_TEXT)
                  "
                  class="ma-8 text-h5"
                  v-html="content.source || note.source"
                ></p>
                <p
                  v-if="
                    ((content.source != 'Content locked' ||
                      content.source != 'Notes locked') &&
                      content.type == CONTENT_TYPE_TEXT) ||
                    ((content.source != 'Notes locked' ||
                      content.source != 'Content locked') &&
                      content.type == CONTENT_TYPE_MEDIA &&
                      content.resource?.status == 'BLOCKED')
                  "
                  class="ma-8 text-h5"
                  v-html="content.source || note.source"
                ></p>
                <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
                <div
                  v-if="
                    (content.source != 'Content locked' &&
                      content.type === CONTENT_TYPE_MEDIA &&
                      content.resource?.status != 'BLOCKED' &&
                      content.resource?.mimeType?.includes('video')) ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_MEDIA &&
                      note.resource?.status != 'BLOCKED' &&
                      note.resource?.mimeType?.includes('video'))
                  "
                >
                  <VideoPlayer
                    :style="collapse ? fullScreenStyle : defaultVideoStyle"
                    :options="{
                      autoplay: true,
                      controls: true,
                      playbackRates: [0.5, 1, 1.5, 2],
                      sources: [
                        {
                          src: `${content.source || note.source}`,
                          type: 'video/mp4',
                        },
                      ],
                    }"
                  ></VideoPlayer>
                </div>
                <!-- Vue friendly Iframe will be shown if content.resource.mimeType  does not contain 'video' string -->
                <div
                  v-if="
                    (content.source != 'Content locked' &&
                      content.type === CONTENT_TYPE_MEDIA &&
                      content.resource?.status != 'BLOCKED' &&
                      !content.resource?.mimeType?.includes('video')) ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_MEDIA &&
                      note.resource?.status != 'BLOCKED' &&
                      !note.resource?.mimeType?.includes('video'))
                  "
                >
                  <div
                    v-if="
                      imagesArray &&
                      !currentActiveElement.resource?.mimeType?.includes(
                        'application'
                      )
                    "
                  >
                    <ImageArray
                      :style="
                        collapse ? fullScreenStyle : defaultImageArrayStyle
                      "
                      :title="content.title || note.title"
                      :images="content.images || note.images"
                    />
                  </div>
                  <div v-else>
                    <vue-friendly-iframe
                      :src="
                        (content.resource?.mimeType?.includes('application')
                          ? content.source + '#toolbar=0'
                          : content.source) ||
                        (note.resource?.mimeType?.includes('application')
                          ? note.source + '#toolbar=0'
                          : note.source)
                      "
                    ></vue-friendly-iframe>
                  </div>
                </div>
                <div>
                  <vue-friendly-iframe
                    v-if="
                      (content.source != 'Content locked' &&
                        content.type === CONTENT_TYPE_CODELY) ||
                      (note.source != 'Notes locked' &&
                        note.type === CONTENT_TYPE_CODELY)
                    "
                    :src="content.source || note.source"
                    allow="clipboard-read; clipboard-write"
                  ></vue-friendly-iframe>
                </div>

                <div v-if="showCodeAssignment == true">
                  <v-card
                    flat
                    class="pa-8 srollContainer"
                    width="100%"
                    height="100%"
                  >
                    <v-row>
                      <v-row class="mt-1 pl-3">
                        <span class="error--text text-h5 font-weight-medium"
                          >Question:
                        </span>
                        <span
                          class="ml-1 text-h5"
                          v-html="codeAssignment.question"
                        ></span>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-row class="mt-1 pl-3">
                        <span class="error--text text-h5 font-weight-medium"
                          >Solution:
                        </span>
                        <span
                          class="ml-1 text-h5"
                          v-html="codeAssignment.solution"
                        ></span>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-row class="mt-1 pl-3">
                        <span class="error--text text-h5 font-weight-medium"
                          >Expected Output:
                        </span>
                        <span
                          class="ml-1 text-h5"
                          v-html="codeAssignment.expectedOutput"
                        ></span>
                      </v-row>
                    </v-row>
                  </v-card>
                </div>
                <div v-if="showTextAssignment == true">
                  <v-card
                    flat
                    class="pa-8 srollContainer"
                    width="100%"
                    height="100%"
                  >
                    <v-row>
                      <v-row class="mt-1 pl-3">
                        <span class="error--text text-h5 font-weight-medium"
                          >Question:
                        </span>
                        <span
                          class="ml-1 text-h5"
                          v-html="textAssignment.question"
                        ></span>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-row class="mt-1 pl-3">
                        <span class="error--text text-h5 font-weight-medium"
                          >Solution:
                        </span>
                        <span
                          class="ml-1 text-h5"
                          v-html="textAssignment.answer"
                        ></span>
                      </v-row>
                    </v-row>
                  </v-card>
                </div>
                <div v-if="showCodelyAssignment">
                  <v-card
                    flat
                    class="pa-8 srollContainer"
                    width="100%"
                    height="100%"
                  >
                    <v-row class="ma-3">
                      <span class="error--text text-h5 font-weight-medium"
                        >Question Link:</span
                      >
                    </v-row>
                    <v-row class="ma-4">
                      <v-text-field
                        v-model="codelyAssignment.codelyLink"
                        prepend-inner-icon="mdi-link"
                        readonly
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                @click="
                                  copyToClipboard(codelyAssignment.codelyLink)
                                "
                                color="primary"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-row>
                    <v-row class="ma-3">
                      <span class="error--text text-h5 font-weight-medium"
                        >Solution Link:</span
                      >
                    </v-row>
                    <v-row class="ma-4">
                      <v-text-field
                        v-model="codelyAssignment.solutionLink"
                        prepend-inner-icon="mdi-link"
                        readonly
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                @click="
                                  copyToClipboard(codelyAssignment.solutionLink)
                                "
                                color="primary"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-row>
                  </v-card>
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="2000"
                    :color="snackbarColor"
                    top
                  >
                    {{ snackbarText }}
                  </v-snackbar>
                </div>
                <div class="srollContainer">
                  <v-dialog v-model="imgDialog" width="44rem">
                    <div style="position: relative">
                      <v-btn
                        color="red"
                        dark
                        width="1.3rem"
                        class="fixed-button"
                        @click="closeImage()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-img :src="quizQuestionMedia"></v-img>
                    </div>
                  </v-dialog>
                  <v-row
                    v-if="quizContent && quizContent.questions.length != 0"
                  >
                    <v-card flat class="ma-4" width="100%" height="100%">
                      <v-col class="ma-2">
                        <span
                          class="text-h5"
                          v-html="
                            formatQuestionTitle(
                              quizContent.questions[index].questionNo,
                              quizContent.questions[index].title,
                              quizContent.questions.length
                            )
                          "
                        >
                        </span>
                        <div
                          class="mt-4"
                          v-if="quizContent.questions[index].media"
                        >
                          <v-img
                            max-height="300"
                            max-width="300"
                            @click="viewImage(quizContent.questions[index])"
                            :src="quizContent.questions[index].media"
                          ></v-img>
                          <span
                            v-if="quizContent.questions[index].media"
                            class="text-body-3"
                            >If image is not clear , click on image to see full
                            image</span
                          >
                        </div>
                        <v-col
                          class="pl-0"
                          v-for="option in quizContent.questions[index].options"
                          :key="option.number"
                        >
                          <label
                            :class="option.isAnswer ? 'success--text' : ''"
                          >
                            <input
                              type="radio"
                              name="option.value"
                              :value="option.number"
                              disabled
                              :checked="option.isAnswer"
                            />
                            {{ option.value }}</label
                          >
                        </v-col>
                      </v-col>
                      <v-row class="ma-4">
                        <span class="font-weight-medium text-body-1">
                          Explanation :&nbsp;
                        </span>
                        <span
                          class="purple--text text-body-2"
                          v-html="quizContent.questions[index].explaination"
                        >
                        </span>
                      </v-row>
                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="ma-2"
                          medium
                          :disabled="index == 0"
                          color="primary"
                          @click="backpage()"
                          >Back</v-btn
                        >
                        <v-btn
                          class="ma-2"
                          medium
                          :disabled="index == quizContent.questions.length - 1"
                          color="primary"
                          @click="nextPage()"
                          >Next</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-card>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
    <ConfirmDialog
      :dialog="reviewConfirmDialog"
      :subMessage1="'Are you sure you want to complete review'"
      :subMessage2="editedCourse.name"
      @close="closeReviewDailog()"
      @confirm="reviewCourse()"
    />
  </div>
</template>
<script>
import {
  GET_TEACHER_COURSES,
  GET_COURSE_BY_ID,
  BASE_URL,
  GET_QUIZ_BY_ID,
  REVIEW_COURSE,
} from "@/common/apiEndpoints.js";
import {
  COURSE_TYPE_PRIVATE,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
  STATUS_REVIEW,
} from "@/common/constants";

import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatQuestionTitle } from "@/common/utility";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE, SET_PARAMS_VALUE } from "@/store/mutations";
import StudentQuizes from "@/components/StudentQuizes.vue";
import ChatBox from "../components/ChatBox.vue";
import VideoPlayer from "./VideoPlayer.vue";
import ImageArray from "./ImageArray2.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  data() {
    return {
      fullScreenStyle: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        height: "100%",
      },
      defaultVideoStyle: {
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        width: "75%",
      },
      defaultImageArrayStyle: {
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        width: "72%",
        padding: "5rem",
      },
      expand_chatbot: true,
      imagesArray: false,
      loading: false,
      index: 0,
      model: null,
      multiple: false,
      mandatory: false,
      fullScreen: false,
      collapse: false,
      contentViewCol: "9",
      imgDialog: false,
      quizQuestionMedia: "",
      showArrows: true,
      prevIcon: true,
      nextIcon: true,
      centerActive: false,
      showSearchField: false,
      reviewConfirmDialog: false,
      searchText: "",
      name: "",
      courses: [],
      courseTypeFilter: [],
      menu: false,
      teacherCourses: [],
      enrolledCourses: [],
      freeCourses: [],
      completedCourses: [],
      showDescArr: [],
      courseDialog: false,
      courseData: [],
      content: {},
      note: {},
      textAssignment: {},
      codeAssignment: {},
      codelyAssignment: {},
      showCodelyAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
      showSelectedContent: false,
      showSelectedNote: false,
      selected: -1,
      explaination: "",
      quizContent: { questions: [] },
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      STATUS_REVIEW: STATUS_REVIEW,
      fab: false,
      top: false,
      left: false,
      currentActiveElement: {},

      // activeContentElement: null,
      // activeNoteElement: null,
      // activeQuizAssignmentElement: null,
      // activeTextAssignmentElement: null,
      // activeCodeAssignmentElement: null,
      // activeCodelyAssignmentElement: null,

      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      editedCourse: {},
    };
  },
  components: {
    StudentQuizes,
    VideoPlayer,
    ChatBox,
    ImageArray,
    ConfirmDialog,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    this.fetchCourses();
    this.teacherCourses = this.courses;
  },

  methods: {
    minimiseChatbot(event) {
      this.expand_chatbot = !this.expand_chatbot;
      event.currentTarget.blur();
    },
    closeImage() {
      this.imgDialog = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackbar("Link copied to clipboard", "success");
        },
        () => {
          this.showSnackbar("Failed to copy link", "error");
        }
      );
    },
    showSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type === "success" ? "green" : "red";
      this.snackbar = true;
    },

    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    getIcon(content, note) {
      if (content) {
        if (content.type == CONTENT_TYPE_QUIZ) {
          return "lightbulb-on-10";
        } else if (content.type == CONTENT_TYPE_TEXT) {
          return "text-box-outline";
        } else if (content.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("pdf")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        } else {
          return "code-block-braces";
        }
      } else {
        if (note.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("pdf")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        } else {
          return "text-box-outline";
        }
      }
    },
    fetchCourses() {
      this.setOverlayValue(true);
      this.loading = true;
      let url = GET_TEACHER_COURSES;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacherCourses = res.data.courses;
          this.showDescArr = [];
          for (let i = 0; i < this.teacherCourses.length; i++) {
            let showDescProp = {};
            showDescProp.courseId = this.teacherCourses[i].courseId;
            showDescProp.showFullDesc = false;
            this.showDescArr.push(showDescProp);
          }
          for (let i = 0; i < this.teacherCourses.length; i++) {
            const grade = this.teacherCourses[i].grades;
            grade.sort((a, b) => {
              if (Number(a) < Number(b)) return -1;
              if (Number(a) > Number(b)) return 1;
              return 0;
            });
          }
          this.courses = res.data.courses;
          this.teacherCourses = this.courses;
          this.loading = false;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.loading = false;
        });
    },
    viewImage(quizQuestions) {
      this.imgDialog = true;
      this.quizQuestionMedia = quizQuestions.media;
    },
    makeFullScreen() {
      this.collapse = !this.collapse;
      this.contentViewCol == "9"
        ? (this.contentViewCol = "12")
        : (this.contentViewCol = "9");
    },
    getCourseData(course) {
      let url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courseData = res.data;
          var courseParams = {};
          courseParams.courseData = this.courseData;
          this.setParams(courseParams);
          this.$router.push({
            name: "TeacherCourseView",
          });

          this.content = this.courseData.chapters[0].lessons[0].content[0];

          const initialContent =
            this.courseData.chapters[0].lessons[0].content[0];
          this.content = initialContent;
          this.showContent(initialContent, 1, 1);
          if (initialContent.type === CONTENT_TYPE_QUIZ) {
            this.getQuizBYQuizId(initialContent.source);
            this.showContent(initialContent, 1, 1);
          }

          // if (this.content.type == CONTENT_TYPE_QUIZ) {
          //   this.getQuizBYQuizId(this.content.source);
          // }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    searchCourse() {
      if (this.searchText) {
        this.teacherCourses = this.courses.filter((course) => {
          return course.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.teacherCourses = this.courses;
      }
    },
    // courseDialogOpen(course) {
    //   this.getCourseData(course);
    //   this.courseDialog = true;
    // },
    closeCourseDialog() {
      this.courseData = [];
      this.chapters = [];
      this.content = {};
      this.note = {};
      this.textAssignment = {};
      this.codeAssignment = {};
      this.codelyAssignment = {};
      this.showSelectedNote = false;
      this.showCodeAssignment = false;
      this.showTextAssignment = false;
      this.showCodelyAssignment = false;
      (this.quizContent = { questions: [] }), (this.courseDialog = false);
    },

    showContent(content, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = content;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;

      if (
        content.type === CONTENT_TYPE_TEXT ||
        content.type === CONTENT_TYPE_MEDIA ||
        content.type === CONTENT_TYPE_CODELY
      ) {
        if (content.images && content.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        this.note = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.showSelectedNote = false;
        this.showCodeAssignment = false;
        this.showTextAssignment = false;
        this.showCodelyAssignment = false;
        this.content = content;
        content.value = true;
        this.showSelectedContent = true;
      }
      if (content.type === CONTENT_TYPE_QUIZ) {
        this.getQuizBYQuizId(content.source);
        this.content = content;
        content.value = true;
      }
    },

    showNotes(note, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = note;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      if (
        note.source === "Notes locked" ||
        note.type === CONTENT_TYPE_TEXT ||
        note.type === CONTENT_TYPE_MEDIA ||
        note.type === CONTENT_TYPE_CODELY
      ) {
        if (note.images && note.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        this.content = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.showSelectedContent = false;
        this.showCodeAssignment = false;
        this.showTextAssignment = false;
        this.showCodelyAssignment = false;
        this.note = note;
        note.value = true;
        this.showSelectedNote = true;
        if (note.type === CONTENT_TYPE_CODELY) {
          window.open(note.source, "_blank");
        }
      }
    },

    showCode(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodeAssignment = true;
      this.codeAssignment = assignment;
    },

    showQuiz(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.getQuizBYQuizId(assignment.quizId);
    },

    showText(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showTextAssignment = true;
      this.textAssignment = assignment;
    },

    showCodely(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodelyAssignment = true;
      this.codelyAssignment = assignment;
    },

    highLightCurrentContent(chapterNo, lessonNo, contentNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        contentNo == this.currentActiveElement.contentNo
      );
    },
    highLightCurrentNote(chapterNo, lessonNo, noteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        noteNo == this.currentActiveElement.noteNo
      );
    },
    highLightCurrentCode(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentQuiz(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentText(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentCodely(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },

    getQuizBYQuizId(quizId) {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = quizId;
      this.index = 0;
      // this.selected = false;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizContent = res.data;
          this.content = {};
          this.note = {};
          this.codelyAssignment = {};
          this.codeAssignment = {};
          this.textAssignment = {};
          this.showCodelyAssignment = false;
          this.showTextAssignment = false;
          this.showCodeAssignment = false;
          // this.selected = true;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    nextPage() {
      if (this.index < this.quizContent.questions.length) {
        this.index++;
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
      }
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
    reviewCourse() {
      const url = REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeReviewDailog();
          this.setApiSuccess("Course review completed successfully");
          this.fetchCourses();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeReviewDailog();
        });
    },
    ReviewCourseConfirm(item) {
      this.editedCourse = Object.assign({}, item);
      this.reviewConfirmDialog = true;
    },
    closeReviewDailog() {
      this.reviewConfirmDialog = false;
      this.$nextTick(() => {
        this.editedCourse = {};
      });
    },
  },
};
</script>
<style scoped>
.srollContainer {
  overflow-y: hidden;
  overflow-x: hidden;
}
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 5rem;
  right: 1.3rem;
}

.border {
  background-color: transparent !important;
}

.right-portion {
  overflow: hidden;
  height: 100%;
  background-color: white;
}
/* background-color: white; */

.active {
  background-color: #f0f0f0;
  /* Highlight the active content */
}
</style>
