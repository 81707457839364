<template>
  <v-card elevation="0">
    <v-row class="mt-0" justify="center">
      <p class="font-weight-bold error--text text-body-1">
        Enter Your Schedule Details (select timings in multiples of hours e.g.
        9:00 to 12:00 or 9:30 to 12:30)
      </p>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="4">
        <v-row justify="center">
          <p class="font-weight-bold text-body-1 black--text">
            Selected Slots
          </p>
        </v-row>
        <v-row class="pa-0 ml-1">
          <v-sheet height="400" class="overflow-y-auto">
            <v-list dense>
              <v-list-item v-for="(slot, index) in weekSlots" :key="index">
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    <span class="font-weight-bold error--text"
                      >{{ slot.day }} -</span
                    >
                    {{ slot.openTime }} to {{ slot.closeTime }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="error" @click="removeSelectedSlot(index)"
                    >mdi-delete</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-row>
      </v-col>

      <v-col class="ml-1 pa-0">
        <v-sheet height="500">
          <v-calendar
            color="primary"
            ref="calendar"
            type="week"
            :events="events"
            :weekdays="weekDaysFormat"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            :event-height="intervals.height"
            :interval-format="intervalFormat"
            :value="calendarValue"
            @mousedown:time="onSelectSlot"
          >
            <template v-slot:event="{ eventParsed }">
              {{ eventParsed.start.time }} - {{ eventParsed.end.time }}
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-spacer></v-spacer>
      <v-btn color="primary" class="ma-1" @click="goToPreviousStep">
        Previous
      </v-btn>
      <v-btn color="primary" class="mt-1" @click="onboard">Onboard</v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { BASE_URL, TEACHER_ONBOARD } from "@/common/apiEndpoints";
import { getAxiosOptions, formatTime } from "@/common/utility";
import axios from "axios";
import { GET_TOKEN } from "@/store/getters";
import { FETCH_USER_PROFILE, SAVE_TOKEN } from "@/store/actions";

export default {
  data() {
    return {
      calendarValue: new Date().toISOString().slice(0, 10),
      events: [],
      selectedWeek: 1,
      weekSlots: [],
      weekDaysFormat: [],
      intervals: {
        first: 0,
        minutes: 30,
        count: 48,
        height: 20,
      },
      weekData: {},
      schedule: [],
      timeSlots: [],
    };
  },
  created() {
    this.getWeekDaysFormat();
  },
  props: ["userDetails", "onboardingStep"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  watch: {
    selectedWeek(newWeek) {
      if (newWeek) {
        this.weekSlots = this.weekData[newWeek] || [];
        this.highlightSlots();
      } else {
        this.weekSlots = [];
        this.highlightSlots();
      }
    },
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
    }),
    ...mapActions({
      saveToken: `schoolModule/${SAVE_TOKEN}`,
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),
    getWeekDaysFormat() {
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let dateObj = new Date();
      let dayName = dayNames[dateObj.getDay()];

      switch (dayName) {
        case "Sun":
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
          break;
        case "Mon":
          this.weekDaysFormat = [1, 2, 3, 4, 5, 6, 0];
          break;
        case "Tue":
          this.weekDaysFormat = [2, 3, 4, 5, 6, 0, 1];
          break;
        case "Wed":
          this.weekDaysFormat = [3, 4, 5, 6, 0, 1, 2];
          break;
        case "Thu":
          this.weekDaysFormat = [4, 5, 6, 0, 1, 2, 3];
          break;
        case "Fri":
          this.weekDaysFormat = [5, 6, 0, 1, 2, 3, 4];
          break;
        case "Sat":
          this.weekDaysFormat = [6, 0, 1, 2, 3, 4, 5];
          break;
        default:
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
      }
    },
    goToPreviousStep() {
      this.$emit("update:onboardingStep", this.onboardingStep - 1);
    },
    intervalFormat(interval) {
      if (interval.time.split(":")[1] != "30") return interval.time;
    },
    isSlotValid(day, startTime, endTime) {
      const start = new Date(startTime);
      if (start.getHours() === 23 && start.getMinutes() === 30) {
        this.setApiError("Booking slot at 11:30 PM is not allowed");
        return false;
      }

      for (let slot of this.weekSlots) {
        if (
          slot.day === day &&
          ((startTime >= slot.startTime && startTime < slot.endTime) ||
            (endTime > slot.startTime && endTime <= slot.endTime) ||
            (startTime <= slot.startTime && endTime >= slot.endTime))
        ) {
          this.setApiError("Selected time slot overlaps with an existing one");
          return false;
        }
      }

      return true;
    },
    onSelectSlot(tms) {
      const mouseTime = this.toTime(tms);
      const roundedStartTime = new Date(this.roundTime(mouseTime));
      const duration = 60 * 60 * 1000;
      const roundedEndTime = new Date(roundedStartTime.getTime() + duration);
      const slot = {
        day: roundedStartTime.toLocaleDateString("en-US", { weekday: "long" }),
        openTime: this.formatTime(roundedStartTime),
        closeTime: this.formatTime(roundedEndTime),
        startTime: roundedStartTime,
        endTime: roundedEndTime,
      };

      if (this.isSlotValid(slot.day, slot.startTime, slot.endTime)) {
        const existingSlots = this.weekData[this.selectedWeek] || [];
        const mergedSlots = this.mergeAdjacentSlots([...existingSlots, slot]);

        this.weekData[this.selectedWeek] = mergedSlots;
        this.weekSlots = mergedSlots;

        this.schedule = Object.values(this.weekData)
          .flat()
          .map((slot) => ({
            startDateTime: slot.startTime.toISOString(),
            endDateTime: slot.endTime.toISOString(),
          }));

        this.highlightSlots();
      }
    },

    formatTime(date) {
      return formatTime(date);
    },
    formatISODate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    removeSelectedSlot(index) {
      const slot = this.weekData[this.selectedWeek][index];
      const startTime = this.formatISODate(slot.startTime);

      this.events = this.events.filter((event) => event.start !== startTime);

      this.weekData[this.selectedWeek].splice(index, 1);
      this.weekSlots = this.weekData[this.selectedWeek];

      this.schedule = Object.values(this.weekData)
        .flat()
        .map((slot) => ({
          startDateTime: slot.startTime.toISOString(),
          endDateTime: slot.endTime.toISOString(),
        }));
    },
    removeSelectedWeek(index) {
      this.weekData.splice(index, 1);
      this.weekData = Object.values(this.weekData);
    },

    highlightSlots() {
      this.events = this.weekSlots.map((slot) => ({
        start: this.formatISODate(slot.startTime),
        end: this.formatISODate(slot.endTime),
        name: `${slot.openTime} - ${slot.closeTime}`,
        color: "green",
      }));
    },
    roundTime(time, down = true) {
      const roundTo = 30;
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    mergeAdjacentSlots(slots) {
      slots.sort((a, b) => a.startTime - b.startTime);

      for (let i = 0; i < slots.length - 1; ) {
        const currentSlot = slots[i];
        const nextSlot = slots[i + 1];

        const currentSlotDay = currentSlot.startTime.getDay();
        const nextSlotDay = nextSlot.startTime.getDay();

        if (
          currentSlotDay === nextSlotDay &&
          currentSlot.endTime.getTime() === nextSlot.startTime.getTime()
        ) {
          currentSlot.endTime = nextSlot.endTime;
          currentSlot.closeTime = nextSlot.closeTime;

          slots.splice(i + 1, 1);
        } else {
          i++;
        }
      }

      return slots;
    },
    onboard() {
      var payload = {};
      payload.dob = this.userDetails.dob;
      payload.gender = this.userDetails.gender;
      payload.experience = this.userDetails.experience;
      payload.classLink = this.userDetails.classLink;
      payload.preferredGrades = this.userDetails.preferredGrades;
      payload.highestQualification = this.userDetails.highestQualification;
      payload.address = this.userDetails.address;
      payload.speakingLanguages = this.userDetails.speakingLanguages;

      const extendedSchedule = [];
      const weeksToExtend = 25;
      const weekDurationMs = 7 * 24 * 60 * 60 * 1000;

      this.schedule.forEach((slot) => {
        extendedSchedule.push(slot);
        for (let i = 1; i <= weeksToExtend; i++) {
          const startDate = new Date(slot.startDateTime);
          const endDate = new Date(slot.endDateTime);

          startDate.setTime(startDate.getTime() + i * weekDurationMs);
          endDate.setTime(endDate.getTime() + i * weekDurationMs);

          extendedSchedule.push({
            startDateTime: startDate.toISOString(),
            endDateTime: endDate.toISOString(),
          });
        }
      });

      payload.schedule = extendedSchedule;

      let url = TEACHER_ONBOARD;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          const storePayload = {};
          storePayload.token = res.data.newToken;
          this.saveToken(storePayload).then(() => {
            this.fetchProfile();
            this.$router.push({ path: "/home/dashboard" });
            this.setApiSuccess("Teacher onboarded successfully");
          });
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
  },
};
</script>
