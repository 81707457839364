<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <p class="pa-0 ma-1 text-h5">
          Price :
          <span class="font-weight-bold error--text">₹ {{ course.price }}</span>
        </p>
      </v-col>
      <v-col cols="2">
        <p class="pa-0 ma-1 text-h5">
          Classes :
          <span class="font-weight-bold error--text">{{
            course.noOfClasses
          }}</span>
        </p>
      </v-col>
      <v-col cols="3" class="ma-1">
        <v-select
          v-model="selectedTeacherId"
          :items="filteredTeachers"
          label="Select Teacher"
          outlined
          dense
          item-value="teacherId"
          @change="ShowAvailability()"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchTerm"
                  placeholder="Search"
                  @input="searchTeacher"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
          <template slot="selection" slot-scope="data">
            {{ data.item.firstName }} {{ data.item.lastName }}
          </template>
          <template slot="item" slot-scope="data">
            <v-list>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-avatar>
                  <v-img
                    v-if="data.item.profilePhoto"
                    :src="data.item.profilePhoto"
                  ></v-img>
                  <img
                    v-else-if="data.item.gender == 'MALE'"
                    src="../assets/Male Avatar.png"
                  />
                  <img v-else src="../assets/Female Avatar.png" alt="Jill" />
                </v-list-item-avatar>
                <v-list-item-content style="display: block !important">
                  <v-list-item-title class="text-body-2"
                    >{{ data.item.firstName }} {{ data.item.lastName }}
                    {{
                      "(" + data.item.highestQualification.qName + ")"
                    }}</v-list-item-title
                  >
                  <span class="text-caption">{{
                    data.item.gender + " (" + data.item.experience + " year)"
                  }}</span>
                  <v-rating
                    v-model="data.item.ratings"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                    readonly
                    x-small
                  ></v-rating>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedTeacherId" class="text-body-1 mt-0">
      <v-col class="pt-0 font-weight-bold">Teacher Details-</v-col>
      <v-col class="pt-0"
        ><span class="font-weight-bold error--text">Gender:</span>
        {{ selectedTeacher.gender }}</v-col
      >
      <v-col class="pt-0"
        ><span class="font-weight-bold error--text">Experience:</span>
        {{ selectedTeacher.experience }}</v-col
      >
      <v-col cols="3" class="pt-0"
        ><span class="font-weight-bold error--text">Qualification:</span>
        {{ selectedTeacher.highestQualification.qName }},
        {{ selectedTeacher.highestQualification.passingYear }}</v-col
      >
      <v-col class="pt-0"
        ><span class="font-weight-bold error--text">Languages:</span>
        <span
          v-for="(language, index) in selectedTeacher.speakingLanguages"
          :key="index"
        >
          {{ language
          }}{{
            index != selectedTeacher.speakingLanguages.length - 1 ? "," : ""
          }}
        </span></v-col
      >
    </v-row>
    <v-col class="ma-1 pa-0" v-if="selectedTeacherId">
      <TeacherAvailabilityCalender
        :startDate="new Date().toISOString().substr(0, 10)"
        :selectedTeacherId="selectedTeacherId"
        :teachers="teachers"
        @update-selected-slots="handleSelectedSlots"
        />
    </v-col>
    <v-col class="ma-1 pt-0 pl-0 d-flex justify-start">
      <v-btn
        class="gradient-btn"
        style="width: 25%"
        @click="processToPay()"
        :disabled="selectedSlots.length < 2 || selectedSlots.length > 4"
        >Proceed To Pay</v-btn
      >
    </v-col>
  </v-container>
</template>
<script>
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import { BASE_URL, GET_ALL_TEACHER_AVAILABILITY } from "@/common/apiEndpoints";
import axios from "axios";
import {
  getAxiosOptions,
  formatDateTime,
  formatTime,
  formatDateNumeric,
} from "@/common/utility";
import TeacherAvailabilityCalender from "./TeacherAvailabilityCalender.vue";
// import SampleCalendar from "@/components/SampleCalendar.vue";
export default {
  data() {
    return {
      teachers: [],
      selectedTeacherId: "",
      teacherAvailability: [],
      teacherSchedule: [],
      selectedTeacher: {},
      filteredTeachers: [],
      selectedSlots: [],
      searchTerm: "",
    };
  },
  components: { TeacherAvailabilityCalender },
  props: ["course"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.getTeacherAvailability();
    // this.getWeekDaysFormat();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getTeacherAvailability() {
      let url = GET_ALL_TEACHER_AVAILABILITY;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teachers = res.data.teachers;
          this.filteredTeachers = this.teachers;
          this.filterTeacherAvailability();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    ShowAvailability() {
      this.selectedTeacher = this.teachers.find(
        (teacher) => teacher.teacherId == this.selectedTeacherId
      );
      this.teacherAvailability = this.selectedTeacher.availability;
      this.teacherSchedule = this.selectedTeacher.schedule;
      const allDay = false;
      this.selectedSlots = [];
      this.events = [];
      this.eventId = 0;

      this.teacherAvailability.forEach((dayAvailability) => {
        dayAvailability.slots.forEach((slot) => {
          const startDateTime = new Date(slot.startDateTime);
          const endDateTime = new Date(slot.endDateTime);

          if (!slot.isAvailable) {
            this.events.push({
              start: startDateTime,
              end: endDateTime,
              color: "error",
              timed: true,
            });
          }
        });
      });
    },
    searchTeacher() {
      if (!this.searchTerm) {
        this.filteredTeachers = this.teachers;
      } else {
        this.filteredTeachers = this.teachers.filter((teacher) => {
          return (
            teacher.firstName
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1
          );
        });
      }
    },
    formatDateNumeric(date) {
      return formatDateNumeric(date);
    },
    formatTime(date) {
      return formatTime(date);
    },
    processToPay() {
      this.extendSchedule();
      this.$router.push({
        name: "CoursePayment",
        params: {
          schedule: this.schedule,
          teacherId: this.selectedTeacher.teacherId,
          course: this.course,
          redirectedFromOrder: false,
        },
      });
    },
    handleSelectedSlots(newSelectedSlots) {
      this.selectedSlots = newSelectedSlots;
    },
    extendSchedule() {
      const extendedSchedule = [...this.selectedSlots];
      const totalClassesRequired = this.course.noOfClasses;
      const selectedSlotsCount = this.selectedSlots.length;

      if (selectedSlotsCount >= totalClassesRequired) {
        this.schedule = extendedSchedule;
        return;
      }

      const slotsNeeded = totalClassesRequired - selectedSlotsCount;

      // Loop through each selected slot to extend it for the required number of slots
      for (
        let addedSlotsCount = 0;
        addedSlotsCount < slotsNeeded;
        addedSlotsCount++
      ) {
        this.selectedSlots.forEach((slot) => {
          if (extendedSchedule.length >= totalClassesRequired) return;

          const startDateTime = new Date(slot.startDateTime);
          const nextSlotDate = new Date(startDateTime);
          nextSlotDate.setDate(
            nextSlotDate.getDate() + 7 * (addedSlotsCount + 1)
          ); // Extend by weeks

          const interval = {
            date: this.formatDateNumeric(nextSlotDate),
            hour: nextSlotDate.getHours(),
            minute: nextSlotDate.getMinutes(),
          };

          // Check if the slot is available
          if (this.isSlotAvailable(interval)) {
            const newSlot = {
              startDateTime: nextSlotDate.toISOString(),
              endDateTime: new Date(
                nextSlotDate.getTime() + 60 * 60 * 1000
              ).toISOString(),
            };
            extendedSchedule.push(newSlot);
          }
        });
      }

      this.schedule = extendedSchedule;
    },
  },
};
</script>
