<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>My Courses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchCourse"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <!--  <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" title="Filter">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Public"
              color="primary"
              value="PUBLIC"
              @click="fetchCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Private"
              color="primary"
              value="PRIVATE"
              @click="fetchCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-toolbar>
    <v-container fluid>
      <v-tabs v-model="tab">
        <v-tab>Ongoing ({{ myCourses.length }})</v-tab>
        <v-tab>Recommended ({{ recommendedCourses.length }})</v-tab>
        <v-tab>Completed ({{ completedCourses.length }})</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mainContentColor">
        <v-tab-item>
          <v-row class="ma-0" v-if="myCourses.length > 0">
            <v-col cols="12" v-for="(course, index) in myCourses" :key="index">
              <v-card class="ma-1">
                <v-row class="ma-0">
                  <v-col cols="3">
                    <v-img
                      width="100%"
                      height="170"
                      style="border-radius: 0.5rem"
                      :src="course.thumbnail"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title class="ma-1 pa-0">
                      <span v-if="course.courseName.length <= 50">{{
                        course.courseName
                      }}</span>
                      <v-tooltip bottom v-if="course.courseName.length > 50">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{
                            course.courseName.substring(0, 50) + "..."
                          }}</span>
                        </template>
                        <span>{{ course.courseName }}</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-container class="pa-0 ma-1">
                      <v-row class="ma-1" v-if="course.classRoomId">
                        <v-col>
                          <v-row v-if="course.classProgressCompletion != null">
                            <v-col cols="10" class="pa-0">
                              <v-progress-linear
                                rounded
                                style="border-radius: 2.2rem"
                                height="20"
                                :value="course.classProgressCompletion"
                                color="success"
                              ></v-progress-linear>
                            </v-col>
                            <v-col cols="2" class="pa-0 mt-2">
                              <v-row justify="end">
                                <span
                                  style="font-size: 1.1rem; font-weight: 500"
                                  >{{ course.classProgressCompletion }}%</span
                                >
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row v-if="course.classProgressCompletion == null">
                            <v-chip
                              medium
                              class="error-chip"
                              style="font-size: 1rem"
                              >Yet to start!</v-chip
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-card-text class="pa-1">
                        <div
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length < 160
                          "
                        >
                          <span v-html="course.description"> </span>
                        </div>
                        <div
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            !showDescArr[index].showFullDesc
                          "
                        >
                          <span
                            v-html="
                              course.description.substring(0, 160) + '...'
                            "
                          ></span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = true"
                            >
                              Read more
                            </v-btn>
                          </v-row>
                        </div>
                        <div
                          class="pa-0 ma-1"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            showDescArr[index].showFullDesc
                          "
                        >
                          <span v-html="course.description"> </span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = false"
                            >
                              Read less
                            </v-btn>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="end"
                    class="d-flex flex-column justify-space-between"
                  >
                    <v-row class="justify-center">
                      <v-img
                        class="mt-10"
                        v-if="
                          course.courseType == COURSE_TYPE_PRIVATE &&
                          course.buyable == true
                        "
                        src="../assets/premium-quality 1.svg"
                        max-height="30"
                        max-width="30"
                      ></v-img>
                      <v-chip
                        v-if="course.courseType == COURSE_TYPE_PUBLIC"
                        class="error-chip mt-9"
                      >
                        FREE
                      </v-chip>
                    </v-row>
                    <v-row class="justify-center">
                      <v-btn
                        v-if="
                          course.classProgressCompletion == 0 ||
                          !course.courseProgressId
                        "
                        class="font-weight-bold text-body-1"
                        color="primary"
                        @click="getStudentCourseByID(course)"
                      >
                        Start
                      </v-btn>
                      <v-btn
                        v-else
                        class="font-weight-bold text-body-1"
                        color="primary"
                        @click="getStudentCourseByID(course)"
                      >
                        Resume
                      </v-btn>
                    </v-row>
                    <v-row
                      class="justify-center"
                      v-if="
                        course.status == STATUS_REVIEW && !course.studentReview
                      "
                    >
                      <v-btn
                        class="font-weight-bold text-body-1"
                        color="primary-btn"
                        @click="ReviewCourseConfirm(course)"
                      >
                        Compelete Review
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="loading == false">
            <v-col class="text-center">
              <v-row class="text-center">
                <v-col class="text-center">
                  <img height="300" src="../assets/oops.png" alt="img" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center text-h2 font-weight-medium">
                  You don't have a ongoing course yet.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="mt-0" v-if="recommendedCourses.length > 0">
            <v-col
              cols="12"
              v-for="(course, index) in recommendedCourses"
              :key="index"
            >
              <v-card class="ma-1">
                <v-row class="ma-0">
                  <v-col cols="3">
                    <v-img
                      width="100%"
                      height="170"
                      style="border-radius: 0.5rem"
                      :src="course.thumbnail"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title class="ma-1 pa-0">
                      <span v-if="course.courseName.length <= 50">{{
                        course.courseName
                      }}</span>
                      <v-tooltip bottom v-if="course.courseName.length > 50">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{
                            course.courseName.substring(0, 50) + "..."
                          }}</span>
                        </template>
                        <span>{{ course.courseName }}</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-container class="pa-0 ma-1">
                      <v-row
                        class="ma-1 pa-0"
                        v-if="course.buyable && !course.classRoomId"
                      >
                        <p class="pa-0 ma-0 text-h5">
                          Price :
                          <span class="primary--text text-h5 font-weight-medium"
                            >₹ {{ course.price }}</span
                          >
                        </p>
                      </v-row>
                      <v-card-text class="pa-1">
                        <div
                          style="font-size: 0.9rem"
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length < 160
                          "
                        >
                          <span v-html="course.description"> </span>
                        </div>
                        <div
                          style="font-size: 0.9rem"
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            !showDescArr[index].showFullDesc
                          "
                        >
                          <span
                            v-html="
                              course.description.substring(0, 160) + '...'
                            "
                          ></span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = true"
                            >
                              Read more
                            </v-btn>
                          </v-row>
                        </div>
                        <div
                          class="pa-0 ma-1"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            showDescArr[index].showFullDesc
                          "
                        >
                          <span v-html="course.description"> </span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = false"
                            >
                              Read less
                            </v-btn>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="end"
                    class="d-flex flex-column justify-space-between"
                  >
                    <v-row class="justify-center">
                      <v-img
                        class="mt-10"
                        v-if="
                          course.courseType == COURSE_TYPE_PRIVATE &&
                          course.buyable == true
                        "
                        src="../assets/premium-quality 1.svg"
                        max-height="30"
                        max-width="30"
                      ></v-img>
                      <v-chip
                        v-if="course.courseType == COURSE_TYPE_PUBLIC"
                        class="error-chip mt-9"
                      >
                        FREE
                      </v-chip>
                    </v-row>
                    <v-row class="justify-center">
                      <v-btn
                        v-if="course.buyable"
                        class="font-weight-bold text-body-1"
                        color="primary"
                        @click="buyCourse(course)"
                      >
                        Buy Now
                      </v-btn>
                      <v-btn
                        v-else
                        class="font-weight-bold text-body-1"
                        color="primary"
                        @click="getStudentCourseByID(course)"
                      >
                        Start
                      </v-btn>
                    </v-row>
                    <v-row
                      class="justify-center"
                      v-if="
                        course.status == STATUS_REVIEW && !course.studentReview
                      "
                    >
                      <v-btn
                        class="font-weight-bold text-body-1"
                        color="primary-btn"
                        @click="ReviewCourseConfirm(course)"
                      >
                        Compelete Review
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="loading == false">
            <v-col class="text-center">
              <v-row class="text-center">
                <v-col class="text-center">
                  <img height="300" src="../assets/oops.png" alt="img" />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-center text-h2 font-weight-medium">
                  We don't have a course to recommended yet.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="ma-0" v-if="completedCourses.length > 0">
            <v-col
              cols="12"
              v-for="(course, index) in completedCourses"
              :key="index"
            >
              <v-card class="ma-1">
                <v-row class="ma-0">
                  <v-col cols="3">
                    <v-img
                      width="100%"
                      height="170"
                      style="border-radius: 0.5rem"
                      :src="course.thumbnail"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title class="ma-1 pa-0">
                      <span v-if="course.courseName.length <= 50">{{
                        course.courseName
                      }}</span>
                      <v-tooltip bottom v-if="course.courseName.length > 50">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{
                            course.courseName.substring(0, 50) + "..."
                          }}</span>
                        </template>
                        <span>{{ course.courseName }}</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-container class="pa-0 ma-1">
                      <v-row
                        class="ma-2"
                        v-if="
                          course.classProgressCompletion != null ||
                          course.courseProgressCompletion != null
                        "
                      >
                        <v-col cols="10" class="pa-0">
                          <v-progress-linear
                            rounded
                            style="border-radius: 1.6rem"
                            height="20"
                            :value="
                              course.classProgressCompletion ||
                              course.courseProgressCompletion
                            "
                            color="success"
                          ></v-progress-linear>
                        </v-col>
                        <v-col cols="2" class="pa-0 mt-2">
                          <v-row justify="end">
                            <span style="font-size: 1.1rem; font-weight: 500"
                              >{{
                                course.classProgressCompletion ||
                                course.courseProgressCompletion
                              }}%</span
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-card-text class="pa-1">
                        <div
                          style="font-size: 0.9rem"
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length < 160
                          "
                        >
                          <span v-html="course.description"> </span>
                        </div>
                        <div
                          style="font-size: 0.9rem"
                          class="pa-0"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            !showDescArr[index].showFullDesc
                          "
                        >
                          <span
                            v-html="
                              course.description.substring(0, 160) + '...'
                            "
                          ></span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = true"
                            >
                              Read more
                            </v-btn>
                          </v-row>
                        </div>
                        <div
                          class="pa-0 ma-1"
                          v-if="
                            course.description &&
                            course.description.length >= 160 &&
                            showDescArr.length > 0 &&
                            showDescArr[index].showFullDesc
                          "
                        >
                          <span v-html="course.description"> </span>
                          <v-row justify="center">
                            <v-btn
                              color="primary"
                              small
                              text
                              @click="showDescArr[index].showFullDesc = false"
                            >
                              Read less
                            </v-btn>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="end"
                    class="d-flex flex-column justify-space-between"
                  >
                    <v-row class="justify-center">
                      <v-img
                        class="mt-10"
                        v-if="
                          course.courseType == COURSE_TYPE_PRIVATE &&
                          course.buyable == true
                        "
                        src="../assets/premium-quality 1.svg"
                        max-height="30"
                        max-width="30"
                      ></v-img>
                      <v-chip
                        v-if="course.courseType == COURSE_TYPE_PUBLIC"
                        class="error-chip mt-9"
                      >
                        FREE
                      </v-chip>
                    </v-row>
                    <v-row class="justify-center">
                      <v-btn
                        class="font-weight-bold text-body-1"
                        color="primary"
                        @click="getStudentCourseByID(course)"
                      >
                        Open
                      </v-btn>
                    </v-row>
                    <v-row
                      class="justify-center"
                      v-if="
                        course.status == STATUS_REVIEW && !course.studentReview
                      "
                    >
                      <v-btn
                        class="font-weight-bold text-body-1"
                        color="primary-btn"
                        @click="ReviewCourseConfirm(course)"
                      >
                        Compelete Review
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="loading == false">
            <v-col class="text-center">
              <v-row class="text-center">
                <v-col class="text-center">
                  <img height="300" src="../assets/oops.png" alt="img" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center text-h2 font-weight-medium">
                  You have not completed a course yet.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <ConfirmDialog
      :dialog="reviewConfirmDialog"
      :subMessage1="'Are you sure you want to complete review'"
      :subMessage2="studentCourse.courseName"
      @close="closeReviewDailog()"
      @confirm="reviewCourse()"
    />
  </div>
</template>
<script>
import {
  GET_STUDENT_COURSES,
  GET_STUDENT_COURSE_DETAILS,
  BASE_URL,
  REVIEW_COURSE,
} from "@/common/apiEndpoints.js";
import {
  COURSE_TYPE_PRIVATE,
  COURSE_TYPE_PUBLIC,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
  FRANCHISE_MODEL,
  NON_FRANCHISE_MODEL,
  STATUS_REVIEW,
} from "@/common/constants";

import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  data() {
    return {
      tab: null,
      showSearchField: false,
      loading: true,
      reviewConfirmDialog: false,
      searchText: "",
      courseName: "",
      courses: [],
      studentCourse: {},
      courseTypeFilter: [],
      menu: false,
      studentCourses: [],
      myCourses: [],
      recommendedCourses: [],
      completedCourses: [],
      showDescArr: [],
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      COURSE_TYPE_PUBLIC: COURSE_TYPE_PUBLIC,
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      STATUS_REVIEW: STATUS_REVIEW,
      FRANCHISE_MODEL: FRANCHISE_MODEL,
      NON_FRANCHISE_MODEL: NON_FRANCHISE_MODEL,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    this.fetchCourses();
    this.studentCourses = this.courses;
  },

  components: { ConfirmDialog },

  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    fetchCourses() {
      this.setOverlayValue(true);
      this.loading = true;
      this.menu = false;
      let url = GET_STUDENT_COURSES;
      if (
        !(
          this.courseTypeFilter.length == 0 || this.courseTypeFilter.length == 2
        )
      ) {
        if (url.indexOf("?") > 0) {
          url = url + "&courseType=" + this.courseTypeFilter[0];
        } else {
          url = url + "?courseType=" + this.courseTypeFilter[0];
        }
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourses = res.data.courses;
          this.showDescArr = [];
          for (let i = 0; i < this.studentCourses.length; i++) {
            let showDescProp = {};
            showDescProp.courseId = this.studentCourses[i].courseId;
            showDescProp.showFullDesc = false;
            this.showDescArr.push(showDescProp);
          }
          this.courses = res.data.courses;
          this.studentCourses = this.courses;
          this.filteredMyCourses();
          this.filteredRecommendedCourses();
          this.filteredCompletedCourses();
          this.setOverlayValue(false);
          this.loading = false;
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.loading = false;
        });
    },
    filteredMyCourses() {
      this.myCourses = this.studentCourses.filter(
        (course) =>
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.model == FRANCHISE_MODEL &&
            (course.classProgressCompletion != 100 ||
              course.courseProgressCompletion != 100)) ||
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.model == NON_FRANCHISE_MODEL &&
            course.classProgressCompletion != 100) ||
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.buyable == true &&
            course.classRoomId) ||
          (course.courseType == COURSE_TYPE_PUBLIC &&
            course.courseProgressId &&
            course.courseProgressCompletion != 100)
      );
      this.myCoursesCopy = this.myCourses;
    },
    filteredRecommendedCourses() {
      this.recommendedCourses = this.studentCourses.filter(
        (course) =>
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.buyable == true &&
            !course.classRoomId) ||
          (course.courseType == COURSE_TYPE_PUBLIC &&
            course.courseProgressId == null)
      );
      this.recommendedCoursesCopy = this.recommendedCourses;
    },
    filteredCompletedCourses() {
      this.completedCourses = this.studentCourses.filter(
        (course) =>
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.model == FRANCHISE_MODEL &&
            course.classProgressCompletion == 100 &&
            course.courseProgressCompletion == 100) ||
          (course.courseType == COURSE_TYPE_PUBLIC &&
            course.courseProgressCompletion == 100) ||
          (course.courseType == COURSE_TYPE_PRIVATE &&
            course.model == NON_FRANCHISE_MODEL &&
            course.classProgressCompletion == 100)
      );
      this.completedCoursesCopy = this.completedCourses;
    },
    searchCourse() {
      if (this.tab == 0) {
        if (this.searchText) {
          this.myCourses = this.myCoursesCopy.filter((course) => {
            return course.courseName
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        } else {
          this.myCourses = this.myCoursesCopy;
        }
      } else if (this.tab == 1) {
        if (this.searchText) {
          this.recommendedCourses = this.recommendedCoursesCopy.filter(
            (course) => {
              return course.courseName
                .toLowerCase()
                .includes(this.searchText.toLowerCase());
            }
          );
        } else {
          this.recommendedCourses = this.recommendedCoursesCopy;
        }
      } else if (this.tab == 2) {
        if (this.searchText) {
          this.completedCourses = this.completedCoursesCopy.filter((course) => {
            return course.courseName
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        } else {
          this.completedCourses = this.completedCoursesCopy;
        }
      }
    },
    getStudentCourseByID(course) {
      let payload = {};
      let url = GET_STUDENT_COURSE_DETAILS;
      payload.courseId = course.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourse = res.data;
          var courseParams = {};
          courseParams.courseData = this.studentCourse;
          this.setParams(courseParams);
          if (this.studentCourse.courseType == COURSE_TYPE_PRIVATE) {
            if (this.studentCourse.model == FRANCHISE_MODEL) {
              this.$router.push({
                name: "FranchiseCourseView",
              });
            } else {
              this.$router.push({
                name: "NonFranchiseCourseView",
              });
            }
          } else {
            this.$router.push({
              name: "PublicCourseView",
            });
          }
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    buyCourse(course) {
      this.$router.push({
        name: "StudentCourseDetails",
        params: { course: course },
      });
    },
    reviewCourse() {
      const url = REVIEW_COURSE;
      let payload = {};
      payload.courseId = this.studentCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeReviewDailog();
          this.setApiSuccess("Course review completed successfully");
          this.fetchCourses();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closeReviewDailog();
        });
    },
    ReviewCourseConfirm(item) {
      this.studentCourse = Object.assign({}, item);
      this.reviewConfirmDialog = true;
    },
    closeReviewDailog() {
      this.reviewConfirmDialog = false;
      this.$nextTick(() => {
        this.studentCourse = {};
      });
    },
  },
};
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
}
</style>
