var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-carousel',{staticStyle:{"height":"100%","width":"100%"},attrs:{"hide-delimiters":""},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_vm._l((_vm.images),function(image,index){return _c('v-carousel-item',{key:index,style:({
      'background-image': `url(${image.source})`,
      'background-size': 'contain',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'height': '100%',
      'width': '100%',
    })})}),_c('div',{staticClass:"number-container"},[_c('div',{staticClass:"numbering"},[_vm._v(_vm._s(_vm.currentIndex + 1)+" / "+_vm._s(_vm.images.length))])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }