import Vue from "vue";
import VueRouter from "vue-router";
import Organization from "@/components/Organization.vue";
// import Assignments from "@/components/Assignments.vue";
import Plans from "@/components/Plans.vue";
import Dashboard from "@/components/Dashboard.vue";
import Courses from "@/components/Courses.vue";
import Quiz from "@/components/Quiz.vue";
import Competitions from "@/components/Competitions.vue";
import Login from "@/components/Login.vue";
import MainContentAuth from "@/components/MainContentAuth.vue";
import User from "@/components/User.vue";
import QuizView from "@/components/QuizView.vue";
import TextView from "@/components/TextView.vue";
import QuizCompetitionView from "@/components/QuizCompetitionView.vue";
import UserProfile from "@/components/UserProfile.vue";
import Blockly from "@/components/Blockly.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import Resources from "@/components/Resources.vue";
import Institutes from "@/components/Institutes.vue";
import StudentCourses from "@/components/StudentCourses.vue";
import StudentCourseDetails from "@/components/StudentCourseDetails.vue";
import CoursePayment from "@/components/CoursePayment.vue";
import RazorPayPayment from "@/components/RazorPayPayment.vue";
import StudentClasses from "@/components/StudentClasses.vue";
import TeacherCourses from "@/components/TeacherCourses.vue";
import TeacherClasses from "@/components/TeacherClasses.vue";
import TeacherCalendar from "@/components/TeacherCalendar.vue";
import ClassSchedule from "@/components/ClassSchedule.vue";
import PracticeCoding from "@/components/PracticeCoding.vue";
import TeacherOnBoarding from "@/components/TeacherOnBoarding.vue";
import StudentOnBoarding from "@/components/StudentOnBoarding.vue";
import Coupons from "@/components/Coupons.vue";
import StudentOrders from "@/components/StudentOrders.vue";
import UKRegistrationsList from "@/components/UKRegistrationsList.vue";
import FranchiseCourseView from "@/components/FranchiseCourseView.vue";
import NonFranchiseCourseView from "@/components/NonFranchiseCourseView.vue";
import PublicCourseView from "@/components/PublicCourseView.vue";
import CoinHistory from "@/components/CoinHistory.vue";
import UkRegistration from "@/components/UkRegistration.vue";
import StudentCompetition from "@/components/StudentCompetition";
import StudentCompAnalytics from "@/components/StudentCompAnalytics";
import QuizCompetition from "@/components/QuizCompetition";
import ImageArray from "@/components/ImageArray";
import CreateClassroom from "@/components/CreateClassroom";
import CourseDetails from "@/components/CourseDetails.vue";
import CompAdminAnalytics from "@/components/CompAdminAnalytics";
import UpdateTeacherSchedule from "@/components/UpdateTeacherSchedule";
import ClassRoom from "@/components/ClassRoom";
import TeacherCourseView from "@/components/TeacherCourseView.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  { path: "/home/private-course-view/franchise", name: "FranchiseCourseView", component: FranchiseCourseView },
  { path: "/home/private-course-view/non-franchise", name: "NonFranchiseCourseView", component: NonFranchiseCourseView },
  { path: "/home/public-course-view", name: "PublicCourseView", component: PublicCourseView },
  { path: "/home/teacher-course-view", name: "TeacherCourseView", component: TeacherCourseView },
  { path: "/student-competition/start", name: "QuizCompetition", component: QuizCompetition, props:true, },
  { path: "/student-competition/details", name: "QuizCompetitionDetails", component: QuizCompetition, props:true, },
  { path: "/image-array", name: "ImageArray", component: ImageArray, props:true, },
  
  {
    path: "/home",
    component: MainContentAuth,
    children: [
      { path: "profile", name: "UserProfile", component: UserProfile },
      { path: "dashboard", name: "Dashboard", component: Dashboard },
      { path: "courses", name: "Courses", component: Courses },
      { path: "courses/details", name: "CourseDetails", component: CourseDetails },
      {
        path: "quiz",
        name: "Quiz",
        component: Quiz,
        children: [],
      },
      {
        path: "quiz-view",
        name: "QuizView",
        component: QuizView,
      },
      { path: "organization", name: "Organization", component: Organization },
      { path: "plans", name: "Plans", component: Plans },
      { path: "competitions", name: "Competitions", component: Competitions },
      { path: "competitions/analytics", name: "CompAdminAnalytics", component: CompAdminAnalytics },
      { path: "student-competition/analytics", name: "CompetitionAnalytics", component: StudentCompAnalytics },
      {
        path: "competition-view",
        name: "QuizCompetitionView",
        component: QuizCompetitionView,
      },
      { path: "text-view", name: "TextView", component: TextView },
      { path: "users", name: "Users", component: User },
      { path: "blockly", name: "Blockly", component: Blockly },
      { path: "coupons", name: "Coupons", component: Coupons },
      { path: "resources", name: "Resources", component: Resources },
      { path: "institutes", name: "Institutes", component: Institutes },
      { path: "institutes/classrooms", name: "ClassRoom", component: ClassRoom, props:true, },
      { path: "institutes/classrooms/create-classroom", name: "CreateClassroom", component: CreateClassroom, props:true, },
      {
        path: "student-courses",
        name: "StudentCourses",
        component: StudentCourses,
      },
      { path: "coin-history", name: "CoinHistory", component: CoinHistory },
      {
        path: "student-classes",
        name: "StudentClasses",
        component: StudentClasses,
      },
      {
        path: "student-classes/class-schedule",
        name: "ClassScheduleStudent",
        component: ClassSchedule,
      },
      {
        path: "student-competition",
        name: "StudentCompetition",
        component: StudentCompetition,
      },
      {
        path: "student-courses/student-course-details",
        name: "StudentCourseDetails",
        component: StudentCourseDetails,
      },
      {
        path: "student-courses/course-payment",
        name: "CoursePayment",
        component: CoursePayment,
      },
      {
        path: "student-courses/razor-pay",
        name: "RazorPayPayment",
        component: RazorPayPayment,
      },
      {
        path: "student-orders",
        name: "StudentOrders",
        component: StudentOrders,
      },
      {
        path: "practice-coding",
        name: "PracticeCoding",
        component: PracticeCoding,
      },
      {
        path: "teacher-courses",
        name: "TeacherCourses",
        component: TeacherCourses,
      },
      {
        path: "teacher-classes",
        name: "TeacherClasses",
        component: TeacherClasses,
      },
      {
        path: "teacher-classes/class-schedule",
        name: "ClassScheduleTeacher",
        component: ClassSchedule,
      },
      {
        path: "teacher-calendar",
        name: "TeacherCalendar",
        component: TeacherCalendar,
      },
      {
        path: "institute/class-schedule",
        name: "ClassSchedule",
        component: ClassSchedule,
      },
      {
        path: "teacher/on-board",
        name: "TeacherOnBoarding",
        component: TeacherOnBoarding,
      },
      {
        path: "student/on-board",
        name: "StudentOnBoarding",
        component: StudentOnBoarding,
      },
      // { path: "assignments", name: "Assignments", component: Assignments },
      {
        path: "uk/registrations",
        name: "UKRegistrationsList",
        component: UKRegistrationsList,
      },
      {
        path: "update-schedule",
        name: "UpdateTeacherSchedule",
        component: UpdateTeacherSchedule,
      },
    ],
  },
  { path: "/uk/colchester", name: "UkRegistration", component: UkRegistration },

  { path: "/login", name: "Login", component: Login },
  { path: "/reset-password", name: "ResetPassword", component: ResetPassword },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (
    !sessionStorage.getItem("token") &&
    to.name !== "Login" &&
    to.name !== "ResetPassword" &&
    to.name !== "UkRegistration"
  ) {
    // redirect the user to the login page
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
