<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Competitions</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4" class="mt-3">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchComp"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon @click="showSearchField = !showSearchField">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="All"
              color="primary"
              value="ALL"
              @click="getAllCompetitions()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="Draft"
              color="primary"
              @click="getAllCompetitions()"
              value="DRAFT"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="Published"
              color="primary"
              @click="getAllCompetitions()"
              value="PUBLISHED"
              hide-details
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog persistent v-model="newCompDialog" max-width="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" class="text-body-2">
            New Competition
          </v-btn>
        </template>
        <v-card>
          <v-card>
            <v-card-title class="primary--text">New Competition</v-card-title>
            <v-form ref="form" v-model="createCompValidation.valid">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        v-model="editedComp.name"
                        label="Competition name"
                        :rules="createCompValidation.nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="3">
                      <v-text-field
                        v-model="editedComp.totalCoins"
                        label="Total Coins"
                        :rules="createCompValidation.coinRules"
                        type="number"
                        min="0"
                        required
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedComp.coinsPerQuestion"
                        label="Per Question Coins"
                        :rules="createCompValidation.quesCoinsRules"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="filteredQuiz"
                        v-model="selectedQuizComp"
                        label="Quiz Name"
                        return-object
                        :rules="createCompValidation.quizRules"
                        dense
                        item-text="quizName"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                v-model="searchTerm"
                                placeholder="Search"
                                @input="searchQuiz"
                              ></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider />
                        </template>
                        <template slot="selection" slot-scope="data">
                          {{ data.item.quizName }}
                        </template>
                      </v-select>
                      <!-- <v-autocomplete
                        v-model="selectedQuizComp"
                        :items="quizes"
                        label="Quizes"
                        return-object
                        :rules="createCompValidation.quizRules"
                        item-text="quizName"
                      ></v-autocomplete> -->
                    </v-col>
                    <v-col cols="6">
                      <v-combobox
                        v-model="editedComp.grades"
                        :items="gradesArray"
                        label="Grades"
                        multiple
                        :rules="createCompValidation.multiValueRules"
                        deletable-chips
                        small-chips
                        item-text="desc"
                        item-value="id"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item @click="selectAll">
                            <v-list-item-action>
                              <v-icon>{{ icon() }}</v-icon>
                            </v-list-item-action>
                            <span> Select All </span>
                          </v-list-item>
                          <v-divider />
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedComp.duration"
                        label="Duration (in minutes)"
                        :rules="createCompValidation.durationRules"
                        type="number"
                        min="0"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="newCompStartDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            :rules="createCompValidation.nameRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedComp.startDate"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="newCompStartDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="newCompStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.startTime"
                            label="Start Time"
                            :rules="createCompValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="editedComp.startTime"
                          :allowed-minutes="[
                            0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                          ]"
                          @input="newCompStartTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="newCompEndDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            :rules="createCompValidation.nameRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedComp.endDate"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="newCompEndDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="newCompEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.endTime"
                            label="End Time"
                            :rules="createCompValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="editedComp.endTime"
                          :allowed-minutes="[
                            0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                          ]"
                          @input="newCompEndTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeNewComp()">
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="createCompetition()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="imgDialog" width="450">
      <v-card>
        <v-img
          v-if="!selectedItem.preview && !selectedItem.thumbnail"
          class="align-start text-white"
          height="50"
          src="@/assets/Avatar_sales.png"
          contain
        ></v-img>
        <v-img
          v-if="!selectedItem.preview && selectedItem.thumbnail"
          :src="selectedItem.thumbnail"
        ></v-img>
        <v-img v-if="selectedItem.preview" :src="selectedItem.preview"></v-img>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollStdDialog" max-width="70rem">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title> Student Enrollment </v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-4 pa-2"
                  v-model="deEnrollMode"
                  @change="changeEnrollMode()"
                  label=""
                  :disabled="selectedCompetition?.statusText !== 'Upcoming' && selectedCompetition?.status === STATUS_PUBLISHED"
                ></v-switch>
              </div>
            </template>
            <span>Remove Enrollment</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
          <v-text-field
            v-if="deEnrollMode"
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>

        <v-container fluid v-model="panel" v-if="!deEnrollMode">
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="studentFilter.mobileNo"
                  label="Mobile No"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="studentFilter.email"
                  label="Email"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedInstitute"
                  :items="institute"
                  label="Institute Name"
                  item-text="instituteName"
                  item-value="instituteId"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="studentFilter.firstName"
                  label="First Name"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="studentFilter.lastName"
                  label="Last Name"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col class="mt-1">
                <v-combobox
                  v-model="studentFilter.grade"
                  :items="gradesArray"
                  label="Grades"
                  dense
                  deletable-chips
                  chips
                  item-text="desc"
                  item-value="id"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="searchStudents()"
                >Apply</v-btn
              >
            </v-row>
          </v-form>
        </v-container>

        <v-container fluid style="max-height: 22rem; overflow-y: auto">
          <v-data-table
            v-if="studentsList.length > 0"
            v-model="selectedStudents"
            :headers="studentHeadersForEnroll"
            :items="studentsList"
            :hide-default-footer="studentsList.length <= 10"
            show-select
            item-key="studentId"
            item-value="studentId"
            :search="searchStudent"
            @toggle-select-all="selectAllStudents"
            @contextmenu:row="rightClickHandler"
          >
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    item.email
                      ? item.email.length > 20
                        ? item.email.substring(0, 20) + "..."
                        : item.email
                      : "---"
                  }}</span>
                </template>
                <span>{{ item.email ? item.email : "---" }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-row justify="center">
                <v-col>
                  <v-chip
                    small
                    :color="
                      item.status == 'ACTIVE' ? 'success-chip' : 'error-chip'
                    "
                  >
                    {{ item.status }}
                  </v-chip>
                </v-col>
                <v-col>
                  <v-icon
                    v-if="!deEnrollMode && item.isEnrolled"
                    color="primary"
                    >mdi-account-check</v-icon
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
              {{ formatDateTime(item.createdOn) }}
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :disabled="
                  item.status != 'ACTIVE' || (!deEnrollMode && item.isEnrolled)
                "
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
          <CustomDataTable
            v-else
            :headers="studentHeadersForEnroll"
            :items="studentsList"
            :hide-default-footer="studentsList.length <= 10"
          />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEnrollStudentDialog()"
            >Cancel</v-btn
          >
          <v-btn
            v-if="!deEnrollMode"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="enrollStudents()"
            >Enroll</v-btn
          >
          <v-btn
            v-if="deEnrollMode"
            :disabled="selectedStudents.length == 0 || selectedCompetition?.statusText !== 'Upcoming' && selectedCompetition?.status === STATUS_PUBLISHED"
            color="primary"
            text
            @click="unEnrollStudents()"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <CustomDataTable
        :headers="headers"
        :items="filteredComps"
        :hide-default-footer="filteredComps.length <= 8"
        :search="searchText"
        :item-key="keyField"
      >
        <template v-slot:[`item.thumbnail`]="{ item }">
          <v-card flat>
            <v-badge icon bottom avatar overlap @click.native.stop>
              <template v-slot:badge>
                <v-icon @click="addThumbnail(item)">mdi-camera</v-icon>
                <input
                  :ref="'uploader-' + item.quizCompId"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  @change="onFileChanged($event, item)"
                />
              </template>
              <v-avatar
                v-if="!item.preview && !item.thumbnail"
                style="border: 0.1rem solid #714eff"
                size="50"
                @click="openImgDialog(item)"
              >
                <v-img
                  class="align-start text-white"
                  height="55"
                  src="@/assets/Avatar_sales.png"
                  contain
                ></v-img>
              </v-avatar>
              <v-avatar
                v-else-if="!item.preview && item.thumbnail"
                style="border: 0.1rem solid #714eff"
                size="55"
                @click="openImgDialog(item)"
              >
                <v-img :src="item.thumbnail"> </v-img>
              </v-avatar>
              <v-avatar
                style="border: 0.1rem solid #714eff"
                v-else
                size="55"
                @click="openImgDialog(item)"
              >
                <v-img :src="item.preview"> </v-img>
              </v-avatar>
            </v-badge>
            <v-card-actions v-if="item.showUploadBtns">
              <v-btn color="primary" x-small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="uploadThumbnail(item)"
                      >mdi-upload</v-icon
                    >
                  </template>
                  <span>Upload</span>
                </v-tooltip>
              </v-btn>
              <v-btn color="primary" x-small icon @click.native.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error" @click="cancelUpload(item)"
                      >mdi-cancel</v-icon
                    >
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="text-body-1 font-weight-medium">{{ item.name }}</span
          ><br />
          <span class="text-body-3"
            ><span class="secondary--text">Quiz Name:</span>
            {{ item.quizes[0].quizName }}</span
          >
        </template>
        <template v-slot:item.grade="{ item }">
          <div v-if="filteredGrades(item.grade).length > 0">
            <span
              v-for="(grade, index) in filteredGrades(item.grade)"
              :key="index"
            >
              {{ grade
              }}<span v-if="index < filteredGrades(item.grade).length - 1"
                >,
              </span>
            </span>
          </div>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDateTime(item.startDate) }}
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          {{ formatDateTime(item.endDate) }}
        </template>
        <template v-slot:[`item.duration`]="{ item }">
          {{ item.duration ? item.duration + " mins" : "-" }}
        </template>
        <template v-slot:[`item.coinsPerQuestion`]="{ item }">
          {{ item.coinsPerQuestion ? item.coinsPerQuestion : "-" }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <template v-if="item.status === STATUS_PUBLISHED">
            <v-row class="m-0" justify="center">
              <v-chip class="mb-1" small :color="item.statusColor">
                {{ item.statusText }}
              </v-chip>
            </v-row>
            <v-row class="m-0" justify="center">
              <v-chip
                small
                :color="
                  item.status == STATUS_PUBLISHED
                    ? 'success-chip'
                    : 'error-chip'
                "
              >
                {{ item.status }}
              </v-chip>
            </v-row>
          </template>
          <template v-else>
            <v-chip
              small
              :color="
                item.status == STATUS_PUBLISHED ? 'success-chip' : 'error-chip'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item @click="openEnrollStudentDialog(item)">
                  <v-icon
                    class="mr-2"
                    :color="
                      item.statusText != 'Upcoming' &&
                      item.status == STATUS_PUBLISHED
                        ? 'green'
                        : 'primary'
                    "
                  >
                    {{
                      item.statusText != "Upcoming" &&
                      item.status == STATUS_PUBLISHED
                        ? "mdi-eye"
                        : "mdi-account-multiple-plus-outline"
                    }}
                  </v-icon>
                  <span>
                    {{
                      item.statusText != "Upcoming" &&
                      item.status == STATUS_PUBLISHED
                        ? "View Students"
                        : "Enroll Students"
                    }}
                  </span>
                </v-list-item>

                <v-list-item
                  @click="openEditDialog(item)"
                  v-if="item.status != STATUS_PUBLISHED"
                >
                  <v-icon class="mr-2" color="warning">mdi-pencil</v-icon>
                  <span>Edit</span>
                </v-list-item>
                <v-list-item
                  @click="openDeleteDialog(item)"
                  v-if="item.status != STATUS_PUBLISHED"
                >
                  <v-icon class="mr-2" color="error">mdi-delete</v-icon>
                  <span>Delete</span>
                </v-list-item>
                <v-list-item
                  @click="openPublishDialog(item)"
                  v-if="item.status != STATUS_PUBLISHED"
                >
                  <v-icon class="mr-2" color="accent"> mdi-file-upload </v-icon>
                  <span>Publish</span>
                </v-list-item>
              </v-list-item-group>
              <v-list-item
                v-if="
                  item.status === STATUS_PUBLISHED &&
                  item.statusText === 'Completed'
                "
                @click="viewAnalytics(item)"
              >
                <v-icon class="mr-2" color="success"
                  >mdi-information-variant-box-outline</v-icon
                >
                <span>View Analytics</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </CustomDataTable>
    </v-container>
    <ConfirmDialog
      :dialog="publishConfirmDialog"
      :subMessage1="'Are you sure you want to publish'"
      :subMessage2="selectedCompetition?.name"
      @close="closePublishDialog()"
      @confirm="publishCompetition()"
    />
    <v-dialog persistent v-model="editCompDialog" max-width="55rem">
      <v-card>
        <v-card>
          <v-card-title class="primary--text">Edit Competition</v-card-title>
          <v-form ref="form" v-model="createCompValidation.valid">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedComp.name"
                      label="Competition name"
                      :rules="createCompValidation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="filteredQuiz"
                      v-model="selectedQuizComp"
                      label="Quiz Name"
                      return-object
                      :rules="createCompValidation.quizRules"
                      dense
                      item-text="quizName"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="searchTerm"
                              placeholder="Search"
                              @input="searchQuiz"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                      <template slot="selection" slot-scope="data">
                        {{ data.item.quizName }}
                      </template>
                    </v-select>
                    <!-- <v-autocomplete
                      v-model="selectedQuizComp"
                      :items="quizes"
                      label="Select New Quiz"
                      return-object
                      :rules="createCompValidation.quizRules"
                      item-text="quizName"
                    ></v-autocomplete> -->
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="editedComp.grades"
                      :items="gradesArray"
                      label="Grades"
                      multiple
                      :rules="createCompValidation.multiValueRules"
                      deletable-chips
                      small-chips
                      item-text="desc"
                      item-value="id"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="selectAll">
                          <v-list-item-action>
                            <v-icon color="primary">{{ icon() }}</v-icon>
                          </v-list-item-action>
                          <span> Select All </span>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedComp.duration"
                      label="Duration (in minutes)"
                      :rules="createCompValidation.durationRules"
                      type="number"
                      min="0"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="editCompStartDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.startDate"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          :rules="createCompValidation.nameRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedComp.startDate"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="editCompStartDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="editCompStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.startTime"
                          label="Start Time"
                          :rules="createCompValidation.nameRules"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="editedComp.startTime"
                        :allowed-minutes="[
                          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                        ]"
                        @input="editCompStartTimeMenu = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="editCompEndDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.endDate"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          :rules="createCompValidation.nameRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedComp.endDate"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="editCompEndDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="editCompEndTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.endTime"
                          label="End Time"
                          :rules="createCompValidation.nameRules"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="editedComp.endTime"
                        :allowed-minutes="[
                          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                        ]"
                        @input="editCompEndTimeMenu = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeEditComp">Cancel</v-btn>
              <v-btn color="primary" text @click="saveEditedCompetition"
                >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :dialog="deleteConfirmDialog"
      :subMessage1="'Are you sure you want to delete'"
      :subMessage2="editedCompetition?.name"
      @close="closeDeleteDialog()"
      @confirm="deleteCompetition()"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  BASE_URL,
  PUBLISH_COMPETETION,
  GET_ALL_QUIZ_COMPETITIONS,
  GET_ALL_QUIZ,
  CREATE_COMPETETION,
  DELETE_COMPETETION,
  SAVE_COMPETETION_DETAILS,
  GET_COMPETETION_BY_ID,
  ADD_COMPETITION_THUMBNAIL,
  SEARCH_QUIZ,
  SEARCH_STUDENT,
  COMPETETION_ENROLL_STUDENTS,
  COMPETETION_UNENROLL_STUDENTS,
  GET_ALL_INSTITUTES,
} from "@/common/apiEndpoints";
import {
  getAxiosOptions,
  rightClickHandler,
  formatDateTime,
  formatTime,
} from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  grades,
} from "@/common/constants";
import CustomDataTable from "./CustomDataTable.vue";
export default {
  data() {
    return {
      selectedStudents: [],
      institute: [],
      studentsList: [],
      selectedInstitute: "",
      studentFilter: {},
      searchStudent: "",
      panel: [],
      deEnrollMode: false,
      enrollStdDialog: false,
      quizCompetitions: [],
      filteredComps: [],
      menu: false,
      showSearchField: false,
      searchText: "",
      compStatusFilter: [],
      // startDateMenu: false,
      // endDateMenu: false,
      // startTimeMenu: false,
      // endTimeMenu: false,

      newCompStartDateMenu: false,
      newCompStartTimeMenu: false,
      newCompEndDateMenu: false,
      newCompEndTimeMenu: false,
      editCompStartDateMenu: false,
      editCompStartTimeMenu: false,
      editCompEndDateMenu: false,
      editCompEndTimeMenu: false,
      headers: [
        {
          align: "start",
          sortable: false,
          value: "thumbnail",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Start Date", value: "startDate", align: "center" },
        { text: "End Date", value: "endDate", align: "center" },
        { text: "Grades", value: "grades", align: "center" },
        { text: "Duration", value: "duration", align: "center" },
        {
          text: "CPQ",
          value: "coinsPerQuestion",
          align: "center",
        },
        { text: "Status", value: "status", align: "center" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      keyField: "quizId",
      publishConfirmDialog: false,
      selectedCompetition: null,
      currentDate: new Date(),
      file: "",
      preview: "",
      showUploadBtns: false,
      imgDialog: false,
      detailDialog: false,
      deleteConfirmDialog: false,
      newCompDialog: false,
      editCompDialog: false,
      editedCompetition: null,
      selectedItem: {},

      createCompValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        durationRules: [
          (v) => !!v || "This is required",
          (v) => Number(0) < v || "Duration cannot be 0",
          (v) => Number(181) > v || "Duration cannot be greater than 3 hours",
        ],
        coinRules: [(v) => !!v || v > 0 || "Coins should be greater than 0"],
        quesCoinsRules: [
          (v) => !!v || "This is required",
          (v) => Number(0) < v || "Coins should be greater than 0",
          (v) => Number(50) >= v || "Coin must be less than 50",
        ],
        multiValueRules: [(v) => v.length > 0 || "Select atleast 1"],
        quizRules: [(v) => !!v || "Select quiz"],
      },
      editedIndex: -1,
      editedComp: {
        name: "",
        grades: [],
        quizes: [],
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        // totalCoins: 0,
        coinsPerQuestion: 0,
      },
      defaultItem: {
        name: "",
        grades: [],
        quizes: [],
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        // totalCoins: 0,
        coinsPerQuestion: 0,
      },
      menu2: false,
      menu3: false,
      searchFilter: {
        active: false,
        status: "",
        onboarded: true,
        dateRange: {},
      },
      studentHeadersForEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "Institute Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      gradesArray: grades,
      quizes: [],
      filteredQuiz: [],
      searchTerm: "",
      selectedQuizComp: {},
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    filteredGrades() {
      return (grades) => grades.filter((grade) => grade !== null);
    },
  },
  components: {
    ConfirmDialog,
    CustomDataTable,
  },
  mounted: function () {
    this.getAllCompetitions();
    // this.filteredComps = this.quizCompetitions;
    this.getAllQuiz(false);
    this.interval = setInterval(() => {
      this.updateCompetitionStatuses();
      // this.currentDate = new Date();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    openEnrollStudentDialog(item) {
      this.selectedCompetition = item;
      this.studentFilter.studentIds = item.students;
      this.selectedInstitute = "";
      this.studentsList = [];
      this.getAllInstitutes();
      this.panel = [0];
      if (item.statusText != "Upcoming" && item.status == STATUS_PUBLISHED) {
        this.deEnrollMode = true;
        this.changeEnrollMode();
      } else {
        this.deEnrollMode = false;
      }
      this.enrollStdDialog = true;
    },
    closeEnrollStudentDialog() {
      this.deEnrollMode = false;
      this.selectedStudents = [];
      this.studentsList = [];
      this.studentFilter = {};
      this.selectedInstitute = "";
      this.enrollStdDialog = false;
    },
    getAllInstitutes() {
      let url = GET_ALL_INSTITUTES;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institute = res.data.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    // selectStudents() {
    //   this.editedClassRoom.students = this.selectedStudents.map((std) => {
    //     return std.studentId;
    //   });
    //   this.studentNames = [];
    //   this.selectedStudents.forEach((element) => {
    //     this.studentNames.push(element.firstName + " " + element.lastName);
    //   });
    //   this.enrollStdDialog = false;
    // },
    enrollStudents() {
      const payload = {};
      payload.compId = this.selectedCompetition.quizCompId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      let url = COMPETETION_ENROLL_STUDENTS;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedCompetition.students = res.data.students;
          this.closeEnrollStudentDialog();
          this.setApiSuccess("Student Enrolled Successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollStudentDialog();
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    unEnrollStudents() {
      const payload = {};
      payload.compId = this.selectedCompetition.quizCompId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      let url = COMPETETION_UNENROLL_STUDENTS;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedCompetition.students = res.data.students;
          this.closeEnrollStudentDialog();
          this.setApiSuccess("Student Un-Enrolled Successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollStudentDialog();
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    selectAllStudents(props) {
      if (
        this.selectedStudents.length !=
        this.studentsList.length - this.disabledCount
      ) {
        this.selectedStudents = [];
        const self = this;
        props.items.forEach((item) => {
          if (item.status == "ACTIVE" && !item.isEnrolled) {
            self.selectedStudents.push(item);
          }
        });
      } else this.selectedStudents = [];
    },
    changeEnrollMode() {
      this.selectedStudents = [];
      this.studentsList = [];
      this.searchStudents();
    },
    openCloseSearchPanel() {
      this.panel.length == 0 ? (this.panel = [0]) : (this.panel = []);
    },
    searchStudents() {
      const payload = {};
      let flag = false;
      if (
        this.deEnrollMode &&
        this.studentFilter.studentIds &&
        this.studentFilter.studentIds.length > 0
      ) {
        payload.studentIds = this.studentFilter.studentIds;
        this.panel = [];
        flag = true;
      }
      if (this.studentFilter.mobileNo) {
        payload.mobileNo = this.studentFilter.mobileNo.trim();
        flag = true;
      }
      if (this.studentFilter.email) {
        payload.email = this.studentFilter.email.trim();
        flag = true;
      }
      if (this.studentFilter.firstName) {
        payload.firstName = this.studentFilter.firstName.trim();
        flag = true;
      }
      if (this.studentFilter.lastName) {
        payload.lastName = this.studentFilter.lastName.trim();
        flag = true;
      }
      if (this.selectedInstitute) {
        payload.instituteId = this.selectedInstitute;
        flag = true;
      }
      if (this.studentFilter.grade) {
        payload.grade = this.studentFilter.grade;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
            const self = this;
            self.disabledCount = 0;
            if (!this.deEnrollMode) {
              this.studentsList.forEach((item) => {
                if (item.status != "ACTIVE") {
                  self.disabledCount += 1;
                }
                if (this.studentFilter.studentIds.includes(item.studentId)) {
                  item.isEnrolled = true;
                  self.disabledCount += 1;
                } else {
                  item.isEnrolled = false;
                }
              });
            }
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
      if (!this.deEnrollMode) {
        this.panel = [0];
      }
    },
    updateCompetitionStatuses() {
      this.filteredComps = this.filteredComps.map((comp) => ({
        ...comp,
        statusText: this.statusText(comp),
        statusColor: this.statusColor(comp),
      }));
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    viewAnalytics(item) {
      var compParams = {};
      compParams = item;
      this.setParams(compParams);
      this.$router.push({ name: "CompAdminAnalytics" });
    },
    addThumbnail(item) {
      const uploader = this.$refs["uploader-" + item.quizCompId];
      if (uploader) {
        uploader.click();
      }
    },
    onFileChanged(e, item) {
      if (e.target && e.target.files && e.target.files.length > 0) {
        item.file = e.target.files[0];
        if (item.file) {
          item.preview = URL.createObjectURL(item.file);
        } else {
          item.preview = "";
        }
        // item.showUploadBtns = true;
        this.$set(item, "showUploadBtns", true);
        e.target.value = null;
      }
    },
    uploadThumbnail(item) {
      let payload = {};
      payload.file = item.file;
      payload.compId = item.quizCompId;
      let url = ADD_COMPETITION_THUMBNAIL;

      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.setOverlayValue(false);
          this.setApiSuccess("Thumbnail updated successfully");
          this.$emit("updateCompetition");
          item.file = "";
          item.preview = "";
          item.showUploadBtns = false;
          this.getAllCompetitions();
        })
        .catch((error) => {
          this.setOverlayValue(false);
          console.error("Error uploading thumbnail:", error);
        });
    },
    cancelUpload(item) {
      item.showUploadBtns = false;
      item.file = "";
      item.preview = "";
    },
    openImgDialog(item) {
      this.selectedItem = item;
      this.imgDialog = true;
    },
    openPublishDialog(item) {
      this.selectedCompetition = item;
      this.publishConfirmDialog = true;
    },
    closePublishDialog() {
      this.publishConfirmDialog = false;
      this.selectedCompetition = null;
    },
    publishCompetition() {
      const url = PUBLISH_COMPETETION;
      let payload = { quizCompId: this.selectedCompetition.quizCompId };
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDialog();
          this.getAllCompetitions();
          this.setOverlayValue(false);
          this.setApiSuccess("Competition published successfully");
        })
        .catch((error) => {
          console.error("Error publishing competition:", error);
          this.setOverlayValue(false);
          this.closePublishDialog();
        });
    },
    openEditDialog(competition) {
      this.editedComp = Object.assign({}, competition);
      this.getAllQuiz(true);
      this.selectedQuizComp = this.editedComp.quizes[0];

      // this.editedComp.startTime = formatDateTime(this.editedComp.startDate);
      // this.editedComp.endTime = formatDateTime(this.editedComp.endDate);
      this.editedComp.startTime = formatTime(this.editedComp.startDate);
      this.editedComp.endTime = formatTime(this.editedComp.endDate);
      this.editedComp.startDate = this.editedComp.startDate.substring(0, 10);
      this.editedComp.endDate = this.editedComp.endDate.substring(0, 10);
      this.editCompDialog = true;
    },
    closeEditComp() {
      // this.dialog = false;
      this.editCompDialog = false;
      this.$nextTick(() => {
        this.editedComp = Object.assign({}, this.defaultItem);
        this.selectedQuizComp = {};
        this.editedIndex = -1;
      });
    },
    saveEditedCompetition() {
      this.validate();
      if (this.createCompValidation.valid) {
        if (!this.editedComp.quizes) {
          this.editedComp.quizes = [];
        }
        this.editedComp.quizes[0] = this.selectedQuizComp;
        // this.editedComp.quizComp = this.selectedQuizComp;
        let payload = Object.assign({}, this.editedComp);
        let startDate = payload.startDate + " " + payload.startTime;
        startDate = new Date(startDate);
        let endDate = payload.endDate + " " + payload.endTime;
        endDate = new Date(endDate);
        payload.startDate = startDate.toISOString();
        payload.endDate = endDate.toISOString();
        delete payload.startTime;
        delete payload.endTime;
        let url = SAVE_COMPETETION_DETAILS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCompValidation.valid = false;
            this.$emit("updateComp");
            this.closeEditComp();
            this.setOverlayValue(false);
            this.getAllCompetitions();
            this.setApiSuccess("Competition updated successfully");
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    openDeleteDialog(item) {
      this.editedCompetition = item;
      this.deleteConfirmDialog = true;
    },
    closeDeleteDialog() {
      this.deleteConfirmDialog = false;
      this.editedCompetition = null;
    },
    deleteCompetition() {
      if (!this.editedCompetition) return;

      const url = DELETE_COMPETETION;
      const payload = { quizCompId: this.editedCompetition.quizCompId };

      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeDeleteDialog();
          this.editedCompetition = null;
          this.setApiSuccess("Competition deleted successfully");
          this.setOverlayValue(false);
          this.getAllCompetitions();
        })
        .catch((error) => {
          console.error(error);
          this.setOverlayValue(false);
          this.closeDeleteDialog();
        });
    },
    statusText(comp) {
      const start = new Date(comp.startDate);
      const end = new Date(comp.endDate);
      const currentDate = new Date();

      if (currentDate < start) {
        return "Upcoming";
      } else if (currentDate >= start && currentDate <= end) {
        return "Live";
      } else {
        return "Completed";
      }
    },
    statusColor(comp) {
      const start = new Date(comp.startDate);
      const end = new Date(comp.endDate);
      const currentDate = new Date();

      if (currentDate < start) {
        return "success-chip";
      } else if (currentDate >= start && currentDate <= end) {
        return "error-chip";
      } else {
        return "secondary-chip";
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    getAllCompetitions() {
      let url = GET_ALL_QUIZ_COMPETITIONS;
      if (!this.compStatusFilter.includes("ALL")) {
        if (this.compStatusFilter.length === 1) {
          url += "?status=" + this.compStatusFilter[0];
        }
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.filteredComps = res.data.quizCompetitions.map((comp) => ({
            ...comp,
            statusText: this.statusText(comp),
            statusColor: this.statusColor(comp),
            file: null,
            preview: null,
            showUploadBtns: false,
          }));
          this.setOverlayValue(false);
        })
        .catch((error) => {
          console.error("Error fetching competitions:", error);
          this.setOverlayValue(false);
        });
    },
    selectAllGrades() {
      return this.editedComp.grades.length === this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedComp.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedComp.grades = [];
        } else {
          this.editedComp.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },
    searchComp() {
      if (this.searchText) {
        this.filteredComps = this.filteredComps.filter((comp) => {
          return comp.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.getAllCompetitions();
      }
    },
    closeNewComp() {
      this.newCompDialog = false;
      this.$nextTick(() => {
        this.editedComp = Object.assign({}, this.defaultItem);
        this.selectedQuizComp = {};
        this.editedIndex = -1;
      });
    },
    trimEditedComp() {
      this.editedComp.name = this.editedComp.name.trim();
    },
    createCompetition() {
      this.validate();
      if (this.createCompValidation.valid) {
        this.trimEditedComp();
        if (!this.editedComp.quizes) {
          this.editedComp.quizes = [];
        }
        this.editedComp.quizes[0] = this.selectedQuizComp;
        // this.editedComp.quizComp = this.selectedQuizComp;
        let payload = Object.assign({}, this.editedComp);
        let startDate = payload.startDate + " " + payload.startTime;
        startDate = new Date(startDate);
        let endDate = payload.endDate + " " + payload.endTime;
        endDate = new Date(endDate);
        payload.startDate = startDate.toISOString();
        payload.endDate = endDate.toISOString();
        delete payload.startTime;
        delete payload.endTime;
        let url = CREATE_COMPETETION;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCompValidation.valid = false;
            this.closeNewComp();
            this.setOverlayValue(false);
            this.getAllCompetitions();
            this.setApiSuccess("Competition created successfully");
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    getAllQuiz(editComp) {
      let url = GET_ALL_QUIZ + "?status=PUBLISHED";
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.filteredQuiz = res.data.quizes;
          this.quizes = res.data.quizes;
          if (editComp) {
            this.filteredQuiz.push(this.editedComp.quizes[0]);
            // this.quizes.push(this.editedComp.quizes[0]);
          }
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    // getQuizNameById(quizId) {
    //   let q = this.quizes.filter((quiz) => {
    //     return quiz.quizId == quizId;
    //   });
    //   if (q.length && q[0].quizName) return q[0].quizName;
    //   return "No Quiz";
    // },
    searchQuiz() {
      if (this.searchTerm.length >= 3) {
        let url = SEARCH_QUIZ;
        let payload = {};
        payload.quizName = this.searchTerm;
        payload.status = "PUBLISHED";
        // this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.filteredQuiz = res.data.quizes;
            // this.quizes = res.data.quizes;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      } else {
        if (this.searchTerm.length == 0) {
          this.filteredQuiz = this.quizes;
        } else {
          this.filteredQuiz = this.quizes.filter((quiz) => {
            return (
              quiz.quizName
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) > -1
            );
          });
        }
      }
    },
  },
};
</script>
